import styled from 'styled-components';

export const ServiceWarp = styled.div`
    @media(min-width:750px){
        width:1200px;
        margin:10px auto;
        overflow:hidden;
        .header{
            width:100%;
            height:300px;
            overflow:hidden;
            display:block;
            .sw{
                width:100%;
                height:500px;
            }
            img{
                width:100%;
            }
        }
        .tab{
            width:100%;
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            margin:50px auto;
            .tab_div{
                width:320px;
                // margin:0 100px;
                img{
                    width:100%;
                }
            }
        }
        .open_time{
            width:100%;
            overflow:hidden;
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            flex-wrap:wrap;
            .open_time_sections{
                width:585px;
                height:340px;
                overflow:hidden;
                flex-direction:column;
                background-position:center center;
                background-size:cover;
                background-repeat:no-repeat;
                margin-bottom:28px;
                overflow:hidden;
                border-radius:15px;
                .open_time_sections_mask{
                    width:100%;
                    height:100%;
                    display:flex;
                    align-items:center;
                    flex-direction:column;
                    overflow:hidden;
                    .name{
                        padding:12px 40px;
                        background:#fff;
                        border-radius:3px;
                        font-size:34px;
                        line-height:44px;
                        margin-bottom:20px;
                        margin-top:70px;
                        text-align:center;
                    }
                    .sec{
                        font-size:20px;
                        line-height:34px;
                        color:#fff;
                        font-weight:bold;
                    }
                }
            }
        }
        .traffic_route{
            width:1200px;
            height:400px;
            display:flex;
            border-radius:10px;
            background:#eeeeee;
            overflow:hidden;
            margin:22px 0 50px;
            .traffic_route_map{
                width:600px;
                height:400px;
                position:relative;
                .map_link{
                    width:100%;
                    height:100%;
                    display: block;
                    position: absolute;
                    z-index:2;
                }
                iframe{
                    width:100%;
                    height:100%;
                    display:block;
                    border:none;
                }
            }
            .traffic_route_content{
                width:600px;
                height:400px;    
                padding:20px;
                box-sizing:border-box;            
            }
        }
    }
    @media(max-width:750px){
        display: none;
    }
`

export const ServiceWarpM = styled.div`
    @media(max-width:750px){
        width:100%;
        overflow:hidden;
        .m_title{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:.2rem 0;
            text-indent:0;
            .i_small2{
                        width:.16rem;
                        height:.16rem;
                        background:#f55c5d;
            }
            .i_large2{
                        width:.3rem;
                        height:.3rem;
                        background:#00a1b4;   
                        margin-right:.07rem;             
            }            
            span{
                        padding:.1rem .4rem;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 .16rem 0 0;
                        box-sizing:border-box;
            }
        }
        .header{
            width:100%;
            height:2rem;
            overflow:hidden;
            display:block;
            .sw{
                width:100%;
                height:4rem;
            }
            img{
                width:100%;
            }
        }
        .tab{
            width:7.1rem;
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            margin:.34rem auto;
            .tab_div{
                width:2.2rem;
                height:.97rem;
                img{
                    width:100%;
                }
            }
        }
        .open_time{
            width:7.1rem;
            margin:.5rem auto;
            overflow:hidden;
            .open_time_sections{
                width:7.1rem;
                height:5rem;
                overflow:hidden;
                flex-direction:column;
                background-position:center center;
                background-size:cover;
                background-repeat:no-repeat;
                margin-top:28px;
                overflow:hidden;
                border-radius:15px;
                .open_time_sections_mask{
                    width:100%;
                    height:100%;
                    display:flex;
                    align-items:center;
                    flex-direction:column;
                    overflow:hidden;
                    .name{
                        padding:12px 43px;
                        background:#fff;
                        border-radius:3px;
                        font-size:.34rem;
                        line-height:.54rem;
                        margin-bottom:20px;
                        margin-top:.7rem;
                        text-align:center;
                    }
                    .sec{
                        font-size:.25rem;
                        line-height:34px;
                        color:#fff;
                        font-weight:bold;
                    }
                }
            }
        }
        .traffic_route{
            width:100%;
            overflow:hidden;
            .traffic_title{
                width:7.1rem;
                overflow:hidden;
                margin:0 auto;                
            }
            .traffic_route_map{
                width:100%;
                height:5.7rem;
                margin-top:.25rem;
                position:relative;
                .map_link{
                    width:100%;
                    height:100%;
                    display: block;
                    position: absolute;
                    z-index:2;
                }
                iframe{
                    width:100%;
                    height:100%;
                    display:block;
                    border:none;
                }
            }
            .traffic_route_content{
                width:7.1rem;
                overflow:hidden;
                margin:0 auto;
                padding:.2rem;
                box-sizing:border-box;  
            }
        }
    }
    @media(min-width:750px){
        display: none;
    }
`
