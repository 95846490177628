import React from 'react'
import styles from '../newslist.module.scss'
import { Menu } from 'antd';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import qs from 'query-string'
import { withRouter } from 'react-router-dom';
import PubSub from 'pubsub-js';
class MenuList extends React.Component {
  state = {
    menuList: []
  }
  componentDidMount() {
    React.$request({
      url: 'Portal/zt/termArticles',
      method: 'get',
      params: {
        termId: 962
      }
    }).then(res => {
      this.setState({ menuList: res.data.son })
    })
  }
  openMenu(typeId, termId) {
    PubSub.publish('termId', [termId, typeId])

  }
  render() {
    const { menuList } = this.state
    const { search } = this.props.location
    const { termId } = qs.parse(search)
    const openKey = ['459', '460', '461', '560'].includes(termId) ? '458' : termId
    return (
      <>
        <div className={styles.menuList}>
          <div className='columNameBox'>资讯信息</div>
          <Menu mode="inline" defaultSelectedKeys={[`${openKey}`]}>
            {
              menuList.filter(item => !['462', '466'].includes(item.term_id)).map(el => {
                return <Menu.Item key={el.term_id} icon={<AppstoreOutlined />} onClick={() => this.openMenu(el.post_keywords, el.term_id)}>
                  {el.name}
                </Menu.Item>
              })
            }
          </Menu>
        </div>
        <div className={styles.menuListYd}>
          {
            menuList.filter(item => !['462', '560', '466'].includes(item.term_id)).map(el => {
              return el.son.length > 0 ?
                el.son.filter(item => item.term_id != 560).map(al => {
                  return <div className='menuItem' onClick={() => this.openMenu(al.post_keywords, al.term_id)}>{al.name}</div>
                }) : <div className='menuItem' onClick={() => this.openMenu(el.post_keywords, el.term_id)}>{el.name}</div>
            })
          }

          {/* <div className='menuItem'>新闻资讯</div>
          <div className='menuItem'>通知公告</div>
          <div className='menuItem'>北京科学中心体系</div>
          <div className='menuItem'>3D游场馆</div>
          <div className='menuItem'>组织架构</div>
          <div className='menuItem'>馆长致辞</div> */}
        </div>
      </>

    )
  }
}
export default withRouter(MenuList)
