import React, { memo, useEffect, useState } from 'react'
import { VideoWrap } from './style'
const VideoList = memo((props) => {
  const { term_id = null, type = null, name = '' } = props
  const [list, setList] = useState([])
  const [curId, setCurId] = useState(0)
  const [curVideo, setCurVideo] = useState({
    video: "",
    path: ''
  })
  useEffect(() => {
    React.$request({
      url: 'Portal/Index/is_ajax_list',
      method: 'get',
      params: {
        termid: term_id,
        type: type,
        size: 10,
      }
    }).then(res => {
      setList(res.data)
      setCurId(res.data[0].id)
      setCurVideo({
        video: res.data[0].video,
        path: res.data[0].path
      })
    })
  }, [term_id])
  const changeVideo = (video, path, id) => {
    setCurId(id)
    setCurVideo({
      video,
      path
    })
  }
  return (
    <VideoWrap>
      <div className='title_type_4'>
        <i className='i_small'></i>
        <i className='i_large'></i>
        <span>{props.name}</span>
        <i className='i_large2'></i>
        <i className='i_small2'></i>
      </div>
      {
        list.length &&
        <div className="hdhx">
          <div className="hdhx_wrap">
            <div className="video">
              <video src={curVideo.video} controls='controls' poster={curVideo.path}></video>
            </div>
            <div className="hdxh_list">
              {
                list.map((item) => {
                  return (
                    <div className={item.id === curId ? 'hdhx_link hdhx_link_active' : 'hdhx_link'} key={item.id} onClick={() => changeVideo(item.video, item.path, item.id)}>
                      {
                        parseInt(item.istop, 10) > 0 ?
                          <span className='zd'>
                            <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zda.png`} alt="" />
                          </span> : null
                      }
                      {item.post_title}
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      }
    </VideoWrap>
  )
})

export default VideoList