import styled from 'styled-components'

export const ExhibitionIndexWrap = styled.div`
    @media(min-width:750px){
        width:100%;
        overflow:hidden;
        .sw{
            width:1200px;
            height:320px;
            overflow:hidden;
            border-radius:10px;
            margin:10px auto;
        }
        .swM{
            display: none;
        }
        .kjzq{
            width:1200px;
            height:385px;
            display:flex;
            overflow:hidden;
            flex-direction:row;
            margin:62px auto 90px;
            .kjzqsw{
                width:650px;
                height:385px;
                border-radius:12px;
                overflow:hidden;
                margin:0 auto;
            }
            .kjzp_p{
                font-size: 18px;
                line-height: 28px;
                color: #4d4d4d;
                flex:1;
                padding:15px 45px;
                box-sizing:border-box;

            }
        }
        .title_type_1{
            height:32px;
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            text-align:center;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:60px auto 30px;
            text-align:center;
            justify-content:center;
            .i_small{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-left:7px;             
            }
            .i_small2{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large2{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-right:7px;             
            }            
            span{
                padding:10px 40px;
                background:#00a1b4;
                border-radius:5px;
                margin:0 16px;
            }
        }
        .nav_title>.title_type_1:hover{
            color:orange!important;
        }
        .nav_title>.title_type_2:hover{
            color:orange!important;
        }
        .nav_title2>.title_type_2:hover{
            color:#fff;
        }
        .title_type_2{
            height:32px;
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            text-align:center;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:60px auto 30px;
            text-align:center;
            justify-content:center;
            .i_small{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-left:7px;             
            }
            .i_small2{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large2{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-right:7px;             
            }            
            span{
                padding:10px 40px;
                background:#00a1b4;
                border-radius:5px;
                margin:0 16px;
            }         
        }
        .kjzp_p_m{
            display: none;
        }
    }
    @media(max-width:750px){
        width:100%;
        overflow:hidden;
        .sw{
            display: none;
        }
        .swM{
            width:100%;
            height:2.3rem;
        }
        .kjzq{
            width:7.1rem;
            overflow:hidden;
            margin:0 auto;
            .kjzqsw{
                width:7.1rem;
                height:4.2rem;
                border-radius:.12rem;
                overflow:hidden;
                margin:0 auto .2rem;
            }
            .kjzp_p{
                font-size:.3rem;
                line-height:.45rem;
                text-align:justify;
                display: none;
            }
            .kjzp_p_m{
                font-size:.3rem;
                line-height:.45rem;
                text-align:justify;     
                .more{
                    color:#00a1b5
                }       
            }
        }
        .title_type_1{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:.4rem 0;
            text-indent:0;
            .i_small2{
                        width:.16rem;
                        height:.16rem;
                        background:#f55c5d;
            }
            .i_large2{
                        width:.3rem;
                        height:.3rem;
                        background:#00a1b4;   
                        margin-right:.07rem;             
            }            
            span{
                        padding:.1rem .4rem;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 .16rem 0 0.2rem;
                        box-sizing:border-box;
            }
        }
        .title_type_2{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:.4rem auto;
            text-indent:0.2rem;
            .i_small2{
                        width:.16rem;
                        height:.16rem;
                        background:#f55c5d;
            }
            .i_large2{
                        width:.3rem;
                        height:.3rem;
                        background:#00a1b4;   
                        margin-right:.07rem;             
            }            
            span{
                        padding:.1rem .4rem;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 .16rem 0 0.2rem;
                        box-sizing:border-box;
            }   
        }
    }
`
