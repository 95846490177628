import styled from 'styled-components'

export const ListWrap = styled.div`
  @media (min-width: 750px) {
    width: 1200px;
    margin: 20px auto;
    .hdbd_wrap {
      width: 1200px;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
       
      /** 20210526 taoZi add start **/      
      .empty{
          margin:100px auto;
      }
      /** 20210526 taoZi add end **/
      .hdbd_section {
        width: 541px;
        margin: 10px;
        height: 170px;
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .hdbd_section_img_wrap {
          width: 270px;
          height: 170px;
          border-radius: 7px;
          overflow: hidden;

          img {
            width: 100%;
            min-height: 100%;
          }
        }
        .hdbd_section_detail {
          width: 252px;
          height: 170px;
          display: flex;
          text-align: left;
          flex-direction: column;
          justify-content: space-between;

          .hdbd_section_detail_title {
            font-size: 22px;
            color: #221815;
            line-height: 30px;
            width: 100%;
          }

          .hdbd_section_date {
            width: 100%;
            color: #009cbe;
            font-size: 15px;
          }
        }
      }
    }
    .title {
      font-size: 20px;
    }
    .tabWrap {
      width: 1200px;
      height: 40px;
      margin: 0 auto 0;
      display: flex;
      flex-direction: row;
      text-align: center;
      .tab_label {
        padding: 0 20px;
        height: 35px;
        font-size: 18px;
        line-height: 35px;
        border: 1px solid #009cbe;
        color: #009cbe;
        cursor: pointer;
        margin-right: 10px;
      }
      .tab_label_active {
        border: 1px solid #fff;
        color: #fff;
        background: #009cbe;
      }
    }
    .pagenation {
      margin: 60px auto 80px;
      text-align: center;
      .ant-pagination-item {
      }
      .ant-pagination-item-active {
        border-radius: 0;
        color: #fff;
        background: #009cbe;
        a {
          color: #fff;
        }
      }
    }
  }
  @media (max-width: 750px) {
    width: 7.1rem;
    margin: 0.2rem auto;
    .hdbd_section {
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0.27rem 0;
      border-bottom: 0.01rem dashed #a0a0a0;
      .hdbd_section_img_wrap {
        width: 2.45rem;
        height: 1.5rem;
        border-radius: 0.1rem;
        overflow: hidden;

        img {
          width: 100%;
          min-height: 100%;
        }
      }

      .hdbd_section_detail {
        flex: 1;
        margin-left: 0.1rem;
        display: flex;
        align-items: center;
        text-align: left;
        flex-direction: column;
        height: 1.5rem;
        justify-content: space-between;

        .hdbd_section_detail_title {
          font-size: 0.29rem;
          color: #000;
          line-height: 0.4rem;
          width: 100%;
        }

        .hdbd_section_date {
          width: 100%;
          text-align: left;
          color: #00a1b4;
          font-size: 0.28rem;
        }
      }
    }
    .title {
      font-size: 0.3rem;
    }
    .tabWrap {
      width: 100%;
      // height: 40px;
      margin: 0 auto 0;
      display: flex;
      flex-direction: row;
      text-align: center;
      flex-wrap: wrap;
      .tab_label {
        padding: 0 20px;
        height: 35px;
        font-size: 18px;
        line-height: 35px;
        border: 1px solid #009cbe;
        color: #009cbe;
        cursor: pointer;
        margin-right: 10px;
        margin-bottom: 10px;
      }
      .tab_label_active {
        border: 1px solid #fff;
        color: #fff;
        background: #009cbe;
      }
    }
    .pagenation {
      margin: 60px auto 80px;
      text-align: center;
      .ant-pagination-item {
      }
      .ant-pagination-item-active {
        border-radius: 0;
        color: #fff;
        background: #009cbe;
        a {
          color: #fff;
        }
      }
    }
  }
`

export const KxjtList = styled.div`
  @media (min-width: 750px) {
    width: 1000px;
    margin: 0 auto;
    .hdbd_section {
      width: 100%;
    }
    .pagenation {
      text-align: center;
      margin: 30px auto;
    }
  }
  @media (max-width: 750px) {
    width: 7rem;
    margin: 0 auto;
    .hdbd_section {
      width: 100%;
    }
    .pagenation {
      text-align: center;
      margin: 30px auto;
    }
  }
`
