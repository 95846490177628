import styled from 'styled-components';

export const Kxzx = styled.div `
    width:100%;
    overflow:hidden;
    @media(max-width:750px){
        /* padding-top:1.37rem; */
        /* padding-top: 3.3rem; */
        /** 20210517 taoZi 去掉，按照要求，顶部不需要固定 **/
        /* padding-top: 4.67rem; */
    }
    @media(min-width:750px){
        min-width:1240px;
    }
`
export const KxzxContent = styled.div `
    width:100%;
    overflow:hidden;
    /* background-color:red; */
    @media(max-width){
        /* padding-top:1.37rem; */
        padding-top: 4.26rem;
    }

`
