/*
 * @Author: your name
 * @Date: 2020-07-20 09:35:57
 * @LastEditTime: 2020-07-27 14:03:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \kxzxweb\src\common\sdkxjtSec\style.js
 */
import styled from 'styled-components'

export const SectionWrap = styled.div `
  @media (min-width: 750px) {
    width: 541px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .icon {
      width: 40px;
      img {
        width: 100%;
      }
      svg {
        width: 70%;
      }
    }
    .hdbd_section_img_wrap {
      width: 270px;
      height: 170px;
      border-radius: 7px;
      overflow: hidden;
      svg {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }
    .hdbd_section_detail {
      width: 100%;
      display: flex;
      text-align: left;
      flex-direction: column;
      margin-left: 10px;
      .hdbd_section_detail_title {
        font-size: 22px;
        color: #666666;
        width: 100%;
        line-height: 30px;
        span{
          width:22px;
          margin-right:5px;
          img{
            width:22px;
          }
        }
      }
      .hdbd_section_date {
        width: 100%;
        color: #656565;
        font-size: 17px;
        line-height: 24px;
        margin-top: 15px;
      }
    }
  }

  @media (max-width: 750px) {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
    padding: 0.27rem 0;
    border-bottom: 0.01rem dashed #a0a0a0;
    .icon {
      width: 0.4rem;
      img {
        width: 100%;
      }
      svg {
        width: 70%;
      }
    }
    .hdbd_section_img_wrap {
      width: 2.45rem;
      height: 1.5rem;
      border-radius: 0.1rem;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .hdbd_section_detail {
      flex: 1;
      margin-left: 0.1rem;
      display: flex;
      align-items: center;
      text-align: left;
      flex-direction: column;
      height: 1.5rem;
      justify-content: space-between;

      .hdbd_section_detail_title {
        font-size: 0.29rem;
        color: #000;
        line-height: 0.4rem;
        width: 100%;
      }

      .hdbd_section_date {
        width: 100%;
        text-align: left;
        color: #00a1b4;
        font-size: 0.28rem;
      }
    }
  }
`