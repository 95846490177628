import React from 'react'
import { TalentPlanWrap } from './style'
import CommonCarousel from 'common/carousel.js'
import SectionWrap from 'common/newSection'
import { NavLink } from 'react-router-dom'
import NavSection from 'common/navSection'
import Msection from 'common/Msection'
import {Empty, LocaleProvider, Modal, Pagination, Skeleton} from 'antd'
import zhCN from "antd/es/locale-provider/zh_CN";
import {KxcbWrap} from "../education/components/qshd/style";
import qs from 'query-string'
class TalentPlan extends React.Component {
    state={
        classifyList : null,
        courseList : [],
        chapterList : [],
        classifyIndex : 0,
        courseIndex : '',
        chapterIndex : '',
        courseMapList : {},
        chapterMapList : {},
        talentList:null,
        allCount:0,
        currentPage:1,
        Mlist:null,
        Mpage:null,
          currentVideo: null,
          currentVideoName: null,
          poster: null,
          currentId: null,
          routerLink:{
  
          }
      }
  
  componentDidMount() {
    document.title = '英才学堂'
    this.initTalentCondition()
  }
  initTalentCondition() {
    React.$request({
        url:'Portal/Index/talent_condition',
        method:'get'
    }).then(res=>{
        const classList = res.data.classList || []
        classList.unshift({term_id:320,name:'全部'})
        this.setState({
            classifyList: classList,
        })
        let otherArr = res.data.classOtherList || [],
            len = otherArr.length,
            courseMapList = {},
            chapterMapList = {}

            for (let i = 0; i < len; i++) {
                let other = otherArr[i]
                if(courseMapList[other.term_id]){
                    if(courseMapList[other.term_id].indexOf(other.post_keywords) ===-1) {
                        courseMapList[other.term_id].push(other.post_keywords)
                    }
                }else{
                    courseMapList[other.term_id] = [other.post_keywords]
                }
                if(chapterMapList[other.term_id]){
                    if(chapterMapList[other.term_id].indexOf(other.post_source) ===-1){
                        chapterMapList[other.term_id].push(other.post_source)
                    }
                }else{
                    chapterMapList[other.term_id] = [other.post_source]
                }
            }

            this.setState({
                courseMapList: courseMapList,
                chapterMapList: chapterMapList
            })

            let courseList = this.state.courseMapList[this.state.classifyList[0].term_id] || [],
                chapterList = this.state.chapterMapList[this.state.classifyList[0].term_id] || []
            courseList.sort()
            this.sortArray(chapterList)
            this.setState({
                courseList: courseList,
                chapterList: chapterList
            })
            this.initTalent(1)
    })
  }
  sortArray(arr){
      let numberArr = ['一','二','三','四','五','六','七','八','九','十']
      arr.sort((a, b)=>{
          if(a.length === 3 && b.length === 3){
              return  numberArr.indexOf(a.charAt(1)) -  numberArr.indexOf(b.charAt(1))
          }
          if(a.length > 3 && b.length === 3){
              return 1
          }
          if(a.length === 3 && b.length >3 ){
              return -1
          }
          return 0
      })
  }
  changeClassify(index){
      this.setState({
          classifyIndex: index,
      })

      let courseList = this.state.courseMapList[this.state.classifyList[index].term_id] || [],
          chapterList = this.state.chapterMapList[this.state.classifyList[index].term_id] || []
      courseList.sort()
      this.sortArray(chapterList)
      this.setState({
          courseIndex : '',
          chapterIndex : '',
          courseList: courseList,
          chapterList: chapterList,
      })
      this.initTalent(1,'','',index)
  }

    courseChange(index){
      this.setState({
          courseIndex: index,
      })
        console.log('courseChange__>',index)
        console.log('courseList>',this.state.courseList)

        this.initTalent(1,index,this.state.chapterIndex)

    }
    chapterChange(index){
      this.setState({
          chapterIndex: index,
      })
        this.initTalent(1,this.state.courseIndex,index)
    }
    initTalent(pageNumber,courseIndex = this.state.courseIndex,chapterIndex=this.state.chapterIndex,classifyIndex = this.state.classifyIndex) {
        this.setState({
            talentList:null,
            currentPage:pageNumber
        })

        let post_keywords = this.state.courseList[courseIndex] || '',
            post_source = this.state.chapterList[chapterIndex] || '',
            term_id = this.state.classifyList[classifyIndex].term_id
        React.$request({
            url:'Portal/Index/talent',
            method:'get',
            params: {
                post_keywords: post_keywords,
                post_source: post_source,
                term_id: term_id,
                p: pageNumber,
                size: 8
            }
        }).then(res=>{
            this.setState({
                talentList: res.data.list || [],
                allCount:parseInt(res.data.count),
                Mlist:res.data.list || [],
                Mpage:Math.ceil(parseInt(res.data.count)/8)
            })
        })
    }
    onChange(pageNumber) {
        this.initTalent(pageNumber)
    }
    changeVideo(item) {
        this.setState({
            currentVideo: item.video,
            currentVideoName: item.post_title,
            currentId: item.id,
            poster: item.path,
            visible:true
        })
    }
    setVisible(flag){
        this.setState({
            visible:flag
        })
    }
    loadMore(){
        const _page = this.state.currentPage+1
        let courseIndex = this.state.courseIndex,
            chapterIndex=this.state.chapterIndex,
            classifyIndex = this.state.classifyIndex,
            post_keywords = this.state.courseList[courseIndex] || '',
            post_source = this.state.chapterList[chapterIndex] || '',
            term_id = this.state.classifyList[classifyIndex].term_id
        React.$request({
            url:'Portal/Index/talent',
            method:'get',
            params: {
                post_keywords: post_keywords,
                post_source: post_source,
                term_id: term_id,
                p: _page,
                size: 8
            }
        }).then(res=>{
            const _list = this.state.Mlist
            this.setState({
                Mlist:_list.concat(res.data.list)
            })
        })
        this.setState({
            currentPage:_page
        })
    }
   
  render() {
    const fromUrl=()=>{
        let _paths = window.location.href
        let index =_paths.lastIndexOf('=')
        let yc=_paths.substring(index+1,_paths.length)
        return yc
    }
    return this.state.classifyList ? (
      <TalentPlanWrap>
        <NavSection yc={fromUrl()}/>
        <div className="header">
            <div className="condition-item">
                <div className="condition-item-label">学科：</div>
                <div className="condition-item-values">
                    {
                        this.state.classifyList.map((item,index)=>{
                            return (
                                <div key={index} onClick={()=>this.changeClassify(index)} className={index===this.state.classifyIndex?'condition-item-value on':'condition-item-value'}>{item.name}</div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="condition-item">
                <div className="condition-item-label">课程：</div>
                <div className="condition-item-values">
                    <div onClick={()=>this.courseChange('')} className={this.state.courseIndex===''?'condition-item-value on':'condition-item-value'}>全部</div>
                    {
                        this.state.courseList.map((item,index)=>{
                            return (
                                <div key={index} onClick={()=>this.courseChange(index)} className={index===this.state.courseIndex?'condition-item-value on':'condition-item-value'}>{item}</div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="condition-item">
                <div className="condition-item-label">章节：</div>
                <div className="condition-item-values">
                    <div onClick={()=>this.chapterChange('')} className={this.state.chapterIndex===''?'condition-item-value on':'condition-item-value'}>全部</div>
                    {
                        this.state.chapterList.map((item,index)=>{
                            return (
                                <div key={index} onClick={()=>this.chapterChange(index)} className={index===this.state.chapterIndex?'condition-item-value on':'condition-item-value'}>{item}</div>
                            )
                        })
                    }
                </div>
            </div>
        </div>

         <div className="paginationWrap">
             <div className="other_section_wrap">
                 {
                     !this.state.talentList?<Skeleton active />:
                         ((this.state.talentList.length > 0 )?this.state.talentList.map((item)=>{
                         return(
                             <div key={item.id} className='ycjh_section' onClick={() => this.changeVideo(item)}>
                                 <div className="ycjh_section_img_wrap">
                                     <img src={item.path?item.path:`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/header/logodemo_02.jpg`} alt=""/>
                                 </div>
                                 <div className="ycjh_section_detail">
                                     <div className="ycjh_section_detail_title">{item.post_title}</div>
                                     <div className='ycjh_section_date'>{item.post_date}</div>
                                 </div>
                             </div>
                         )
                     }):<Empty className='empty'/>)
                 }
                 {
                     (this.state.talentList && this.state.talentList.length > 0 )?
                         <LocaleProvider locale={zhCN}>
                             <Pagination  size="small" defaultCurrent={this.state.currentPage} total={this.state.allCount} onChange={this.onChange.bind(this)} pageSize={8} showQuickJumper className='pagenation'/>
                         </LocaleProvider>:null
                 }
             </div>
         </div>

          <div className="paginationWrapM">
              <div className="paginationWrapM_list">
                  {
                      (this.state.Mlist && this.state.Mlist.length > 0 )?this.state.Mlist.map((item,index)=>{
                          return(
                              <div key={index} className='ycjh_section' onClick={() => this.changeVideo(item)} >
                                  <div className="ycjh_section_img_wrap" >
                                      <img src={item.path?item.path:`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/header/logodemo_02.jpg`} alt=""/>
                                  </div>
                                  <div className="ycjh_section_detail">
                                      <div className="ycjh_section_detail_title">{item.post_title}</div>
                                      <div className='ycjh_section_date'>{item.post_date}</div>
                                  </div>
                              </div>
                          )
                      }):<Empty className='empty'/>
                  }

                  {
                      this.state.currentPage < this.state.Mpage?
                          <div className="see_more" onClick={()=>this.loadMore(this)}>
                              查看更多
                          </div>:null
                  }
              </div>
          </div>
          <Modal
              getContainer={false}
              visible={this.state.visible}
              title={this.state.currentVideoName} width={800} footer={null} onCancel={() => this.setVisible(false)}>
              <div className="video">
                  {
                      <video src={this.state.currentVideo} controls='controls' poster={this.state.poster}></video>
                  }
              </div>
          </Modal>
      </TalentPlanWrap>
    ) : (
      <Skeleton active />
    )
  }
}
export default TalentPlan
