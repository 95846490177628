import styled from 'styled-components';


// const red = '#74c0f2'
// 202107
// const red = '#B52619'
// 20210911
// const red = '#2f3ed6'
// 20210919
// const red = '#df9937'
// 20210923
// const red = '#f8c53f'
// 20211108
// const red = '#e0e0ef'
// 20220101
// const red = '#f3a978'
// 20220111
// const red = '#237db2'
// 20220322
// const red = '#50a154'
// 20220520
// const red = '#336464'
// 20220610
// const red = '#1b5c3c'
// 20220810 秋季
//const red = '#ffa440'
// 20221104 冬季
// const red ='#7a0202'
// 20220930 国庆
// const red = '#aa1700'
// 20220130
// const red = '#a12c2b'
// 20210930
// const red = '#ba2320'
// 换肤-页脚-背景色
const red ='#2e7faa '
export const FooterWrap = styled.div `
    @media(min-width:750px){
        width:100%;
        height:365px;
        /* background:#00a1b4; */
        background:${red};
        /* background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/202151/footer_02.png) center top no-repeat; */
        position: relative;
        .code-list {
            display: none;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 320px;
            z-index: 99;
            img {
                width: 100%;
                height: auto;
            }
        }
        .jzmooter{
            display:none;
            width:100vw;
            height:100vh;
            background:rgba(0,0,0,.5);
            position:fixed;
            top:0;
            left:0;
            z-index:2000000000;
            .conBox{
                margin:150px auto;
                width: 95%;
                background: #fff;
                padding: 30px;
                box-sizing: border-box;
                .closeBtn{
                    width:40px;
                    float:right;
                    margin-bottom:10px;
                    cursor: pointer;
                }
            }
            img{
                width:100%;
                // margin:0 auto;
                display:block;
                height:auto;
            }
        }
        .footer{
            width:100%;
            margin:0px auto;
            padding: 60px 0;
            box-sizing:border-box;
            .line{
                width:100%;
                height:1px;
                background:rgba(255,255,255,0.5);
            }
            .footer_top{
                width:1240px;
                height:242px;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                margin:0 auto;
                .footer_top_left{
                    display:flex;
                    flex-direction:row;
                    justify-content:flex-start;
                    .footer_meun{
                        margin-right:100px;
                        .footer_menu_title{
                            text-indent:14px;
                            font-size:21px;
                            color:#fff;
                            font-weight:bold;
                            background:url('${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/footer/footer_03.png') left center no-repeat;
                        }
                        .footer_menu_list{
                            margin-left:14px;
                            margin-top:11px;
                            color:#fff;
                            font-size:15px;
                            line-height:35px;
                            color:#fff;
                            .footer_link{
                                display: block;
                                color:#fff;
                            }
                        }
                    }
                }
                .footer_top_right{
                    display:flex;
                    flex-direction:row;
                    .ir_img{
                        cursor: pointer;
                        width:135px;
                        display:flex;
                        flex-direction:column;
                        // margin-left:15px;
                        margin-right: 0px;
                        align-items: center;
                        img{
                            width:120px;
                            height:120px;
                        }
                        span{
                            color:#fff;
                            font-size:15px;
                            text-align:center;
                            line-height: 20px;
                            padding-top: 10px;
                        }
                    }
                }
            }
            .footer_bot{
                width:1240px;
                height:50px;
                line-height:50px;
                display:flex;
                flex-direction:row;
                justify-content:center;
                color:#fff;
                font-size:12px;
                align-items:center;
                margin:0 auto;
                span{
                    margin:0 15px;
                }
                a{
                    color:#fff;  
                    margin:0 15px;
                }
            }
        }
    }
    @media(max-width:750px){
        width:100%;
        background:${red};
        margin-bottom:1.2rem;
        position: relative;
        .code-list {
            display: none;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 4.2rem;
            z-index: 99;
            img {
                width: 100%;
                height: auto;
            }
        }
        .jzmooter{
            display:none!important;
        }
        .footer{
            width:100%;
            overflow:hidden;
            padding:0 .34rem;
            box-sizing:border-box;
            .footer_top{
                width:100%;
                height:2.62rem;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                align-items:center;
                .footer_top_left{
                    width:3.8rem;
                    overflow:hidden;
                    .footer_meun:nth-of-type(1){
                        display: none;
                    }
                    .footer_meun:nth-of-type(2){
                        display: none;
                    }
                    .footer_meun{
                        .footer_menu_title{
                            display: none;
                        }
                        .footer_menu_list{
                            color:#fff;
                            font-size:.22rem;
                            line-height:.32rem;
                            color:#fff;
                        }
                    }
                }
                .footer_top_right{
                    width:2.95rem;
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    .ir_img{
                        display:flex;
                        flex-direction:column;
                        justify-content:flex-start;
                        align-items:center;
                        height:2.3rem;
                        img{
                            width:1.35rem;
                        }
                        span{
                            width:100%;
                            color:#fff;
                            font-size:.22rem;
                            text-align:center;
                            line-height:.3rem;
                            margin-top:.1rem;
                        }
                    }
                }
            }
            .line{
                width:100%;
                height:1px;
                background:rgba(255,255,255,0.5);
            }
            .footer_bot{
                width:100%;
                line-height:.4rem;
                justify-content:center;
                color:#fff;
                font-size:.22rem;
                padding-bottom:.5rem;
                padding-top:.5rem;

                span{
                    margin:0 0;
                    display: block;
                }
                a{
                    color:#fff;  
                }
            }
        }
    }
`
