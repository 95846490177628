/*
 * @Author: your name
 * @Date: 2020-03-19 10:54:01
 * @LastEditTime: 2020-07-23 17:51:24
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \kxzxweb\src\pages\article\index.js
 */

import React from 'react'
import { Ldetail } from './style'
import { Empty, Skeleton } from 'antd'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import qs from 'query-string'

const _article = ''

class Preview extends React.Component {
  render() {
    
    return (
      <Ldetail>
        {this.state.detailLoading?<Skeleton active />:
        (this.state.article.post_title ? (
          <div>
            <h2>{this.state.article.post_title}</h2>
            <p className="l_d_time">
              {this.state.article.post_date}<br/>
              {
                this.state.article.post_source?(
                    <span className="l_d_time">来源：{this.state.article.post_source}</span>
                ):null
              }
            </p>
            {
              this.state.article.post_content &&
              <>
                {
                  parseInt(this.props.match.params.id) === 3230 ?
                    <p
                      dangerouslySetInnerHTML={{
                        __html: _article,
                      }}
                      className="l_d_content"
                    ></p>
                    : <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.article.post_content,
                      }}
                      className="l_d_content"
                    ></p>
                }
              </>
            }
            <div onClick={() => this.goBack()} className="goBack">
              返回
            </div>
          </div>
        ) : (
          <Empty className='empty'/>
          )
        )
      }
      </Ldetail>
    )
  }
  constructor(props) {
    super(props)
    this.state = {
      article: {},
      catName: [],
      historyPath: '',
      detailLoading: true
    }
  }
  fromUrl=()=>{
    const { search } = this.props.location
    const { yc } = qs.parse(search)
    console.log('======================')
    console.log(yc)
    return yc
  }
  goBack() {
    // if(this.props.history.length > 1){
    //     this.props.history.goBack()
    // }else{
    //     window.location.href = "/";
    // }
    const articlePage= localStorage.getItem('article_'+this.props.match.params.id)
    const tzggPage= localStorage.getItem('article_tzgg_'+this.props.match.params.id)

    if(articlePage){
      localStorage.setItem('NOW_PAGE',articlePage)
    }else if(tzggPage){
      const labelId= localStorage.getItem('article_labelId_'+this.props.match.params.id)
      const labelIndex= localStorage.getItem('article_labelIndex_'+this.props.match.params.id)
      localStorage.setItem('NOW_TZGG_PAGE',tzggPage)
      localStorage.setItem('NOW_LABEL_ID',labelId)
      localStorage.setItem('NOW_LABEL_INDEX',labelIndex)
    }
    this.props.history.push(this.state.historyPath)
  }
  componentWillUnmount(){
    localStorage.removeItem('article_'+this.props.match.params.id)
    localStorage.removeItem('article_tzgg_'+this.props.match.params.id)
    localStorage.removeItem('article_labelId_'+this.props.match.params.id)
    localStorage.removeItem('article_labelIndex_'+this.props.match.params.id)
  }
  componentWillReceiveProps(nextProps){
    this.setState({
      historyPath: this.props.historyPath,
      detailLoading: true,
    })
    this.goTo()
    if (nextProps.match.params.id) {
      React.$request({
        url:'Portal/Index/post_detail',
        method:'get',
        params: {
          id: nextProps.match.params.id
        }
      }).then(res=>{
        document.title = res.data.post_title
        this.setState({
          article: res.data,
          catName: res.cat_name,
          detailLoading: false
        })
      })
      React.$request({
        url:'Portal/Index/storeArticleClicks',
        method:'get',
        params: {
          id: nextProps.match.params.id
        }
      }).then(res=>{
        console.log(res)
      })
    }
  }
  goTo() {
    let scrollToTop = window.setInterval(function () {
      let pos = window.pageYOffset
      if (pos > 0) {
        window.scrollTo(0, pos - 10000) // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop)
      }
    }, 2)
  }

}
const mapStateToProps = (state) => {
  return {
    historyPath: state.getIn(['history', 'historyPath']),
  }
}

export default withRouter(connect(mapStateToProps)(Preview))
