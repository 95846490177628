import React from 'react'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'
import './style.css'

const swSection = (arr,pa,sw) =>{
    if(arr.length){
        return(     
                <div className={sw}>
                    <div className="swiper-wrapper">                       
                        {
                            arr.length>1?                           
                            arr.map((item)=>{
                                let _style = {
                                        backgroundImage:'url('+item.path+')'
                                };
                                return(
                                    <div className="swiper-slide" key={item.id} style={_style}>
                                        <div className="jxhd_section_p" style={{'background':`url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat`,' backgroundSize':'100% 100%'}}>{item.post_title}</div>
                                    </div>
                                )
                            }):<img src={arr[0].path} />                          
                        }              
                    </div>
                    {
                        arr.length>1?<div className={pa}></div>:<div className="swiper-pagination"></div>
                    }
                      
                </div>            
        )

    }
}
class CommonCarouselTitle extends React.Component{
    constructor(props){
        super(props)
        this.state={
            swArr:[],
            paginationM:"swiper-pagination opa "+'pa'+Math.floor((Math.random()*10000)),
            sw:"swiper-container swiper-container-common "+'sw'+Math.floor((Math.random()*10000))
        }
    }
    componentDidMount() {
        var _name ='.'+this.state.paginationM.split('opa ')[1]
        var _sw = '.'+this.state.sw.split('swiper-container-common ')[1]
        new Swiper(_sw, {
            loop: false,  //循环,
            pagination: {
                el:_name
            },
        }) 
    }
    render() {
        return (
            <div className={this.props.className}>
                    {
                        swSection(this.props.Carouselarr,this.state.paginationM,this.state.sw)
                    }
            </div>

        )
    }
}
export default CommonCarouselTitle