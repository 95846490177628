import React from 'react';
import {Pagination,Skeleton,LocaleProvider} from 'antd';
import {withRouter} from 'react-router-dom';
import {ListWrap} from './style'
import zhCN from 'antd/es/locale-provider/zh_CN';
import ListSection from 'common/navSection/list'
class List4 extends React.Component{
    render(){
        return(
            this.state.list.length?
                <ListWrap>
                    <ListSection catName={this.state.cat_name} catId={this.state.cat_id} name={this.state.name}/>
                    <div className="hdbd_wrap">
                        {
                            this.state.list.map((item)=>{
                                return(
                                    <div className='hdbd_section'>
                                        <div className="hdbd_section_img_wrap">
                                            <img src={item.path?item.path:`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/header/logodemo_02.jpg`} alt=""/>
                                        </div>
                                        <div className="hdbd_section_detail">
                                            <a className='hdbd_section_detail_title' href={item.posts_link} >{item.post_title}</a>
                                            <div className='hdbd_section_date'>{item.post_date}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        this.state.list.length?
                            <LocaleProvider locale={zhCN}>
                                <Pagination  size="small" defaultCurrent={this.state.page} total={this.state.allCount} onChange={this.onChange} showQuickJumper className='pagenation'/>
                            </LocaleProvider>:<Skeleton active />
                    }
                </ListWrap>:<Skeleton active />
        )
    }
    constructor(props){
        super(props)
        this.state = {
            list:[],
            allCount: null,
            page: 1,
            cat_name:null,
            cat_id:null,
            name:null
        }
        this.onChange = this.onChange.bind(this)

    }
    goBack(){
        this.props.history.goBack()
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
            let pos = window.pageYOffset;
            if ( pos > 0 ) {
                window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
            } else {
                window.clearInterval( scrollToTop );
            }
        }, 2);
    }
    onChange(pageNumber) {
        this.setState({
            page: pageNumber,
            list: []
        })
        React.$request({
            url:'Portal/Index/is_ajax_list',
            method:'get',
            params: {
                type: 2,
                size:10,
                p:pageNumber,
                termid:this.props.match.params.id
            }
        }).then(res=>{
            this.setState({
                list: res.data.list
            })
            this.goTo()
        })
    }
    componentDidMount(){
        this.goTo()
        if(this.props.match.params.id){
            React.$request({
                url:'Portal/Index/is_ajax_list',
                method:'get',
                params: {
                    termid:this.props.match.params.id,
                    size:10,
                    type: 2,
                    p:1
                }
            }).then(res=>{
                this.setState({
                    list:res.data.list,
                    allCount: parseInt(res.data.count),
                    cat_name:res.data.cat_name,
                    cat_id:res.data.cat_id,
                    name:res.data.name
                })
                document.title = res.data.name
            })
        }
    }
}

export default withRouter(List4)
