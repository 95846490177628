import styled from 'styled-components'
export const ExIndexZzt = styled.div`
    @media(min-width:750px){
        width:1200px;
        display:flex;
        margin:20px auto;
        flex-direction:row;
        justify-content:space-between;
        flex-wrap:wrap;
        .ex_m{
            display: none!important;
        }
        .zzt_img_wrap{
            width:590px;
            height:340px;
            border-radius:10px;
            overflow:hidden;
            margin-top:20px;
            img{
                width:100%;
            }
        }
    }
    @media(max-width:750px){
        .ex_index{
            display: none!important;
        }
        width:7.1rem;
        margin:0 auto;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        flex-wrap:wrap;
        margin-bottom:-.2rem;
        .zzt_img_wrap{
            width:3.45rem;
            height:1.725rem;
            border-radius:.13rem;
            overflow:hidden;
            margin-bottom:.2rem;
            display:flex;
            justify-content:center;
            align-items:center;
            img{
                width:100%;
            }
        }
    }
`
export const ExIndexEtly = styled.div`
    @media(min-width:750px){
        .ex_m{
            display: none!important; 
        }
        width:1200px;
        display:flex;
        margin:20px auto;
        flex-direction:row;
        justify-content:space-between;
        flex-wrap:wrap;
        .etly_img_wrap{
            width:590px;
            height:355px;
            border-radius:10px;
            overflow:hidden;
            margin-top:20px;
            img{
                width:100%;
            }
        }
    }
    @media(max-width:750px){
        .ex_index{
            display: none!important;
        }
        width:7.1rem;
        margin:0 auto;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        flex-wrap:wrap;
        margin-bottom:-.2rem;
        .etly_img_wrap{
            width:3.45rem;
            height:1.79rem;
            border-radius:.13rem;
            overflow:hidden;
            margin-bottom:.2rem;
            display:flex;
            justify-content:center;
            align-items:center;
            img{
                width:100%;
            }
        }
    }
`
export const ExIndexLszl = styled.div`
    @media(min-width:750px){
        width:1200px;
        height:340px;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        overflow:hidden;
        margin:60px auto 0;
        .edu_jhhd_wrap{
                width:1200px;
                overflow:hidden;
                margin:0 auto;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
                /* .jxhd_section{
                    width:585px;
                    height:340px;
                    position:relative;
                    overflow:hidden;
                    background-position:center center;
                    background-size:cover;
                    background-repeat:no-repeat;
                    border-radius:10px;
                    .jxhd_section_p{
                        width:100%;
                        height:100px;
                        background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                        background-size:100% 100%;
                        position:absolute;
                        left:0;
                        bottom:0;
                        display:flex;
                        padding:10px 35px;
                        box-sizing:border-box;
                        color:#fff;
                        font-size:18px;
                        align-items:flex-end;
                    }
                } */
                .jxhd_section{
                    width:100%;
                    height:340px;
                    position:relative;
                    overflow:hidden;
                    background-position:center center;
                    background-size:cover;
                    background-repeat:no-repeat;
                    border-radius:10px;
                }
        }
    }
    @media(max-width:750px){
        width:7.1rem;
        height:2.1rem;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        overflow:hidden;
        margin:.33rem auto 0;
        .edu_jhhd_wrap{
                width:100%;
                overflow:hidden;
                margin:0 auto;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
                /* .jxhd_section{
                    width:3.5rem;
                    height:2.1rem;
                    position:relative;
                    overflow:hidden;
                    background-position:center center;
                    background-size:cover;
                    background-repeat:no-repeat;
                    border-radius:10px;
                    .jxhd_section_p{
                        width:100%;
                        height:1rem;
                        background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                        background-size:100% 100%;
                        position:absolute;
                        left:0;
                        bottom:0;
                        display:flex;
                        padding:.1rem .35rem;
                        box-sizing:border-box;
                        color:#fff;
                        font-size:.3rem;
                        align-items:flex-end;
                    }
                } */
                .jxhd_section{
                    width:7.1rem;
                    height:2.1rem;
                    position:relative;
                    overflow:hidden;
                    background-position:center center;
                    background-size:cover;
                    background-repeat:no-repeat;
                    border-radius:10px;
                    /* .jxhd_section_p{
                        width:100%;
                        height:1rem;
                        background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                        background-size:100% 100%;
                        position:absolute;
                        left:0;
                        bottom:0;
                        display:flex;
                        padding:.1rem .35rem;
                        box-sizing:border-box;
                        color:#fff;
                        font-size:.3rem;
                        align-items:flex-end;
                    } */
                }
        }
    }

`
export const ExIndexLszlV2 = styled.div`
    @media(min-width:750px){
        .ex_m{
            display: none!important; 
        }
        width:1200px;
        display:flex;
        margin:20px auto;
        flex-direction:row;
        justify-content:space-between;
        flex-wrap:wrap;
        .lszl_img_wrap{
            width:590px;
            height:355px;
            border-radius:10px;
            overflow:hidden;
            margin-top:20px;
            img{
                width:100%;
                height:100%;
            }
        }
        .lszl_img_wrap0{
            width:100%;
            height:340px;
            border-radius:10px;
            overflow:hidden;
            margin-top:20px;
            img{
                width:100%;
                height:100%;
            }
        }
    }
    @media(max-width:750px){
        .ex_index{
            display: none!important;
        }
        width:7.1rem;
        margin:0 auto;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        flex-wrap:wrap;
        margin-bottom:-.2rem;
        .lszl_img_wrap{
            width:3.45rem;
            height:1.79rem;
            border-radius:.13rem;
            overflow:hidden;
            margin-bottom:.2rem;
            display:flex;
            justify-content:center;
            align-items:center;
            img{
                width:100%;
                height:100%;
            }
        }
        .lszl_img_wrap0{
            width:100%;
            height:1.79rem;
            border-radius:.13rem;
            overflow:hidden;
            margin-bottom:.2rem;
            img{
                width:100%;
                height:100%;
            }
        }
    }

`
export const ExIndexSdcx = styled.div`
    @media(min-width:750px){
        width:1200px;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        margin:60px auto;
        .img_wrap{
            width:290px;
            height:185px;
            border-radius:10px;
            overflow:hidden;
            img{
                width:100%;
            }
        }
    }
    @media(max-width:750px){
        width:7.1rem;
        margin:0 auto;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        flex-wrap:wrap;
        .img_wrap{
            width:3.45rem;
            height:2.19rem;
            border-radius:.13rem;
            overflow:hidden;
            margin-bottom:.2rem;
            display:flex;
            justify-content:center;
            align-items:center;
            img{
                width:100%;
            }
        }
    }
`
export const ExIndexQwwx = styled.div`
    @media(min-width:750px){
        width:1200px;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        margin:60px auto 0;
        .swiper-container-qwwx {
            width: 100%;
            height: 280px;
        }
        .swiper-slide-qwwx {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            display: block;
            position: relative;
        }
        
        .swiper-slide-qwwx .title {
            width: 100%;
            height: 60px;
            position: absolute;
            left: 0;
            bottom: 0;
            /* padding:0 60px; */
            box-sizing: border-box;
            display: none;
        }
        
        .swiper-slide-qwwx.swiper-slide {
            transition: transform 0.5s ease-in-out;
            transform: scale(0.95);
        }
        
        .qwwxtitle {
            color: #fff;
            // height: 60px;
            width: 100%;
            font-size: 20px;
            line-height: 28px;
            background-size: 100% 100%;
            text-align: center;
            padding: 0 5px;
        }

        .swMask {
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, .5);
        }
        
        .swiper-container-qwwx .swiper-slide-active .swMask {
            display: none;
        }
        
        .swiper-container-qwwx .swiper-slide-active .title {
            display: flex;
            align-items: center;
        }
    }
    @media(max-width:750px){
        width:7.1rem;
        margin:0 auto;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        flex-wrap:wrap;
        margin-bottom:-.2rem;

        .swiper-container-qwwx {
            width: 100%;
            height: 2rem; 
        }
        .swiper-slide-qwwx {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            display: block;
            position: relative;
        }
        
        .swiper-slide-qwwx .title {
            width: 100%;
            height: 0.7317rem; /* 60px 转换为 rem (60 / 82px) */
            position: absolute;
            left: 0;
            bottom: 0;
            box-sizing: border-box;
            display: none;
        }
        
        .swiper-slide-qwwx.swiper-slide {
            transition: transform 0.5s ease-in-out;
            transform: scale(0.95);
        }
        
        .qwwxtitle {
            color: #fff;
            // height: 0.7317rem; /* 60px 转换为 rem (60 / 82px) */
            width: 100%;
            font-size: 0.2439rem; /* 20px 转换为 rem (20 / 82px) */
            line-height: 0.7317rem; /* 60px 转换为 rem (60 / 82px) */
            background-size: 100% 100%;
            text-align: center;
            display: none;
        }
    
        .swMask {
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, .5);
        }
        
        .swiper-container-qwwx .swiper-slide-active .swMask {
            display: none;
        }
        
        .swiper-container-qwwx .swiper-slide-active .title {
            display: flex;
            align-items: center;
        }
    }
`
