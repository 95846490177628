import React from 'react';
import {EduWrap} from './style'
import CommonCarousel from 'common/carousel.js'
import {NavLink} from 'react-router-dom'
import { Skeleton } from 'antd';
import NavSection from 'common/navSection'
import SectionWrap from 'common/newSection'


export const title_type_1 = (font,id)=>{
    return(
        <NavLink className='title_type_1 nav_title' to={'/list2/'+id}>
                <i className='i_small'></i>
                <i className='i_large'></i>
                <span>{font}</span>
                <i className='i_large2'></i>
                <i className='i_small2'></i>
        </NavLink>
    )
}
export const title_type_3 = (font,id)=>{
    return(
        <NavLink className='title_type_1 nav_title' to={'/listtab/'+id}>
                <i className='i_small'></i>
                <i className='i_large'></i>
                <span>{font}</span>
                <i className='i_large2'></i>
                <i className='i_small2'></i>
        </NavLink>
    )
}
export const title_type_2 = (font,id)=>{
    return(
        <NavLink className='title_type_1 nav_title' to={'/listtab/'+id}>
                {font}
        </NavLink>
    )
}
class EduIndex extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            eduList:null,
            jckcList:null
        }
    }
    zxyd(arr){
        let zxydList = []
        for(let item in arr){
            if(item !== 'cat_name'){
                zxydList.push(arr[item])
            }
        }
        console.log(zxydList)
        return zxydList
    }

    initEduIndex(){
        React.$request({
            url:'Portal/index/education',
            method:'get',
          }).then(res=>{
            let jckcList =  this.zxyd(res.data.jckc)
            this.setState({
                eduList:res.data,
                jckcList:jckcList
            })
        })
    }
    componentDidMount(){
        document.title = '教育活动'
        this.initEduIndex()
    }
    render(){
        return(
            this.state.eduList?
            <EduWrap>
                <NavSection />
                <div className="eduHeader">
                    <CommonCarousel className='sw'  Carouselarr={this.state.eduList.lp} />
                    <div className="edu_hdyg">
                        <NavLink className='edu_hdyg_title' to={'/list3/46'}>
                            <span>{this.state.eduList.hdyg.cat_name}</span>
                            <i className='i_large2'></i>
                            <i className='i_small2'></i>
                        </NavLink>
                        <div className="edu_hdyg_list">
                            {
                                this.zxyd(this.state.eduList.hdyg).map((item)=>{
                                    return(
                                        <NavLink key={item.id} className='hdyg_section' to={'/article/'+item.id}>
                                            {/* <div className="time">
                                                {item.post_date.split('/')[1]}.
                                                {item.post_date.split('/')[2]}
                                            </div> */}
                                            <div className="content">
                                                {
                                                    parseInt(item.istop, 10) > 0 ?
                                                    <span className='zd'>
                                                    <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zda.png`} alt=""/>
                                                    </span> :
                                                    ""
                                                }
                                                {item.post_title}
                                            </div>
                                        </NavLink>
                                    )
                                })
                            }
                            <NavLink className='more' to={'/list3/46'}>
                            更多
                            </NavLink>
                        </div>
                        <a href='http://ticket.bjsc.net.cn/' target='_blank' className="sign_link">
                            <i><img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/onlineIcon_03.png`} alt=""/></i><span>在线报名</span>
                        </a>
                    </div>
                </div>
                {/* <div className="hot_avtivity">
                    {
                        title_type_1(this.state.eduList.rmhd.cat_name,this.state.eduList.rmhd[0].term_id)
                    }
                    <div className="hot_a_wrap">
                        {
                            this.zxyd(this.state.eduList.rmhd).map((item)=>{
                                return(
                                    <div key={item.id} className='hot_a_section'>
                                        <NavLink className="hot_a_section_img_wrap" to={'/education/'+item.post_activity}>
                                            <img src={item.path} alt=""/>
                                        </NavLink>
                                        <div className="hot_a_section_bot">
                                            <span>{item.post_title}</span>
                                            <a href={item.posts_link}>在线报名</a>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div> */}
                <div className="edu_jxhd">
                    <div className='edu_more'>
                            {
                                title_type_1(this.state.eduList.jxhd.cat_name,this.state.eduList.jxhd[0].term_id)
                            }
                            <NavLink className='edu_more_link' to={'/list2/'+this.state.eduList.jxhd[0].term_id}>更多</NavLink>
                    </div>
                    <div className="edu_jhhd_wrap">
                        {
                            this.zxyd(this.state.eduList.jxhd).map((item)=>{
                                let _style = {
                                    backgroundImage:"url("+item.path+")"
                                }
                                return(
                                    parseInt(item.post_aptype) === 7?
                                    <a key={item.id} className='jxhd_section' style={_style} href={item.posts_link} target={item.posts_link.indexOf(location.host)>=0?'':'_blank'}>
                                        <div className="jxhd_section_p">{item.post_title}</div>
                                    </a>:
                                    <NavLink key={item.id} className='jxhd_section' style={_style} to={'/education/'+item.post_activity}>
                                        <div className="jxhd_section_p">{item.post_title}</div>
                                    </NavLink>
                                )

                            })
                        }
                    </div>
                </div>
                {
                    (this.state.jckcList && this.state.jckcList.length > 0 )?
                        <div className="edu_jckc">
                            <div className='edu_more'>
                                {
                                    title_type_1(this.state.eduList.jckc.cat_name,this.state.eduList.jckc[0].term_id)
                                }
                                <NavLink className='edu_more_link' to={'/list2/'+this.state.eduList.jckc[0].term_id}>更多</NavLink>
                            </div>
                            <div className="edu_jhhd_wrap">
                                {
                                    this.state.jckcList.map((item)=>{
                                        let _style = '',className = 'jckc_section'
                                        if(this.state.jckcList &&  this.state.jckcList.length === 1){
                                            _style = {
                                                backgroundImage:`url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/kxssb.png)`
                                            }
                                            className = 'jckc_section jckc_section_full'
                                        }else{
                                            _style = {
                                                backgroundImage:"url("+item.path+")"
                                            }
                                        }
                                        return (
                                            <NavLink key={item.id} className={className} style={_style} to={'/education/'+item.post_activity}>
                                                <div className="jckc_section_p">{item.post_title}</div>
                                            </NavLink>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        :null
                }
                
                <div className="yyry">
                    {/* {home_title('科代表讲科学')} */}
                    <a className="yyry_img" href="//www.bjsc.net.cn/#/xsdll">
                        <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/20220921/632ad2f00845b.png`} alt="" />
                    </a>
                    </div>

                {/* <div className="edu_mkjkx">
                    {
                        title_type_1(this.state.eduList.mkjkxsp.cat_name,this.state.eduList.mkjkxsp[0].term_id)
                    }
                    <div className="edu_mk_wrap">
                        <div className="edu_video">
                            {
                                <video src={this.state.eduList.mkjkxsp[0].video} controls='controls'></video>
                            }
                        </div>
                        <div className="edu_mk_list">
                            {
                                this.zxyd(this.state.eduList.mkjkxtp).map((item)=>{
                                    let _style = {
                                        backgroundImage:"url("+item.path+")"
                                    }
                                    return(
                                        <NavLink key={item.id} style={_style} className='edu_mk_img_section' to={'/article/'+item.id}>
                                            <div className="edu_mk_img_section_mask">
                                                <div className="jxhd_section_p">{item.post_title}</div>
                                            </div>
                                        </NavLink>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div> */}
                <div className="edu_hdbd">
                        {
                            title_type_3(this.state.eduList.hdbd.cat_name,this.state.eduList.hdbd[0].term_id)
                        }
                        <div className="edu_hdbd_wrap">
                            {
                                    this.zxyd(this.state.eduList.hdbd).map((item)=>{
                                        return(
                                            <SectionWrap secarr={item} key={item.id}/>
                                        )
                                    })
                            }
                        </div>
                </div>
            </EduWrap>
            :<Skeleton active />
        )
    }
}

export default EduIndex
