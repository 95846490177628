import styled from 'styled-components'

export const EduOtherWrap = styled.div `
    @media(min-width:750px){
        width:100%;
        overflow:hidden;
        .paginationWrapM{
            display: none;
        }
        .pagenation{
                margin:60px auto 80px;
                text-align:center;
                .ant-pagination-item{
                    
                }
                .ant-pagination-item-active {
                    border-radius:0;
                    color:#fff;
                    background: #009cbe;
                    a{
                        color:#fff;     
                    }
                }
        }
        .title_type_1{
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            .i_small2{
                        width:8px;
                        height:8px;
                        background:#f55c5d;
            }
            .i_large2{
                        width:16px;
                        height:16px;
                        background:#00a1b4;   
                        margin-right:7px;             
            }            
            span{
                        padding:10px 40px;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 16px 0 0;
                        box-sizing:border-box;
            }
        }
        .title_type_2{
                width:154px;
                height:37px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/qthd_03.png) center center no-repeat;
                background-size: 100% 100%;
                font-size:28px;
                color:#fff;
                font-weight:bold;
                line-height:37px;
                text-indent:40px;
                
        }
        .header{
            width:1200px;
            height:380px;
            display:flex;
            flex-direction:row;
            margin:10px auto 0;
            .sw{
                width:830px;
                height:380px;
                border-radius:10px;
                overflow:hidden;
            }
            .header_detail{
                flex:1;
                margin-left:45px;
                p{
                    font-size:18px;
                    line-height:30px;
                    color:#000000;
                    margin-top:30px;
                }
            }
        }
        .paginationWrap{
            width:1200px;
            overflow:hidden;
            margin:60px auto;
            .paginationWrap_title{
                width:1200px;
                height:37px;
                display:flex;
                flex-direction:row;
                align-items:center;
                .line{
                    width:1060px;
                    height:2px;
                    background:#009cbe;
                    margin-left:-10px;
                }
            }
            .other_section_wrap{
                width:1200px;
                overflow:hidden;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
            }
        }
    }
    @media(max-width:750px){
        width:100%;
        overflow:hidden;
        .title_type_1{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:0;
            text-indent:0;
            .i_small2{
                width:.16rem;
                height:.16rem;
                background:#f55c5d;
            }
            .i_large2{
                width:.3rem;
                height:.3rem;
                background:#00a1b4;   
                margin-right:.07rem;             
            }            
            span{
                padding:.1rem .4rem;
                background:#00a1b4;
                border-radius:5px;
                margin:0 .16rem 0 0;
                box-sizing:border-box;
            }
        }
        .title_type_2{
                width:2.67rem;
                height:.5rem;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/qthd_03.png) center center no-repeat;
                background-size: 100% 100%;
                font-size:.34rem;
                color:#fff;
                font-weight:bold;
                line-height:.5rem;
                text-indent:.5rem;              
        }
        .header{
            width:100%;
            overflow:hidden;
            .sw{
                width:100%;
                height:4rem;
            }
            .header_detail{
                width:7.1rem;
                margin:.2rem auto 0;
                p{
                    font-size:.28rem;
                    color:#282828;
                    line-height:.4rem;
                    text-indent:.0;          
                    color:#000; 
                    margin-top:.2rem;
                    text-align:justify;
                }
            }
        }
        .paginationWrap{
            display: none;
        }
        .paginationWrapM{
            width:7.1rem;
            overflow:hidden;
            margin:.1rem auto;
            .paginationWrapM_list{
                width:100%;
                overflow:hidden;
                margin:.3rem auto .9rem;
            }
        }
        .see_more{
            width:2.5rem;
            height:.64rem;
            background:#009cbd;
            border-radius:.04rem;
            font-size:.3rem;
            color:#fff;
            line-height:.64rem;
            text-align:center;
            margin:.5rem auto .7rem;
        }
    }
`