/*
 * @Author: your name
 * @Date: 2020-09-16 15:22:40
 * @LastEditTime: 2020-09-18 17:43:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \kxzxweb\src\pages\education\components\jnh\mryx.js
 */
import React, { memo } from 'react'
import { MryxWrap } from './style'
import { NavLink } from 'react-router-dom'

const Mryx = memo((props) => {
  const { listarr } = props
  return (
    <MryxWrap>
      <div className="h3">
        <div className="title_type_1">
          <i className="i_small"></i>
          <i className="i_large"></i>
          <span>{listarr.name}</span>
          <i className="i_large2"></i>
          <i className="i_small2"></i>
        </div>
      </div>
      <div className="section_nav_1">
        {listarr.list.slice(0, 4).map((item) => {
          return (
            <NavLink
              className="section_comm_1"
              to={'/article/' + item.id}
              key={item.id}
            >
              <div className="x_img_1">
                <img src={item.path} />
              </div>
              <div className="right">
                <div className="title">{item.post_title}</div>
                <div className="time">{item.post_date}</div>
                <div className="detail">{item.post_excerpt}</div>
              </div>
            </NavLink>
          )
        })}
        <NavLink to={'/list/' + listarr.term_id} className="more">
          更多
        </NavLink>
      </div>
    </MryxWrap>
  )
})

export default Mryx
