import styled from 'styled-components'

export const ZzjgWrap = styled.div`
    @media(max-width:750px){
        width:7.1rem;
        margin:.1rem auto;
        font-size:.2rem;
        line-height:.35rem;
        img{
            width:6rem;
            margin:.2rem auto;
            display:block;
        }
    }
    @media(min-width:750px){
        width:687px;
        margin:0 auto;
        
    }    
`