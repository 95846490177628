import React from 'react';
import {KxcbWrap} from './style'
import CommonCarouselTitle from 'common/qthdSlider.js'
import {Skeleton, Empty, Modal, LocaleProvider, Pagination} from 'antd'
import {NavLink} from 'react-router-dom'
import NavSection from 'common/navSection'
import SectionWrap from 'common/newSection'
import CommonCarousel from 'common/carousel.js'
import {EduOtherWrap} from "../aijkx/style";
import zhCN from "antd/es/locale-provider/zh_CN";

export const title_type_1 = (font)=>{
    return(
        <div className='title_type_1'>
            <span>{font}</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>
        </div>
    )
}
export const title_type_2 = (font)=>{
    return(
        <div className='title_type_2'>
            <span>{font}</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>
        </div>
    )
}
export const title_type_3 = (font,id)=>{
    if(id>0){
        return (<NavLink style={{color:'#FFF'}} className='title_type_3 nav_title' to={'/list/'+id}>
            <i className='i_small'></i>
            <i className='i_large'></i>
            <span>{font}</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>
        </NavLink>)
    }
    return(
        <div className='title_type_3'>
            <i className='i_small'></i>
            <i className='i_large'></i>
            <span>{font}</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>
        </div>
    )
}
class EduQshd extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            sdkpjtArr:null,
            classifyList:[],
            currentList:[],
            allCount:0,
            Mlist:null,
            Mpage:null,
            termId:null,
            visible:false,
            currentVideo: null,
            currentVideoName: null,
            poster: null,
            currentId: null,
            routerLink:{

            }
        }
    }
    componentDidMount(){
        this.sdkpjtInit()
        document.title = '青少活动'
    }
    zxyd(arr){
        let zxydList = []
        for(let item in arr){
            if(item !== 'cat_name'){
                zxydList.push(arr[item])
            }
        }
        return zxydList
    }
    sdkpjtInit(){
        React.$request({
            url:'Portal/index/education_son',
            method:'get',
            params:{
                activity:309
            }
          }).then(res=>{
            const rcList = res.data.classifyList || []
            let classifyList = []
            rcList.forEach(item=>{
                if(item.term_id !=310 && item.term_id!=314){
                    classifyList.push(item)
                }
            })

            this.setState({
                sdkpjtArr:res.data,
                classifyList: classifyList,
                termId:classifyList.length > 0? classifyList[0].term_id:''
            })
            if(classifyList && classifyList.length > 0 ){
                this.currentListInit()
            }
          })
    }

    currentListInit(){
        this.onChange(1)
    }
    onChange(pageNumber) {
        this.setState({
            currentList:null,
            currentPage:pageNumber
        })

        React.$request({
            url:'Portal/index/is_ajax_list',
            method:'get',
            params:{
                termid:this.state.termId,
                type:2,
                size:6,
                p:pageNumber,
                aptype:3 //视频
            }
          }).then(res=>{
            this.setState({
                currentList:res.data.list,
                allCount:parseInt(res.data.count),
                Mlist:res.data.list,
                Mpage:Math.ceil(parseInt(res.data.count)/10)
            })
          })
    }

    loadMore(){
        const _page = this.state.currentPage+1
        React.$request({
            url:'Portal/index/is_ajax_list',
            method:'get',
            params:{
                termid:this.state.termId,
                type:2,
                size:6,
                aptype:3,
                p:_page
            }
          }).then(res=>{
            const _list = this.state.Mlist
            this.setState({
                Mlist:_list.concat(res.data.list)
            })
          })
        this.setState({
            currentPage:_page
        })
    }
    tabChange(itemId){
        this.setState({
            termId:itemId
        })
        setTimeout(()=>{
            this.onChange(1)
        })
    }

    changeVideo(item) {
        this.setState({
            currentVideo: item.video,
            currentVideoName: item.post_title,
            currentId: item.id,
            poster: item.path,
            visible:true
        })
    }
    setVisible(flag){
        this.setState({
            visible:flag
        })
    }
    render(){
        return(
            this.state.sdkpjtArr?
            <KxcbWrap>
                <NavSection />
                <div className="header">
                    <CommonCarousel className="sw" Carouselarr={this.state.sdkpjtArr.lp} />
                    <div className="header_detail">
                        {title_type_1(this.state.sdkpjtArr.lp[0].post_title)}
                        <p>{this.state.sdkpjtArr.lp[0].post_excerpt.length > 148?this.state.sdkpjtArr.lp[0].post_excerpt.substring(0,148):this.state.sdkpjtArr.lp[0].post_excerpt}</p>
                        <NavLink to={'/article/'+this.state.sdkpjtArr.lp[0].id} className='more'>更多</NavLink>
                    </div>
                </div>
                {
                    (this.state.sdkpjtArr.hdbd && this.state.sdkpjtArr.hdbd.length > 0)?
                        <div className="dsgg_wrap">
                            <div className='edu_more'>
                                {
                                    title_type_3("活动报道",314)
                                }
                                <NavLink className='edu_more_link' to={'/list/314'}>更多</NavLink>
                            </div>
                            <div className="dsgg">
                                {
                                    this.state.sdkpjtArr.hdbd.map((item,index) => {
                                        if(index<=3){
                                            return <SectionWrap secarr={item} key={item.id}></SectionWrap>
                                        }
                                    })
                                }
                            </div>
                        </div>:null
                }

                <div className='tabWrap'>
                    {
                        this.state.classifyList.map((item,index)=>{
                            let className = 'tab_1'
                            if(index === 0){
                                className = 'tab_1'
                            }else if(index === this.state.classifyList.length - 1){
                                className = 'tab_3'
                            }else{
                                className = 'tab_2'
                            }
                            if(item.term_id === this.state.termId){
                                className = className + ' '+(className+'_active')
                            }

                            return (
                                <div className={className} key={item.term_id} onClick={()=>this.tabChange(item.term_id)}>
                                    {item.name}
                                </div>
                            )
                        })
                    }
                </div>

                <div className="paginationWrap">
                    <div className="other_section_wrap">
                        {
                            (this.state.currentList && this.state.currentList.length > 0 )?this.state.currentList.map((item)=>{
                                return(
                                    <div className='hdbd_section' onClick={() => this.changeVideo(item)}>
                                        <div className="hdbd_section_img_wrap">
                                            <img src={item.path?item.path:`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/header/logodemo_02.jpg`} alt=""/>
                                        </div>
                                        <div className="hdbd_section_detail">
                                            <div className="hdbd_section_detail_title">{item.post_title}</div>
                                            <div className='hdbd_section_date'>{item.post_date}</div>
                                        </div>
                                    </div>
                                )
                            }):<Empty className='empty'/>
                        }
                        {
                            this.state.currentList?
                                <LocaleProvider locale={zhCN}>
                                    <Pagination  size="small" defaultCurrent={this.state.currentPage} total={this.state.allCount} onChange={this.onChange.bind(this)} pageSize={6} showQuickJumper className='pagenation'/>
                                </LocaleProvider>:null
                        }
                    </div>
                </div>

                <div className="paginationWrapM">
                    <div className="paginationWrapM_list">
                        {
                            (this.state.Mlist && this.state.Mlist.length > 0 )?this.state.Mlist.map((item)=>{
                                return(
                                    <div className='hdbd_section' onClick={() => this.changeVideo(item)} >
                                        <div className="hdbd_section_img_wrap" >
                                            <img src={item.path?item.path:`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/header/logodemo_02.jpg`} alt=""/>
                                        </div>
                                        <div className="hdbd_section_detail">
                                            <div className="hdbd_section_detail_title">{item.post_title}</div>
                                            <div className='hdbd_section_date'>{item.post_date}</div>
                                        </div>
                                    </div>
                                )
                            }):<Empty className='empty'/>
                        }

                        {
                            this.state.currentPage < this.state.Mpage?
                                <div className="see_more" onClick={()=>this.loadMore(this)}>
                                    查看更多
                                </div>:null
                        }
                    </div>
                </div>

                <div className="jcsjWrap">
                    {
                        title_type_3("作品海报")
                    }
                    <CommonCarouselTitle className='sw'  Carouselarr={this.zxyd(this.state.sdkpjtArr.jcsj)} />
                </div>




                <Modal
                    getContainer={false}
                    visible={this.state.visible}
                    title={this.state.currentVideoName} width={800} footer={null} onCancel={() => this.setVisible(false)}>
                    <div className="video">
                        {
                            <video src={this.state.currentVideo} controls='controls' poster={this.state.poster}></video>
                        }
                    </div>
                </Modal>
            </KxcbWrap>
            :<Skeleton active />
        )
    }
}
export default EduQshd
