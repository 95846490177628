import * as constants  from './constants'
import React from 'react'
const ServiceInit = (data)=>{
    return{
        type:constants.SERVICE,
        data:data
    }
}
export const getService = ()=>{
    return (dispatch)=>{ 
        React.$request({
            url:'Portal/index/service',
            method:'get',
          }).then(res=>{
            dispatch(ServiceInit(res.data))
          })
    }
}