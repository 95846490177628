import styled from 'styled-components'

export const ExhCommonSectionL = styled.div`
    @media(min-width:750px){
        width:1200px;
        height:400px;
        overflow:hidden;
        margin:40px auto 0;
        display:flex;
        flex-direction:row;
        border-radius:10px;
        .section{
            width:100%;
            height:100%;
            display:flex;
            flex-direction:row;
            .section_img_wrap{
                flex:1;
                img{
                    width:100%;
                }
                .sw{
                    height:100%;
                }
            }
            .font_seciotn{
                flex:1;
                background:#eeeeee;
                display:flex;
                flex-direction:column;
                justify-content:center;
                align-items:center;
                h2{
                    color:#009cbd;
                    font-size:30px;
                    font-weight:bold;
                    text-align:center;
                }
                p{
                    width:430px;
                    font-size:18px;
                    line-height:28px;
                    color:#4d4d4d;
                    text-align:justify;
                }
                .address{
                    height:46px;
                    font-size:20px;
                    line-height:46px;
                    color:#ff7413;
                    background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/icons_07.png) left center no-repeat;
                    background-size:46px 46px;
                    text-indent:56px;
                }
            }
        }
    }
    @media(max-width:750px){
        width:7.1rem;
        overflow:hidden;
        margin:.3rem auto .72rem;
        border-radius:.1rem;
        .section{
            width:100%;
            overflow:hidden;
            border-radius:.1rem;
            .section_img_wrap{
                width:100%;
                height:4.6rem;
                overflow:hidden;
                .sw{
                    width:100%;
                    height:4.6rem;
                }
            }
            .font_seciotn{
                background:#eeeeee;
                width:100%;
                display:flex;
                flex-direction:column;
                justify-content:center;
                align-items:center;
                padding-bottom:.4rem;
                h2{
                    color:#009cbd;
                    font-size:.34rem;
                    font-weight:bold;
                    text-align:center;
                    margin-top:.4rem;
                }
                p{
                    width:6.7rem;
                    font-size:.26rem;
                    line-height:.41rem;
                    color:#4d4d4d;
                    text-align:justify;
                    margin:0 auto;
                }
                .address{
                    height:.5rem;
                    font-size:.26rem;
                    line-height:.5rem;
                    color:#515151;
                    border-bottom:1px solid #bfbfbf;
                    background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/icons_07.png) left center no-repeat;
                    background-size:.18rem .25rem;
                    text-indent:25px;
                    margin-top:.2rem;
                }
            }
        }
    }
`
export const ExhCommonSectionR = styled.div`
    @media(min-width:750px){
        width:1200px;
        height:400px;
        overflow:hidden;
        margin:40px auto 0;
        display:flex;
        flex-direction:row;
        border-radius:10px;
        .section{
            width:100%;
            height:100%;
            display:flex;
            flex-direction:row;
            .section_img_wrap{
                flex:1;
                img{
                    width:100%;
                }
            }
            .font_seciotn{
                flex:1;
                background:#eeeeee;
                display:flex;
                flex-direction:column;
                justify-content:center;
                align-items:center;
                h2{
                    color:#009cbd;
                    font-size:30px;
                    font-weight:bold;
                    text-align:center;
                }
                p{
                    width:430px;
                    font-size:18px;
                    line-height:28px;
                    color:#4d4d4d;
                    text-align:justify;
                }
                .address{
                    height:32px;
                    font-size:20px;
                    line-height:32px;
                    color:#515151;
                    border-bottom:1px solid #bfbfbf;
                    background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/icons_07.png) left center no-repeat;
                    background-size:18px 25px;
                    text-indent:25px;
                }
            }
        }
    }
`
