/*
 * @Author: your name
 * @Date: 2020-03-19 10:54:01
 * @LastEditTime: 2020-10-13 13:30:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \kxzxweb\src\pages\cinema\index.js
 */
import React from 'react'
import { NavLink, Switch, Route, Redirect } from 'react-router-dom'
import { CinemaWrap } from './style'
import ThisMonth from './components/thisMonth'
import NextMonth from './components/nextMonth'
import CommonCarousel from 'common/carousel.js'
import NavSection from 'common/navSection'
import { Skeleton } from 'antd'

const title_type_1 = (font) => {
  return (
    <div className="title_type_1">
      <span>{font}</span>
      <i className="i_large2"></i>
      <i className="i_small2"></i>
    </div>
  )
}
class Cinema extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cinemaArr: null,
    }
  }
  componentDidMount() {
    document.title = '特效影院'
    this.initCinema()
  }
  initCinema() {
    React.$request({
      url:'Portal/index/cinema',
      method:'get',
    }).then(res=>{
      this.setState({
        cinemaArr: res.data,
      })
    })
  }
  render() {
    return this.state.cinemaArr ? (
      <CinemaWrap>
        <NavSection />
        <div className="header">
          <CommonCarousel
            className="sw"
            Carouselarr={this.state.cinemaArr.lp}
          />
          <div className="header_detail">
            {/* {title_type_1(this.state.cinemaArr.yyjs[0].post_title)} */}
            <span>{this.state.cinemaArr.yyjs[0].post_title}</span>
            <p>{this.state.cinemaArr.yyjs[0].post_excerpt}</p>
            {/* <a>在线预订</a> */}
          </div>
        </div>
        <div className="content">
          <NavLink
            to="/cinema/thismonth"
            className="cinemaLink1"
            activeClassName="cinemaLink1Active"
          >
            {this.state.cinemaArr.classify[0].name}
          </NavLink>
          <NavLink
            to="/cinema/nextmonth"
            className="cinemaLink2"
            activeClassName="cinemaLink2Active"
          >
            {this.state.cinemaArr.classify[1].name}
          </NavLink>
          <Switch>
            <Route path="/cinema/thismonth" component={ThisMonth} />
            <Route path="/cinema/nextmonth" component={NextMonth} />
            <Redirect from="/cinema*" to="/cinema/thismonth" />
          </Switch>
        </div>
        {/* <div className="none_img">
          <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/cinema/movie_02.jpg" alt="" />
        </div> */}
      </CinemaWrap>
    ) : (
      <Skeleton active />
    )
  }
}
export default Cinema
