import styled from 'styled-components'

export const SectionWrap = styled.div ` 
    @media(max-width:750px) {
    width: 100%;
    height: 1.8rem;
    margin-bottom: .2rem;
    overflow: hidden;
    .jxhd_section{
        border-radius: .1rem;
        display: flex;
        flex-direction: row;
        .jxhd_section_bg {
            width: 3rem;
            height: 1.8rem;
            border-radius: .1rem;
            overflow: hidden;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            .zd{
                width:.8rem;
                position: absolute;
                left:0;
                top:0;
                img{
                    width:100%;
                }
            }
        }
        .hdbd_section_detail {
            flex: 1;
            margin-left: .1rem;
            display: flex;
            align-items: center;
            text-align: left;
            flex-direction: column;
            height: 1.8rem;
            justify-content: space-between;

            .hdbd_section_detail_title {
                font-size: .29rem;
                color: #000;
                line-height: .4rem;
                width: 100%;
                margin-bottom: 0;
            }

            .hdbd_section_date {
                width: 100%;
                text-align: left;
                color: #00a1b4;
                font-size: .28rem;
            }
        }
    }
}`