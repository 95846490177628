import React,{Fragment} from 'react';
import {NavLink,Switch,Route} from 'react-router-dom'
import ExIndex from './components/index/index'
import ExZzt from './components/zzt'
import ExEtly from './components/etly'
import ExQwwx from './components/qwwx'
import ExSdcxcgz from './components/sdcxcgz'
import Lszl from './components/lszl/lszl.jsx'
import Dqfy from './components/dqfy/index.jsx'
class Exhibition extends React.Component{
    componentDidMount(){
        this.goTo()
        document.title = '展览展示'
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    render(){
        return(
            <Fragment>
                <Switch>
                    <Route exact path='/exhibition' component={ExIndex}></Route>
                    <Route path='/exhibition/index' component={ExIndex}></Route>
                    <Route exact path='/exhibition/zzt' component={ExZzt}></Route>
                    <Route path='/exhibition/zzt/:id' component={ExZzt}></Route>
                    <Route exact path='/exhibition/etly'  component={ExEtly}></Route>
                    <Route path='/exhibition/etly/:id'  component={ExEtly}></Route>
                    <Route path='/exhibition/qwwx' component={ExQwwx}></Route>
                    <Route path='/exhibition/sdcxkjz' component={ExSdcxcgz}></Route>
                    <Route path='/exhibition/52' component={Lszl}></Route>
                    <Route path='/exhibition/282' component={Dqfy}></Route>
                </Switch>
            </Fragment>
        )
    }
}
export default Exhibition