import React from 'react'
import {ExhCommonSectionL} from './style'
import CommonCarousel from 'common/carousel.js'

const kjqz = (arr) => {
    console.log(arr)
    let exhkjzq = []
    if(typeof(arr) === 'string'){
        let _arr = {}
        _arr.id = 'cdcx_'+1+Math.floor(Math.random()*1000)
        _arr.path = arr
        exhkjzq.push(_arr)
    }else if(typeof(arr) === 'object'){
        arr.map((item,index)=>{
            let _arr = {}
            _arr.id = 'cdcx_'+index+Math.floor(Math.random()*1000)
            _arr.path = item
            exhkjzq.push(_arr)
        })
    }
    return exhkjzq

}
const kjqzs = (arrs)=>{
    let res = []
    for (let arrsKey in arrs) {
        res = res.concat(kjqz(arrs[arrsKey].path))
    }
    return res
}
export const LeftImgS = (arrs)=>{
    return(
        <ExhCommonSectionL>
            <div className="section" >
                <div className="section_img_wrap">
                    <CommonCarousel className='sw' Carouselarr={kjqzs(arrs)} />
                </div>
                <div className='font_seciotn'>
                    <h2>{arrs[0].post_title}</h2>
                    <p dangerouslySetInnerHTML={{__html:arrs[0].post_excerpt}}></p>
                    <div className='address'>{arrs[0].posts_address}</div>
                </div>
            </div>
        </ExhCommonSectionL>
    )
}
export const LeftImg = (arr)=>{
    return(
        <ExhCommonSectionL>
            <div className="section" >
                <div className="section_img_wrap">
                    <CommonCarousel className='sw' Carouselarr={kjqz(arr.path)} />
                </div>
                <div className='font_seciotn'>
                    <h2>{arr.post_title}</h2>
                    <p dangerouslySetInnerHTML={{__html:arr.post_excerpt}}></p>
                    <div className='address'>{arr.posts_address}</div>
                </div>
            </div>
        </ExhCommonSectionL>
    )
}
export const LeftImg2 = (arr) => {
    console.log(arr)
    return(
        <ExhCommonSectionL>
            <div className="section" >
                <div className="section_img_wrap">
                    <CommonCarousel className='sw' Carouselarr={arr} />
                </div>
                <div className='font_seciotn'>
                    <h2>{arr[0].post_title}</h2>
                    <p dangerouslySetInnerHTML={{__html:arr[0].post_excerpt}}></p>
                    <div className='address'>{arr[0].posts_address}</div>
                </div>
            </div>
        </ExhCommonSectionL>
    )
}
export const RightImg = (arr)=>{
    return(
        <ExhCommonSectionL>
            <div className="section">
                <div className='font_seciotn'>
                    <h2>{arr.post_title}</h2>
                    <p dangerouslySetInnerHTML={{__html:arr.post_excerpt}}></p>
                    <div className='address'>{arr.posts_address}</div>
                </div>
                <div className="section_img_wrap">
                    <CommonCarousel className='sw' Carouselarr={kjqz(arr.path)} />
                </div>
            </div>
        </ExhCommonSectionL>
    )
}
export const RightImgS = (arr)=>{
    return(
        <ExhCommonSectionL>
            <div className="section">
                <div className='font_seciotn'>
                    <h2>{arr[0].post_title}</h2>
                    <p dangerouslySetInnerHTML={{__html:arr[0].post_excerpt}}></p>
                    <div className='address'>{arr[0].posts_address}</div>
                </div>
                <div className="section_img_wrap">
                    <CommonCarousel className='sw' Carouselarr={kjqzs(arr)} />
                </div>
            </div>
        </ExhCommonSectionL>
    )
}
