import styled from 'styled-components'

export const LszlWrap = styled.div `
    @media(min-width:750px){
      .title_type_1{
            height:32px;
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            text-align:center;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:20px auto 30px;
            text-align:center;
            justify-content:center;
            .i_small{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-left:7px;             
            }
            .i_small2{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large2{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-right:7px;             
            }            
            span{
                padding:10px 40px;
                background:#00a1b4;
                border-radius:5px;
                margin:0 16px;
            }
        }
      .top{
        width:1200px;
        height:380px;
        border-radius:7px;
        overflow:hidden;
        display: flex;
        flex-direction:row;
        justify-content:space-between;
        align-items:center;
        background:#0197d6;
        border-radius:7px;
        margin:23px auto 50px;
        .video{
          width:610px;
          height:350px;
          margin-left:25px;
        }
        .topcontent{
          width:516px;
          margin-right:25px;
          display: flex;
          flex-direction:column;
          .title{
            font-size:30px;
            color:#fff;
            font-weight:bold;
          }
          .abstra{
            font-size:18px;
            line-height:30px;
            color:#fff;
            margin-top:30px;
          }
          .area{
            font-size:22px;
            color:#fff;
            font-weight:bold;
            margin-top:35px;
          }
        }
      }
      .sxzx_list{
          width:1240px;
          display: flex;
          flex-direction:row;
          justify-content:space-between;
          flex-wrap:wrap;
          margin:45px auto;
          .tab{
            width:395px;
            height:250px;
            border-radius:7px;
            overflow:hidden;
            background-size:cover;
            margin-bottom:35px;
            cursor: pointer;
            position: relative;
            .title{
              width:100%;
              height:54px;
              text-align:center;
              font-size:22px;
              line-height:54px;
              color:#fff;
              position: absolute;
              left:0;
              bottom:0;
              opacity:1;
            }
            .abstra{
              width:100%;
              height:100%;
              background-color:rgba(12,49,88,.6);
              display: flex;
              justify-content:center;
              align-items:center;
              font-size:18px;
              color:#fff;
              line-height:30px;
              padding:0 50px;
              box-sizing:border-box;
              opacity:0;
              text-indent:0;
            }
          }
          .tab:hover{
            .title{
              opacity:0;
            }
            .abstra{
              opacity:1;
            }
          }
      }
      .sw{
        width:1240px;
        margin:0 auto;
        position: relative;
        .lsleft{
          width:40px;
          position: absolute;
          left:0;
          top:50%;
          transform:translate(0,-50%);
          cursor: pointer;
          img{
            width:100%;
          }
        }
        .lsright{
          width:40px;
          position: absolute;
          right:0;
          top:50%;
          transform:translate(0,-50%);
          cursor: pointer;
          img{
            width:100%;
          }
        }
      }
      .sl{
          width:1125px;
          margin:50px auto 0;
          overflow:hidden;
          .swiper-wrapper{
            width:1240px;
            height:540px;
            margin:0 auto;
            .swiper-slide:nth-of-type(2n){
              flex-direction:column-reverse;
            }
            .swiper-slide:nth-of-type(2n+1){
              flex-direction:column;
            }
            .swiper-slide{
              height:482px;
              border-radius:7px;
              background:#f0f0f0;
              display: flex;
              align-items:center;
              justify-content: center;
              img{
                width:330px;
                height:238px;
                border-radius:7px;
                margin-top:17px;
                margin-bottom:17px;
              }
              .title{
                width:330px;
                font-size:23px;
                color:#3a4045;
                font-weight:bold;
                text-align:left;
                margin:25px auto;
              }
              .abr{
                width:330px;
                font-size:16px;
                color:#3a4045;
                height:140px;
                line-height:26px;
              }
            }
            .swiper-slide:hover{
              background:#bf0123;
              .title{
                width:330px;
                font-size:23px;
                color:#fff;
                font-weight:bold;
                text-align:left;
                margin:25px auto;
              }
              .abr{
                width:330px;
                font-size:16px;
                color:#fff;
                height:140px;
                line-height:26px;
              }
            }
            /* .swiper-slide-active{
              background:#bf0123;
              .title{
                width:330px;
                font-size:23px;
                color:#fff;
                font-weight:bold;
                text-align:left;
                margin:25px auto;
              }
              .abr{
                width:330px;
                font-size:16px;
                color:#fff;
                height:140px;
                line-height:26px;
              }
            } */
          }
      }
      .slm{
        display: none;
      }
      .commAbstra{
        width:1160px;
        margin:0 auto;
        position: relative;
        height:117px;
        .car{
          width:1120px;
          height:117px;
          padding:0 43px 0 60px;
          display: flex;
          align-items:center;
          justify-content:center;
          font-size:18px;
          line-height:25px;
          color:#3a4045;
          background:#f0f0f0;
          border-radius:7px;
          position: absolute;
          right:0;
        }
        .cal{
          width:77px;
          height:72px;
          background:#0197d6;
          font-size:21px;
          color:#fff;
          font-weight:bold;
          display: flex;
          align-items:center;
          justify-content:center;
          position: absolute;
          left:0;
          top:50%;
          transform:translate(0,-50%);
          z-index:20;
          border-radius:7px;
        }
      }
      .swm{
        display: none;
      }
      .sxzy_wrap{
        width:1240px;
        display: flex;
        flex-direction:row;
        justify-content: space-between;
        margin:0 auto;
        .sxzy_left{
          width:400px;
          height:530px;
          background-size:100% 100%;
          display: flex;
          justify-content:center;
          align-items:center;
          padding:0 43px;
          font-size:20px;
          line-height:28px;
          color:#fff;
        }
        .sxzy_leftm{
          display: none;
        }
        .sxzy_list{
          width:820px;
          display: flex;
          flex-direction:row;
          justify-content:space-between;
          flex-wrap:wrap;
          margin:0 auto;
          .tab{
            width:395px;
            height:250px;
            border-radius:7px;
            overflow:hidden;
            background-size:cover;
            margin-bottom:25px;
            cursor: pointer;
            position: relative;
            .title{
              width:100%;
              height:54px;
              text-align:center;
              font-size:22px;
              line-height:54px;
              color:#fff;
              position: absolute;
              left:0;
              bottom:0;
              opacity:1;
            }
            .abstra{
              width:100%;
              height:100%;
              background-color:rgba(12,49,88,.6);
              display: flex;
              justify-content:center;
              align-items:center;
              font-size:18px;
              color:#fff;
              line-height:30px;
              padding:0 50px;
              box-sizing:border-box;
              opacity:0;
              text-indent:0;
            }
          }
          .tab:hover{
            .title{
              opacity:0;
            }
            .abstra{
              opacity:1;
            }
          }
      }
      }
      .sxzs{
        .sxzx_top{
          width:1240px;
          height:320px;
          display: flex;
          border-radius:7px;
          overflow:hidden;
          margin:0 auto;
          .left{
            width:590px;
            height:100%;
            display: flex;
            justify-content:center;
            align-items:center;
            background:#0197d6;
            padding:0 80px;
            box-sizing:border-box;
            font-size:20px;
            color:#fff;
            line-height:35px;
          }
          .right{
            width:650px;
            position: relative;
            img{
              width:auto;
              height:100%;
            }
            .cor{
              position: absolute;
              left:0;
              top:0;
              width: 0;
              height: 0;
              border-left: 0px solid transparent;
              border-right: 70px solid transparent;
              border-bottom: 320px solid #0197d6;
            }
          }
        }
      }
      .hdbd_wrap{
            width:1200px;
            overflow:hidden;
            display:flex;
            flex-direction:row;
            flex-wrap:wrap;
            justify-content:space-between;
            margin:0 auto 50px;
      }  
    }
    @media(max-width:750px){
      .title_type_1{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:.2rem auto;
            text-indent:0;
            justify-content:center;
            .i_small2{
                        width:.16rem;
                        height:.16rem;
                        background:#f55c5d;
            }
            .i_large2{
                        width:.3rem;
                        height:.3rem;
                        background:#00a1b4;   
                        margin-right:.07rem;             
            }
            .i_small{
                        width:.16rem;
                        height:.16rem;
                        background:#f55c5d;
            }
            .i_large{
                        width:.3rem;
                        height:.3rem;
                        background:#00a1b4;   
                        margin-left:.07rem; 
                        margin-right:.07rem;                
            }             
            span{
                        padding:.1rem .4rem;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 .16rem 0 0;
                        box-sizing:border-box;
            }
        }
      .top{
        width:7rem;
        border-radius:7px;
        overflow:hidden;
        display: flex;
        flex-direction:column;
        justify-content:space-between;
        align-items:center;
        background:#0197d6;
        border-radius:7px;
        margin:23px auto;
        .video{
          width:95%;
          margin:.1rem auto;
        }
        .topcontent{
          width:95%;
          display: flex;
          flex-direction:column;
          margin:.2rem auto;
          .title{
            font-size:.3rem;
            color:#fff;
            font-weight:bold;
          }
          .abstra{
            font-size:.25rem;
            line-height:.3rem;
            color:#fff;
            margin-top:.2rem;
          }
          .area{
            font-size:.25rem;
            color:#fff;
            font-weight:bold;
            margin-top:.25rem;
          }
        }
      }
      .sxzx_list{
          width:95%;
          display: flex;
          flex-direction:column;
          justify-content:center;
          flex-wrap:wrap;
          margin:15px auto;
          .tab{
            width:100%;
            height:3.5rem;
            overflow:hidden;
            background-size:3rem 1.8rem;
            background-position: .2rem center;
            background-repeat:no-repeat;
            position: relative;
            margin-bottom:.3rem;
            padding:.3rem 0 .3rem 3.4rem;
            display: flex;
            justify-content:center;
            flex-direction:column;
            position: relative;
            .title{
              flex:1;
              height:.5rem;
              text-align:left;
              font-size:.26rem;
              line-height:.54rem;
              color:#000;
              font-weight:bold;
              text-indent:0;
            }
            .abstra{
              width: 3.6rem;
              height:3rem;
              font-size:.24rem;
              color:#fff;
              line-height:.35rem;
              padding: .2rem 0rem;
              box-sizing:border-box;
              color:#000;
              text-indent:0;
            }
          }
          /* .tab:hover{
            .title{
              opacity:0;
            }
            .abstra{
              opacity:1;
            }
          } */
      }
      .swp{
        display: none;
      }
      .sw{
        width:100%;
        margin:0 auto;
        position: relative;
        .lsleft{
          width:40px;
          position: absolute;
          left:0;
          top:50%;
          transform:translate(0,-50%);
          cursor: pointer;
          img{
            width:100%;
          }
        }
        .lsright{
          width:40px;
          position: absolute;
          right:0;
          top:50%;
          transform:translate(0,-50%);
          cursor: pointer;
          img{
            width:100%;
          }
        }
      }
      .sl{
        display: none;
      }
      .slm{
          width:5.6rem;
          margin: 20px auto 0;
          overflow:hidden;
          .swiper-wrapper{
            width:5.6rem;
            height: 6rem;
            margin:0 auto;
            .swiper-slide{
              height:6rem;
              border-radius:7px;
              background:#f0f0f0;
              display: flex;
              align-items:center;
              flex-direction:column;
              justify-content: center;
              img{
                width:70%;
                border-radius:7px;
                margin-top:7px;
              }
              .title{
                width:90%;
                font-size:.3rem;
                color:#3a4045;
                font-weight:bold;
                text-align:left;
                margin:.25rem auto;
              }
              .abr{
                width:90%;
                font-size:.28rem;
                color:#3a4045;
                height:2.4rem;
                line-height:.4rem;
              }
            }
          }
      }
      .commAbstra{
        width: 7rem;
        margin: 0 auto;
        position: relative;
        min-height: 2.7rem;
        .car{
          /* width: 6.84rem; */
          min-height:2.7rem;
          padding: 0 .2rem 0 .7rem;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          font-size: .25rem;
          line-height: .4rem;
          color: #000;
          background: #f0f0f0;
          border-radius: 7px;
          position: absolute;
          right: 0;
        }
        .cal{
          width:.5rem;
          height:1.3rem;
          background:#0197d6;
          font-size:.26rem;
          color:#fff;
          font-weight:bold;
          display: flex;
          align-items:center;
          justify-content:center;
          position: absolute;
          left:0;
          top:50%;
          transform:translate(0,-50%);
          z-index:20;
          border-radius:7px;
          text-align:center;
          line-height:.3rem;
        }
      }
      .sxzy_wrap{
        width:7rem;
        display: flex;
        flex-direction:column;
        justify-content: space-between;
        margin:0 auto;
        .sxzy_left{
          width:100%;
          height:3.7rem;
          background-size:100% 100%;
          display: flex;
          justify-content:center;
          align-items:center;
          padding:0 43px;
          font-size:.25rem;
          line-height:.4rem;
          color:#fff;
        }
        .sxzy_leftpc{
          display: none;
        }
        .sxzy_list{
          width:95%;
          display: flex;
          flex-direction:column;
          justify-content:center;
          flex-wrap:wrap;
          margin:15px auto;
          .tab{
            width:100%;
            height:3.5rem;
            overflow:hidden;
            background-size:3rem 1.8rem;
            background-position: .2rem center;
            background-repeat:no-repeat;
            position: relative;
            margin-bottom:.3rem;
            padding:.3rem 0 .3rem 3.4rem;
            display: flex;
            justify-content:center;
            flex-direction:column;
            position: relative;
            .title{
              flex:1;
              height:.5rem;
              text-align:left;
              font-size:.26rem;
              line-height:.54rem;
              color:#000;
              font-weight:bold;
              text-indent:0;
            }
            .abstra{
              width:100%;
              height:3rem;
              font-size:.24rem;
              color:#fff;
              line-height:.35rem;
              padding: .2rem 0rem;
              box-sizing:border-box;
              color:#000;
              text-indent:0;
            }
          }
        }
      }
      .sxzs{
        .sxzx_top{
          width:7rem;
          display: flex;
          overflow:hidden;
          margin:0 auto;
          flex-direction:column;
          justify-content:flex-end;
          .left{
            width:100%;
            display: flex;
            justify-content:center;
            align-items:center;
            box-sizing:border-box;
            font-size:.26rem;
            color:#000;
            line-height:.4rem;
            margin-bottom:.1rem;
            text-align:justify;
            text-indent:.52rem;
          }
          .right{
            width:100%;
            position: relative;
            img{
              width:100%;
            }
            /* .cor{
              position: absolute;
              left:0;
              top:0;
              width: 0;
              height: 0;
              border-left: 0px solid transparent;
              border-right: 70px solid transparent;
              border-bottom: 320px solid #0197d6;
            } */
          }
        }
      }
    .hdbd_wrap{
            width:90%;
                overflow:hidden;
                margin:.3rem auto .3rem;

        }  
}
`