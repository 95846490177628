import React from 'react'
import styles from './list.module.scss'
import Crumbs from './list-components/crumbs'
import { ClockCircleOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import qs from 'query-string'
import PubSub from 'pubsub-js';
class unionCon extends React.Component {
  state = {
    articleCon: {},
    articleNext: null,
    articleId: 0,
    termId: 0
  }
  componentDidMount() {
    const { search } = this.props.location
    const { articleId, termId } = qs.parse(search)
    this.setState({ articleId, termId }, () => {
      this.getArticleData()
      this.getArticleNext()
      this.getCrumbsType()
    })
  }
  getCrumbsType() {
    const { termId } = this.state
    React.$request({
      url: 'Portal/zt/termArticles',
      method: 'get',
      params: {
        termId: 457
      }
    }).then(res => {
      res.data.son.forEach(el => {
        if (el.term_id == termId) {
          PubSub.publish('typeId', el.post_keywords)
          return;
        }
        if (el.son.length > 0) {
          el.son.forEach(al => {
            if (al.term_id == termId) {
              PubSub.publish('typeId', al.post_keywords)
              return;
            }
          })
        }
      })
    })
  }
  getArticleData() {
    const { articleId } = this.state
    React.$request({
      url: 'Portal/index/post_detail',
      method: 'get',
      params: {
        id: articleId
      }
    }).then(res => {
      this.setState({ 'articleCon': res.data })
    })
  }
  getArticleNext() {
    const { articleId, termId } = this.state
    React.$request({
      url: 'Portal/Zxjnh/getPreNext',
      method: 'get',
      params: {
        id: articleId,
        termId: termId
      }
    }).then(res => {
      this.setState({ 'articleNext': res })
    })
  }
  turn(id) {
    this.setState({ articleId: id }, () => {
      this.getArticleData()
      this.getArticleNext()
    })
  }
  back() {
    this.props.history.goBack()
  }
  render() {
    const { articleCon, articleNext } = this.state
    const { search } = this.props.location
    const { articleId, termId } = qs.parse(search)
    return (
      <div className={styles.conBj}>
        <Crumbs termId={termId} />
        <div className={styles.contentStyle}>
          <p className='title'>{articleCon.post_title}</p>
          <p className='time'><ClockCircleOutlined />&nbsp;&nbsp;{articleCon.post_date}</p>
          <div className='main' dangerouslySetInnerHTML={{ __html: articleCon.post_content }}>
          </div>
          {
            articleNext !== null ?
              <div className='listBox'>
                {articleNext.pre.post_title ? <p onClick={() => this.turn(articleNext.pre.id)}>上一条：{articleNext.pre.post_title}</p> : null}
                {articleNext.next.post_title ? <p onClick={() => this.turn(articleNext.next.id)}>下一条：{articleNext.next.post_title}</p> : null}
                <div className='ljfhBtn' onClick={() => this.back()}>
                  Return
                </div>
              </div> : null
          }

        </div>

      </div>
    )
  }
}
export default withRouter(unionCon)
