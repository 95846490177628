import styled from 'styled-components';

export const TalentPlanWrap = styled.div `
@media(min-width:750px) {
    width: 1200px;
    overflow: hidden;
    margin: 0 auto;

    .header {
        width: 100%;
        margin-top:50px;
        padding: 0 20px;
        .condition-item{
            display:flex;
            //align-items: center;
            //margin-bottom: 20px;
            font-size:18px;
            .condition-item-label{
                font-size:18px;
                font-weight:bold;
            }
            
            .condition-item-values{
                display:flex;
                flex-wrap: wrap;
                flex:1;
                .condition-item-value{
                    margin-right:20px;
                    box-sizing:border-box;
                    height:30px;
                    line-height:30px;
                    text-align:center;
                    padding:0 20px;
                    border:1px solid #0091b6;
                    color:#0091b6
                    border-radius:4px;
                    cursor:pointer;
                    margin-bottom: 20px;
                }
                
                .condition-item-value.on{
                    background:#0091b6;
                    color:#FFFFFF;
                }
            }
        }
    }
    .paginationWrapM{
        display:none;
    }
    
    .paginationWrap{
        width:1200px;
        overflow:hidden;
        //margin:60px auto;
        .empty{
            padding:100px 0;
            margin: 0 auto;
        }
        .other_section_wrap{
            width:1200px;
            overflow:hidden;
            display:flex;
            flex-direction:row;
            flex-wrap:wrap;
            justify-content:space-between;
            .ycjh_section{
                width: 541px;
                margin: 10px;
                height: 170px;
                margin-top: 30px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            
                .ycjh_section_img_wrap {
                    width: 270px;
                    height: 170px;
                    border-radius: 7px;
                    overflow: hidden;
                    position: relative;
                    cursor:pointer;
                    .zd{
                        width:50px;
                        position: absolute;
                        top:10px;
                        left:0;
                        img{
                            width:100%;
                        }
                    }
                    img {
                        width: 100%;
                        min-height: 100%;
                    }
                }
                .ycjh_section_detail {
                    width: 252px;
                    height: 170px;
                    display: flex;
                    text-align: left;
                    flex-direction: column;
                    justify-content: space-between;
            
                    .ycjh_section_detail_title {
                        cursor:pointer;
                        font-size: 22px;
                        color: #221815;
                        line-height: 30px;
                        width: 100%;
                    }
            
                    .ycjh_section_subtitle{
                        font-size: 22px;
                        color: #666666;
                        letter-spacing: 0;
                        text-align: justify;
                    }
                    .ycjh_section_date {
                        width: 100%;
                        color: #009cbe;
                        font-size: 15px;
                    }
                }
            }
        }
        
        .pagenation{
            width:100%;
            margin:60px auto;
            text-align:center;
            .ant-pagination-item{
                
            }
            .ant-pagination-item-active {
                border-radius:0;
                color:#fff;
                background: #009cbe;
                a{
                    color:#fff;     
                }
            }
        }
    }
    
    .ant-modal{
        .ant-modal-body{
            text-align:center;
            .video{
                width:750px;
                height:450px;
                border-radius:10px;
                overflow:hidden;
                // background:#009cbe;
                background:#000000;
                video{
                    width:100%;
                    height:100%;
                }
            }
        }
    }
}

@media(max-width:750px) {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;

    .header {
        width: 100%;
        overflow: hidden;
        width: 100%;
        margin-top:.5rem;
        padding: 0 .2rem;
        .condition-item{
            display:flex;
            // align-items: center;
            margin-bottom: .2rem;
            font-size:.18rem;
            .condition-item-label{
                font-weight:bold;
            }
            
            .condition-item-values{
                display:flex;
                flex-wrap: wrap;
                flex:1;
                .condition-item-value{
                    margin-right:.2rem;
                    box-sizing:border-box;
                    height:.5rem;
                    line-height:.5rem;
                    text-align:center;
                    padding:0 .3rem;
                    border:1px solid #0091b6;
                    color:#0091b6
                    border-radius:4px;
                    cursor:pointer;
                    margin-bottom:.2rem;
                }
                
                .condition-item-value.on{
                    background:#0091b6;
                    color:#FFFFFF;
                }
            }
        }
    }
    
    
    .paginationWrap{
        display: none;
    }
    .paginationWrapM{
        width:7.1rem;
        overflow:hidden;
        margin:.1rem auto;
        .paginationWrapM_list{
            width:100%;
            overflow:hidden;
            margin:.3rem auto;
        }
        
        .empty{
            padding:1rem 0;
        }
        
        .ycjh_section{
            width: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: .27rem 0;
            border-bottom: 0.01rem dashed #a0a0a0;
        
            .ycjh_section_img_wrap {
                width: 2.45rem;
                height: 1.5rem;
                border-radius: .1rem;
                overflow: hidden;
                position: relative;
                .zd{
                    width:.8rem;
                    position: absolute;
                    top:.1rem;
                    left:0;
                    img{
                        width:100%;
                    }
                }
                img {
                    width: 100%;
                    min-height: 100%;
                }
            }
        
            .ycjh_section_detail {
                flex: 1;
                margin-left: .1rem;
                display: flex;
                align-items: center;
                text-align: left;
                flex-direction: column;
                height: 1.5rem;
                justify-content: space-between;
        
                .ycjh_section_detail_title {
                    font-size: .29rem;
                    color: #000;
                    line-height: .4rem;
                    width: 100%;
                }
        
                .ycjh_section_date {
                    width: 100%;
                    text-align: left;
                    color: #00a1b4;
                    font-size: .28rem;
                }
            }
        }
        
        .see_more{
            width:2.5rem;
            height:.64rem;
            background:#009cbd;
            border-radius:.04rem;
            font-size:.3rem;
            color:#fff;
            line-height:.64rem;
            text-align:center;
            margin:.5rem auto 0;
        }
    }
    
    .ant-modal{
        .ant-modal-body{
            text-align:center;
            .video{
                width:6.0rem;
                height:4rem;
                border-radius:10px;
                overflow:hidden;
                // background:#009cbe;
                background:#000000;
                margin:0 auto;
                video{
                    width:100%;
                    height:100%;
                }
            }
        }
    }
}

`
