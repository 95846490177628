import styled from 'styled-components';

export const QuestionsWrap = styled.div `
    @media(min-width:750px){
        width:1200px;
        margin:0 auto;
        .header{
            width:100%;
            img{
                width:100%;
                height:auto;
            }
        }
        .content{
            width:100%;
            background: #009DBE;
            padding:0 40px 40px 40px;
            .question-content{
                background: #FFF;
                .question-title{
                    font-size: 32px;
                    color: #000000;
                    border-bottom:1px solid #D9D9D9;
                    padding:30px 40px;
                }
            }
            .question-list{
                .question-item{
                    margin:0 40px; 
                    padding:30px 0;
                    border-bottom:1px solid #EEEEEE;
                    .text{
                        padding-left:15px;
                        display:flex;
                        flex:1;
                    }
                    .question{
                        font-size: 28px;
                        color: #221815;
                        letter-spacing: 0;
                        text-align: justify;
                        display:flex;
                        align-items:center;
                        margin-bottom:10px;
                    }
                    .answer{
                        font-size: 28px;
                        color: #555555;
                        letter-spacing: 0;
                        text-align: justify;
                        display:flex;
                        // align-items:center;
                    }
                }
            }
            
            
            .question-icon{
                width:40px;
                height:29px;
                display:flex;
                font-size:24px;
                color:#FFF;
                background:#0092b6;
                border-top-right-radius:40%;
                border-bottom-left-radius:40%;
                justify-content:center;
                align-items:center;
            }
            .answer-icon{
                width:40px;
                height:29px;
                display:flex;
                font-size:24px;
                color:#FFF;
                background:#e98f17;
                border-bottom-right-radius: 50%;
                border-top-left-radius: 50%;
                justify-content:center;
                align-items:center;
                margin-top: 8px;
            }
        }
        .goBack{
            width:100px;
            height:40px;
            background:#00a1b4;
            margin:30px auto;
            font-size:18px;
            color:#fff;
            text-align:center;
            line-height:40px;
            border-radius:5px;
            cursor: pointer;
        }
    }
    @media(max-width:750px){
        width:100%;
        margin:0 auto;
        // .header{
        //     display:none;
        // }
        .header{
            width:100%;
            img{
                width:100%;
                height:auto;
            }
        }
        .content{
            width:100%;
            background: #009DBE;
            padding:0 .25rem .4rem .25rem;
            .question-content{
                background: #FFF;
                .question-title{
                    font-size: .32rem;
                    color: #000000;
                    border-bottom:1px solid #D9D9D9;
                    padding:.3rem .4rem;
                }
            }
            .question-list{
                .question-item{
                    margin:0 .4rem; 
                    padding:.3rem 0;
                    border-bottom:1px solid #EEEEEE;
                    .text{
                        padding-left:.15rem;
                        display:flex;
                        flex:1;
                    }
                    .question{
                        font-size: .28rem;
                        color: #221815;
                        letter-spacing: 0;
                        text-align: justify;
                        display:flex;
                        align-items:center;
                        margin-bottom:.1rem;
                    }
                    .answer{
                        font-size: .28rem;
                        color: #555555;
                        letter-spacing: 0;
                        text-align: justify;
                        display:flex;
                        // align-items:center;
                    }
                }
            }
            
            
            .question-icon{
                width:.4rem;
                height:.29rem;
                display:flex;
                font-size:.24rem;
                color:#FFF;
                background:#0092b6;
                border-top-right-radius:40%;
                border-bottom-left-radius:40%;
                justify-content:center;
                align-items:center;
            }
            .answer-icon{
                width:.4rem;
                height:.29rem;
                display:flex;
                font-size:.24rem;
                color:#FFF;
                background:#e98f17;
                border-bottom-right-radius: 50%;
                border-top-left-radius: 50%;
                justify-content:center;
                align-items:center;
                margin-top: .08rem;
            }
        }
        
        .goBack{
            width:1rem;
            height:.4rem;
            background:#00a1b4;
            margin:.3rem auto;
            font-size:.18rem;
            color:#fff;
            text-align:center;
            line-height:.4rem;
            border-radius:5px;
            cursor: pointer;
        }
    }
`
