import React from 'react';
import {Pagination, Skeleton, LocaleProvider, Empty} from 'antd';
import {withRouter} from 'react-router-dom';
import {ListWrap} from './style'
import SectionWrapNew from 'common/newSectionItem'
import zhCN from 'antd/es/locale-provider/zh_CN';
import ListSection from 'common/navSection/list'
class ListTab2 extends React.Component{
    render(){
        return(
            !this.state.listLoading && this.state.label.length&&this.state.cat_id?
            <ListWrap>
                <ListSection catName={this.state.cat_name} catId={this.state.cat_id} name={this.state.name}/>
                <div className='tabWrap'>
                    {
                        this.state.label.map((item,index)=>{
                            return(
                                <div className={this.state.currentLabel === index?"tab_label tab_label_active":"tab_label"} onClick={()=>this.changeLabel(item.term_id,index)} key={item.term_id}>{item.name}</div>
                            )
                        })
                    }
                </div>
                {this.state.isWwmxdShow ?
                    <div className='tabWrap'>
                        {
                            this.state.secondLabel.map((item,index)=>{
                                return(
                                    <div className={this.state.secondCurrentLabel === index?"tab_label tab_label_active":"tab_label"} onClick={()=>this.changeSecondLabel(item.term_id, index)} key={item.term_id}>{item.name}</div>
                                )
                            })
                        }
                    </div>
                    :
                    null
                }
                <div className="hdbd_wrap">
                    {
                        this.state.list.length? this.state.list.map((item)=>{
                            return(
                                <SectionWrapNew secarr={item} key={item.id}/>
                            )
                        }):<Empty className='empty'/>
                    }
                </div>
                {
                    this.state.list.length?
                    <LocaleProvider locale={zhCN}>
                        <Pagination  size="small" defaultCurrent={this.state.page} total={this.state.allCount} onChange={this.onChange} showQuickJumper className='pagenation'/>
                    </LocaleProvider>:null
                }
            </ListWrap>:<Skeleton active />
        )
    }
    constructor(props){
        super(props)
        this.state = {
            list:[],
            listLoading: null,
            allCount: null,
            page: 1,
            cat_name:null,
            cat_id:null,
            name:null,
            label:[],
            secondLabel: [],
            currentLabel:0,
            secondCurrentLabel:0,
            termId: this.props.match.params.id,
            isWwmxdShow: false,
            limit: 10,
            gIndex: 0,
            gId: 0
        }
        this.onChange = this.onChange.bind(this)
    }
    changeLabel(id,index){
        this.setState({
            currentLabel: index,
        })
        if (this.state.label[index].name === '万万没想到') {
            this.setState({
                isWwmxdShow: true
            })
            this.changeSecondLabel(844, 0)
        } else {
            this.setState({
                currentLabel: index,
                isWwmxdShow: false
            })
            this.getList(id, index)
        }
    }
    changeSecondLabel (id, index) {
        this.setState({
            secondCurrentLabel: index
        })
        this.getList(id, index)
    }
    initNav(id){
        React.$request({
            url: 'Portal/index/navLists',
            method: 'get',
            params: {
                termId: id
            }
        }).then(res => {
            console.log()
            this.setState({
                cat_name: res.nav[0].name,
                cat_id: res.nav[0].term_id,
                name: res.nav[1].name
            })
            document.title = res.nav[1].name
        })
        React.$request({
            url: 'Portal/zt/getTermTrees',
            method: 'get',
            params: {
                termId: id
            }
        }).then(res => {
            let  labels = [{term_id: id, name: '全部'}]
            labels = labels.concat(res.data.son || [])
            let  secondLabels = [{term_id: '844', name: '全部'}]
            secondLabels = secondLabels.concat(res.data.son[0].son || [])
            this.setState({
                label: labels,
                secondLabel: secondLabels
            })
        })
    }
    goBack(){
        this.props.history.goBack()
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    onChange(pageNumber) {
        this.setState({
            page: pageNumber
        })
        this.getList(this.state.gId, this.state.gIndex, pageNumber)
    }
    getList (id, index, page = 1) {
        this.setState({
            page: page
        })
        this.setState({
            list: [],
            listLoading: true
        })
        let level = ''
        if (index === 0) {
            level = 'children'
        } else {
            level = 'current'
        }
        React.$request({
            url:'Portal/zt/getListsByTermId',
            method:'get',
            params: {
                limit: this.state.limit,
                page: page,
                termId: id,
                level: level
            }
        }).then(res=>{
            this.setState({
                gId: id,
                gIndex: index,
                list: res.data.lists,
                allCount: parseInt(res.data.totalPage) * this.state.limit,
                listLoading: false
            })
            this.goTo()
        })
    }
    componentDidMount(){
        if(this.props.match.params.id){
            this.initNav(this.props.match.params.id)
            this.getList(this.props.match.params.id, 0)
        }
    }
}

export default withRouter(ListTab2)
