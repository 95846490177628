import { fromJS } from 'immutable'
import * as contants from './constants'

const library = fromJS({
    historyPath:''
})

export default(state=library,action)=>{
    console.log(action)
    switch(action.type){
        case contants.HISTORYPATH:
            return state.set('historyPath',action.data)
        default:
            return state
    }
}