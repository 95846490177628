import React from 'react'
import styles from './index.module.scss'
import { changeImg } from '../../pub'




class TopBanner extends React.Component {
  render() {
    const {lmjs}=this.props
    return (
        <div className={['flexBox-sb',styles.topBanner].join(' ')} >
            <img src={changeImg(lmjs.articles[0].smeta.photo[0].url)} alt="" />
            <div className='right'>
                <p className="title">{lmjs.articles[0].post_title}</p>
                <p dangerouslySetInnerHTML={{ __html: lmjs.articles[0].post_content }}></p>
            </div>
        </div>
    )
  }
}
export default TopBanner
