import React from 'react'
import {ExIndexSdcx} from './style'
import {NavLink} from 'react-router-dom'

const ExhSdcx = (arr)=>{
    return(
        <ExIndexSdcx>
            {
                arr.map((item)=>{
                    return(
                        <NavLink key={item.id} className='img_wrap' to='/exhibition/sdcxkjz'>
                            <img src={item.path} alt=''/>
                        </NavLink>
                    )
                })
            }
        </ExIndexSdcx>
    )
}
export default ExhSdcx