import React from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'
import styles from '../index.module.scss'
import { changeImg } from '../pub';
import { Link } from 'react-router-dom';

export default class SellMetting extends React.Component {
    componentDidMount() {
        let thumbs = new Swiper('.swiper-container-thrumbs', {
            slidesPerView: 5,
            spaceBetween: 10,
            freeMode: true,
            loop: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
        })
        let bigSwiper = new Swiper('.big-swiper-container', {
            spaceBetween: 10,
            loop: true,
            navigation: {
                prevEl: '.tjh-turnBtn-l',
                nextEl: '.tjh-turnBtn-R',
            },
            thumbs: {
                swiper: thumbs
            }
        });

        // 移动端
        let thumbsYD = new Swiper('.thrumbs-yd', {
            slidesPerView: 3,
            spaceBetween: 10,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
        })
        new Swiper('.big-yd', {
            spaceBetween: 10,
            navigation: {
                prevEl: '.tjh-yd-turnBtn-l',
                nextEl: '.tjh-yd-turnBtn-R',
            },
            thumbs: {
                swiper: thumbsYD
            }
        });


    }
    render() {
        const { tjhData } = this.props
        return (
            <div className={styles.sellMetting}>
                <p className="title">2024科普作品推介
                    <Link className='link' to={`/curatorSessionList?termId=${tjhData.term_id}&typeId=${tjhData.post_keywords}`}>
                        更多
                    </Link>
                </p>
                <div className='bigImg'>
                    <div className='turnBtn tjh-turnBtn-l'><LeftOutlined /></div>
                    <div className="big-swiper-container">
                        <div className="swiper-wrapper">
                            {
                                tjhData.articles.slice(0, 10).map(el => {
                                    return (

                                        <div className='swiper-slide' key={el.id}>
                                            <Link to={`/curatorSessionCon?articleId=${el.id}&termId=${el.term_id}`} key={el.id}>
                                                <img src={el.smeta.photo ? changeImg(el.smeta.photo[0].url) : ''} alt="" />
                                            </Link>
                                        </div>

                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='turnBtn tjh-turnBtn-R'><RightOutlined /></div>
                </div>
                <div className="swiperBox">
                    <div className="swiper-container-thrumbs">
                        <div className="swiper-wrapper">
                            {
                                tjhData.articles.slice(0, 10).map((el, i) => {
                                    return (
                                        <div className='swiper-slide' key={el.id}>
                                            <img src={el.smeta.photo ? changeImg(el.smeta.photo[0].url) : ''} alt="" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>


                {/* 移动端 */}

                <div className='bigImg-yd'>
                    <div className='turnBtn tjh-yd-turnBtn-l'><LeftOutlined /></div>
                    <div className="big-yd">
                        <div className="swiper-wrapper">
                            {
                                tjhData.articles.slice(0, 10).map(el => {
                                    return (

                                        <div className='swiper-slide' key={el.id}>
                                            <Link to={`/curatorSessionCon?articleId=${el.id}&termId=${el.term_id}`} key={el.id}>
                                                <img src={el.smeta.photo ? changeImg(el.smeta.photo[0].url) : ''} alt="" />
                                            </Link>
                                        </div>

                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='turnBtn tjh-yd-turnBtn-R'><RightOutlined /></div>
                </div>
                <div className="swiperBox-yd">
                    <div className="thrumbs-yd">
                        <div className="swiper-wrapper">
                            {
                                tjhData.articles.slice(0, 10).map((el, i) => {
                                    return (
                                        <div className='swiper-slide' key={el.id}>
                                            <img src={el.smeta.photo ? changeImg(el.smeta.photo[0].url) : ''} alt="" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <a href='https://www.wcsl.org.cn/' target="_blank"><img src="${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/curatorSession/banner.jpg" alt="" className='banner' /></a>
            </div>
        )
    }
}
