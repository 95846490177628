/*
 * @Author: your name
 * @Date: 2020-09-15 15:30:11
 * @LastEditTime: 2020-09-17 18:18:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \kxzxweb\src\pages\education\components\jnh\kxsgp.js
 */
import React, { memo, useEffect } from 'react'
import { KxsgpWrap } from './style'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'
import { NavLink } from 'react-router-dom'
const Kxsgp = memo((props) => {
  const { kxsgpArr } = props
  useEffect(() => {
    new Swiper('.swiper-container-jxhd', {
      slidesPerView: 3,
      spaceBetween: 0,
      centeredSlides: !0,
      loop: true,
      navigation: {
        nextEl: '.next2',
        prevEl: '.prev2',
      },
    })
    new Swiper('.swiper-container-m', {})
  }, [])
  return (
    <KxsgpWrap>
      <div className="h3 cm10">
        <div className="title_type_1">
          <i className="i_small"></i>
          <i className="i_large"></i>
          <span>{kxsgpArr.name}</span>
          <i className="i_large2"></i>
          <i className="i_small2"></i>
        </div>
      </div>
      <div className="jxhd_sw">
        <div className="swiper-container-jxhd">
          <div className="swiper-wrapper">
            {kxsgpArr.list.map((item) => {
              return (
                <div
                  className="swiper-slide swiper-slide-jxhd"
                  key={item.id}
                  style={{ backgroundImage: 'url(' + item.path + ')' }}
                >
                  <div className="navlink">
                    <div className="swMask"></div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="swiper-button swiper-button-next next2"></div>
          <div className="swiper-button swiper-button-prev prev2"></div>
        </div>
      </div>
      <div className="jxhd_swm">
        <div className="swiper-container-m">
          <div className="swiper-wrapper">
            {kxsgpArr.list.map((item) => {
              return (
                <div
                  className="swiper-slide swiper-slide-jxhd"
                  key={item.id}
                  style={{ backgroundImage: 'url(' + item.path + ')' }}
                >
                  <NavLink
                    to={'/article/' + item.id}
                    className="navlink"
                  ></NavLink>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </KxsgpWrap>
  )
})

export default Kxsgp
