import styled from 'styled-components';

export const LibraryHeader = styled.div`
    @media (max-width: 750px) {
        width:100%;
        height:4rem;
        overflow:hidden;
        background-position:center center;
        background-size:cover;
        background-repeat:no-repeat;
    }
    @media (min-width: 750px) {
        width:100%;
        height:400px;
        overflow:hidden;
        display:flex;
        background-position:center center;
        background-size:cover;
        background-repeat:no-repeat;
    }

`
export const LibraryNav = styled.div`
    @media (max-width: 750px) {
        width:7.1rem;
        margin:.3rem auto;
        height:.8rem;
        overflow-x:scroll;
        overflow-y:hidden;
        ::-webkit-scrollbar {
                 display: none;
        }
        div{   
            /* display:flex;
            align-items:center;
            flex-wrap: wrap;
            justify-content:space-between; */ 
            width:30rem;  
            .library_nav_link{
                width:2.26rem;
                height:.8rem;
                font-size: .26rem;
                color: #009cbd;
                display:block;
                text-align:center;
                line-height:.8rem;
                background:#eeeeee;
                border-radius:.04rem;
                margin-bottom:.25rem;
                float:left;
                margin:0 .1rem;
            }
            .library_nav_link_active{
                color: #fff;
                background: #009cbd;
            }
        }

    }
    @media (min-width: 750px) {
        width:190px;
        overflow:hidden;
        float:left;
        border-radius:6px;
        .library_nav_link{
            width:190px;
            height:50px;
            font-size: 20px;
            color: #009cbe;
            background:#eeeeee;
            display:block;
            text-align:center;
            line-height:50px;
            margin-right:10px;
            margin-bottom:1px;
        }
        .library_nav_link_active{
            color: #fff;
            background: #009cbe;
            font-weight:bold;
        }
    }
`

export const LibraryRoute = styled.div`
    @media (min-width: 750px) {
        width:953px;
        float:right;
    }
`

export const LibraryWrap = styled.div`
    @media (min-width: 750px) {
        .library_content{
            width:1200px;
            overflow:hidden;
            margin:30px auto;
        }
    }
`