/*
 * @Author: your name
 * @Date: 2020-09-15 09:37:49
 * @LastEditTime: 2020-09-19 22:50:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \kxzxweb\src\pages\education\components\jnh\style.js
 */
import styled from 'styled-components'

export const JnhWrap = styled.div`
  @media (min-width: 750px) {
    width: 100%;
    background-image: url('${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/jnh/bg_02.png');
    background-size: 100% auto;
    background-position: center top;
    background-color: #69e1d7;
    padding-bottom: 50px;
    .banner {
      width: 100%;
      position: relative;
      z-index: 40;
      img {
        width: 100%;
      }
    }
    .mbanner {
      width: 100%;
      display: none;
      img {
        width: 100%;
      }
    }
    .nav {
      width: 1240px;
      height: 543px;
      background: url('${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/jnh/nav_03.png') 0 0 no-repeat;
      background-size: 1051px 543px;
      margin: -180px auto 30px;
      position: relative;
      .nbtn {
        position: absolute;
        cursor: pointer;
        display: block;
        /* border: 1px solid red; */
        .ball {
          width: 30px;
          height: 30px;
          position: absolute;
          background: #3fbfbe;
          border-radius: 50%;
        }
        .font {
          font-size: 18px;
          color: #010e16;
          position: absolute;
        }
      }
      .nbtn:hover {
        .font {
          color: #fd7f4f;
        }
        .ball {
          background: #fd7f4f;
        }
      }
      .nbtn:nth-of-type(1) {
        width: 144px;
        height: 40px;
        left: 0;
        top: 180px;
        .ball {
          right: 11px;
          top: 0;
        }
        .font {
          left: 3px;
        }
      }
      .nbtn:nth-of-type(2) {
        width: 154px;
        height: 40px;
        left: 0;
        top: 230px;
        .ball {
          right: 21px;
          top: 0;
        }
      }
      .nbtn:nth-of-type(3) {
        width: 164px;
        height: 40px;
        left: 10px;
        top: 280px;
        .ball {
          right: 34px;
          top: 0;
        }
      }
      .nbtn:nth-of-type(4) {
        width: 160px;
        height: 40px;
        left: 11px;
        top: 330px;
        .ball {
          right: 15px;
          top: 0;
        }
      }
      .nbtn:nth-of-type(5) {
        width: 160px;
        height: 40px;
        left: 36px;
        top: 380px;
        .ball {
          right: 15px;
          top: 0;
        }
        .font {
          left: 14px;
        }
      }
      .nbtn:nth-of-type(6) {
        width: 160px;
        height: 40px;
        left: 70px;
        top: 428px;
        .ball {
          right: 15px;
          top: 0;
        }
        .font {
          left: 14px;
        }
      }
      .nbtn:nth-of-type(7) {
        width: 214px;
        height: 178px;
        left: 365px;
        bottom: 20px;
      }
      .nbtn:nth-of-type(8) {
        width: 171px;
        height: 149px;
        left: 884px;
        top: 230px;
      }
      .nbtn:nth-of-type(9) {
        width: 250px;
        height: 160px;
        left: 624px;
        top: 305px;
      }
      .nbtn:nth-of-type(10) {
        width: 80px;
        height: 70px;
        left: 670px;
        bottom: 0px;
        .ball {
          right: 21px;
          top: 0;
        }
        .font {
          width: 100%;
          bottom: 0;
          text-align: center;
        }
      }
      .nbtn:nth-of-type(11) {
        width: 80px;
        height: 70px;
        left: 765px;
        bottom: 0px;
        .ball {
          right: 21px;
          top: 0;
        }
        .font {
          width: 100%;
          bottom: 0;
          text-align: center;
        }
      }
      .nbtn:nth-of-type(12) {
        width: 163px;
        height: 209px;
        left: 181px;
        bottom: 147px;
      }
    }
    .dkx_bot {
      width: 1323px;
      margin: 30px auto;
      display: block;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .commonBox {
      width: 1323px;
      padding: 0 0;
      display: flex;
      border-radius: 10px;
      background-color: #fff;
      flex-direction: column;
      margin: 0 auto 35px;
    }
    .videoplay {
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgba(0, 0, 0, 0.65);
      left: 0;
      top: 0;
      z-index: 30;
      display: flex;
      justify-content: center;
      align-items: center;
      video {
        width: 60%;
      }
      .close {
        width: 50px;
        height: 50px;
        position: absolute;
        right: 50px;
        top: 50px;
        cursor: pointer;
        svg {
          width: 100%;
        }
      }
    }
    .h2 {
      width: 294px;
      margin: 0 auto;
      padding: 52px 0 0;
    }
    .dkx {
      width: 100%;
      padding: 50px 0;
      background: rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      flex-direction: column;
      .container {
        width: 1240px;
        margin: 0 auto;
        position: relative;
        .section_nav_3 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          .section_comm_3 {
            width: 603px;
            /* height: 378px; */
            display: inline-block;
            position: relative;
            cursor: pointer;
            .x_img_3 {
              width: 603px;
              height: 378px;
              overflow: hidden;
              border-radius: 10px;
              position: relative;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .mask {
              width: 100%;
              height: 105px;
              line-height: 105px;
              position: absolute;
              background: 0;
              left: 0;
              bottom: 0;
              background: rgba(0, 0, 0, 0.65);
              z-index: 15;
              font-size: 36px;
              color: #ffffff;
              font-weight: bold;
              text-align: center;
              letter-spacing: 1px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              padding: 0 2%;
            }
          }
        }
      }
      .dkx_bot {
        width: 1240px;
        margin-top: 50px;
        img {
          width: 100%;
        }
      }
    }
    .mkygg {
      width: 100%;
      .container {
        width: 1240px;
        margin: 0 auto;
        position: relative;
        .title {
          width: 294px;
          margin: 0 auto;
          padding: 52px 0 42px;
        }
        .mkygg_nav {
          position: relative;
          .container {
            width: 1240px;
            margin: 0 auto;
            position: relative;
            .more {
              position: absolute;
              right: 0;
              top: 0;
              bottom: auto;
            }
            .section_nav_3 {
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-wrap: wrap;
              .section_comm_3 {
                width: 603px;
                /* height: 378px; */
                display: inline-block;
                position: relative;
                cursor: pointer;
                .mkygg_txt {
                  width: 96%;
                  font-size: 16px;
                  font-family: 微软雅黑;
                  color: rgb(0, 0, 0);
                  text-align: center;
                  margin-top: 24px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  padding: 0px 2%;
                  overflow: hidden;
                }
                .mkygg_txt:hover {
                  color: #f1581f;
                }
                .x_img_3 {
                  width: 603px;
                  height: 378px;
                  overflow: hidden;
                  border-radius: 10px;
                  position: relative;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                  .mkygg_mask {
                    width: 53px;
                    height: 53px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 750px) {
    width: 100%;
    background-image: url('${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/jnh/bg_02.png');
    background-size: 100% auto;
    background-position: center top;
    background-color: #69e1d7;
    padding-bottom: 50px;
    .banner {
      width: 100%;
      display: none;
      img {
        width: 100%;
      }
    }
    .mbanner {
      width: 100%;
      margin-bottom: 0.2rem;
      img {
        width: 100%;
      }
    }
    .dkx_bot {
      width: 95%;
      margin: 30px auto;
      display: block;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .nav {
      width: 1240px;
      height: 577px;
      background: url('${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/jnh/nav_03.png') 0 0 no-repeat;
      background-size: 982px 577px;
      margin: -180px auto 30px;
      position: relative;
      display: none;
      .nbtn {
        position: absolute;
        cursor: pointer;
      }
      .nbtn:nth-of-type(1) {
        width: 144px;
        height: 60px;
        left: 0;
        top: 180px;
      }
      .nbtn:nth-of-type(2) {
        width: 154px;
        height: 60px;
        left: 0;
        top: 230px;
      }
      .nbtn:nth-of-type(3) {
        width: 164px;
        height: 60px;
        left: 0;
        top: 290px;
      }
      .nbtn:nth-of-type(4) {
        width: 160px;
        height: 60px;
        left: 0;
        top: 340px;
      }
      .nbtn:nth-of-type(5) {
        width: 190px;
        height: 60px;
        left: 0;
        top: 380px;
      }
      .nbtn:nth-of-type(6) {
        width: 222px;
        height: 60px;
        left: 0;
        top: 434px;
      }
      .nbtn:nth-of-type(7) {
        width: 105px;
        height: 78px;
        left: 465px;
        bottom: 0;
      }
      .nbtn:nth-of-type(8) {
        width: 171px;
        height: 149px;
        left: 839px;
        top: 230px;
      }
      .nbtn:nth-of-type(9) {
        width: 400px;
        height: 160px;
        left: 374px;
        top: 340px;
      }
      .nbtn:nth-of-type(10) {
        width: 400px;
        height: 160px;
        left: 374px;
        top: 340px;
        border: 1px solid red;
      }
    }
    .commonBox {
      width: 95%;
      padding: 0 0;
      display: flex;
      border-radius: 10px;
      background-color: #fff;
      flex-direction: column;
      margin: 0 auto 35px;
    }
    .videoplay {
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgba(0, 0, 0, 0.65);
      left: 0;
      top: 0;
      z-index: 30;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      video {
        width: 90%;
        display: block;
      }
      .close {
        width: 50px;
        height: 50px;
        cursor: pointer;
        svg {
          width: 100%;
        }
      }
    }
    .h2 {
      width: 2.94rem;
      margin: 0 auto;
      padding: 20px 0 0;
      img {
        width: 100%;
      }
    }
    .dkx {
      width: 95%;
      padding: 10px 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0 auto;
      .container {
        width: 100%;
        margin: 0 auto;
        position: relative;
        .section_nav_3 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;

          .section_comm_3 {
            width: 48%;
            /* height: 378px; */
            display: inline-block;
            position: relative;
            cursor: pointer;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 10px;
            .x_img_3 {
              width: 100%;
              height: 189px;
              overflow: hidden;

              position: relative;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .mask {
              width: 100%;
              height: 0.6rem;
              line-height: 0.6rem;
              position: absolute;
              background: 0;
              left: 0;
              bottom: 0;
              background: rgba(0, 0, 0, 0.65);
              z-index: 15;
              font-size: 14px;
              color: #ffffff;
              font-weight: bold;
              text-align: center;
              letter-spacing: 1px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              padding: 0 2%;
            }
          }
        }
      }
      .dkx_bot {
        width: 100%;
        margin-top: 10px;
        img {
          width: 100%;
        }
      }
    }
    .mkygg {
      width: 95%;
      margin: 0 auto;
      .container {
        width: 100%;
        margin: 0 auto;
        position: relative;
        .title {
          width: 2.94rem;
          margin: 0 auto;
          padding: 0.2rem 0 0.2rem;
          img {
            width: 100%;
          }
        }
        .mkygg_nav {
          position: relative;
          .container {
            width: 100%;
            margin: 0 auto;
            position: relative;
            .more {
              position: absolute;
              right: 0;
              top: 0;
              bottom: auto;
            }
            .section_nav_3 {
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-wrap: wrap;
              .section_comm_3 {
                width: 48%;
                /* height: 378px; */
                display: inline-block;
                position: relative;
                cursor: pointer;
                .mkygg_txt {
                  width: 96%;
                  font-size: 16px;
                  font-family: 微软雅黑;
                  color: rgb(0, 0, 0);
                  text-align: center;
                  margin-top: 24px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  padding: 0px 2%;
                  overflow: hidden;
                }
                .x_img_3 {
                  width: 100%;
                  height: 89px;
                  overflow: hidden;
                  border-radius: 10px;
                  position: relative;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                  .mkygg_mask {
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export const ListWrap = styled.div`
  @media (min-width: 750px) {
    width: 1240px;
    margin: 0 auto;
    padding-bottom: 75px;
    .h3 {
      padding-bottom: 15px;
      border-bottom: 1px solid #dbdbdb;
      margin-top: 30px;
      .title_type_1 {
        font-size: 32px;
        font-weight: bold;
        line-height: 32px;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        .i_small {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-left: 7px;
          margin-right: 14px;
        }
        .i_small2 {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large2 {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-right: 7px;
        }
        span {
          padding: 10px 40px;
          background: #00a1b4;
          border-radius: 5px;
          margin: 0 16px 0 0;
          box-sizing: border-box;
        }
      }
    }
    .section_nav_1 {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .section_comm_1 {
        width: 399px;
        display: inline-block;
        margin-right: 17px;
        cursor: pointer;
        margin-top: 24px;
        .x_img_1 {
          width: 399px;
          height: 258px;
          border-radius: 10px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        p {
          width: 96%;
          font-size: 16px;
          font-family: 微软雅黑;
          color: rgb(0, 0, 0);
          text-align: center;
          margin-top: 24px;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0px 2%;
          overflow: hidden;
        }
        p:hover {
          color: #e8623c;
        }
      }
      .section_comm_1:nth-of-type(3n) {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 750px) {
    width: 95%;
    margin: 0 auto;
    padding-bottom: 75px;
    .h3 {
      padding-bottom: 15px;
      border-bottom: 1px solid #dbdbdb;
      margin-top: 30px;
      .title_type_1 {
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        .i_small {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-left: 3.5px;
          margin-right: 7px;
        }
        .i_small2 {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large2 {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-right: 3.5px;
        }
        span {
          padding: 10px 40px;
          background: #00a1b4;
          border-radius: 5px;
          margin: 0 8px 0 0;
          box-sizing: border-box;
        }
      }
    }
    .section_nav_1 {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .section_comm_1 {
        width: 48%;
        cursor: pointer;
        margin-top: 5px;
        .x_img_1 {
          width: 100%;
          height: 2.49rem;
          border-radius: 10px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        p {
          width: 100%;
          font-size: 16px;
          font-family: 微软雅黑;
          color: #000;
          text-align: center;
          margin-top: 0.2rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0px 2%;
          overflow: hidden;
        }
      }
    }
  }
`

export const ListWrap2 = styled.div`
  @media (min-width: 750px) {
    width: 1240px;
    margin: 0 auto;
    padding-bottom: 75px;
    .h3 {
      padding-bottom: 15px;
      border-bottom: 1px solid #dbdbdb;
      margin-top: 30px;
      .title_type_1 {
        font-size: 32px;
        font-weight: bold;
        line-height: 32px;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        .i_small {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-left: 7px;
          margin-right: 14px;
        }
        .i_small2 {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large2 {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-right: 7px;
        }
        span {
          padding: 10px 40px;
          background: #00a1b4;
          border-radius: 5px;
          margin: 0 16px 0 0;
          box-sizing: border-box;
        }
      }
    }
    .section_nav_1 {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .section_comm_1 {
        width: 290px;
        display: inline-block;
        margin-right: 17px;
        cursor: pointer;
        margin-top: 24px;
        .x_img_1 {
          width: 290px;
          height: 205px;
          border-radius: 10px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        p {
          width: 96%;
          font-size: 16px;
          font-family: 微软雅黑;
          color: rgb(0, 0, 0);
          text-align: center;
          margin-top: 24px;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0px 2%;
          overflow: hidden;
        }
        p:hover {
          color: #e8623c;
        }
      }
      .section_comm_1:nth-of-type(4n) {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 750px) {
    width: 95%;
    margin: 0 auto;
    padding-bottom: 20px;
    .h3 {
      padding-bottom: 15px;
      border-bottom: 1px solid #dbdbdb;
      margin-top: 30px;
      .title_type_1 {
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        .i_small {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-left: 3.5px;
          margin-right: 7px;
        }
        .i_small2 {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large2 {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-right: 3.5px;
        }
        span {
          padding: 10px 40px;
          background: #00a1b4;
          border-radius: 5px;
          margin: 0 8px 0 0;
          box-sizing: border-box;
        }
      }
    }
    .section_nav_1 {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .section_comm_1 {
        width: 48%;
        display: inline-block;
        cursor: pointer;
        margin-top: 4px;
        .x_img_1 {
          width: 100%;
          height: 102.5px;
          border-radius: 10px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        p {
          width: 96%;
          font-size: 16px;
          font-family: 微软雅黑;
          color: rgb(0, 0, 0);
          text-align: center;
          margin-top: 12px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
`

export const XkjWrap = styled.div`
  @media (min-width: 750px) {
    width: 1240px;
    margin: 0 auto;
    padding-bottom: 75px;
    .h3 {
      padding-bottom: 15px;
      border-bottom: 1px solid #dbdbdb;
      margin-top: 30px;
      .title_type_1 {
        font-size: 32px;
        font-weight: bold;
        line-height: 32px;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        .i_small {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-left: 7px;
          margin-right: 14px;
        }
        .i_small2 {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large2 {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-right: 7px;
        }
        span {
          padding: 10px 40px;
          background: #00a1b4;
          border-radius: 5px;
          margin: 0 16px 0 0;
          box-sizing: border-box;
        }
      }
    }
    .section_nav_2 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .section_comm_2 {
        width: 590px;
        display: inline-block;
        margin-right: 30px;
        cursor: pointer;
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .x_img_2 {
          width: 261px;
          height: 189px;
          border-radius: 10px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .section_comm_content {
          width: 300px;
          color: rgb(0, 0, 0);
          .tit {
            width: 100%;
            font-size: 20px;
            font-family: 微软雅黑;
            text-overflow: ellipsis;
            margin: 10px 0px;
            overflow: hidden;
          }
          .content {
            font-size: 14px;
            font-family: 微软雅黑;
            line-height: 20px;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            text-align: justify;
            overflow: hidden;
            p {
              text-indent: 0 !important;
            }
          }
        }
        .section_comm_content:hover {
          color: #e8623c;
        }
      }
    }
  }
  @media (max-width: 750px) {
    width: 95%;
    margin: 0 auto;
    padding-bottom: 25px;
    .h3 {
      padding-bottom: 15px;
      border-bottom: 1px solid #dbdbdb;
      margin-top: 30px;
      .title_type_1 {
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        .i_small {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-left: 3.5px;
          margin-right: 7px;
        }
        .i_small2 {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large2 {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-right: 3.5px;
        }
        span {
          padding: 10px 40px;
          background: #00a1b4;
          border-radius: 5px;
          margin: 0 8px 0 0;
          box-sizing: border-box;
        }
      }
    }
    .section_nav_2 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .section_comm_2 {
        width: 48%;
        cursor: pointer;
        .x_img_2 {
          width: 100%;
          height: 87.5px;
          border-radius: 10px;
          overflow: hidden;
          margin: 15px 0;
          background-color: #69e1d7;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .section_comm_content {
          width: 100%;
          .tit {
            width: 100%;
            font-size: 16px;
            font-family: 微软雅黑;
            color: rgb(0, 0, 0);
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 10px 0px;
            overflow: hidden;
          }
          .content {
            font-size: 14px;
            font-family: 微软雅黑;
            color: rgb(0, 0, 0);
            line-height: 20px;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            text-align: justify;
            overflow: hidden;
            p {
              text-indent: 0 !important;
            }
          }
        }
      }
    }
  }
`

export const DyzWrap = styled.div`
  @media (min-width: 750px) {
    .h2 {
      width: 877px;
      margin: 0 auto;
      padding: 52px 0 42px;
      img {
        width: 100%;
      }
    }
    .container {
      width: 1240px;
      margin: 0 auto;
      position: relative;
      padding-bottom: 75px;
      .kjdyz_nav {
        height: 490px;
        overflow: hidden;
        .kjdyz_left {
          width: 830px;
          height: 490px;
          background: #ffffff;
          float: left;
          .swiper-container1 {
            width: 100%;
            height: 100%;
            position: relative;
            .swiper-slide {
              width: 100%;
              height: 100%;
              a {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .swiper-pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 20px;
            opacity: 1 !important;
            .swiper-pagination-bullet {
              width: 15px;
              height: 15px;
              background: #bcbab8;
              border-radius: 50%;
              opacity: 1;
              display: block;
            }
            .swiper-pagination-bullet-active {
              width: 18px;
              height: 18px;
              background: #e6e6e6;
              display: block;
            }
          }
        }
        .kjdyz_right {
          width: 410px;
          height: 100%;
          float: left;
          background: #322b3a;
          .kjdyz_right_nav {
            height: 440px;
            margin: 25px 30px;
            position: relative;

            .kjdyz_right_content {
              color: #ffffff;
              .kjdyz_icon {
                font-size: 20px;
                font-family: 微软雅黑;
                color: rgb(82, 216, 184);
                letter-spacing: 1px;
              }
              .kjdyz_tit {
                font-size: 20px;
                font-family: 微软雅黑;
                color: rgb(255, 255, 255);
                letter-spacing: 1px;
                height: 50px;
              }
              .kjdyz_content {
                font-size: 15px;
                font-family: 微软雅黑;
                color: rgb(255, 255, 255);
                letter-spacing: 1px;
                line-height: 20px;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                text-align: justify;
                overflow: hidden;
                margin-top: 20px;
              }
            }
          }
          .kjdyz_right_list {
            padding-top: 40px;
            .kjdyz_li {
              color: #fff;
              display: block;
              margin: 5px 0;
              font-size: 18px;
              p {
                display: inline;
                padding-left: 5px;
              }
            }
            .kjdyz_li:hover {
              color: #f1581f;
            }
          }
          .more {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
      }
    }
  }
  @media (max-width: 750px) {
    width: 95%;
    margin: 0 auto;
    .h2 {
      width: 100%;
      margin: 0 auto;
      padding: 20px 0 0;
      img {
        width: 100%;
      }
    }
    .container {
      width: 100%;
      margin: 0 auto;
      position: relative;
      padding-bottom: 15px;
      .kjdyz_nav {
        width: 100%;
        overflow: hidden;
        padding-bottom: 10px;
        .kjdyz_left {
          width: 100%;
          height: 4.9rem;
          background: #ffffff;
          margin-top: 0.2rem;
          .swiper-container1 {
            width: 100%;
            height: 100%;
            position: relative;
            .swiper-slide {
              width: 100%;
              height: 100%;
              a {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .swiper-pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 20px;
            opacity: 1 !important;
            .swiper-pagination-bullet {
              width: 15px;
              height: 15px;
              background: #bcbab8;
              border-radius: 50%;
              opacity: 1;
              display: block;
            }
            .swiper-pagination-bullet-active {
              width: 18px;
              height: 18px;
              background: #e6e6e6;
              display: block;
            }
          }
        }
        .kjdyz_right {
          width: 100%;
          background: #322b3a;
          padding-bottom: 10px;
          .kjdyz_right_nav {
            width: 95%;
            margin: 0 auto;
            position: relative;
            overflow: hidden;

            .kjdyz_right_content {
              color: #ffffff;
              margin-top: 10px;
              .kjdyz_icon {
                font-size: 20px;
                font-family: 微软雅黑;
                color: rgb(82, 216, 184);
                letter-spacing: 1px;
              }
              .kjdyz_tit {
                font-size: 16px;
                font-family: 微软雅黑;
                color: rgb(255, 255, 255);
                letter-spacing: 1px;
              }
              .kjdyz_content {
                font-size: 13px;
                font-family: 微软雅黑;
                color: rgb(255, 255, 255);
                letter-spacing: 1px;
                line-height: 20px;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                text-align: justify;
                overflow: hidden;
                margin-top: 10px;
              }
            }
          }
          .kjdyz_right_list {
            padding: 20px 0;
            .kjdyz_li {
              color: #fff;
              display: block;
              margin: 5px 0;
              font-size: 16px;
              p {
                display: inline;
                padding-left: 5px;
              }
            }
          }
          .more {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            right: 0;
            bottom: 0;
            color: #fff;
          }
        }
      }
    }
  }
`

export const KxsgpWrap = styled.div`
  @media (min-width: 750px) {
    .h3 {
      width: 1240px;
      margin: 0 auto;
      padding-bottom: 15px;
      border-bottom: 1px solid #dbdbdb;
      .title_type_1 {
        font-size: 32px;
        font-weight: bold;
        line-height: 32px;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        .i_small {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-left: 7px;
          margin-right: 14px;
        }
        .i_small2 {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large2 {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-right: 7px;
        }
        span {
          padding: 10px 40px;
          background: #00a1b4;
          border-radius: 5px;
          margin: 0 16px 0 0;
          box-sizing: border-box;
        }
      }
    }
    .jxhd_sw {
      width: 100%;
      height: 340px;
      margin-bottom: 80px;
      .swiper-container-jxhd {
        width: 100%;
        height: 340px;
        position: relative;
        .swiper-slide {
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50%;
          display: block;
          position: relative;
          .sw_link {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .next2 {
          width: 50px;
          height: 90px;
          color: #fff;
          border-radius: 5px;
          background-color: rgba(0, 0, 0, 0.6);
          outline: none;
          right: 33.3%;
        }
        .prev2 {
          width: 50px;
          height: 90px;
          color: #fff;
          border-radius: 5px;
          background-color: rgba(0, 0, 0, 0.6);
          outline: none;
          left: 33.3%;
        }
      }
    }
    .jxhd_swm {
      display: none;
    }
  }
  @media (max-width: 750px) {
    width: 95%;
    margin: 0 auto;
    overflow: hidden;
    .h3 {
      padding-bottom: 15px;
      border-bottom: 1px solid #dbdbdb;
      margin-top: 30px;
      .title_type_1 {
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        .i_small {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-left: 3.5px;
          margin-right: 7px;
        }
        .i_small2 {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large2 {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-right: 3.5px;
        }
        span {
          padding: 10px 40px;
          background: #00a1b4;
          border-radius: 5px;
          margin: 0 8px 0 0;
          box-sizing: border-box;
        }
      }
    }
    .jxhd_sw {
      width: 100%;
      height: 340px;
      margin-bottom: 80px;
      display: none;
      .swiper-container-jxhd {
        width: 100%;
        height: 340px;
        position: relative;
        .swiper-slide {
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50%;
          display: block;
          position: relative;
          .sw_link {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .next2 {
          width: 50px;
          height: 90px;
          color: #fff;
          border-radius: 5px;
          background-color: rgba(0, 0, 0, 0.6);
          outline: none;
          right: 33.3%;
        }
        .prev2 {
          width: 50px;
          height: 90px;
          color: #fff;
          border-radius: 5px;
          background-color: rgba(0, 0, 0, 0.6);
          outline: none;
          left: 33.3%;
        }
      }
    }
    .jxhd_swm {
      width: 100%;
      height: 3rem;
      margin-bottom: 20px;
      .swiper-container-m {
        width: 100%;
        height: 3rem;
        overflow: hidden;
      }
    }
  }
`

export const MryxWrap = styled.div`
  @media (min-width: 750px) {
    width: 1240px;
    margin: 0 auto;
    padding-bottom: 75px;
    position: relative;
    .h3 {
      padding-bottom: 15px;
      border-bottom: 1px solid #dbdbdb;
      margin-top: 30px;
      .title_type_1 {
        font-size: 32px;
        font-weight: bold;
        line-height: 32px;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        .i_small {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-left: 7px;
          margin-right: 14px;
        }
        .i_small2 {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large2 {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-right: 7px;
        }
        span {
          padding: 10px 40px;
          background: #00a1b4;
          border-radius: 5px;
          margin: 0 16px 0 0;
          box-sizing: border-box;
        }
      }
    }
    .section_nav_1 {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .section_comm_1 {
        width: 608px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 25px;
        .x_img_1 {
          width: 260px;
          height: 350px;
          border-radius: 7px;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        .right {
          width: 320px;
          color: #000;
          .title {
            font-size: 20px;
            margin-top: 10px;
          }
          .time {
            font-size: 14px;
            margin-top: 15px;
          }
          .detail {
            font-size: 15px;
            line-height: 25px;
            margin-top: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 8;
            -webkit-box-orient: vertical;
          }
        }
        .right:hover {
          color: #f1581f;
        }
      }
    }
    .more {
      color: #000;
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }
  @media (max-width: 750px) {
    width: 95%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    padding-bottom: 30px;
    .h3 {
      padding-bottom: 15px;
      border-bottom: 1px solid #dbdbdb;
      margin-top: 30px;
      .title_type_1 {
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        .i_small {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-left: 3.5px;
          margin-right: 7px;
        }
        .i_small2 {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large2 {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-right: 3.5px;
        }
        span {
          padding: 10px 40px;
          background: #00a1b4;
          border-radius: 5px;
          margin: 0 8px 0 0;
          box-sizing: border-box;
        }
      }
    }
    .section_nav_1 {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .section_comm_1 {
        width: 48%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        margin-bottom: 15px;
        .x_img_1 {
          width: 130px;
          height: 175px;
          border-radius: 7px;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        .right {
          width: 130px;
          color: #000;
          .title {
            font-size: 16px;
            margin-top: 5px;
            height: 40px;
            line-height: 20px;
          }
          .time {
            font-size: 14px;
            margin-top: 5px;
            display: none;
          }
          .detail {
            font-size: 15px;
            line-height: 25px;
            margin-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    .more {
      position: absolute;
      right: 10px;
      bottom: 10px;
      color: #000;
      display: block;
      margin-top: 10px;
    }
  }
`

export const DyzWrap2 = styled.div`
  @media (min-width: 750px) {
    .h2 {
      width: 556px;
      margin: 0 auto;
      padding: 52px 0 42px;
      img {
        width: 100%;
      }
    }
    .h3 {
      .title_type_1 {
        font-size: 24px;
        font-weight: bold;
        line-height: 24px;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        .i_small {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-left: 3.5px;
          margin-right: 7px;
        }
        .i_small2 {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large2 {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-right: 3.5px;
        }
        span {
          padding: 10px 40px;
          background: #00a1b4;
          border-radius: 5px;
          margin: 0 8px 0 0;
          box-sizing: border-box;
        }
      }
    }
    .container {
      width: 1240px;
      margin: 0 auto;
      position: relative;
      padding: 30px 0;
      .kjdyz_nav {
        height: 412px;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        .kjdyz_left {
          width: 624px;
          height: 412px;
          background: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          video {
            width: 624px;
            height: 412px;
          }
          .p {
            width: 100%;
            height: 50px;
            background: rgba(0, 0, 0, 0.5);
            text-align: center;
            line-height: 50px;
            font-size: 25px;
            color: #fff;
            position: absolute;
            bottom: 0;
          }
        }
        .kjdyz_right {
          width: 588px;
          height: 100%;
          .kjdyz_right_nav {
            width: 100%;
            height: 412px;
            position: relative;
            .kjdyz_icon {
              font-size: 22px;
              font-family: 微软雅黑;
              color: rgb(82, 216, 184);
              letter-spacing: 1px;
              margin-top: 20px;
              margin-bottom: 20px;
            }
            .kjdyz_right_list {
              .kjdyz_li {
                display: block;
                margin-top: 20px;
                border-bottom: 1px solid #ccc;
                padding-bottom: 5px;
                .title {
                  font-size: 20px;
                  color: #000;
                  font-weight: bold;
                }
                .detail {
                  font-size: 24px;
                  color: #333333;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  margin-top: 10px;
                  border-bottom: 1px solid #ccc;
                }
              }
            }
            .more {
              position: absolute;
              right: 0;
              bottom: 0;
              color: #000;
              font-size: 16px;
              a {
                color: #000;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 750px) {
    .h2 {
      width: 4rem;
      margin: 20px auto;
      padding: 20px 0 0;
      img {
        width: 100%;
      }
    }
    .h3 {
      padding-bottom: 15px;
      border-bottom: 1px solid #dbdbdb;
      margin-top: 30px;
      .title_type_1 {
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        .i_small {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-left: 3.5px;
          margin-right: 7px;
        }
        .i_small2 {
          width: 8px;
          height: 8px;
          background: #f55c5d;
        }
        .i_large2 {
          width: 16px;
          height: 16px;
          background: #00a1b4;
          margin-right: 3.5px;
        }
        span {
          padding: 10px 40px;
          background: #00a1b4;
          border-radius: 5px;
          margin: 0 8px 0 0;
          box-sizing: border-box;
        }
      }
    }
    .container {
      width: 100%;
      margin: 0 auto;
      position: relative;
      padding-bottom: 15px;
      .kjdyz_nav {
        width: 100%;
        overflow: hidden;
        padding-bottom: 10px;
        .kjdyz_left {
          width: 100%;
          height: 4rem;
          text-align: center;
          margin-bottom: 0.2rem;
          font-size: 16px;
          video {
            width: 100%;
          }
        }
        .kjdyz_right {
          width: 100%;
          .kjdyz_right_nav {
            width: 95%;
            margin: 0 auto;
            position: relative;
            overflow: hidden;

            .kjdyz_right_content {
              color: #ffffff;
              margin-top: 10px;
              .kjdyz_icon {
                font-size: 20px;
                font-family: 微软雅黑;
                color: rgb(82, 216, 184);
                letter-spacing: 1px;
              }
              .kjdyz_tit {
                font-size: 20px;
                font-family: 微软雅黑;
                color: rgb(255, 255, 255);
                letter-spacing: 1px;
              }
              .kjdyz_content {
                font-size: 15px;
                font-family: 微软雅黑;
                color: rgb(255, 255, 255);
                letter-spacing: 1px;
                line-height: 20px;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                text-align: justify;
                overflow: hidden;
                margin-top: 10px;
              }
            }
          }
          .kjdyz_right_list {
            padding: 20px 0;
            .kjdyz_li {
              color: #000;
              display: block;
              margin: 5px 0;
              font-size: 16px;
              p {
                display: inline;
                padding-left: 5px;
              }
            }
          }
          .more {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            right: 0;
            bottom: 0;
            color: #fff;
          }
        }
      }
    }
  }
`
