import React from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'
import styles from '../index.module.scss'
import { changeImg } from '../pub';
import { Link } from 'react-router-dom';

export default class Venue extends React.Component {
    
    render() {
        const { cgData } = this.props
        return (
            <div className={styles.venue}>
                <div className='list'>    
                    <p className="title">
                        <span 
                            dangerouslySetInnerHTML={{
                            __html: cgData.name.split('|').join("<br />"),
                            }} >
                        </span>
                        <Link className='link' to={`/unionCgList?termId=${cgData.term_id}&typeId=${cgData.post_keywords}`}>
                            More
                        </Link>
                    </p>
                    {
                        cgData.articles.slice(0, 9).map(el => {
                            return (
                                <a href={el.posts_link} 
                                onClick={e => {  
                                if (!el.posts_link) {  
                                    e.preventDefault(); // 阻止默认行为，即不跳转  
                                }  
                                }}  
                                target="_blank">
                                    <div className='item' key={el.id}>
                                        <img src={el.smeta.photo ? changeImg(el.smeta.photo[0].url) : ''} alt="" />  
                                        <div className='title'>{el.post_title}</div>  
                                    </div> 
                                </a>
                            )
                        })
                    }
                </div>
                
            </div>
        )
    }
}
