import styled from 'styled-components'


export const Ldetail = styled.div`
    @media(max-width:750px){
        width:7.1rem;
        overflow:hidden;
        margin:0 auto;
        h2{
            font-size:.4rem;
            font-weight:bold;
            color:#424242;
            text-align:center;
            margin-top:.6rem;
            line-height:.65rem;
        }
        .l_d_time{
            text-align:center;
            font-size:.2rem;
            p{
                color:#8d8d8d;
            }
        }
        .goBack{
            width:100px;
            height:40px;
            background:#00a1b4;
            margin:.1rem auto;
            font-size:18px;
            color:#fff;
            text-align:center;
            line-height:40px;
            border-radius:5px;
            cursor: pointer;
        }
        .l_d_content{
            font-size:.34rem;
            line-height:.53rem;
            text-indent:.68rem;
            color:#424242;
            width:7.1rem;
            margin: 0 auto;
            p{
                p{
                    img{
                        width:100%!important;
                        height:auto!important;
                        margin-left: -.68rem!important;
                    }                       
                }
                img{
                        width:100%!important;
                        height:auto!important;
                        margin-left: -.68rem!important;
                }                
            }
            div{
                span{
                    font-size:.34rem;
                    line-height:.53rem;
                    text-indent:.68rem;
                    color:#424242; 
                    img{
                        max-width: 100%!important;
                        height: auto;
                    }
                }
                margin-top:.5rem;    
                img{
                    width:100%;
                    margin-left:-.68rem;
                }         
            }
        }        
    }
    @media(min-width:750px){
        width:1200px;
        margin:0 auto;
        h2{
            font-size:27px;
            font-weight:bold;
            color:#000;
            text-align:center;
            margin-top:50px;
        }
        .goBack{
            width:100px;
            height:40px;
            background:#00a1b4;
            margin:30px auto;
            font-size:18px;
            color:#fff;
            text-align:center;
            line-height:40px;
            border-radius:5px;
            cursor: pointer;
        }
        .l_d_time{
            text-align:center;
            font-size:15px;
            p{
                color:#fff;
            }

        }
        .l_d_content{
            font-size:17px;
            line-height:30px;
            text-indent:34px;
            color:#000;
            p{
                img{          
                    margin:0 auto;
                    display: block;
                }   
            div{
                span{
                    font-size:17px;
                    line-height:30px;
                    text-indent:34px;
                    color:#000;   
                }
                margin-top:12px;             
            }
        }
    }
`