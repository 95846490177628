import React from 'react'
import {CaretLeftOutlined} from '@ant-design/icons';
import styles from '../index.module.scss'
import { changeImg } from '../pub'
import { Link } from 'react-router-dom';

export default class MainFunc extends React.Component {
state={
    videoDefault: '',
    posterDefault: '',
    active:0
}

  render() {
    const { zyznData } = this.props
    console.log('==========')
    const {videoDefault, posterDefault,active} = this.state
    return (
        <div className={styles.mainFunc}>
            <div className="funcList">  
                {
                    zyznData.son.slice(0,5).map(el=>{
                        return (
                            <div className="func" key={el.id}>
                                <Link className='link' to={`/unionList?termId=${el.term_id}&typeId=${el.post_keywords}`}>
                                    <div
                                    dangerouslySetInnerHTML={{
                                        __html: el.name.split('|').join("<br />"),
                                      }} 
                                    className="text">
                                    </div>   
                                </Link>
                            </div> 
                        )
                    })
                }                 
            </div> 
            <div className="funcListYd">  
                {
                    zyznData.son.slice(0,5).map(el=>{
                        return (
                            <div className="func" key={el.id}>
                                <Link className='link' to={`/unionList?termId=${el.term_id}&typeId=${el.post_keywords}`}>
                                    <div className="text">
                                        {el.name}
                                    </div>   
                                </Link>
                            </div> 
                        )
                    })
                }                 
            </div> 
        </div>
    )
  }
}
