import styled from 'styled-components'
export const ZyzWrap = styled.div `
    @media(min-width:750px){
        width:100%;
        overflow:hidden;
        .title_1{
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            .i_small2{
                        width:8px;
                        height:8px;
                        background:#f55c5d;
            }
            .i_large2{
                        width:16px;
                        height:16px;
                        background:#00a1b4;   
                        margin-right:7px;             
            }            
            span{
                        padding:10px 20px;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 16px 0 0;
                        box-sizing:border-box;
            }
        }
        .title_2{
            height:32px;
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            text-align:center;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:60px auto 30px;
            text-align:center;
            justify-content:center;
            .i_small{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-left:7px;             
            }
            .i_small2{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large2{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-right:7px;             
            }            
            span{
                padding:10px 40px;
                background:#00a1b4;
                border-radius:5px;
                margin:0 16px;
            }
        }
        .nav_title>.title_2:hover{
            color:orange
        }
        .header{
            width:1200px;
            margin:0 auto;
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            .imgWrap{
                width:655px;
                height:333px;
                overflow:hidden;
                display:flex;
                justify-content:center;
                align-items:center;
                border-radius:10px;
                img{
                    width:100%;
                }
            }
            .header_right{
                width:510px;
                p{
                    font-size:18px;
                    line-height:30px;
                    margin:4px;
                }
                .header_link{
                    width:365px;
                    height:50px;
                    background:#f55668;
                    color:#fff;
                    font-size:26px;
                    border-radius:5px;
                    margin:-10px auto;
                    display:flex;
                    flex-direction:row;
                    align-items:center;
                    justify-content:center;
                    i{
                        display:flex;
                        align-items:center;
                        margin-right:20px;
                    }
                }
            }
        }
        .zyzzm{
            width:100%;
            // height:410px;
            background:#dff4fd;
            padding-top: 30px;
            .zyzzm_wrap{
                width:1200px;
                height:100%;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                flex-wrap: wrap;
                margin:0 auto;
                align-items:center;
                .fwgwSection{
                    width:520px;
                    height:340px;
                    display:flex;
                    flex: 0 1 calc(50% - 20px); 
                    margin-bottom: 20px;
                    flex-direction:row;
                    justify-content:space-between;
                    .img_wrap{
                        width:100%;
                        height:340px;
                        display:flex;
                        justify-content:center;
                        align-items:center;
                        overflow:hidden;
                        border-radius: 10px;
                        img{
                            width:100%;
                        }
                    }
                    .section_content{
                        flex:1;
                        margin-left:30px;
                        h2{
                            font-size:25px;
                            color:#00a1b4;
                            font-weight:bold;
                        }
                        p{
                            font-size:16px;
                            line-height:26px;
                            color:#111111;
                            margin-top:20px;
                        }
                    }
                }
            }
        }
        .zyfwgw{
            width:100%;
            height:500px;
            background:#dff4fd;
            padding-top: 30px;
            .zyfwgw_wrap{
                width:1200px;
                height:100%;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                // flex-wrap: wrap;
                margin:0 auto;
                align-items:center;
                .fwgwSection{
                    width:265px;
                    height:450px;
                    // display:flex;
                    margin-bottom: 20px;
                    flex-direction:row;
                    justify-content:space-between;
                    .img_wrap{
                        width:265px;
                        height:340px;
                        // display:flex;
                        justify-content:center;
                        align-items:center;
                        overflow:hidden;
                        border-radius: 10px;
                        img{
                            height:100%;
                        }
                    }
                    .section_content{
                        h2{
                            font-size:25px;
                            color:#00a1b4;
                            font-weight:bold;
                            text-align:center;
                        }
                        p{
                            font-size:20px;
                            color:#111111;
                            letter-spacing: 3px;
                        }
                    }
                }
            }
        }
        .edu_jxhd{
            width:100%;
            overflow:hidden;
            margin-bottom:50px;
            .edu_jhhd_wrap{
                width:1200px;
                overflow:hidden;
                margin:0 auto;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:flex-start;
                .jxhd_section{
                    width:390px;
                    height:240px;
                    margin-top:30px;
                    position:relative;
                    overflow:hidden;
                    background-position:center center;
                    background-size:cover;
                    background-repeat:no-repeat;
                    border-radius:10px;
                    margin:5px 5px;
                    .jxhd_section_p{
                        width:100%;
                        min-height:50px;
                        max-height:100px;
                        background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                        background-size:100% 100%;
                        position:absolute;
                        left:0;
                        bottom:0;
                        display:flex;
                        padding:0 25px 10px;
                        box-sizing:border-box;
                        color:#fff;
                        font-size:18px;
                        align-items:flex-end;
                    }
                }
            }
        }
        .edu_jxhdM{
            display: none;
        }
        .zyzfc{
            width:1200px;
            margin:0 auto 60px;
            .sw{
                width:100%;
                height:530px;
                overflow:hidden;
                border-radius:10px;
            }
            .zyzfcBot{
                width:1200px;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                margin-top:10px;
                .zyz_bot_img{
                    width:290px;
                    height:190px;
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    border-radius:10px;
                    overflow:hidden;
                    margin-right:10px;
                    img{
                        width:100%;
                    }   
                }
            }
        }
    }
    @media(max-width:750px){
        width:100%;
        overflow:hidden;
        .title_1{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:0.3rem auto .3rem;
            text-indent:0;
            .i_small2{
                width:.16rem;
                height:.16rem;
                background:#f55c5d;
            }
            .i_large2{
                width:.3rem;
                height:.3rem;
                background:#00a1b4;   
                margin-right:.07rem;             
            }            
            span{
                padding:.1rem .4rem;
                background:#00a1b4;
                border-radius:5px;
                margin:0 .16rem 0 0;
                box-sizing:border-box;
            }
        }
        .title_2{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:.3rem .2rem .3rem;
            text-indent:0;
            .i_small2{
                width:.16rem;
                height:.16rem;
                background:#f55c5d;
            }
            .i_large2{
                width:.3rem;
                height:.3rem;
                background:#00a1b4;   
                margin-right:.07rem;             
            }            
            span{
                padding:.1rem .4rem;
                background:#00a1b4;
                border-radius:5px;
                margin:0 .16rem 0 0;
                box-sizing:border-box;
            }
        }
        .header{
            width:7.1rem;
            margin:0 auto;
            .imgWrap{
                width:100%;
                height:3.6rem;
                overflow:hidden;
                display:flex;
                justify-content:center;
                align-items:center;
                border-radius:.1rem;
                img{
                    width:100%;
                }
            }
            .header_right{
                width:100%;
                p{
                    font-size:.36rem;
                    line-height:.72rem;
                    margin:.04rem auto;
                }
                .header_link{
                    width:365px;
                    height:50px;
                    background:#f55668;
                    color:#fff;
                    font-size:26px;
                    border-radius:5px;
                    margin:20px auto 20px;
                    display:flex;
                    flex-direction:row;
                    align-items:center;
                    justify-content:center;
                    i{
                        display:flex;
                        align-items:center;
                        margin-right:20px;
                    }
                }
            }
        }
        .zyzzm{
            width:100%;
            overflow:hidden;
            .zyzzm_wrap{
                width:7.1rem;
                height:100%;
                margin:0 auto;
                .fwgwSection{
                    width:100%;
                    overflow:hidden;
                    display:flex;
                    flex-direction:row;
                    justify-content:space-between;
                    margin-bottom:.1rem;
                    .img_wrap{
                        width:100%;
                        height:4.1rem;
                        display:flex;
                        justify-content:center;
                        align-items:center;
                        border-radius:.1rem;
                        overflow:hidden;
                        img{
                            width:100%;
                        }
                    }
                    .section_content{
                        flex:1;
                        margin-left:.2rem;
                        h2{
                            font-size:.3rem;
                            color:#00a1b4;
                            font-weight:bold;
                        }
                        p{
                            font-size:.27rem;
                            line-height:.37rem;
                            color:#111111;
                        }
                    }
                }
            }
        }
        .zyfwgw{
            width:100%;
            overflow:hidden;
            .zyfwgw_wrap{
                width:7.1rem;
                height:100%;
                margin:0 auto;
                .fwgwSection{
                    width:100%;
                    overflow:hidden;
                    display:flex;
                    flex-direction:row;
                    justify-content:space-between;
                    margin-bottom:.1rem;
                    .img_wrap{
                        width:2.65rem;
                        height:3.4rem;
                        display:flex;
                        justify-content:center;
                        align-items:center;
                        border-radius:.1rem;
                        overflow:hidden;
                        img{
                            height:100%;
                        }
                    }
                    .section_content{
                        flex:1;
                        margin-left:.2rem;
                        h2{
                            font-size:.3rem;
                            color:#00a1b4;
                            font-weight:bold;
                        }
                        p{
                            font-size:.27rem;
                            line-height:.37rem;
                            color:#111111;
                        }
                    }
                }
            }
        }
        .zyzfc{
            width:7.1rem;
            margin:0 auto 60px;
            .sw{
                width:100%;
                height:3.9rem;
                overflow:hidden;
                border-radius:.1rem;
            }
            .zyzfcBot{
                display: none;
            }
        }
        .edu_jxhd{
            display: none;
        }
        .edu_jxhdM{
            width:7.1rem;
            margin:0rem auto .3rem;
            overflow:hidden;
            .edu_jhhd_wrap{
                width:7.1rem;
                overflow:hidden;
                margin:0 auto 0;
                display:flex;
                flex-direction:column;
                .jxhd_section{
                    width:100%;
                    height:1.8rem;
                    margin-bottom:.2rem;
                    overflow:hidden;
                    background-position:center center;
                    background-size:cover;
                    background-repeat:no-repeat;
                    border-radius:.1rem;
                    display:flex;
                    flex-direction:row;
                    .jxhd_section_bg{
                        width: 3rem;
                        height: 1.8rem;
                        border-radius: .1rem;
                        overflow: hidden;
                        background-repeat:no-repeat;
                        background-size:cover;
                        position: relative;
                        .zd{
                            width:.8rem;
                            position: absolute;
                            left:0;
                            top:0;
                            i
                        }
                    }
                    .hdbd_section_detail {
                        flex: 1;
                        margin-left: .1rem;
                        height: 1.8rem;
                        font-size: .29rem;
                        color: #000;
                        line-height: .4rem;
                    }
                }
            }
        }
    }
`