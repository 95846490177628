import React from "react"
import { withRouter, NavLink } from 'react-router-dom'
import styles from './index.module.scss'
import QRCode from 'qrcode.react';

class ShareBox extends React.Component{
    state={
        dialogFlag:false,
        qrcodeUrl:""    
    }
    showDialog=(val)=>{
        if(val=="wx"){
            this.setState({dialogFlag:true})
        }else{
            const url = '//service.weibo.com/share/share.php' +
            '?url=' + encodeURIComponent(window.location.href) +
            '&title=' + encodeURIComponent(window.location.href.indexOf('article') > -1 ? document.title : "北京科学中心")
            // const sharesinastring = 'http://v.t.sina.com.cn/share/share.php?title=北京科学中心' + '&url=' + window.location.href + '&content=utf-8&sourceUrl=' + window.location.href
            window.open(url, 'newwindow', 'height=400,width=400,top=100,left=100')
        }
    }
    render() {
        return (
            <>
                <div class={styles.shareBox}>
                    <div class="shareItem" onMouseLeave={()=>this.setState({dialogFlag:false})} onMouseEnter={()=>this.showDialog("wx")}>
                        <img src="/img/indexShare/wx.png" alt=""/>
                        {
                            this.state.dialogFlag?
                                <div class="shareEwm">
                                    <QRCode
                                        id="qrCode"
                                        value={window.location.href}
                                        fgColor="#000000" // 二维码的颜色
                                    /> 
                                </div> 
                                :''
                        }
                    </div>
                    <div class="shareItem" onClick={()=>this.showDialog("wb")}><img src="/img/indexShare/wb.png" alt=""/></div>
                </div>
               
                
            </>
        )
    }
}
export default withRouter(ShareBox)

