import axios from 'axios'

const service = axios.create({
  baseURL: process.env.REACT_APP_APIBASE,
  timeout: 1800000,
})

// 请求拦截器
service.interceptors.request.use(config => {
  console.log(config)
  return config
}, error => {
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(response => {
  if (response.status === 200) {
    return response.data
  }
}, error => {
  return Promise.reject(error)
})

// 导出
export default service
