import * as constants from './constants'
import React from 'react'

const Exhibition = (data) => {
    return {
        type: constants.EXHIBITIONINDEX,
        data: data
    }
}
const ExhZzt = (data) => {
    return {
        type: constants.ZZQ,
        data: data
    }
}

const ExhSdcx = (data) => {
    return {
        type: constants.SDCXCGZ,
        data: data
    }
}

const ExhQwwx = (data) => {
    return {
        type: constants.QWWX,
        data: data
    }
}

export const getExhibition = () => {
    return (dispatch) => {
        React.$request({
            url:'Portal/index/exhibition_hall',
            method:'get',
          }).then(res=>{
            dispatch(Exhibition(res.data))
          })
    }
}

export const getExhZzt = () => {
    return (dispatch) => {
        React.$request({
            url:'Portal/index/exhibition_hall_son',
            method:'get',
            params:{
                termid: 50
            }
          }).then(res=>{
            dispatch(ExhZzt(res.data))
          })
    }
}


export const getExhSdcx = () => {
    return (dispatch) => {
        React.$request({
            url:'Portal/index/exhibition_hall_son',
            method:'get',
            params:{
                termid: 53
            }
          }).then(res=>{
            dispatch(ExhSdcx(res.data))
          })
    }
}

export const getExhQwwx = () => {
    return (dispatch) => {
        React.$request({
            url:'Portal/index/exhibition_hall',
            method:'get',
          }).then(res=>{
            dispatch(ExhQwwx(res.data))
          })
    }
}