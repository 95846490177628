import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import { actions } from './../../store'
import {ZzjgWrap} from './style'
import { Skeleton } from 'antd';

class Zzjg extends React.Component{
    componentDidMount(){
        this.goTo()
        if(!this.props.zzjgDetail){
            this.props.get_zzjg()
        } 
        document.title = '组织架构'
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
            let pos = window.pageYOffset;
            if ( pos > 0 ) {
                window.scrollTo( 0, pos - 1000 ); // how far to scroll on each step
            } else {
                window.clearInterval( scrollToTop );
            }
        }, 0);
    }
    render(){
        return(
            <ZzjgWrap>
                {
                    this.props.zzjgDetail?
                        <img src={this.props.zzjgDetail.path} alt=''/>:<Skeleton active />
                }            
            </ZzjgWrap>
        )
    }
}
const mapStateToProps = (state)=>{
    return{
        zzjgDetail:state.getIn(['library','zzjg'])
    }
}
const mapDispatchToProps = (dispatch)=>{
    return{
        get_zzjg(){
            dispatch(actions.getZzjg())
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Zzjg))
