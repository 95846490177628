import React from 'react'
import { TrainWrap } from './style'
import CommonCarousel from 'common/carousel.js'
import SectionWrap from 'common/newSection'
import { NavLink } from 'react-router-dom'
import NavSection from 'common/navSection'
import Msection from 'common/Msection'
import { Skeleton } from 'antd'
import JxhdCarousel from './jxhdSw'

export const title_type_1 = (font, id) => {
  return (
    <NavLink className="title_type_1" to={'/list/' + id}>
      <i className="i_small"></i>
      <i className="i_large"></i>
      <span>{font}</span>
      <i className="i_large2"></i>
      <i className="i_small2"></i>
    </NavLink>
  )
}
export const title_type_2 = (font, id) => {
  return (
    <NavLink className="title_type_2" to={'/list/' + id}>
      <i className="i_small"></i>
      <i className="i_large"></i>
      <span>{font}</span>
      <i className="i_large2"></i>
      <i className="i_small2"></i>
    </NavLink>
  )
}
export const title_type_3 = (font)=>{
    return(
        <div className='title_type_3'>
            <i className='i_small'></i>
            <i className='i_large'></i>
            <span>{font}</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>
        </div>
    )
}
class Train extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      trainArr: null,
      currentVideo: null,
      poster: null,
      currentId: null
    }
  }
  componentDidMount() {
    document.title = '科普培训'
    this.initTrain()
  }
  zxyd(arr) {
    let zxydList = []
    for (let item in arr) {
      if (item !== 'cat_name') {
        zxydList.push(arr[item])
      }
    }
    return zxydList
  }
  initTrain() {
    React.$request({
      url:'Portal/Index/train',
      method:'get'
    }).then(res=>{
      this.setState({
        trainArr: res.data,
      })
    })
  }
  render() {
    return this.state.trainArr ? (
      <TrainWrap>
        <NavSection />
        <div className="header">
          <CommonCarousel className="sw" Carouselarr={this.state.trainArr.lp} />
          <div className="header_detail">
            <h2>
              <span>{this.state.trainArr.jskg[0].post_title}</span>
              <i className="i_large2"></i>
              <i className="i_small2"></i>
            </h2>
            <p>{this.state.trainArr.jskg[0].post_excerpt}</p>
          </div>
        </div>
        {/* /newtrain */}
        <NavLink to='/newtrain'> {(this.state.trainArr.ycjh && this.state.trainArr.ycjh[0] )?title_type_3(this.state.trainArr.ycjh.cat_name):null}</NavLink>
        {/* <div className='ycjh'>
          <JxhdCarousel></JxhdCarousel>
        </div> */}
        {
          (this.state.trainArr.ycjh && this.state.trainArr.ycjh[0] )?
              <div className="ycjh">
                  <div className="ycjh_wrap">
                      {
                          this.zxyd(this.state.trainArr.ycjh).map((item)=>{
                              let className = 'ycjh_section ycjh_section_full'
                              let _style = {
                                  backgroundImage:"url("+item.path+")"
                              }
                              return (
                                  <a key={item.id} className={className} style={_style} href={item.posts_link} target={item.posts_link.indexOf(location.host)>=0?'':'_blank'}>
                                      <div className="ycjh_section_p">{item.post_title}</div>
                                  </a>
                              )
                          })
                      }
                  </div>
              </div>
              :null
        }

          {/* <div className="kjypt">
                        <img src={this.state.trainArr.kjypt[0].path} alt=""/>
                </div> */}
          {title_type_1(
              this.state.trainArr.pxkj.cat_name,
              this.state.trainArr.pxkj[0].term_id
          )}
          <div className="pxkj">
              <div className="pxkjWrap">
                  {this.zxyd(this.state.trainArr.pxkj).map((item) => {
                      return (
                          <NavLink
                              className="pxkjSection"
                              key={item.id}
                              to={'/article/' + item.id}
                          >
                              <div className="imgWrap">
                                  <img src={item.path} alt="" />
                                  {
                                      parseInt(item.istop, 10) > 0 ?
                                          <div className='zd'>
                                              <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zd.png`} alt="" />
                                          </div> : ''
                                  }
                                  <div className="p pm">{item.post_title}</div>
                              </div>
                              <div className="p pc">{item.post_title}</div>
                          </NavLink>
                      )
                  })}
              </div>
          </div>


          <div className="msgzs">
              {title_type_1(
                  this.state.trainArr.msgzs.cat_name,
                  this.state.trainArr.msgzs[0].term_id
              )}
              <div className="sdzkjWrap">
                  {this.zxyd(this.state.trainArr.msgzs).map((item) => {
                      let _style = {
                          backgroundImage: 'url(' + item.path + ')',
                      }
                      return (
                          <NavLink
                              className="imgWrap"
                              key={item.id}
                              style={_style}
                              to={'/article/' + item.id}
                          >
                              {
                                  parseInt(item.istop, 10) > 0 ?
                                      <div className='zd'>
                                          <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zd.png`} alt="" />
                                      </div> : ''
                              }
                              <div className="jxhd_section_p">{item.post_title}</div>
                          </NavLink>
                      )
                  })}
              </div>
              <div className="edu_jhhd_wrapM">
                  {this.zxyd(this.state.trainArr.msgzs) &&
                  this.zxyd(this.state.trainArr.msgzs).map((item) => {
                      let _style = {
                          backgroundImage: 'url(' + item.path + ')',
                      }
                      let Mnew = item
                      Mnew._style = _style
                      return <Msection Mnew={Mnew} key={item.id} />
                  })}
              </div>
          </div>
          <div className="sdzkj">
              {title_type_1(
                  this.state.trainArr.sdkj.cat_name,
                  this.state.trainArr.sdkj[0].term_id
              )}
              <div className="sdzkjWrap">
                  {this.zxyd(this.state.trainArr.sdkj).map((item) => {
                      let _style = {
                          backgroundImage: 'url(' + item.path + ')',
                      }
                      return (
                          <NavLink
                              className="imgWrap"
                              key={item.id}
                              style={_style}
                              to={'/article/' + item.id}
                          >
                              {
                                  parseInt(item.istop, 10) > 0 ?
                                      <div className='zd'>
                                          <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zd.png`} alt="" />
                                      </div> : ''
                              }
                              <div className="jxhd_section_p">{item.post_title}</div>
                          </NavLink>
                      )
                  })}
              </div>
              <div className="edu_jhhd_wrapM">
                  {this.zxyd(this.state.trainArr.sdkj).map((item) => {
                      let _style = {
                          backgroundImage: 'url(' + item.path + ')',
                      }
                      let Mnew = item
                      Mnew._style = _style
                      return <Msection Mnew={Mnew} key={item.id} />
                  })}
              </div>
          </div>
        <div className="kjfdypx">
          {title_type_2(
            this.state.trainArr.kjpx.cat_name,
            this.state.trainArr.kjpx[0].term_id
          )}
          <div className="edu_jhhd_wrap">
            {this.zxyd(this.state.trainArr.kjpx).map((item) => {
              let _style = {
                backgroundImage: 'url(' + item.path + ')',
              }
              let Mnew = item
              Mnew._style = _style
              return (
                <NavLink
                  key={item.id}
                  className="jxhd_section"
                  style={_style}
                  to={'/article/' + item.id}
                >
                  {
                    parseInt(item.istop, 10) > 0 ?
                      <div className='zd'>
                        <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zd.png`} alt="" />
                      </div> : ''
                  }
                  <div className="jxhd_section_p">{item.post_title}</div>
                </NavLink>
              )
            })}
          </div>
          <div className="edu_jhhd_wrapM">
            {this.zxyd(this.state.trainArr.kjpx).map((item) => {
              let _style = {
                backgroundImage: 'url(' + item.path + ')',
              }
              let Mnew = item
              Mnew._style = _style
              return <Msection Mnew={Mnew} key={item.id} />
            })}
          </div>
        </div>


        <div className="mtbd">
          {title_type_1(
            this.state.trainArr.mtbd.cat_name,
            this.state.trainArr.mtbd[0].term_id
          )}
          <div className="mtbd_wrap">
            {this.zxyd(this.state.trainArr.mtbd).map((item) => {
              return <SectionWrap secarr={item} key={item.id} />
            })}
          </div>
        </div>
      </TrainWrap>
    ) : (
      <Skeleton active />
    )
  }
}
export default Train
