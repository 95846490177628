import * as constants  from './constants'
import React from 'react'
const Zxgk = (data)=>{
    return{
        type:constants.ZXGK,
        data:data
    }
}
const Gzzc = (data)=>{
    return{
        type:constants.GZZC,
        data:data
    }
}

const Zzjg = (data)=>{
    return{
        type:constants.ZZJG,
        data:data
    }
}

const Bjkxzxtx = (data)=>{
    return{
        type:constants.KXZXTX,
        data:data
    }
}


const Tzgg = (data)=>{
    return{
        type:constants.TZGG,
        data:data
    }
}

const BgxxBanner = (data)=>{
    return{
        type:constants.HEADERBANNER,
        data:data        
    }
}

export const getBgxxBanner = ()=>{
    return (dispatch)=>{
        React.$request({
            url:'Portal/Index/is_ajax_list',
            method:'get',
            params: {
                type: 4,
                termid: 33
            }
        }).then(res=>{
            dispatch(BgxxBanner(res.data[0].path))
        }) 
    }
}


export const getZxgk = ()=>{
    return (dispatch)=>{
        React.$request({
            url:'Portal/Index/is_ajax_list',
            method:'get',
            params: {
                type:1,
                termid:42
            }
        }).then(res=>{
            dispatch(Zxgk(res.data[0]))
        })
    }
}

export const getGzzc = ()=>{
    return (dispatch)=>{
        React.$request({
            url:'Portal/Index/is_ajax_list',
            method:'get',
            params: {
                type:1,
                termid:43
            }
        }).then(res=>{
            dispatch(Gzzc(res.data[0]))
        })
    }
}

export const getZzjg = ()=>{
    return (dispatch)=>{
        React.$request({
            url:'Portal/Index/is_ajax_list',
            method:'get',
            params: {
                type:1,
                termid:44
            }
        }).then(res=>{
            dispatch(Zzjg(res.data[0]))
        }) 
    }  
}

export const getBjkxzxtx = ()=>{
    return (dispatch)=>{
        React.$request({
            url:'Portal/Index/is_ajax_list',
            method:'get',
            params: {
                type:5,
                termid:45
            }
        }).then(res=>{
            dispatch(Bjkxzxtx(res.data))
        }) 
    }  
}

export const getTzgg = ()=>{
    return (dispatch)=>{
        React.$request({
            url:'Portal/Index/is_ajax_list',
            method:'get',
            params: {
                type:2,
                termid:46
            }
        }).then(res=>{
            dispatch(Tzgg(res.data))
        }) 
    }  
}