import React from 'react'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'
import './style.css'

const swSection = (arr,pa,sw) =>{
    if(arr.length){
        return(
            <div className='quhdSlider'>
                <div className='swiper-container swiper-top'>
                    <div className="swiper-wrapper">
                        {
                            arr.length>1?
                            arr.map((item)=>{
                                let _style = {
                                        backgroundImage:'url('+item.path+')',
                                        backgroundSize:'contain',
                                        backgroundRepeat:'no-repeat',
                                };
                                return(
                                    <div className="swiper-slide" key={item.id} style={_style}>
                                        <div className="jxhd_section_p" style={{'background':`url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat`,' backgroundSize':'100% 100%'}}>{item.post_excerpt}</div>
                                    </div>
                                )
                            }):<img src={arr[0].path} />
                        }
                    </div>
                    {/* {
                        arr.length>1?<div className="swiper-pagination"></div>:<div className="swiper-pagination"></div>
                    } */}
                </div>
                <div className="swiper-container gallery-thumbs">
                    <div className="swiper-wrapper">
                            {
                                arr.length>1?
                                arr.map((item)=>{
                                    let _style = {
                                            backgroundImage:'url('+item.path+')',
                                            backgroundSize:'contain',
                                            backgroundRepeat:'no-repeat',
                                    };
                                    return(
                                        <div className="swiper-slide" key={item.id} style={_style}>
                                        </div>
                                    )
                                }):<img src={arr[0].path} />
                            }
                    </div>
                    <div className="swiper-button-next swiper-button-white"></div>
                    <div className="swiper-button-prev swiper-button-white"></div>
                </div>
                <div className='swiper-container swiper-M'>
                    <div className="swiper-wrapper">
                        {
                            arr.length>1?
                            arr.map((item)=>{
                                let _style = {
                                        backgroundImage:'url('+item.path+')',
                                        backgroundSize:'contain',
                                        backgroundRepeat:'no-repeat',
                                };
                                return(
                                    <div className="swiper-slide" key={item.id} style={_style}>
                                        <div className="jxhd_section_p" style={{'background':`url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat`,' backgroundSize':'100% 100%'}}>{item.post_excerpt}</div>
                                    </div>
                                )
                            }):<img src={arr[0].path} />
                        }
                    </div>
                    {/* {
                        arr.length>1?<div className="swiper-pagination"></div>:<div className="swiper-pagination"></div>
                    } */}
                </div>
            </div>

        )

    }
}
class CommonCarouselTitle extends React.Component{
    constructor(props){
        super(props)
        this.state={
            swArr:[],
            paginationM:"swiper-pagination opa "+'pa'+Math.floor((Math.random()*10000)),
            sw:"swiper-container swiper-container-common "+'sw'+Math.floor((Math.random()*10000))
        }
    }
    componentDidMount() {
        var _name ='.'+this.state.paginationM.split('opa ')[1]
        const galleryThumbs = new Swiper('.gallery-thumbs', {
            spaceBetween: 10,
            slidesPerView: 10,
            freeMode: true,
            loopedSlides: 5, //looped slides should be the same
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
        const galleryTop = new Swiper('.swiper-top', {
            spaceBetween: 10,
            loop:true,
            loopedSlides: 5, //looped slides should be the same
            thumbs: {
              swiper: galleryThumbs,
            },
        })
        const galleryM = new Swiper('.swiper-M', {
            spaceBetween: 10,
            loop:true,
        })
    }
    render() {
        return (
            <div>
                    {
                        swSection(this.props.Carouselarr,this.state.paginationM,this.state.sw)
                    }
            </div>

        )
    }
}
export default CommonCarouselTitle
