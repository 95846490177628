import React from 'react'
import {ClockCircleOutlined} from '@ant-design/icons';
import styles from '../index.module.scss'
import { changeImg } from '../pub';
import { Link } from 'react-router-dom';

export default class PreviousReview extends React.Component {
  render() {
    const {wjhgData} = this.props
    return (
        <div className={styles.previousReview}>
            <p className="title">媒体报道
                <Link className='link' to={`/curatorSessionList?termId=${wjhgData.term_id}&typeId=${wjhgData.post_keywords}`}>
                    更多
                </Link>
            </p>
            <div className='conBox'>
                {
                    wjhgData.articles.slice(0,6).map(el=>{
                        return (
                            <Link to={`/curatorSessionCon?articleId=${el.id}&termId=${el.term_id}`} key={el.id}>
                            <div className="conItem" key={el.id}>
                                <img src={changeImg(el.smeta.photo[0].url)} alt="" />
                                <div className="msgBox">
                                    <p className="title">{el.post_title.trim()}</p>
                                    <p className="msg">{el.post_excerpt}</p>
                                    <p><ClockCircleOutlined />&nbsp;{el.post_date.split(' ')[0]}</p>
                                </div>
                            </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
  }
}
