import styled from 'styled-components';

export const WeifuwuRoute = styled.div`
    @media (min-width: 750px) {
        width:953px;
        float:right;
    }
`

export const WeifuwuWrap = styled.div`
    @media (min-width: 750px) {
        .weifuwu_content{
            width:1200px;
            overflow:hidden;
            margin:30px auto;
        }
    }
`