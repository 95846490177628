import React from 'react'
import {SectionWrap} from './style'
import {NavLink} from 'react-router-dom'

class MSection extends React.Component{
    constructor(props){
        super(props)
        this.state={
            swArr:[]
        }
    }
    render(){
        return(
            <SectionWrap key={this.props.Mnew.id} className='hdbd_section'>
                <NavLink className='jxhd_section' to={'/article/'+this.props.Mnew.id}>
                    <div className="jxhd_section_bg" style={this.props.Mnew._style}>
                        {
                            parseInt(this.props.Mnew.istop, 10) > 0 ?
                            <div className='zd'>
                                <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zd.png`} alt="" />
                            </div> : ''
                        }
                        </div>
                        <div className="hdbd_section_detail">
                            <p className='hdbd_section_detail_title'>{this.props.Mnew.post_title}</p>
                            <span className='hdbd_section_date'>{this.props.Mnew.post_date} </span>
                        </div>                            
                </NavLink>
            </SectionWrap>
        ) 
    }
}
export default MSection