import React from 'react'
import styles from './index.module.scss'
import TitleBox from '../titleBox'
import { changeImg } from '../../pub'


class YqljBox extends React.Component {
  trun=(turnUrl)=>{
    window.open(turnUrl)
  }
  render() {
    const {yqlj} = this.props
    return (
        <>
        <TitleBox name={yqlj.name}/>
        <div className={styles.yqljBox} >
           {
                yqlj.articles.map((el)=>{
                    return (  
                        <p onClick={()=>this.trun(el.post_excerpt)} key={el.id} className="link">{el.post_title}</p>
                    )
                })
           }
           

        </div>
        </>
    )
  }
}
export default YqljBox
