import styled from 'styled-components'

export const SdkpjtWrap = styled.div `
    @media(min-width:750px){
        width:100%;
        overflow:hidden;
        .title_type_1{
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            .i_small2{
                        width:8px;
                        height:8px;
                        background:#f55c5d;
            }
            .i_large2{
                        width:16px;
                        height:16px;
                        background:#00a1b4;   
                        margin-right:7px;             
            }            
            span{
                        padding:10px 40px;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 16px 0 0;
                        box-sizing:border-box;
            }
        }
        .title_type_2{
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            .i_small2{
                        width:8px;
                        height:8px;
                        background:#f55c5d;
            }
            .i_large2{
                        width:16px;
                        height:16px;
                        background:#00a1b4;   
                        margin-right:7px;             
            }            
            span{
                        padding:10px 40px;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 16px 0 0;
                        box-sizing:border-box;
            }
        }
        
        a.ycxx_title_type_2 {
            color: #fff!important;
        }
        .ycxx_title_type_2{
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            .i_small2{
                        width:8px;
                        height:8px;
                        background:#f55c5d;
            }
            .i_large2{
                        width:16px;
                        height:16px;
                        background:#00a1b4;   
                        margin-right:7px;             
            }            
            span{
                        padding:10px 40px;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 16px 0 0;
                        box-sizing:border-box;
            }
        }
        
        .ycxx_title_type_2:hover{
            color:orange
        }
        .header{
            width:1200px;
            height:380px;
            display:flex;
            margin:10px auto 0;
            flex-direction:row;
            border-radius:10px;
            justify-content:space-between;
            .video{
                width:765px;
                height:380px;
                border-radius:10px;
                overflow:hidden;
                background:#00a1b4;
                video{
                    width:100%;
                    height:100%;
                }
            }
            .header_detail{
                width:370px;
                margin-right:37px;
                p{
                    margin-top:20px;
                    font-size:18px;
                    line-height:26px;
                }

            }
        }
        .sdkp_part_2{
            width:1200px;
            display:flex;
            margin:50px auto 0;
            flex-direction:row;    
            justify-content:space-between;
            .jdjm{               
                width:520px;
                .jdjm_wrap{
                    width:520px;
                    display:flex;
                    flex-direction:row;
                    height:423px;
                    flex-wrap:wrap;
                    margin-top:30px;  
                    .jdjm_section{
                        width:165px;
                        height:205px;
                        margin-right:12px;
                        border-radius:6px;
                        overflow:hidden;
                        margin-bottom:16px;
                        img{
                            width:100%;
                        }
                    }
                    .jdjm_section:nth-of-type(3n){
                        margin-right:0px;
                    }
                }
            } 
            .ycxx{
                width:515px;
                margin-right:65px;
                
                .edu_ycxx_list{
                    width:100%;
                    height:256px;
                    display:flex;
                    flex-direction:column;
                    margin-bottom:20px;
                    margin-top: 30px;
                    .more{
                        width:100%;
                        text-align:right;
                        /* margin-left:300px; */
                        font-size: 18px;
                        color:#00a1b4;
                        margin-top:-5px;
                    }
                    .hdyg_section{
                        // width:437px;
                        height:46px;
                        .time{
                            /* width:60px;
                            height:60px;
                            background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/eduIcons_06.png) 0 0 no-repeat;
                            background-size:100% 100%;
                            font-size:18px;
                            font-weight:bold;
                            color:#585858;
                            text-align:center;
                            line-height:70px; */
                        }
                        .content{
                            font-size:18px;
                            line-height:46px;
                            color:#515151;
                            display:flex;
                            justify-content:space-between;
                            .post_title{
                                width:400px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            .post_date{
                                
                            }
                            .post_title::before{
                                content:'';
                                width:8px;
                                height:8px;
                                background:#00a1b4;
                                border-radius:4px;
                                display: block;
                                margin:19px 10px 0 0;
                                float:left;
                            }
                        }
                        
                    }
                }
                
                .ycxx_wrap{
                    width:515px;
                    height:423px;
                    display:flex;
                    flex-direction:row;
                    justify-content:space-between;              
                    margin-top:30px;      
                    .ycxx_img_wrap{
                        width:283px;
                        height:423px;
                        border-radius:10px;
                        overflow:hidden;
                        background-position:center center;
                        background-repeat:no-repeat;
                        background-size:cover;
                    }
                    .ycxx_detail{
                        width:205px;
                        height:423px;
                        overflow:hidden;
                        .detail_wrap{
                            h2{
                                font-size:20px;
                                color:#585858;
                                font-weight:bold;
                                height:20px;
                                line-height:20px;
                                margin-top:8px;
                                margin-bottom:16px
                            }
                            p{
                                font-size:18px;
                                line-height:18px;
                                height:18px;
                                margin-bottom:13px
                            }
                        }
                        .detail_links{
                            width:100%;
                            height:280px;
                            display:flex;
                            flex-direction:column;
                            justify-content:space-between;
                            margin-top:20px;
                            a{
                                width:203px;
                                border:1px solid #ff7413;
                                padding:20px 0;
                                border-radius:3px;
                                line-height:100%;
                                text-align:center;
                                font-size:17px;
                                color:#ff7413;
                            }
                        }
                    }
                }

            }
        }
        .jcsjWrap{
            width:1200px;
            margin:56px auto 0;
            overflow:hidden;
            .sw{
                width:100%;
                height:670px;
                overflow:hidden;
                border-radius:10px;
                margin-top:30px;
            }
        }
        a:link {
            color: #000;
            text-decoration: none;
        }
        .sdkp_hdbd{
            width:1200px;
            margin:56px auto;     
            .hdbd_wrap{
                width:1200px;
                overflow:hidden;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
            }   
        }
    }
    @media(max-width:750px){
        width:100%;
        overflow:hidden;
        .title_type_1{                    
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:0;
            text-indent:0;
            .i_small2{
                width:.16rem;
                height:.16rem;
                background:#f55c5d;
            }
            .i_large2{
                width:.3rem;
                height:.3rem;
                background:#00a1b4;   
                margin-right:.07rem;             
            }            
            span{
                padding:.1rem .4rem;
                background:#00a1b4;
                border-radius:5px;
                margin:0 .16rem 0 0;
                box-sizing:border-box;
            }
        }
        a.title_type_2 {
            color: #fff;
        }
        .title_type_2{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:0;
            text-indent:0;
            .i_small2{
                width:.16rem;
                height:.16rem;
                background:#f55c5d;
            }
            .i_large2{
                width:.3rem;
                height:.3rem;
                background:#00a1b4;   
                margin-right:.07rem;             
            }            
            span{
                padding:.1rem .4rem;
                background:#00a1b4;
                border-radius:5px;
                margin:0 .16rem 0 0;
                box-sizing:border-box;
            }
        }
        .ycxx_title_type_2{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:0;
            text-indent:0;
            .i_small2{
                width:.16rem;
                height:.16rem;
                background:#f55c5d;
            }
            .i_large2{
                width:.3rem;
                height:.3rem;
                background:#00a1b4;   
                margin-right:.07rem;             
            }            
            span{
                padding:.1rem .4rem;
                background:#00a1b4;
                border-radius:5px;
                margin:0 .16rem 0 0;
                box-sizing:border-box;
            }
        }
        
        .ycxx_title_type_2:hover{
            color:orange
        }
        .header{
            width:100%;
            overflow:hidden;
            .video{
                width:100%;
                height:5.3rem;
                overflow:hidden;
                video{
                    width:100%;
                    height:100%;
                }
            }
            .header_detail{
                width:7.1rem;
                margin:0.2rem auto 0;
                p{
                    margin-top:.1rem;
                    font-size:.26rem;
                    line-height:.4rem;
                }

            }
        }
        .sdkp_part_2{
            width:7.1rem;
            margin:.2rem auto 0;
            .jdjm{               
                width:100%;
                .jdjm_wrap{
                    width:100%;
                    display:flex;
                    flex-direction:row;
                    flex-wrap:wrap;
                    justify-content:space-between;
                    margin-top:.3rem;
                    .jdjm_section{
                        width:3.4rem;
                        height:4.23rem;
                        border-radius:.1rem;
                        margin-bottom:.2rem;  
                        overflow:hidden;
                        img{
                            width:100%;
                        }
                    }
                }
            } 
            .ycxx{
                width:7.1rem;
                
                .edu_ycxx_list{
                    margin-top: .3rem;
                    width:100%;
                    overflow:hidden;
                    display:flex;
                    flex-direction:column;
                    .more{
                        color:#00a1b4;
                        width:100%;
                        font-size:.35rem;
                        text-align:right;
                        line-height:.7rem;
                    }
                    .hdyg_section{
                        width:100%;
                        display:flex;
                        flex-direction:row;
                        align-items:center;
                        border-bottom:1px dashed #dddddd;
                        padding:.2rem 0;
                        .time{
                            width:60px;
                            height:60px;
                            background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/eduIcons_06.png) 0 0 no-repeat;
                            background-size:100% 100%;
                            font-size:18px;
                            font-weight:bold;
                            color:#585858;
                            text-align:center;
                            line-height:70px;
                        }
                        .content{
                            flex:1;
                            padding:0 9px 0 20px;
                            box-sizing:border-box;
                            font-size:18px;
                            line-height:30px;
                            color:#515151
                        }
                    }

                }
                .ycxx_wrap{
                    width:100%;
                    display:flex;
                    flex-direction:row;
                    justify-content:space-between;              
                    margin-top:.3rem;      
                    .ycxx_img_wrap{
                        width:3.4rem;
                        height:4.85rem;
                        border-radius:10px;
                        overflow:hidden;
                        background-position:center center;
                        background-repeat:no-repeat;
                        background-size:cover;
                    }
                    .ycxx_detail{
                        flex:1;
                        margin-left:.3rem;
                        .detail_wrap{
                            h2{
                                font-size:.3rem;
                                color:#585858;
                                font-weight:bold;
                                height:.3rem;
                                line-height:.3rem;
                                margin-top:.08rem;
                            }
                            p{
                                font-size:.27rem;
                                line-height:.35rem;
                                height:.35rem;
                                margin-bottom:.13rem;
                            }
                        }
                        .detail_links{
                            width:100%;
                            display:flex;
                            flex-direction:column;
                            justify-content:space-between;
                            margin-top:.2rem;
                            height:3rem;
                            a{
                                width:100%;
                                border:0.01rem solid #ff7413;
                                padding:.2rem .4rem;
                                border-radius:.03rem;
                                line-height:100%;
                                text-align:center;
                                font-size:.24rem;
                                color:#ff7413;
                            }
                        }
                    }
                }

            }
        }
        .jcsjWrap{
            width:7.1rem;
            margin:.56rem auto 0;
            overflow:hidden;
            .sw{
                width:100%;
                height:4rem;
                overflow:hidden;
                border-radius:.1rem;
                margin-top:.3rem;
            }
        }
        .sdkp_hdbd{
            width:7.1rem;
            margin:.56rem auto;       
        }
    }
`
