import styled from 'styled-components';
const red = '#70c1f8'
export const FooterFixedWrap = styled.div `
    @media(min-width:750px){
        display: none;
    }
    @media(max-width:750px){
        width:100%;
        height:1.2rem;
        position: fixed;
        left:0;
        bottom:0;
        z-index:3;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        padding:0 .5rem;
        box-sizing:border-box;
        background:#fff;
        align-items:center;
        .footer_link{
            width:3.2rem;
            height:.8rem;
            border-radius:.06rem;
            background:${red};
            font-size:.35rem;
            color:#fff;
            line-height:.8rem;
            text-align:center;
            display:flex;
            flex-direction:row;
            justify-content:center;
            align-items:center;
            i{
                width:.54rem;
                display:flex;
                align-items:center;
                margin-right:.12rem;
                img{
                    width:100%;
                }
            }
        }
    }    
`