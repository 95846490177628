import styled from 'styled-components';

export const GjjlWrap = styled.div `
    @media(min-width:750px){
        width:1200px;
        margin:50px auto;
        .pagenation{
                    margin:60px auto 80px;
                    text-align:center;
                    .ant-pagination-item{
                        
                    }
                    .ant-pagination-item-active {
                        border-radius:0;
                        color:#fff;
                        background: #009cbe;
                        a{
                            color:#fff;     
                        }
                    }
        }
        .hdbd_wrapM{
            display: none;
        }
        .see_more{
            display: none;
        }
        .hdbd_wrap{
                    width:1200px;
                    overflow:hidden;
                    display:flex;
                    flex-direction:row;
                    flex-wrap:wrap;
                    justify-content:space-between;
                }  
    }
    @media(max-width:750px){
        width:7.1rem;
        margin:0 auto;
        .pagenation{
            display: none;
        }
        .hdbd_wrap{
            display: none;
        }
        .hdbd_wrapM{
            width:100%;
            overflow:hidden;
            margin:.3rem auto .3rem;

        }  
        .see_more {
            width: 2.5rem;
            height: .64rem;
            background: #009cbd;
            border-radius: .04rem;
            font-size: .3rem;
            color: #fff;
            line-height: .64rem;
            text-align: center;
            margin: .2rem auto .3rem;
        }
    } 
`