import React from 'react'
import './nyrzZqyhDefault.scss'
import styles from './index.module.scss'
import TopBanner from './compontents/topBanner/index' 
import ThreeLine from './compontents/threeLine/index'
import VideoBox from './compontents/videoBox'
import YqljBox from './compontents/yqljBox'
import Crumbs from './compontents/crumbs'



class NyrzZqyh extends React.Component {
  state={
    lmjs:null,
    hdyg:null,
    zxtj:null,
    jmdn:null,
    yqlj:null
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    React.$request({
      url: 'Portal/zt/termArticles',
      method: 'get',
      params: {
        termId: 931
      }
    }).then(res => {
      res.data.son.forEach(el => {
        switch (el.term_id.toString()) {
          case '932':
            this.setState({ 'lmjs': el })
            break;
          case '933':
            this.setState({ 'hdyg': el })
            break;
          case '934':
            this.setState({ 'zxtj': el })
            break
          case '935':
            this.setState({ 'jmdn': el })
            break;
          case '936':
            this.setState({ 'yqlj': el })
            break;
          case '466':
            this.setState({ 'lmtjData': el })
            break;
          default:
            break;
        }
      })
    //   this.setState({ showCon: true })
    })
  }

  render() {
    const {lmjs,hdyg,zxtj,jmdn,yqlj}=this.state
    return (
        
        lmjs!=null&&hdyg!=null&&zxtj!=null&&jmdn!=null&&yqlj!=null?
        <div className={styles.curatorSession}>
            <Crumbs termId='931'/>
            <TopBanner lmjs={lmjs} />
            <ThreeLine hdyg={hdyg}/>
            <ThreeLine hdyg={zxtj}/>
            <VideoBox jmdn={jmdn}/>
            <YqljBox yqlj={yqlj}/>
        </div>:''
    )
  }
}
export default NyrzZqyh
