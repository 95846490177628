import React from 'react'
import {ExIndexLszl, ExIndexLszlV2} from './style'
import {NavLink} from 'react-router-dom'
const ExhLszl = (arr)=>{
    let classname = 'lszl_img_wrap'
    if(arr && arr.length ===1){
        classname  ='lszl_img_wrap0'
    }
    return(
        <ExIndexLszlV2>
            {

                arr.map((item,index)=>{
                    return(
                        <a key={item.id} className={classname+' ex_index'} href={item.posts_link}>
                            <img src={item.path} alt={item.id}/>
                        </a>
                    )
                })
            }
            {
                arr.map((item,index)=>{
                    return(
                        <a key={item.id} className={classname + ' ex_m'} href={item.posts_link}>
                            <img src={item.path} alt={item.id}/>
                        </a>
                    )
                })
            }
        </ExIndexLszlV2>
    )
}
export default ExhLszl
