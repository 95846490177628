export function changeImg(val){
    if (!val) {
        return ''
    }
    if(val.indexOf('www.bjsc.net.cn')!=-1){
        return val
    }else{
        return `${process.env.REACT_APP_IMGBASE}` + val
    }
}

export function getColumKey(){
    return {
        961:'北京国际城市科学节联盟|Beijing Global Network of Science Festivals',
        963:'成员单位|Member List',
        964:'最新资讯|News',
        965:'联盟年会|Annual Conference',
        966:'理论研究|Research',
        967:'资源共享|Resources',
        968:'人才培养|Training',
        969:'品牌创立|Activities/Events',
        970:'决策咨询|Consulting'      
    }
}