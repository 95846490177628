import React from 'react';
import {Ldetail} from './style'
import {Skeleton} from 'antd';
class Detail extends React.Component{
    render(){
        return(
            <Ldetail>
                {
                    this.state.article.post_title?
                    <div>
                        <h2>{this.state.article.post_title}</h2>
                        <p className='l_d_time'>{this.state.article.post_date}</p>
                        <p dangerouslySetInnerHTML={{__html:this.state.article.post_content}} className='l_d_content'></p>
                    </div>
                    :<Skeleton active />
                    
                }
                <div onClick={()=>this.goBack()} className='goBack'>返回</div>
            </Ldetail>
        )
    }
    constructor(props){
        super(props)
        this.state = {
            article:{}
        }
    }
    goBack(){
        this.props.history.goBack() 
    }
    componentDidMount(){
        if(this.props.match.params.id){
            React.$request({
                url:'Portal/Index/post_detail',
                method:'get',
                params: {
                    id:this.props.match.params.id
                }
            }).then(res=>{
                this.setState({
                    article:res.data
                })
                document.title = res.data.post_title
            })
        }
    }
}

export default Detail