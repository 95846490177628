import { fromJS } from 'immutable'
import * as contants from './constants'
import constants from 'jest-haste-map/build/constants';

const library = fromJS({
    headerBanner:null,
    zxgk:null,
    gzzc:null,
    zzjg:null,
    bjkxzxtx:null,
    tzgg:null,
    xwzx:null
})

export default(state=library,action)=>{
    switch(action.type){
        case contants.HEADERBANNER:
            return state.set('headerBanner',action.data)
        case contants.ZXGK:
            return state.set('zxgk',action.data)
        case contants.GZZC:
            return state.set('gzzc',action.data)
        case contants.ZZJG:
            return state.set('zzjg',action.data)
        case contants.KXZXTX:
            return state.set('bjkxzxtx',action.data)
        case contants.TZGG:
            return state.set('tzgg',action.data)
        case constants.XWZX:
            return state.set('xwzx',action.data)
        default:
            return state
    }
}