import React, { memo, useEffect, useState } from 'react'
import { ListWrap } from './style'
import SectionWrap from 'common/newSection'
import { NavLink } from 'react-router-dom'
const CommList = memo((props) => {
  const { term_id = null, type = null, name = '' } = props
  const [list, setList] = useState([])
  useEffect(() => {
    React.$request({
      url:'Portal/Index/is_ajax_list',
      method:'get',
      params: {
        termid: term_id,
        type: type,
        size: 20,
      }
  }).then(res=>{
    setList(res.data.list)
  })
  }, [term_id])
  return (
    <ListWrap>
      <NavLink className='title_type_4' to={'/list/'+term_id}>
        <i className='i_small'></i>
        <i className='i_large'></i>
        <span>{name}</span>
        <i className='i_large2'></i>
        <i className='i_small2'></i>
      </NavLink>
      <div className="other_section_wrap">
            {list.length && list.map((item) => {
              return <SectionWrap secarr={item} key={item.id}></SectionWrap>
            })}
      </div>
    </ListWrap>
  )
})

export default CommList