import React from 'react';
import {connect} from 'react-redux';
import {withRouter,NavLink} from 'react-router-dom';
import { actions } from './../../store'
import {Sdcxcgz} from './style'
import CommonCarousel from 'common/carousel.js'
import {LeftImg,RightImg,LeftImg2} from './../exhcommon'
import {title_type_1} from './../index/index'
import {Skeleton} from 'antd'
import NavSection from 'common/navSection'

class ExSdcxcgz extends React.Component{
    componentDidMount(){
        this.goTo()
        if(!this.props.exhSdcxArr){
            this.props.getExSdcx()
        }
    }
    zxyd(arr){
        let zxydList = []
        for(let item in arr){
            if(item !== 'cat_name'){
                zxydList.push(arr[item])
            }
        }
        console.log(zxydList)
        return zxydList
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    render(){
        return(
            this.props.exhSdcxArr?
            <Sdcxcgz>
                <NavSection />
                <div className="zzt_header">
                    <CommonCarousel className='sw' Carouselarr={this.props.exhSdcxArr.lp}/>
                    <div className="zzt_header_detail">
                        <h2 className='zzt_title'>
                            <span>
                                {this.props.exhSdcxArr.sdgz[0].post_title}
                            </span>
                            <i className='i_large2'></i>
                            <i className='i_small2'></i>
                        </h2>
                        <div className='zzt_content' dangerouslySetInnerHTML={{__html:this.props.exhSdcxArr.sdgz[0].post_excerpt}}></div>
                        <div className='zzt_line'></div>
                        <div className="zzt_address"><span>地点:</span> {this.props.exhSdcxArr.sdgz[0].posts_address}</div>
                        <div className="zzt_opentime"><span>开放时间:</span>{this.props.exhSdcxArr.sdgz[0].posts_duration}</div>
                    </div>
                </div>
                {title_type_1('主题分区')}
                {
                    LeftImg2(this.zxyd(this.props.exhSdcxArr.zq_1))
                }
                {/* {title_type_1(this.props.exhSdcxArr.zq_2[0].post_title)}     */}
                {
                    window.innerWidth <750?
                    LeftImg(this.props.exhSdcxArr.zq_2[0]):
                    RightImg(this.props.exhSdcxArr.zq_2[0])
                }
                {/* {title_type_1(this.props.exhSdcxArr.zq_3[0].post_title)} */}
                {
                    LeftImg(this.props.exhSdcxArr.zq_3[0])
                }
                {/* {title_type_1(this.props.exhSdcxArr.zq_4[0].post_title)} */}
                {
                    window.innerWidth <750?
                    LeftImg(this.props.exhSdcxArr.zq_4[0]):
                    RightImg(this.props.exhSdcxArr.zq_4[0])
                }

                {/* {
                    LeftImg(this.props.exhSdcxArr.zq_5[0])
                } */}
                {/* {
                    window.innerWidth <750?
                    LeftImg(this.props.exhSdcxArr.hdq[0]):
                    RightImg(this.props.exhSdcxArr.hdq[0])
                } */}
                <NavLink className="mxdw_wrap" to={'/article/'+this.props.exhSdcxArr.xmdw[1].id}>
                    <img src={this.props.exhSdcxArr.xmdw[1].path} alt='...'/>
                </NavLink>
                <a className="mxdw_wrap" target={this.props.exhSdcxArr.xmdw[0].posts_link.indexOf(location.host)>=0?'':'_blank'} /*href={this.props.exhSdcxArr.xmdw[0].posts_link}*/>
                    <img src={this.props.exhSdcxArr.xmdw[0].path} alt='...'/>
                </a>
                {title_type_1('往期回顾')}
                <div className="wqhg_wrap">
                    {
                        this.zxyd(this.props.exhSdcxArr.wqhg).map((item)=>{
                            return(
                                <NavLink key={item.id} className='wqhg_section' to={'/article/'+item.id}>
                                    <div className="wqhg_section_img_wrap">
                                        <img src={item.path} alt='...' />
                                        <div className="wqhg_section_p">
                                            {item.post_title}
                                        </div>
                                    </div>
                                </NavLink>
                            )
                        })
                    }
                </div>
            </Sdcxcgz>
            :<Skeleton active />
        )
    }
}
const mapStateToProps = (state)=>{
    return{
        exhSdcxArr:state.getIn(['exhibition','sdcxcgz'])
    }
}
const mapDispatchToProps = (dispatch)=>{
    return{
        getExSdcx(){
            dispatch(actions.getExhSdcx())
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExSdcxcgz))
