import styled from 'styled-components'

export const EduOtherWrap = styled.div`
  @media (min-width: 750px) {
    width: 100%;
    overflow: hidden;
    .paginationWrapM {
      display: none;
    }
    .pagenation {
      margin: 60px auto 80px;
      text-align: center;
      .ant-pagination-item {
      }
      .ant-pagination-item-active {
        border-radius: 0;
        color: #fff;
        background: #009cbe;
        a {
          color: #fff;
        }
      }
    }
    .title_type_1 {
      font-size: 32px;
      font-weight: bold;
      line-height: 32px;
      color: #fff;
      display: flex;
      flex-direction: row;
      align-items: center;
      .i_small2 {
        width: 8px;
        height: 8px;
        background: #f55c5d;
      }
      .i_large2 {
        width: 16px;
        height: 16px;
        background: #00a1b4;
        margin-right: 7px;
      }
      span {
        padding: 10px 40px;
        background: #00a1b4;
        border-radius: 5px;
        margin: 0 16px 0 0;
        box-sizing: border-box;
      }
    }
    .title_type_2 {
      width: 154px;
      height: 37px;
      background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/qthd_03.png) center center no-repeat;
      background-size: 100% 100%;
      font-size: 28px;
      color: #fff;
      font-weight: bold;
      line-height: 37px;
      text-indent: 40px;
    }
    .header {
      width: 1200px;
      height: 380px;
      display: flex;
      flex-direction: row;
      margin: 10px auto 0;
      .sw {
        width: 830px;
        height: 380px;
        border-radius: 10px;
        overflow: hidden;
      }
      .header_detail {
        flex: 1;
        margin-left: 45px;
        p {
          font-size: 18px;
          line-height: 30px;
          color: #000000;
          margin-top: 30px;
        }
      }
    }
    .listWrap {
      width: 1200px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 60px auto;
      .paginationWrap {
        width: 550px;
        overflow: hidden;
        flex-wrap: wrap;
        .paginationWrap_title {
          width: 1200px;
          height: 37px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .line {
            width: 1060px;
            height: 2px;
            background: #009cbe;
            margin-left: -10px;
          }
        }
        .other_section_wrap {
          width: 100%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }
    }
  }
  @media (max-width: 750px) {
    width: 100%;
    overflow: hidden;
    .title_type_1 {
      font-size: 0.32rem;
      font-weight: bold;
      line-height: 0.32rem;
      color: #fff;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
      text-indent: 0;
      .i_small2 {
        width: 0.16rem;
        height: 0.16rem;
        background: #f55c5d;
      }
      .i_large2 {
        width: 0.3rem;
        height: 0.3rem;
        background: #00a1b4;
        margin-right: 0.07rem;
      }
      span {
        padding: 0.1rem 0.4rem;
        background: #00a1b4;
        border-radius: 5px;
        margin: 0 0.16rem 0 0;
        box-sizing: border-box;
      }
    }
    .title_type_2 {
      width: 2.67rem;
      height: 0.5rem;
      background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/qthd_03.png) center center no-repeat;
      background-size: 100% 100%;
      font-size: 0.34rem;
      color: #fff;
      font-weight: bold;
      line-height: 0.5rem;
      text-indent: 0.5rem;
    }
    .header {
      width: 100%;
      overflow: hidden;
      .sw {
        width: 100%;
        height: 4rem;
      }
      .header_detail {
        width: 7.1rem;
        margin: 0.2rem auto 0;
        p {
          font-size: 0.28rem;
          color: #282828;
          line-height: 0.4rem;
          text-indent: 0;
          color: #000;
          margin-top: 0.2rem;
          text-align: justify;
        }
      }
    }
    .paginationWrap {
      width: 7rem;
      margin: 0.3rem auto;
    }
    .paginationWrapM {
      display: none;
    }
    .see_more {
      width: 2.5rem;
      height: 0.64rem;
      background: #009cbd;
      border-radius: 0.04rem;
      font-size: 0.3rem;
      color: #fff;
      line-height: 0.64rem;
      text-align: center;
      margin: 0.5rem auto 0.7rem;
    }
  }
`
