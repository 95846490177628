import React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { QuestionsWrap } from './style'
import CommonCarouselMk from 'common/mkjkxSlide'
import SectionWrap from 'common/newSection'
import { Skeleton } from 'antd';
import NavSection from 'common/navSection'
import {MkjkxWrap} from "../mkjkx/style";

class Questions extends React.Component {
    goTo() {
        let scrollToTop = window.setInterval(function () {
            let pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - 10000); // how far to scroll on each step
            } else {
                window.clearInterval(scrollToTop);
            }
        }, 2);
    }
    constructor(props) {
        super(props)
        this.state = {
            mkArr: [
                {
                    title:'一、票务问题',
                    list:[
                        {
                            question:'1.网上没有预订到票怎么才能进馆参观？',
                            answer:'北京科学中心实行实名制网络预订参观方式，未预订无法参观。'
                        },
                        {
                            question:'2.当天可不可以两次进馆参观？约错日期怎么才能进入？',
                            answer:'每张票当日/当场次有效，一张证件一天只能预订一次。'
                        },
                        {
                            question:'3.网上预订好了，没带证件怎么办？',
                            answer:'请到换票窗口告知工作人员证件号或订单号换取纸质票。'
                        },
                        {
                            question:'4.在哪里购票，费用多少？',
                            answer:'目前开放的三生主题馆、创新成果展、儿童乐园实行实名制预订，至少提前一天进行网上预订，免费不免票。'
                        },
                        {
                            question:'5.预订成功了还用取票吗？',
                            answer:'使用第二代居民身份证预订的观众，可直接刷身份证入场；使用护照或港澳台居民身份证预订的观众，请到换票窗口核对证件，换票入场。'
                        },
                        {
                            question:'6.用什么证件可以预订？',
                            answer:'可使用第二代居民身份证，护照，港澳台居民身份证等进行预订。'
                        },
                        {
                            question:'7.网上能提前多少天预订？',
                            answer:'网上最多可以提前7天预订。'
                        },
                        {
                            question:'8.一笔订单可以预订几个人？一张证件可以预订几个人？',
                            answer:'每个注册用户每笔订单限5人，超过5人需分开下单。因实行实名制网络预订参观方式，每个证件号码在同一天/同一场次只能预订一张票。'
                        },
                        {
                            question:'9.团队如何在网上预订，需要什么证件？',
                            answer:'目前只开放个人预订，暂不开放团体预订。'
                        },
                        {
                            question:'10.为什么有时候持票还被限制入场？',
                            answer:'您好，馆内观众流量过大容易发生危险，为保证观众的生命财产安全，超过最大承载量时馆区会适时采取限流措施，暂缓观众进场。'
                        },
                        {
                            question:'11.公园年票可以直接进吗？',
                            answer:'非常抱歉，目前不接受公园年票直接入馆。'
                        },
                        {
                            question:'12.可以预订或购买当天的票吗？ ',
                            answer:'门票统一由网上预订。最早提前7天，最晚提前一天下午16:00之前预订，当天无法预订。暂未开通现场预订及售票服务。'
                        },
                        {
                            question:'13.如何退票？',
                            answer:'登录北京科学中心官网，进入门票预订页面，点击用户名，进入订单中心，选择订单，点击退票。'
                        },
                        {
                            question:'14.什么时间放票？',
                            answer:'我们会提前7天在凌晨零时更新门票的数量。'
                        },
                        {
                            question:'15.儿童乐园和课程活动预订成功后如何参加？',
                            answer:'儿童乐园和课程活动在官网预订成功后，需在馆内自助换票机换票方可参加活动。（自助换票机位置：2号楼1层东侧和4号楼入口处）。'
                        }
                    ]
                },
                {
                    title:'二、交通问题',
                    list:[
                        {
                            question:'1.你们那里有没有停车场？',
                            answer:'非常抱歉，北京科学中心暂时不提供停车服务。'
                        },
                        {
                            question:'2.最近的地铁（或公交）是哪站？',
                            answer:'北京科学中心紧邻地铁8号线安华桥站。公交可以乘坐13路、21路、409路、607路安华桥北站下车即可到达，300路、302路、328路、361路、387路、601路、671路、847路、848路、特8路、运通101路、运通104路、运通201路安华桥西站下车即可到达。因交通线路存在不定期更改的因素，建议您出行前再核实一下乘车路线。'
                        },
                        {
                            question:'3.从哪个门进馆参观？',
                            answer:'所有进馆参观的观众统一由东门经过检票及安检入馆。'
                        },
                        {
                            question:'4.出口在哪？',
                            answer:'所有参观完毕的观众统一由东门离馆。'
                        }
                    ]
                },
                {
                    title:'三、展馆问题',
                    list:[
                        {
                            question:'1.有没有参观路线？',
                            answer:'北京科学中心现阶段开放首都科技创新成果展、三生主题馆、儿童乐园三个展馆，您可以依次参观，也可以根据时间自行安排。'
                        },
                        {
                            question:'2.馆里有热水供应吗？',
                            answer:'馆内综合服务厅设有热水供应点，需要观众自备水杯。'
                        },
                        {
                            question:'3.展厅里有WIFI吗，密码多少？',
                            answer:'北京科学中心为观众提供免费WIFI，认证登录后可以使用。'
                        },
                        {
                            question:'4.适合多大年龄的孩子参观？',
                            answer:'创新成果展和三生主题展推荐8岁以上青少年参观，儿童乐园推荐3至8岁儿童参观。'
                        },
                        {
                            question:'5.平时有什么活动吗，一般在什么时间？',
                            answer:'我们会不定期地组织线上和线下的科普活动，您可以关注北京科学中心官方网站或数字北京科学中心微信公众号，随时了解相关活动内容。'
                        },
                        {
                            question:'6.附近有什么好玩的吗？',
                            answer:'北京科学中心距离国家体育场较近，您可以自行安排时间前往。'
                        },
                        {
                            question:'7.科学中心有吃饭或休息的地方吗？',
                            answer:'北京科学中心暂时不提供餐饮服务。在馆内环廊设有休息区。'
                        },
                        {
                            question:'8.可以购买纪念品吗？',
                            answer:'非常抱歉，暂时没有纪念品出售。'
                        },
                        {
                            question:'9.有场馆资料吗，在哪里领取？',
                            answer:'展厅内有宣传资料提供，您可以自行领取。'
                        },
                        // {
                        //     question:'10.有轮椅吗？',
                        //     answer:'我们提供轮椅租赁服务，您可以到2号楼一层观众服务中心了解租赁事宜。'
                        // },
                        {
                            question:'10.行李/婴儿车可以寄存吗？',
                            answer:'非常抱歉，馆内只设有小件寄存柜，大件物品无法为您提供寄存服务，请您自行保管。'
                        },
                        {
                            question:'11.有急救箱吗？',
                            answer:'我们在观众服务中心备有简单外用药品。'
                        },
                        {
                            question:'12.开馆时间？',
                            answer:'北京科学中心在每周一、周二（国家法定节假日除外）、除夕至正月初二闭馆。如遇特殊活动，我们会调整开闭馆时间，请您登陆北京科学中心官方网站或数字北京科学中心微信公众号了解相关情况。'
                        }
                    ]
                }
            ]
        }
    }
    componentDidMount() {
        document.title = 'Q&A问答'
    }

    goBack() {
        this.props.history.goBack()
    }
    render() {
        return (
            this.state.mkArr ?
                <QuestionsWrap>
                    <NavSection />
                    <div className="header">
                        <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/questions/questions-top.png`}/>
                    </div>
                    <div className="content">
                        <div className="question-content">
                            {
                                this.state.mkArr.map((item,index) => {
                                    return (
                                        <div className="question-item">
                                            <div className="question-title">{item.title}</div>
                                            <div className="question-list">
                                                {
                                                    item.list.map((q)=>{
                                                        return (
                                                            <div className="question-item">
                                                                <div className="question">
                                                                    <span className="question-icon">Q:</span>
                                                                    <div className="text">{q.question}</div>
                                                                </div>
                                                                <div className="answer">
                                                                    <span className="answer-icon">A:</span>
                                                                    <div className="text">{q.answer}</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>

                    <div onClick={() => this.goBack()} className="goBack">
                        返回
                    </div>
                </QuestionsWrap> : <Skeleton active />
        )
    }
}

export default withRouter(Questions)
