import styled from 'styled-components'

export const Search_wrap = styled.div`
    @media(min-width:750px){
        width:100%;
        overflow:hidden;
        .empty{
            margin:100px auto;
        }
        .pagenation{
            margin:60px auto 80px;
            text-align:center;
            .ant-pagination-item{
                
            }
            .ant-pagination-item-active {
                border-radius:0;
                color:#fff;
                background: #009cbe;
                a{
                    color:#fff;     
                }
            }
        }
        .wxzx_list{
            width:1000px;
            margin:0 auto;
            h2{
                margin:30px 60px;
                font-size:30px;
                span{
                    font-weight:bold;
                    color:#00a1b4;
                }
            }
            .xwzxSection{
                    width:100%;
                    display:flex;
                    overflow:hidden;
                    overflow:hidden;
                    margin-top:30px;
                    flex-direction:row;
                    justify-content:flex-start;
                    .imgWrap{
                        width:260px;
                        height:150px;
                        background-repeat:no-repeat;
                        background-size:cover;
                        background-position:center center;
                        border-radius:8px;
                    }
                    .sec_detail{
                        flex:1;
                        display:flex;
                        flex-direction:column;
                        margin-left:60px;
                        justify-content:center;
                        .sec_detail_top{
                            width:100%;
                            i{
                                width:14px;
                                height:14px;
                                border-radius:100%;
                                border:1px solid #19a6c4;
                                display: inline-block;
                            }
                            .sec_detail_title{
                                font-size:20px;
                                color:#323232;
                                overflow: hidden;
                                text-overflow:ellipsis;
                                white-space: nowrap;
                                margin-left:13px;
                            }
                        }
                        .sec_detail_time{
                            width:158px;
                            height:26px;
                            border:1px solid #009cbe;
                            margin-top:30px;
                            color:#009cbe;
                            text-align:center;
                            line-height:26px;
                            margin-left:26px
                        }
                    }
                }
            }
    }
    @media(max-width:750px){
        width:7.1rem;
        margin:0 auto;
        overflow:hidden;
        h2{
            margin-left:.2rem;  
            font-size:.3rem;
            span{
                color:#009cbe;
                font-weight:bold;
            }
        }
        .empty{
            margin:100px auto;
        }
        .pagenation{
            margin:.2rem auto;
            text-align:center;
            .ant-pagination-item{
                
            }
            .ant-pagination-item-active {
                border-radius:0;
                color:#fff;
                background: #009cbe;
                a{
                    color:#fff;     
                }
            }
        }
        .wxzx_list{
            width:100%;
            overflow:hidden;
            .xwzxSection{
                width:100%;
                display:flex;
                overflow:hidden;
                flex-direction:row;
                justify-content:space-between;
                align-items:center;
                padding:0.3rem 0;
                border-bottom:0.01rem dashed #9f9f9f;
                .imgWrap{
                    width:2.4rem;
                    height:1.55rem;
                    background-repeat:no-repeat;
                    background-size:cover;
                    background-position:center center;
                    border-radius:0.11rem;
                }
                .sec_detail{
                    flex:1;
                    display:flex;
                    flex-direction:column;
                    justify-content:center;
                    margin-left:.2rem;
                    .sec_detail_top{
                        width:100%;
                        .sec_detail_title{
                            font-size:.3rem;
                            color:#323232;
                            overflow: hidden;
                            line-height:.36rem;
                        }
                    }
                    .sec_detail_time{
                        color:#009cbe;
                        text-align:right;
                        font-size:.2rem;
                    }
                }
            }
        }
    }
`