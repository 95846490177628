import styled from 'styled-components';

export const XydggWrap = styled.div `
    @media(min-width:750px){
        background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/library/xydgg/xydgg-bg.jpg) center top repeat-y;
        width: 100%;
        overflow: hidden;
        background-size: cover;
        background-position: center top;
        min-height: 2800px;
        .bQvkRk a {
            color: #FFFFFF;
        }
        .head-box{
            width:1200px;
            margin:0 auto ;
            .head-title{
                text-align:center;
                .head-title-icon{
                    width:807px;
                    height:171px;
                }
                .subtitle{
                    font-family: PingFangSC-Regular;
                    font-size: 40px;
                    color: #FFEBC4;
                    letter-spacing: 1.5px;
                    text-align: right;
                }
            }
            .head-content{
                margin-top:20px;
                padding:16px;
                background: rgba(255,235,196,.15);
                border-radius: 8px;
                font-family: PingFangSC-Regular;
                font-size: 18px;
                color: #FFEBC4;
                letter-spacing: 0;
                text-align: justify;
            }
        }
        .xydgg-wrap{
            width:1200px;
            margin:0 auto ;
            background-color:#FFFFFF;
            border-radius:8px;
            padding:0 30px;
            margin-top:50px;
            min-height:2130px;
            position:relative;
            .hdbd_section{
                width:100%;
                height:260px;
                padding:30px 0;
                border-bottom:1px solid #EEEEEE;
                margin:0;
                .hdbd_section_img_wrap{
                    height:100%;
                    width:200px;
                    img{
                        width:100%;
                        min-height:100%;
                    }
                }
                .hdbd_section_detail{
                    height:100%;
                    width:auto;
                    flex:1;
                    margin-left:20px;
                    .hdbd_section_detail_title{
                        font-size: 24px;
                        color: #221815;
                        letter-spacing: 0;
                        text-align: justify;
                    }
                    .hdbd_section_date{
                        font-size: 18px;
                        color: #999999;
                        letter-spacing: 0;
                        text-align: justify;
                    }
                }
            }
            .check-more{
                position:absolute;
                left:0;
                right:0;
                bottom:0;
                height:50px;
                line-height:50px;
                margin:0 auto;
                font-size: 18px;
                // color: #FFEBC4;
                text-align:center;
            }
            .empty{
                padding:100px 0;
            }
        }
    }
    @media(max-width:750px){
        width:100%;
        margin:0 auto;
        .head-box{
            margin:0 auto ;
            background-color:#B52619;
            padding:0.3rem;
            .head-title{
                text-align:center;
                .head-title-icon{
                    width: 4.06rem;
                    height: 0.85rem;
                }
                .subtitle{
                    font-family: PingFangSC-Regular;
                    font-size: .40rem;
                    color: #FFEBC4;
                    text-align: right;
                }
            }
            .head-content{
                margin-top:.20rem;
                padding:.16rem;
                background: rgba(255,235,196,.15);
                border-radius: 8px;
                font-family: PingFangSC-Regular;
                font-size: .18rem;
                color: #FFEBC4;
                letter-spacing: 0;
                text-align: justify;
            }
        }
        
        .xydgg-wrap{
            width:7.1rem;
            overflow:hidden;
            display:flex;
            flex-direction:row;
            flex-wrap:wrap;
            justify-content:space-between;
            margin-bottom:.3rem;
            margin:0 auto;
            .hdbd_section{
                width:7.1rem;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                align-items:center;
                .hdbd_section_img_wrap{
                    width:2.4rem;
                    height:1.55rem;
                    border-radius:7px;
                    overflow:hidden;
                    img{
                        width:100%;
                        min-height:100%
                    }
                }
                .hdbd_section_detail{
                    flex:1;
                    height:1.55rem;
                    display:flex;
                    text-align:left;
                    flex-direction:column;
                    justify-content:space-between;
                    .hdbd_section_detail_title{
                        font-size:.28rem;
                        color:#221815;
                        line-height:.35rem;
                        width:100%;
                    }
                    .hdbd_section_date{
                        width:100%;
                        color:#009cbe;
                        font-size:15px;
                    }
                }
            }
            .check-more{
                height:50px;
                line-height:50px;
                margin:0 auto;
                font-size: 18px;
                text-align:center;
            }
            .empty{
                padding:100px 0;
                margin: 0 auto;
            }
        }
    }
`
