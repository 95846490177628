import styled from 'styled-components';

export const MkjkxWrap = styled.div `
    @media(min-width:750px){
        width:1200px;
        margin:0 auto;
        .header{
            width:100%;
            height:380px;
            display:flex;
            flex-direction:row;
            margin:10px auto 0;
            border-radius:10px;
            overflow:hidden;
            .sw{
                width:835px;
                height:380px;
                .swiper-slide img {
                  width: 100%;
                  min-height:100%;
                }
            }
            .header_detail{
                flex:1;
                /** background: #40b5ce;*/
                background-color:#ee6e47;
                padding:0 20px;
                display:flex;
                font-size:20px;
                color:#fff;
                justify-content:center;
                align-items:center;
                flex-direction:column;
                h2{
                    font-size:30px;
                    font-weight:bold;
                    color:#fff;
                    .mkjkx-icon{
                        display:none;
                    }
                }
                p{
                    font-size:18px;
                    line-height:30px;
                }
            }
        }
        .title_type_2:hover{
            color:orange;
        }
        .title_type_2{
            height:32px;
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            text-align:center;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:60px auto 30px;
            text-align:center;
            justify-content:space-between;
            .i_small{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-left:7px;             
            }
            .i_small2{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large2{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-right:7px;             
            }
            .title-icon{
                display:flex;
                align-items:center;
            }
            span{
                font-family: PingFangSC-Semibold;
                font-size: 38px;
                color: #222222;
                letter-spacing: 0;
                text-align: justify;
            }
            .icon{
                width:38px;
                height:38px;
                margin-right:10px;
            }
            .more{
                width:264px;
                text-align:right;
                background-image: linear-gradient(90deg, rgba(172,225,250,0.00) 2%, #70c1f8 100%);
                border-radius: 0 100px 100px 0;
                font-size: 20px;
                color: #FFFFFF;
                letter-spacing: 0;
                display:flex;
                justify-content:flex-end;
                align-items:center;
                img{
                    width:10px;
                    height:15px;
                    margin-right:10px;
                }
                span{
                    font-family: PingFangSC-Medium;
                    font-size: 20px;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    padding-right: 10px;
                }
            }
        }
        .dmbcg{
            width:100%;
            display: flex;
            flex-direction:row;
            justify-content:space-between;
            flex-wrap:wrap;
            .dmSection{
                width:386px;
                overflow:hidden;
                margin-top:20px;
                .imgWrap{
                    width:100%;
                    height:215px;
                    border-radius:5px;
                    overflow:hidden;
                    position: relative;
                    border: 1px solid #F0F0F0;
                    img{
                        width:100%;
                    }
                    .tag{
                        width:64px;
                        height:33px;
                        // background:#f9bd48;
                        background-color:#70c1f8;
                        color:#fff;
                        position:absolute;
                        top:13px;
                        right:0;
                        line-height:33px;
                        text-align:center;
                        font-size:20px;
                        border-radius:18px 0 0 18px;
                    }
                    .tag2{
                        width:64px;
                        height:33px;
                        // background:rgba(81,105,150,.7);
                        background-color:#70c1f8;
                        color:#fff;
                        position:absolute;
                        top:13px;
                        right:0;
                        line-height:33px;
                        text-align:center;
                        font-size:20px;
                        border-radius:18px 0 0 18px;
                    }
                    .zd{
                        width:50px;
                        position: absolute;
                        left:0;
                        top:10px;
                        img{
                            width:100%;
                        }
                    }
                }
                .dmtitle{
                    font-size:18px;
                    color:#000;
                    line-height:25px;
                    margin-top:20px;
                    
                    .hdbd_section_detail_title{
                        font-family: PingFangSC-Regular;
                        font-size: 22px;
                        color: #221815;
                        letter-spacing: 0;
                        text-align: justify;
                        
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        white-space: normal!important;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        margin-bottom:10px;
                    }
                    
                    .hdbd_section_date{
                        font-family: PingFangSC-Regular;
                        font-size: 18px;
                        color: #999999;
                        letter-spacing: 0;
                    }
                }
            }
        }
        .ssmWrap{
            width:100%;
            display: flex;
            flex-direction:row;
            justify-content:space-between;
            flex-wrap:wrap;
            .dmSection{
                width:285px;
                overflow:hidden;
                margin-top:20px;
                .imgWrap{
                    width:285px;
                    height:285px;
                    border-radius:5px;
                    overflow:hidden;
                    position: relative;
                    img{
                        width:100%;
                    }
                    .zd{
                        width:50px;
                        position: absolute;
                        left:0;
                        top:10px;
                        img{
                            width:100%;
                        }
                    }
                }
                .dmtitle{
                    font-size:18px;
                    color:#000;
                    line-height:25px;
                    margin-top:10px;
                    margin-bottom:10px;
                    
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-wrap: break-word;
                    white-space: normal!important;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
                .dm-time{
                    font-family: PingFangSC-Regular;
                    font-size: 18px;
                    color: #999999;
                    letter-spacing: 0;
                    text-align: justify;
                }
            }
        }
        .kdkxcdWrap{
            width:100%;
            display: flex;
            flex-direction:row;
            justify-content:space-between;
            flex-wrap:wrap;
            .dmSection{
                width:220px;
                overflow:hidden;
                margin-top:20px;
                .imgWrap{
                    width:220px;
                    height:220px;
                    border-radius:5px;
                    overflow:hidden;
                    position: relative;
                    img{
                        width:100%;
                        min-height:100%;
                    }
                    .zd{
                        width:50px;
                        position: absolute;
                        left:0;
                        top:10px;
                        img{
                            width:100%;
                        }
                    }
                }
                .dmtitle{
                    font-size:18px;
                    color:#000;
                    line-height:25px;
                    margin-top:10px;
                    margin-bottom:10px;
                    
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-wrap: break-word;
                    white-space: normal!important;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
                .dm-time{
                    font-family: PingFangSC-Regular;
                    font-size: 18px;
                    color: #999999;
                    letter-spacing: 0;
                    text-align: justify;
                }
            }
        }
        .hdhx{
            width:1200px;
            overflow:hidden;
            margin:0 auto;
            margin-bottom:30px;
            .hdhx_wrap{
                width:100%;
                height:450px;
                display:flex;
                flex-direction:row;
                // margin-top:35px;
                margin-top:20px;
                .video{
                    width:750px;
                    height:450px;
                    border-radius:10px;
                    overflow:hidden;
                    background:#009cbe;
                    video{
                        width:100%;
                        height:100%;
                    }
                }
                .hdxh_list{
                    flex:1;
                    margin-left:20px;
                    display:flex;
                    flex-direction:column;
                    margin-top:12px;
                    margin-bottom:20px;
                    
                    .hdhx_link{
                        padding:8px 10px;
                        font-size:22px;           
                        color:#221815;
                        line-height:34px;
                        border-bottom:1px solid #d0d0d0;
                        cursor: pointer;
                        box-sizing:border-box;
                    }
                    .hdhx_link_active{
                        color:#70c1f8;
                        font-weight:bold;
                        // background:#eeeeee;
                        position:relative;
                        display: flex;
                        align-items: center;
                        .active-icon{
                            position:absolute;
                            width:13px;
                            height:22px;
                            left:-10px ;
                            line-height:34px;
                        }
                    }
                }
            }
        }
        .mtbd_wrap{
            width:1200px;
            overflow:hidden;
            // display:flex;
            // flex-direction:row;
            // flex-wrap:wrap;
            // justify-content:space-between;
            margin-bottom:30px;
            
            display: grid;
            justify-content: space-between;
            grid-template-columns: repeat(auto-fill, 270px);
            grid-gap: 10px;
            .hdbd_section{
                // width:540px;
                width:250px;
                margin:10px;
                // height:170px;
                height:auto;
                // margin-top:30px;
                display:flex;
                // flex-direction:row;
                flex-direction:column;
                justify-content:space-between;
                align-items:center;
                .hdbd_section_img_wrap{
                    width:270px;
                    height:170px;
                    border-radius:7px;
                    overflow:hidden;
                    position:relative;
                    img{
                        width:100%;
                        min-height:100%;
                    }
                    .tag{
                        padding:0 16px;
                        height:30px;
                        line-height:30px;
                        background-color:#70c1f8;
                        color:#fff;
                        position:absolute;
                        top:13px;
                        left:0;
                        text-align:center;
                        font-size:16px;
                        border-radius:0 18px 18px 0;
                    }
                }
                .hdbd_section_detail{
                    width:270px;
                    // height:170px;
                    height:auto;
                    display:flex;
                    text-align:left;
                    flex-direction:column;
                    justify-content:space-between;
                    .hdbd_section_detail_title{
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        white-space: normal!important;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        margin:10px 0px;
                        font-family: PingFangSC-Regular;
                        font-size: 22px;
                        color: #221815;
                    }
                    .hdbd_section_date{
                        color:#999999;
                    }
                    
                    h2{
                        font-size:22px;
                        color:#221815;
                        line-height:30px;
                        width:100%;
                    }
                    p{
                        width:100%;
                        // color:#009cbe;
                        color:#999999;
                        font-size:15px;
                    }
                }
            }
        }
        .xcjt_wrap{
            width:1200px;
            overflow:hidden;
            // display:flex;
            // flex-direction:row;
            // flex-wrap:wrap;
            // justify-content:space-between;
            margin-bottom:30px;
            
            display: grid;
            justify-content: space-between;
            grid-template-columns: repeat(auto-fill, 270px);
            grid-gap: 10px;
            .hdbd_section{
                // width:540px;
                width:250px;
                margin:10px;
                // height:170px;
                height:auto;
                // margin-top:30px;
                display:flex;
                // flex-direction:row;
                flex-direction:column;
                justify-content:space-between;
                align-items:center;
                .hdbd_section_img_wrap{
                    width:270px;
                    height:170px;
                    border-radius:7px;
                    overflow:hidden;
                    img{
                        width:100%;
                        min-height:100%;
                    }
                }
                .hdbd_section_detail{
                    width:270px;
                    // height:170px;
                    height:auto;
                    display:flex;
                    text-align:left;
                    flex-direction:column;
                    justify-content:space-between;
                    .hdbd_section_detail_title{
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        white-space: normal!important;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        margin:10px 0px;
                        font-family: PingFangSC-Regular;
                        font-size: 22px;
                        color: #221815;
                    }
                    .hdbd_section_date{
                        color:#999999;
                    }
                    
                    h2{
                        font-size:22px;
                        color:#221815;
                        line-height:30px;
                        width:100%;
                    }
                    p{
                        width:100%;
                        // color:#009cbe;
                        color:#999999;
                        font-size:15px;
                    }
                }
            }
        }
    }
    @media(max-width:750px){
        width:100%;
        margin:0 auto;
        .header{
            width:100%;
            overflow:hidden;
            .sw{
                width:100%;
                height:3.4rem;
            }
            .header_detail{
                flex:1;
                padding:0 .2rem;
                display:flex;
                font-size:20px;
                color:#fff;
                flex-direction:column;
                h2{
                    font-size:.32rem;
                    font-weight:bold;
                    line-height:.32rem;
                    color:#fff;
                    display:flex;
                    flex-direction:row;
                    align-items:center;
                    margin:.2rem 0;
                    text-indent:0;
                    display:flex;
                    align-items:center;
                    .i_small2{
                                width:.16rem;
                                height:.16rem;
                                background:#f55c5d;
                    }
                    .i_large2{
                                width:.3rem;
                                height:.3rem;
                                background:#00a1b4;   
                                margin-right:.07rem;             
                    }
                    .mkjkx-icon{
                        width:.38rem;
                        height:.38rem;
                        margin-right:.10rem;
                    }      
                    span{
                        // padding:.1rem .4rem;
                        // background:#00a1b4;
                        // border-radius:5px;
                        // margin:0 .16rem 0 0;
                        // box-sizing:border-box;
                        font-family: PingFangSC-Semibold;
                        color: #222222;
                        letter-spacing: 0;
                        text-align: justify;
                    }
                }
                p{
                    font-size:.28rem;
                    line-height:.45rem;
                    color:#292929;
                    text-align:justify;
                }
            }
        }
        .title_type_2:hover{
            color:orange;
        }
        .title_type_2{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:.2rem 0.2rem;
            text-indent:0;
            justify-content:space-between;
            .i_small2{
                width:.16rem;
                height:.16rem;
                background:#f55c5d;
                    }
            .i_large2{
                width:.3rem;
                height:.3rem;
                background:#00a1b4;   
                margin-right:.07rem;             
            }            
            span{
                // padding:.1rem .4rem;
                // background:#00a1b4;
                // border-radius:5px;
                // margin:0 .16rem 0 0;
                // box-sizing:border-box;
            }
            
            span{
                // font-family: PingFangSC-Semibold;
                // font-size: 38px;
                color: #222222;
                letter-spacing: 0;
                text-align: justify;
            }
            .icon{
                width:.38rem;
                height:.38rem;
                margin-right:.10rem;
            }
            .more{
                width:2rem;
                height:.48rem;
                text-align:right;
                background-image: linear-gradient(90deg, rgba(172,225,250,0.00) 2%, #70c1f8 100%);
                border-radius: 0 100px 100px 0;
                font-size: .20rem;
                color: #FFFFFF;
                letter-spacing: 0;
                display:flex;
                justify-content:flex-end;
                align-items:center;
                img{
                    width:.10rem;
                    height:.15rem;
                    margin-right:.10rem;
                }
                span{
                    font-family: PingFangSC-Medium;
                    font-size: .20rem;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    padding-right: .10rem;
                }
            }
        }
        .dmbcg{
            width:7.1rem;
            display: flex;
            flex-direction:row;
            justify-content:space-between;
            flex-wrap:wrap;
            margin:0 auto;
            .dmSection{
                width:7.1rem;
                height:2.18rem;
                overflow:hidden;
                margin-top:.2rem;
                position: relative;
                border-radius:5px;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                .imgWrap{
                    width:3.45rem;
                    height:2.18rem;
                    border-radius:5px;
                    overflow:hidden;
                    position: relative;
                    img{
                        width:100%;
                        height:100%;
                    }
                    .tag{
                        width:.74rem;
                        height:.33rem;
                        line-height:.33rem;
                        // background:#f9bd48;
                        background:#70c1f8;
                        color:#fff;
                        position:absolute;
                        top:.2rem;
                        right:0;
                        text-align:center;
                        font-size:.25rem;
                        border-radius:.20rem 0 0 .20rem;
                    }
                    .tag2{
                        width:.74rem;
                        height:.33rem;
                        line-height:.33rem;
                        // background:rgba(81,105,150,.7);
                        background:#70c1f8;
                        color:#fff;
                        position:absolute;
                        top:.2rem;
                        right:0;
                        text-align:center;
                        font-size:.25rem;
                        border-radius:.20rem 0 0 .20rem;
                    }
                    .zd{
                        width:.8rem;
                        position: absolute;
                        left:0;
                        top:.1rem;
                        img{
                            width:100%;
                        }
                    }
                }
                .dmtitle{
                    width:3.45rem;
                    background-size:100% 100%;
                    padding:0 .2rem;
                    color:#000;
                    font-size:.3rem;
                    display: flex;
                    align-items: center;
                    text-align: left;
                    flex-direction: column;
                    justify-content: space-between;
                    .hdbd_section_detail_title {
                        font-size: .29rem;
                        color: #000;
                        line-height: .4rem;
                        width: 100%;
                    }
                    .hdbd_section_date {
                        width: 100%;
                        text-align: left;
                        color: #00a1b4;
                        font-size: .28rem;
                    }
                }
            }
        }
        .ssmWrap{
            width:7.1rem;
            margin:0 auto;
            display: flex;
            flex-direction:row;
            justify-content:space-between;
            flex-wrap:wrap;
            .dmSection{
                width:3.45rem;
                height:2.78rem;
                overflow:hidden;
                margin-bottom:.2rem;
                position: relative;
                border-radius:.11rem;
                .imgWrap{
                    width:3.45rem;
                    height:2.78rem;
                    border-radius:5px;
                    overflow:hidden;
                    position: relative;
                    img{
                        width:100%;
                        height:100%;
                    }
                    .zd{
                        width:.8rem;
                        position: absolute;
                        left:0;
                        top:.1rem;
                        img{
                            width:100%;
                        }
                    }
                }
                .dmtitle{
                    width:100%;
                    height:.5rem;
                    background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                    background-size:100% 100%;
                    position:absolute;
                    left:0;
                    bottom:0;
                    padding:0 .2rem;
                    color:#fff;
                    font-size:.3rem;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                }
            }
        }
        .kdkxcdWrap{
            width:7.1rem;
            margin:0 auto;
            display: flex;
            flex-direction:row;
            justify-content:space-between;
            flex-wrap:wrap;
            .dmSection{
                width:3.45rem;
                height:2.78rem;
                overflow:hidden;
                margin-bottom:.2rem;
                position: relative;
                border-radius:.11rem;
                .imgWrap{
                    width:3.45rem;
                    height:2.78rem;
                    border-radius:5px;
                    overflow:hidden;
                    position: relative;
                    img{
                        width:100%;
                        min-height:100%;
                    }
                    .zd{
                        width:.8rem;
                        position: absolute;
                        left:0;
                        top:.1rem;
                        img{
                            width:100%;
                        }
                    }
                }
                .dmtitle{
                    width:100%;
                    height:.5rem;
                    background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                    background-size:100% 100%;
                    position:absolute;
                    left:0;
                    bottom:0;
                    padding:0 .2rem;
                    color:#fff;
                    font-size:.3rem;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                }
            }
        }
        .hdhx{
            width:7.1rem;
            overflow:hidden;
            margin:0 auto;
            .hdhx_wrap{
                width:100%;
                display:flex;
                flex-direction:column;
                .video{
                    width:7.1rem;
                    height:4rem;
                    border-radius:10px;
                    overflow:hidden;
                    background:#009cbe;
                    margin:0 auto;
                    video{
                        width:100%;
                        height:100%;
                    }
                }
                .hdxh_list{
                    flex:1;
                    display:flex;
                    flex-direction:column;
                    .hdhx_link{
                        padding:0 .25rem;
                        font-size:.26rem;           
                        color:#221815;
                        line-height:.4rem;
                        padding:18px 0;
                        border-bottom:1px dashed #747373;
                        cursor: pointer;
                    }
                    .hdhx_link_active{
                        color:#70c1f8;
                        font-weight:bold;
                        position:relative;
                        display: flex;
                        align-items: center;
                        .active-icon{
                            position:absolute;
                            width:.13rem;
                            height:.22rem;
                            right:.10rem;
                            line-height:.4rem;
                        }
                    }
                }
            }
        }
        .mtbd_wrap{
            width:7.1rem;
            overflow:hidden;
            display:flex;
            flex-direction:row;
            flex-wrap:wrap;
            justify-content:space-between;
            margin:0 auto;
            .hdbd_section{
                width:7.1rem;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                align-items:center;
                margin-bottom:.3rem;
                .hdbd_section_img_wrap{
                    width:2.4rem;
                    height:1.55rem;
                    border-radius:7px;
                    overflow:hidden;
                    position:relative;
                    margin-right:.2rem;
                    img{
                        width:100%;
                        min-height:100%
                    }
                    .tag{
                        padding:0 .16rem;
                        height:.3rem;
                        line-height:.3rem;
                        background-color:#70c1f8;
                        color:#fff;
                        position:absolute;
                        top:.13rem;
                        left:0;
                        text-align:center;
                        font-size:.16rem;
                        border-radius:0 .18rem .18rem 0;
                    }
                }
                .hdbd_section_detail{
                    flex:1;
                    height:1.55rem;
                    display:flex;
                    text-align:left;
                    flex-direction:column;
                    justify-content:space-between;
                    .hdbd_section_detail_title{
                        font-size:.28rem;
                        color:#221815;
                        line-height:.35rem;
                        width:100%;
                    }
                    .hdbd_section_date{
                        width:100%;
                        color:#009cbe;
                        font-size:15px;
                    }
                }
            }
        }
        .xcjt_wrap{
            width:7.1rem;
            overflow:hidden;
            display:flex;
            flex-direction:row;
            flex-wrap:wrap;
            justify-content:space-between;
            margin-bottom:.3rem;
            margin:0 auto;
            .hdbd_section{
                width:7.1rem;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                align-items:center;
                .hdbd_section_img_wrap{
                    width:2.4rem;
                    height:1.55rem;
                    border-radius:7px;
                    overflow:hidden;
                    img{
                        width:100%;
                        min-height:100%
                    }
                }
                .hdbd_section_detail{
                    flex:1;
                    height:1.55rem;
                    display:flex;
                    text-align:left;
                    flex-direction:column;
                    justify-content:space-between;
                    .hdbd_section_detail_title{
                        font-size:.28rem;
                        color:#221815;
                        line-height:.35rem;
                        width:100%;
                    }
                    .hdbd_section_date{
                        width:100%;
                        color:#009cbe;
                        font-size:15px;
                    }
                }
            }
        }
    }
`
