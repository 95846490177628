/*
 * @Author: your name
 * @Date: 2020-09-15 09:52:43
 * @LastEditTime: 2020-09-16 19:43:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \kxzxweb\src\pages\education\components\jnh\list.js
 */
import React, { memo, useEffect } from 'react'
import { XkjWrap } from './style'
import { NavLink } from 'react-router-dom'
const Xkj = memo((props) => {
  const { listarr } = { ...props }
  useEffect(() => {
    console.log(listarr)
  })
  return (
    <XkjWrap>
      <div className="h3">
        <div className="title_type_1">
          <i className="i_small"></i>
          <i className="i_large"></i>
          <span>{listarr.name}</span>
          <i className="i_large2"></i>
          <i className="i_small2"></i>
        </div>
      </div>
      <div className="section_nav_2">
        {listarr.list.map((item) => {
          return (
            <NavLink
              to={'/article/' + item.id}
              key={item.id}
              className="section_comm_2"
            >
              <div className="x_img_2">
                <img src={item.path} />
              </div>
              <div className="section_comm_content">
                <p className="tit">{item.post_title}</p>
                <div
                  className="content"
                  //   dangerouslySetInnerHTML={{
                  //     __html: item.post_content,
                  //   }}
                >
                  {item.post_excerpt}
                </div>
              </div>
            </NavLink>
          )
        })}
      </div>
    </XkjWrap>
  )
})

export default Xkj
