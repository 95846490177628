import React, { memo, useEffect, useState } from 'react';
import './style.css'

const Slide = memo((props) => {
  useEffect(() => {
    console.log(props.Carouselarr)
  }, [])
  const [curIndex, setCurIndex] = useState(0)
  const enter = (index) => {
    setCurIndex(index)
  }
  const clickCount = (item) => {
    React.$request({
      url: 'Portal/index/storeArticleClicks',
      method: 'get',
      params: {
        id: item.id
      }
    }).then(res => {
      console.log(res)
    })

  }
  return (
    <div className="slide">
      <a className="slideImg" onClick={() => clickCount(props.Carouselarr[curIndex])} href={props.Carouselarr[curIndex].posts_link} target={props.Carouselarr[curIndex].posts_link.indexOf(location.host) >= 0 ? '' : '_blank'}>
        <img src={props.Carouselarr[curIndex].path} alt="" />
      </a>
      <div className="tabs">
        {
          props.Carouselarr.length && props.Carouselarr.map((item, index) => {
            return (
              <a key={item.id} onClick={() => clickCount(item)} className={curIndex === index ? 'tabactive tab' : 'tab'} href={item.posts_link} target={item.posts_link.indexOf(location.host) >= 0 ? '' : '_blank'} onMouseEnter={() => enter(index)}>
                {item.post_title}
              </a>
            )
          })
        }
      </div>
    </div>
  )
})

export default Slide
