/*
 * @Author: your name
 * @Date: 2020-03-19 10:54:01
 * @LastEditTime: 2020-09-15 09:39:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \kxzxweb\src\pages\education\index.js
 */

import React, { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import EduIndex from './components/index'
import EduSdkpjt from './components/sdkpjt'
import EduKxkfg from './components/kxkfg'
import EduOther from './components/qthd'
import EduOther2 from './components/qthd2'
import EduKxssb from './components/kxssb'
import EduAijkx from './components/aijkx'
import EduZjmxxy from './components/zjmxxy'
import EduHljjkx from './components/hljjkx'
import EduQshd from './components/qshd'
import Kxcbds from './components/kxcbds'
import Sdkxjt from './components/sdkxjt'
import Jnh from './components/jnh'
import Kxsgp from './components/kxsgp'
class Education extends React.Component {
  render() {
    return (
      <Fragment>
        <Switch>
          <Route exact path="/education" component={EduIndex}></Route>
          <Route path="/education/index" component={EduIndex}></Route>
          <Route path="/education/125" component={Kxsgp}></Route>
          <Route path="/education/105" component={EduSdkpjt}></Route>
          <Route path="/education/100" component={EduKxkfg}></Route>
          <Route path="/education/181" component={Kxcbds}></Route>
          <Route path="/education/179" component={EduOther2}></Route>
          <Route path="/education/192" component={Sdkxjt}></Route>
          <Route path="/education/195" component={Jnh}></Route>
          <Route path="/education/301" component={EduKxssb}></Route>
          <Route path="/education/302" component={EduAijkx}></Route>
          <Route path="/education/303" component={EduZjmxxy}></Route>
          <Route path="/education/304" component={EduHljjkx}></Route>
          <Route path="/education/309" component={EduQshd}></Route>
          <Route path="/education/:id" component={EduOther}></Route>
        </Switch>
      </Fragment>
    )
  }
}
export default Education
