import React from 'react'
import styles from './index.module.scss'
import { withRouter } from 'react-router-dom';



class TitleBox extends React.Component {
  turn=(termId)=>{
    this.props.history.push(`/nyrzZqyhList?termId=${termId}`)
  }
  render() {
    const {name,termId} = this.props
    return (
        <div className={['flexBox-cen',styles.titleBox].join(' ')} >
            <div className='hon'></div>
            <div className="lv"></div>
            <span className='title'>{name}</span>
            <div className="lv"></div>
            <div className='hon'></div>
            {
                termId?<p className='gdBtn' onClick={()=>this.turn(termId)}>更多</p>:''
            }
            
        </div>
    )
  }
}
export default withRouter(TitleBox)
