import React from 'react'
import styles from './cglist.module.scss'
import Crumbs from './list-cg/crumbs'
import ListCon from './list-cg/listCon'
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { withRouter } from 'react-router-dom'

class UnionCgList extends React.Component {
  render() {
   
    return (
      <div className={styles.conBj}>
        <Crumbs/> 
        <div className={styles.UnionCgList}>
            <ConfigProvider locale={zhCN}>
              <ListCon/>
            </ConfigProvider>
        </div>
      </div>
    )
  }
}
export default withRouter(UnionCgList)
