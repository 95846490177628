import styled from 'styled-components'

export const KxcbWrap = styled.div`
    @media(min-width:750px){
        width:100%;
        overflow:hidden;
        .title_type_1{
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            .i_small2{
                        width:8px;
                        height:8px;
                        background:#f55c5d;
            }
            .i_large2{
                        width:16px;
                        height:16px;
                        background:#00a1b4;   
                        margin-right:7px;             
            }            
            span{
                        padding:10px 40px;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 16px 0 0;
                        box-sizing:border-box;
            }
        }
        .title_type_2{
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            .i_small2{
                        width:8px;
                        height:8px;
                        background:#f55c5d;
            }
            .i_large2{
                        width:16px;
                        height:16px;
                        background:#00a1b4;   
                        margin-right:7px;             
            }            
            span{
                        padding:10px 40px;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 16px 0 0;
                        box-sizing:border-box;
            }
        }
        .title_type_3{
            height:32px;
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            text-align:center;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:60px auto 30px;
            text-align:center;
            justify-content:center;
            .i_small{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-left:7px;             
            }
            .i_small2{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large2{
                width:16px;
                height:16px
                background:#00a1b4;   
                margin-right:7px;             
            }            
            span{
                padding:10px 40px;
                background:#00a1b4;
                border-radius:5px;
                margin:0 16px;
            }
        }
        .header{
            width:1200px;
            height:380px;
            display:flex;
            margin:10px auto 0;
            flex-direction:row;
            border-radius:10px;
            justify-content:space-between;
            .video{
                width:765px;
                height:380px;
                border-radius:10px;
                overflow:hidden;
                background:#00a1b4;
                video{
                    width:100%;
                    height:100%;
                }
            }
            .header_detail{
                width:370px;
                margin-right:37px;
                p{
                    margin-top:20px;
                    font-size:18px;
                    line-height:26px;
                }
                .more{
                    width: 100%;
                    display: block;
                    text-align: right;
                    font-size: 18px;
                    color: rgb(0, 161, 180);
                    margin-top: -5px;
                }
            }
        }
        .edu_jxhd{
            width:100%;
            overflow:hidden;
            margin-bottom:50px;
            .edu_jhhd_wrap{
                width:1200px;
                overflow:hidden;
                margin:0 auto;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:flex-start;
                .jxhd_section{
                    width:390px;
                    height:240px;
                    margin-top:30px;
                    position:relative;
                    overflow:hidden;
                    background-position:center center;
                    background-size:cover;
                    background-repeat:no-repeat;
                    border-radius:10px;
                    margin:5px 5px;
                    .jxhd_section_p{
                        width:100%;
                        min-height:50px;
                        max-height:100px;
                        background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                        background-size:100% 100%;
                        position:absolute;
                        left:0;
                        bottom:0;
                        display:flex;
                        padding:0 25px 10px;
                        box-sizing:border-box;
                        color:#fff;
                        font-size:18px;
                        align-items:flex-end;
                    }
                }
            }
        }
        .edu_jxhdM{
            display: none;
        }
        .sdkp_part_2{
            width:1200px;
            display:flex;
            margin:50px auto 0;
            flex-direction:row;    
            justify-content:space-between;
            .jdjm{               
                width:520px;
                .jdjm_wrap{
                    width:520px;
                    display:flex;
                    flex-direction:row;
                    height:423px;
                    flex-wrap:wrap;
                    margin-top:30px;  
                    .jdjm_section{
                        width:165px;
                        height:205px;
                        margin-right:12px;
                        border-radius:6px;
                        overflow:hidden;
                        margin-bottom:16px;
                        img{
                            width:100%;
                        }
                    }
                    .jdjm_section:nth-of-type(3n){
                        margin-right:0px;
                    }
                }
            } 
            .ycxx{
                width:515px;
                margin-right:65px;
                .ycxx_wrap{
                    width:515px;
                    height:423px;
                    display:flex;
                    flex-direction:row;
                    justify-content:space-between;              
                    margin-top:30px;      
                    .ycxx_img_wrap{
                        width:283px;
                        height:423px;
                        border-radius:10px;
                        overflow:hidden;
                        background-position:center center;
                        background-repeat:no-repeat;
                        background-size:cover;
                    }
                    .ycxx_detail{
                        width:205px;
                        height:423px;
                        overflow:hidden;
                        .detail_wrap{
                            h2{
                                font-size:20px;
                                color:#585858;
                                font-weight:bold;
                                height:20px;
                                line-height:20px;
                                margin-top:8px;
                                margin-bottom:16px
                            }
                            p{
                                font-size:18px;
                                line-height:18px;
                                height:18px;
                                margin-bottom:13px
                            }
                        }
                        .detail_links{
                            width:100%;
                            height:280px;
                            display:flex;
                            flex-direction:column;
                            justify-content:space-between;
                            margin-top:20px;
                            a{
                                width:203px;
                                border:1px solid #ff7413;
                                padding:20px 0;
                                border-radius:3px;
                                line-height:100%;
                                text-align:center;
                                font-size:17px;
                                color:#ff7413;
                            }
                        }
                    }
                }

            }
        }
        .jcsjWrap{
            width:1200px;
            margin:0 auto 0;
            overflow:hidden;
            .sw{
                width:100%;
                height:670px;
                overflow:hidden;
                border-radius:10px;
                margin-top:30px;
            }
        }
        .dsgg_wrap{
            width:1200px;
            margin:0 auto 0;
            overflow:hidden;
            
            .edu_more{
                width:1200px;
                overflow:hidden;
                margin:0 auto;
                position:relative;
                .edu_more_link{
                    position:absolute;
                    right:0;
                    top:64px;
                    font-size: 18px;
                    color: #00a1b4;
                    z-index:20;
                }
            }
            .dsgg{
                width:100%;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
                .hdbd_section{
                    width: 541px;
                    margin: 10px;
                    height: 270px;
                    margin-top: 30px;
                    display: flex;
                    // flex-direction: row;
                    // justify-content: space-between;
                    // align-items: center;
                    position:relative;
                    .hdbd_section_img_wrap {
                        width: 100%;
                        height: 100%;
                        border-radius: 7px;
                        overflow: hidden;
                        position: relative;
                        cursor:pointer;
                        border:1px solid #DEDEDE;
                        .zd{
                            width:50px;
                            position: absolute;
                            top:10px;
                            left:0;
                            img{
                                width:100%;
                            }
                        }
                        img {
                            width: 100%;
                            min-height: 100%;
                        }
                    }
                    .hdbd_section_detail {
                        position:absolute;
                        bottom:0;
                        width: 100%;
                        height: auto;
                        padding:20px;
                        display: flex;
                        text-align: left;
                        background: linear-gradient(to top, black, transparent);
                        border-radius: 0 0 7px 7px;
                        .hdbd_section_detail_title {
                            cursor:pointer;
                            font-size: 22px;
                            // color: #221815;
                            color: #FFFFFF;
                            line-height: 30px;
                            width: 100%;
                        }
                
                        .hdbd_section_subtitle{
                            font-size: 22px;
                            // color: #666666;
                            color: #FFFFFF;
                            letter-spacing: 0;
                            text-align: justify;
                        }
                        .hdbd_section_date {
                            width: 100%;
                            // color: #009cbe;
                            color: #FFFFFF;
                            font-size: 15px;
                        }
                    }
                }
            }
        }
        a:link {
            color: #000;
            text-decoration: none;
        }
        .sdkp_hdbd{
            width:1200px;
            margin:56px auto;     
            .hdbd_wrap{
                width:1200px;
                overflow:hidden;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
            }   
        }
    }
    @media(max-width:750px){
        .title_type_1{                    
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:0;
            text-indent:0;
            .i_small2{
                width:.16rem;
                height:.16rem;
                background:#f55c5d;
            }
            .i_large2{
                width:.3rem;
                height:.3rem;
                background:#00a1b4;   
                margin-right:.07rem;             
            }            
            span{
                padding:.1rem .4rem;
                background:#00a1b4;
                border-radius:5px;
                margin:0 .16rem 0 0;
                box-sizing:border-box;
            }
        }
        .nav_title:hover{
            color:orange
        }
        .title_type_3{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:.3rem .2rem .3rem;
            text-indent:0;
            .i_small2{
                width:.16rem;
                height:.16rem;
                background:#f55c5d;
            }
            .i_large2{
                width:.3rem;
                height:.3rem;
                background:#00a1b4;   
                margin-right:.07rem;             
            }            
            span{
                padding:.1rem .4rem;
                background:#00a1b4;
                border-radius:5px;
                margin:0 .16rem 0 0;
                box-sizing:border-box;
            }
        }
        .title_type_2{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:0;
            text-indent:0;
            .i_small2{
                width:.16rem;
                height:.16rem;
                background:#f55c5d;
            }
            .i_large2{
                width:.3rem;
                height:.3rem;
                background:#00a1b4;   
                margin-right:.07rem;             
            }            
            span{
                padding:.1rem .4rem;
                background:#00a1b4;
                border-radius:5px;
                margin:0 .16rem 0 0;
                box-sizing:border-box;
            }
        }
        .header{
            width:100%;
            overflow:hidden;
            .video{
                width:100%;
                height:5.3rem;
                overflow:hidden;
                video{
                    width:100%;
                    height:100%;
                }
            }
            .header_detail{
                width:7.1rem;
                margin:0.2rem auto 0;
                p{
                    margin-top:.1rem;
                    font-size:.26rem;
                    line-height:.4rem;
                }
                .more{
                    width: 100%;
                    display: block;
                    text-align: right;
                    font-size: 18px;
                    color: rgb(0, 161, 180);
                    margin-top: -5px;
                }
            }
        }
        .edu_jxhd{
            display: none;
        }
        .edu_jxhdM{
            width:7.1rem;
            margin:0rem auto .3rem;
            overflow:hidden;
            .edu_jhhd_wrap{
                width:7.1rem;
                overflow:hidden;
                margin:0 auto 0;
                display:flex;
                flex-direction:column;
                .jxhd_section{
                    width:100%;
                    height:1.8rem;
                    margin-bottom:.2rem;
                    overflow:hidden;
                    background-position:center center;
                    background-size:cover;
                    background-repeat:no-repeat;
                    border-radius:.1rem;
                    display:flex;
                    flex-direction:row;
                    .jxhd_section_bg{
                        width: 3rem;
                        height: 1.8rem;
                        border-radius: .1rem;
                        overflow: hidden;
                        background-repeat:no-repeat;
                        background-size:cover;
                    }
                    .hdbd_section_detail {
                        flex: 1;
                        margin-left: .1rem;
                        height: 1.8rem;
                        font-size: .29rem;
                        color: #000;
                        line-height: .4rem;
                    }
                }
            }
        }
        .sdkp_part_2{
            width:7.1rem;
            margin:.2rem auto 0;
            .jdjm{               
                width:100%;
                .jdjm_wrap{
                    width:100%;
                    display:flex;
                    flex-direction:row;
                    flex-wrap:wrap;
                    justify-content:space-between;
                    margin-top:.3rem;
                    .jdjm_section{
                        width:3.4rem;
                        height:4.23rem;
                        border-radius:.1rem;
                        margin-bottom:.2rem;  
                        overflow:hidden;
                        img{
                            width:100%;
                        }
                    }
                }
            } 
            .ycxx{
                width:7.1rem;
                .ycxx_wrap{
                    width:100%;
                    display:flex;
                    flex-direction:row;
                    justify-content:space-between;              
                    margin-top:.3rem;      
                    .ycxx_img_wrap{
                        width:3.4rem;
                        height:4.85rem;
                        border-radius:10px;
                        overflow:hidden;
                        background-position:center center;
                        background-repeat:no-repeat;
                        background-size:cover;
                    }
                    .ycxx_detail{
                        flex:1;
                        margin-left:.3rem;
                        .detail_wrap{
                            h2{
                                font-size:.3rem;
                                color:#585858;
                                font-weight:bold;
                                height:.3rem;
                                line-height:.3rem;
                                margin-top:.08rem;
                            }
                            p{
                                font-size:.27rem;
                                line-height:.35rem;
                                height:.35rem;
                                margin-bottom:.13rem;
                            }
                        }
                        .detail_links{
                            width:100%;
                            display:flex;
                            flex-direction:column;
                            justify-content:space-between;
                            margin-top:.2rem;
                            height:3rem;
                            a{
                                width:100%;
                                border:0.01rem solid #ff7413;
                                padding:.2rem .4rem;
                                border-radius:.03rem;
                                line-height:100%;
                                text-align:center;
                                font-size:.24rem;
                                color:#ff7413;
                            }
                        }
                    }
                }

            }
        }
        .jcsjWrap{
            width:7.1rem;
            margin:.56rem auto 0;
            overflow:hidden;
            .sw{
                width:100%;
                height:4rem;
                overflow:hidden;
                border-radius:.1rem;
                margin-top:.3rem;
            }
        }
        
        .dsgg_wrap{
            width:7.1rem;
            margin:.56rem auto 0;
            .edu_more{
                width:100%;
                overflow:hidden;
                margin:0 auto;
                position:relative;
                .edu_more_link{
                    position: absolute;
                    right: 0;
                    top: 0.2rem;
                    font-size: .35rem;
                    color: #00a1b4;
                    z-index: 20;
                }
            }
            .dsgg{
                width:100%;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
                
                img {
                    width: 100%;
                    min-height: 100%;
                }
            }
        }
        .sdkp_hdbd{
            width:7.1rem;
            margin:.56rem auto;       
        }
`
