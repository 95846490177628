import React from 'react'
import styles from './index.module.scss'
import TitleBox from '../titleBox'
import { changeImg } from '../../pub'



class VideoBox extends React.Component {
  state={
    nowVideoUrl:this.props.jmdn.articles[0].smeta.thumb,
    nowPostUrl:this.props.jmdn.articles[0].smeta.photo[0].url,
    nowId:this.props.jmdn.articles[0].id
  }
  checkVideo(val) {
    this.setState({
        nowVideoUrl:val.smeta.thumb,
        nowPostUrl:val.smeta.photo[0].url,
        nowId:val.id
    })
  }
  render() {
    const {jmdn} = this.props
    const {nowVideoUrl,nowPostUrl,nowId} = this.state
    return (
        <>
            <TitleBox name={jmdn.name}/>
            <div className={['flexBox-sb',styles.videoBox].join(' ')} >
                <video className="video" controls src={changeImg(nowVideoUrl)} poster={changeImg(nowPostUrl)}></video>
                <div className="right">
                    {
                        jmdn.articles.map(el=>{
                            return (
                                <p key={el.id} className={nowId==el.id?'active':''} onClick={()=>this.checkVideo(el)} title={el.post_title}>{el.post_title}</p>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
  }
}
export default VideoBox
