import styled from 'styled-components';

export const GuojjlWrap = styled.div `
    @media(min-width:750px){
        width:1200px;
        margin:50px auto;
        .pagenation{
                    margin:60px auto 80px;
                    text-align:center;
                    .ant-pagination-item{
                        
                    }
                    .ant-pagination-item-active {
                        border-radius:0;
                        color:#fff;
                        background: #009cbe;
                        a{
                            color:#fff;     
                        }
                    }
        }
        .hdbd_wrapM{
            display: none;
        }
        .see_more{
            display: none;
        }
        .hdbd_wrap{
                    width:1200px;
                    overflow:hidden;
                    display:flex;
                    flex-direction:row;
                    flex-wrap:wrap;
                    justify-content:space-between;
                    .hdbd_section{
                        width:540px;
                        margin:10px;
                        height:170px;
                        margin-top:30px;
                        display:flex;
                        flex-direction:row;
                        justify-content:space-between;
                        align-items:center;
                        .hdbd_section_img_wrap{
                            width:270px;
                            height:170px;
                            border-radius:7px;
                            overflow:hidden;
                            img{
                                width:100%;
                            }
                        }
                        .hdbd_section_detail{
                            width:252px;
                            height:170px;
                            display:flex;
                            text-align:left;
                            flex-direction:column;
                            justify-content:space-between;
                            .hdbd_section_detail_title{
                                font-size:22px;
                                color:#221815;
                                line-height:30px;
                                width:100%;
                            }
                            .hdbd_section_date{
                                width:100%;
                                color:#009cbe;
                                font-size:15px;
                            }
                        }
                    }
                }  
    }
    @media(max-width:750px){
        width:7.1rem;
        margin:0 auto;
        .pagenation{
            display: none;
        }
        .hdbd_wrap{
            display: none;
        }
        .hdbd_wrapM{
            width:100%;
                overflow:hidden;
                margin:.3rem auto .9rem;
        }  
    } 
`