import React from 'react'
import { ZxfkWrap } from './style'
import { message } from 'antd'
import NavSection from 'common/navSection'
import { jsonp } from 'common/index'

class Zxfk extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      phone: '',
      email: '',
      question: '',
      messageT: '',
      errorName: '',
      errorPhone: '',
      errorEmail: '',
      yzmUrl: `${process.env.REACT_APP_APIBASE}/administrator/api/checkcode/index?length=4&font_size=20&width=238&height=50&use_curve=1&use_noise=1`,
      yzmIpt: '',
      yzmFlag: false,
    }
    this.changeYzm = this.changeYzm.bind(this)
  }
  componentDidMount() {
    document.title = '在线反馈'
    this.goTo()
  }
  changeYzm() {
    this.setState({
      yzmUrl: '',
    })
    setTimeout(() => {
      this.setState({
        yzmUrl: `${process.env.REACT_APP_APIBASE}/administrator/api/checkcode/index?length=4&font_size=20&width=238&height=50&use_curve=1&use_noise=1`,
      })
    }, 10)
  }
  goTo() {
    let scrollToTop = window.setInterval(function () {
      let pos = window.pageYOffset
      if (pos > 0) {
        window.scrollTo(0, pos - 10000) // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop)
      }
    }, 2)
  }
  ipt_name(e) {
    this.setState({
      name: e.target.value,
    })
    if (e.target.value === '') {
      this.setState({
        errorName: '姓名不能为空',
      })
    } else {
      this.setState({
        errorName: '',
      })
    }
  }
  ipt_phone(e) {
    this.setState({
      phone: e.target.value,
    })
    if (this.checkPhone(e.target.value)) {
      this.setState({
        errorPhone: '',
      })
    } else {
      this.setState({
        errorPhone: '手机号码格式错误',
      })
    }
  }
  yzmSubmit() {
    if (this.state.yzmIpt === '') {
      message.info('验证码不能为空')
    } else {
      jsonp('/administrator/Portal/index/Verification', {
        verify: this.state.yzmIpt,
        name: this.state.name,
        phone: this.state.phone,
        email: this.state.email,
        message: this.state.messageT,
      }).then((res) => {
        if (res.message === 'ok') {
          this.setState({
            yzmIpt: '',
            name: '',
            phone: '',
            email: '',
            messageT: '',
          })
          this.changeYzm()
          message.info('提交成功')
        } else if (res.message === 'error') {
          message.info('验证码错误')
        } else {
          message.error('提交失败')
        }
      })
    }
  }
  fkSubmit() {
    React.$request({
      url:'Portal/Index/feedback',
      method:'get',
      params: {
        name: this.state.name,
        phone: this.state.phone,
        email: this.state.email,
        message: this.state.messageT,
      }
    }).then(res=>{
      if (res.message === 'ok') {
        this.setState({
          yzmIpt: '',
          name: '',
          phone: '',
          email: '',
          messageT: '',
        })
        changeYzm()
        message.info('提交成功')
        this.goTo()
      } else {
        message.error('提交失败')
      }
    })
  }
  ipt_email(e) {
    this.setState({
      email: e.target.value,
    })
    if (this.checkEmail(e.target.value)) {
      this.setState({
        errorEmail: '',
      })
    } else {
      this.setState({
        errorEmail: '邮箱格式错误',
      })
    }
  }
  checkPhone(val) {
    if (!/^1[3456789]\d{9}$/.test(val)) {
      return false
    } else {
      return true
    }
  }
  checkEmail(val) {
    if (
      !/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(
        val
      )
    ) {
      return false
    } else {
      return true
    }
  }
  ipt_radio(e) {
    this.setState({
      question: e.target.value,
    })
  }
  ipt_textarea(e) {
    this.setState({
      messageT: e.target.value,
    })
  }
  yzmIpt_change(e) {
    this.setState({
      yzmIpt: e.target.value,
    })
  }
  submitForm() {
    if (this.state.name === '') {
      this.setState({
        errorName: '姓名不能为空',
      })
    } else {
      if (this.state.phone === '') {
        this.setState({
          errorPhone: '手机号码不能为空',
        })
      } else if (!this.checkPhone(this.state.phone)) {
        this.setState({
          errorPhone: '手机号码格式错误',
        })
      } else {
        this.setState({
          errorPhone: '',
        })
        if (this.state.email === '') {
          this.setState({
            errorEmail: '邮箱不能为空',
          })
        } else if (!this.checkEmail(this.state.email)) {
          this.setState({
            errorEmail: '邮箱格式错误',
          })
        } else {
          this.setState({
            errorEmail: '',
          })
          if (this.state.message === '') {
            message.info('请输入反馈详情')
          } else {
            this.yzmSubmit()
          }
        }
      }
    }
  }
  render() {
    return (
      <ZxfkWrap>
        <NavSection />
        <div className="banner">
          <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/index_01.png`} alt="" />
        </div>
        <div className="form">
          <h2>在线反馈</h2>
          <div className="form_section">
            <span>·姓名*</span>
            <input
              type="text"
              value={this.state.name}
              onChange={this.ipt_name.bind(this)}
            />
          </div>
          <div className="error">{this.state.errorName}</div>
          <div className="form_section">
            <span>·手机*</span>
            <input
              type="phone"
              value={this.state.phone}
              onChange={this.ipt_phone.bind(this)}
            />
          </div>
          <div className="error">{this.state.errorPhone}</div>
          <div className="form_section">
            <span>·E-mail*</span>
            <input
              type="text"
              value={this.state.email}
              onChange={this.ipt_email.bind(this)}
            />
          </div>
          <div className="error">{this.state.errorEmail}</div>
          {/* <h3>您反应的问题为：</h3> */}
          {/* <div className="form_section2">
                        <label htmlFor="ra1">
                            <input type="radio" name='radio' id='ra1' value='1' onChange={this.ipt_radio.bind(this)}/>
                            <span>票务问题</span>
                        </label>
                        <label htmlFor="ra2">
                            <input type="radio" name='radio' id='ra2' value='2' onChange={this.ipt_radio.bind(this)}/>
                            <span>购票问题</span>
                        </label>
                        <label htmlFor="ra3">
                            <input type="radio" name='radio' id='ra3' value='3' onChange={this.ipt_radio.bind(this)}/>
                            <span>其他</span>
                        </label>
                    </div>   */}
          <h3>反馈详情：</h3>
          <textarea
            onChange={this.ipt_textarea.bind(this)}
            value={this.state.messageT}
          ></textarea>
          <div className="yzm_section">
            <span>验证码</span>
            <input
              type="text"
              value={this.state.yzmIpt}
              onChange={this.yzmIpt_change.bind(this)}
            />
            <img
              className="verify_img"
              src={this.state.yzmUrl}
              onClick={() => this.changeYzm()}
              alt=""
            />
          </div>
          <div className="form_submit" onClick={() => this.yzmSubmit()}>
            提交
          </div>
        </div>
      </ZxfkWrap>
    )
  }
}
export default Zxfk
