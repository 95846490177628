import React from 'react'
import styles from './index.module.scss'
import { withRouter } from 'react-router-dom';


class Crumbs extends React.Component {
  state={
    navList:null
  }

  componentDidMount() {
    this.getCrumbs()
  }

  turn=(val)=>{
    if(val=='/nyrzZqyhList'){
        const {termId}=this.props
        this.props.history.push(val+'?termId='+termId)
    }else{
        this.props.history.push(val)
    }
  }

  getCrumbs=()=>{
    const {termId}=this.props
    React.$request({
      url: '/portal/index/navLists',
      method: 'get',
      params: {
        termId: termId
      }
    }).then(res => {
      this.setState({
        navList:res.nav
      })
    })
  }

  render() {
    const {navList} = this.state
    const {articleId}=this.props
    return (
        navList!=null?
        <div className={styles.crumbsBox}>
            <p onClick={()=>this.turn('/')}>首页 &gt;</p>
            {
                navList.map(el=>{
                    return <p onClick={()=>this.turn(el.css_path)}>{el.name} <span>&gt;</span></p>
                })
            }
            {
                articleId?<p>正文</p>:''
            }
        </div>:''
    )
  }
}
export default withRouter(Crumbs)
