    import React from 'react'
    import Swiper from 'swiper/js/swiper.js'
    import 'swiper/css/swiper.min.css'
    import {ExIndexQwwx} from './style'
    import {NavLink} from 'react-router-dom'

    const swSection = (arr) =>{
        if(arr.length){
            return(    
                <ExIndexQwwx>
                    <div className="swiper-container swiper-container-qwwx">
                        <div className="swiper-wrapper">                       
                            {                     
                                arr.map((item,index)=>{
                                    let _style = {
                                        backgroundImage:'url('+item.path+')'
                                    }
                                    return(
                                        <div className="swiper-slide swiper-slide-qwwx" key={item.id} style={_style}>  
                                            <NavLink key={item.id} className='img_wrap' to={'/article/' + item.id}>
                                                <a className='navlink'>
                                                    <div className="swMask"></div>    
                                                    <div className="title" style={{'background': `url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat`,'backgroundSize':'100% 100%'}}>
                                                        <span className='qwwxtitle'>{item.post_title}</span> 
                                                    </div>  
                                                </a>
                                            </NavLink>
                                        </div>
                                    )
                                })                   
                            }             
                        </div>
                    </div> 
                </ExIndexQwwx>            
            )
        }
    }
    class ExhQwwx extends React.Component{
        constructor(props){
            super(props)
            this.state={
                swArr:[]
            }
        }
        componentDidMount() {
            new Swiper('.swiper-container-qwwx', {
                slidesPerView:5,
                centeredSlides: true,
                loop: true,
                grabCursor: true,
                slideToClickedSlide: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }, 
            }) 
        }
        render() {
            return (
                <div className={this.props.className}>
                    {
                        swSection(this.props.Carouselarr)
                    }
                </div>

            )
        }
    }

    export default ExhQwwx