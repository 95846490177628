import React from 'react';
import {ThisMonthWrap} from './style'

class NextMonth extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            tagIndex:0,
            monthList:null,
            monthMenuArr:null,
            currentTermid:null
        }
    }
    componentDidMount(){
        this.initThisMonethMenu()
    }
    initThisMonthList(id,index){
        React.$request({
            url:'Portal/index/is_ajax_list',
            method:'get',
            params:{
                termid:id
            }
          }).then(res=>{
            this.setState({
                monthList:res.data,
                tagIndex:index
            })
          })
             
        this.setState({
            currentTermid:id
        })   
    }
    initThisMonethMenu(){
        React.$request({
            url:'Portal/index/is_ajax_classify',
            method:'get',
            params:{
                term_id:92
            }
          }).then(res=>{
            this.setState({
                monthMenuArr:res.data,
                currentTermid:res.data[0].term_id
            })
            this.initThisMonthList(parseInt(this.state.monthMenuArr[0].term_id),0)
          })    
        
    }
    render(){
        return(
            this.state.monthList?
            <ThisMonthWrap>
                <div className="tabs">
                    {
                        this.state.monthMenuArr.map((item,index)=>{
                            return(
                                <div className={this.state.currentTermid == item.term_id?"tabActive tab":"tab"}  onClick={()=>this.initThisMonthList(item.term_id,index)} key={item.term_id}>{item.name}</div>
                            )
                        })
                    } 
                </div>
                <div className="cinemaList">
                    {
                        this.state.monthList.map((item)=>{
                            let _style= {
                                backgroundImage:"url("+item.path+")"
                            }
                            return(
                                <div className="cinemaSeciton" key={item.id}>
                                    <div className="imgWrap" style={_style}>
                                        <p>{['球幕影片','巨幕影片','4D影片'][this.state.tagIndex]}</p>
                                    </div>
                                    <div className="textArea">
                                        <p className='text_title'>{item.post_title}</p>
                                        <p className='text_date'>时长:{item.posts_duration}</p>
                                        <p className='text_actor'>导演:{item.posts_director}</p>
                                        <p className='text_detail'>{item.post_excerpt}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </ThisMonthWrap>
            :null
        )
    }
}
export default NextMonth