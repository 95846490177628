import React from 'react';
import {connect} from 'react-redux';
import {withRouter,NavLink} from 'react-router-dom';
import { actions } from './../../store'
import {Zzt} from './style'
import CommonCarousel from 'common/carousel.js'
import {RightImgS,LeftImgS} from './../exhcommon'
import {Skeleton} from 'antd'
import NavSection from 'common/navSection'

class ExZzt extends React.Component{
    constructor(props){
        super(props)
        this.tag_1 = React.createRef();
        this.tag_2 = React.createRef();
        this.tag_3 = React.createRef();
        this.tag_4 = React.createRef();
    }
    componentDidMount(){
        if(!this.props.exhZztArr){
            this.props.getExZzt()
        }
        if(this.props.match.params.id){
            const _this = this
            setTimeout(()=>{
                const _id = _this.props.match.params.id
                var _top = 0;
                switch(_id){
                    case 'tag_1':
                        _top = _this.tag_1.current?.offsetTop
                        break;
                    case 'tag_2':
                        _top = _this.tag_2.current?.offsetTop
                        break;
                    case 'tag_3':
                        _top = _this.tag_3.current?.offsetTop
                        break;
                    case 'tag_4':
                        _top = _this.tag_4.current?.offsetTop
                        break;
                }
                window.scrollTo(0 ,_top);
            },300)
        }else{
            this.goTo()
        }
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    handleScroll(){
        console.log(window.scrollY)
    }
    render(){
        return(
            this.props.exhZztArr?
            <Zzt>
                <NavSection/>
                <div className="zzt_header" >
                    <CommonCarousel className='sw' Carouselarr={this.props.exhZztArr.lp}/>
                    <div className="zzt_header_detail">
                        <h2 className='zzt_title'>{this.props.exhZztArr.zzt[0].post_title}</h2>
                        <div className='zzt_content' >
                            <div dangerouslySetInnerHTML={{__html:this.props.exhZztArr.zzt[0].post_content}}></div>
                            <div className="openTime">
                                <span className='title'>开放时间：</span>
                                <span className='time'>{this.props.exhZztArr.zzt[0].posts_duration}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='zzt_tag_1' ref={this.tag_1}>
                    {
                        LeftImgS(this.props.exhZztArr.smzt)
                    }
                </div>
                <div className='zzt_tag_2' ref={this.tag_2}>
                    {
                        window.innerWidth >750?
                            RightImgS(this.props.exhZztArr.shzt):
                        LeftImgS(this.props.exhZztArr.shzt)
                    }
                </div>
                <div className='zzt_tag_3' ref={this.tag_3}>
                {
                    LeftImgS(this.props.exhZztArr.sczt)
                }
                </div>
                <div className='zzt_tag_4' ref={this.tag_4}>
                    {
                        window.innerWidth >750?
                            RightImgS(this.props.exhZztArr.xqdsj):
                        LeftImgS(this.props.exhZztArr.xqdsj)
                    }
                </div>
            </Zzt>
            :<Skeleton active />
        )
    }
}
const mapStateToProps = (state)=>{
    return{
        exhZztArr:state.getIn(['exhibition','zzq'])
    }
}
const mapDispatchToProps = (dispatch)=>{
    return{
        getExZzt(){
            dispatch(actions.getExhZzt())
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExZzt))
