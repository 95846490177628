import React from 'react';
import zhCN from 'antd/es/locale-provider/zh_CN';
import {Empty,Pagination,Skeleton,LocaleProvider} from 'antd';
import {Search_wrap} from './style'
import {NavLink} from 'react-router-dom'


class SearchWrap extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            searchList:null,
            page:1,
            allCount:0,
            Mlist:null,
            pList:null
        }
        this.onChange = this.onChange.bind(this)
    }
    componentDidMount(){
        document.title = '搜索'
        this.searchList(this.props.match.params.id)
        window.addEventListener('hashchange', this.handleHashChange)
    }
    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleHashChange);
    }
    
    handleHashChange = () => {
        this.searchList(this.props.match.params.id);
    }
    onChange(pageNumber) {
        console.log('onChange')
        this.setState({
            pList:[],
            page:pageNumber
        })
        React.$request({
            url:'Portal/Index/search',
            method:'get',
            params: {
                keyword:this.props.match.params.id,
                size:10,
                p:pageNumber
            }
        }).then(res=>{
            this.setState({
                pList:res.data
            })
        })
    }
    searchList(keywords){
        console.log('searchList')
        React.$request({
            url:'Portal/Index/search',
            method:'get',
            params: {
                keyword:keywords
            }
        }).then(res=>{
            this.setState({
                searchList:res,
                allCount:res.data?res.conut:0,
                pList:res.data?res.data:null
            })
        })
    }
    render(){
        return(
            this.state.searchList?
            <Search_wrap>
                
                <div className='wxzx_list'>
                    <h2>共搜到关于“<span>{this.props.match.params.id}</span>”的<span>{this.state.searchList.conut?this.state.searchList.conut:0}</span>条结果</h2>
                    {
                        this.state.searchList.message == '无结果'?<Empty className='empty'/>:
                        this.state.pList.map((item)=>{
                            return(
                                item.id?
                                <div key={item.id} className='xwzxSection'>
                                    <div className="sec_detail">
                                        <div className="sec_detail_top">
                                            <i></i>                                        
                                            <NavLink to={'/article/'+item.id} className='sec_detail_title'>
                                                {item.post_title}
                                            </NavLink>
                                        </div>
                                        <div className='sec_detail_time'>{item.post_date}</div>
                                    </div>
                                </div>:
                                <div key={item.term_id} className='xwzxSection'>
                                    <div className="sec_detail">
                                        <div className="sec_detail_top">
                                            <i></i>                                        
                                            <NavLink to={'/'+item.description} className='sec_detail_title'>
                                                {item.post_title}
                                            </NavLink>
                                        </div>
                                        <div className='sec_detail_time'>栏目</div>
                                    </div>
                                </div>
                            )                         
                        })
                    }
                    {
                        this.state.searchList.data?                
                        <LocaleProvider locale={zhCN}>
                            <Pagination  size="small" defaultCurrent={this.state.page} total={parseInt(this.state.allCount)} onChange={this.onChange} showQuickJumper className='pagenation'/> 
                        </LocaleProvider>:null
                    }   
                </div>
            </Search_wrap>:<Skeleton active/>
        )
    }
}
export default SearchWrap