import styled from 'styled-components';

export const TalentPlanWrap = styled.div `
@media(min-width:750px) {
    width: 1200px;
    overflow: hidden;
    margin: 0 auto;

    .header {
        width: 100%;
        margin-top:50px;
        padding: 0 20px;
        .condition-item{
            display:flex;
            //align-items: center;
            //margin-bottom: 20px;
            font-size:18px;
            .condition-item-label{
                font-size:18px;
                font-weight:bold;
            }
            
            .condition-item-values{
                display:flex;
                flex-wrap: wrap;
                flex:1;
                .condition-item-value{
                    margin-right:20px;
                    box-sizing:border-box;
                    height:30px;
                    line-height:30px;
                    text-align:center;
                    padding:0 20px;
                    border:1px solid #0091b6;
                    color:#0091b6
                    border-radius:4px;
                    cursor:pointer;
                    margin-bottom: 20px;
                }
                
                .condition-item-value.on{
                    background:#0091b6;
                    color:#FFFFFF;
                }
            }
        }
    }
    .paginationWrapM{
        display:none;
    }
    
    .paginationWrap{
        width:1200px;
        overflow:hidden;
        //margin:60px auto;
        .empty{
            padding:100px 0;
            margin: 0 auto;
        }
        .other_section_wrap{
            width:1200px;
            overflow:hidden;
            display:flex;
            flex-direction:row;
            flex-wrap:wrap;
            justify-content:space-between;
            .ycjh_section{
                width: 541px;
                margin: 10px;
                height: 170px;
                margin-top: 30px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            
                .ycjh_section_img_wrap {
                    width: 270px;
                    height: 170px;
                    border-radius: 7px;
                    overflow: hidden;
                    position: relative;
                    cursor:pointer;
                    .zd{
                        width:50px;
                        position: absolute;
                        top:10px;
                        left:0;
                        img{
                            width:100%;
                        }
                    }
                    img {
                        width: 100%;
                        min-height: 100%;
                    }
                }
                .ycjh_section_detail {
                    width: 252px;
                    height: 170px;
                    display: flex;
                    text-align: left;
                    flex-direction: column;
                    justify-content: space-between;
            
                    .ycjh_section_detail_title {
                        cursor:pointer;
                        font-size: 22px;
                        color: #221815;
                        line-height: 30px;
                        width: 100%;
                    }
            
                    .ycjh_section_subtitle{
                        font-size: 22px;
                        color: #666666;
                        letter-spacing: 0;
                        text-align: justify;
                    }
                    .ycjh_section_date {
                        width: 100%;
                        color: #009cbe;
                        font-size: 15px;
                    }
                }
            }
        }
        
        .pagenation{
            width:100%;
            margin:60px auto;
            text-align:center;
            .ant-pagination-item{
                
            }
            .ant-pagination-item-active {
                border-radius:0;
                color:#fff;
                background: #009cbe;
                a{
                    color:#fff;     
                }
            }
        }
    }
    
    .ant-modal{
        .ant-modal-body{
            text-align:center;
            .video{
                width:750px;
                height:450px;
                border-radius:10px;
                overflow:hidden;
                // background:#009cbe;
                background:#000000;
                video{
                    width:100%;
                    height:100%;
                }
            }
        }
    }
}

@media(max-width:750px) {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;

    .header {
        width: 100%;
        overflow: hidden;
        width: 100%;
        margin-top:.5rem;
        padding: 0 .2rem;
        .condition-item{
            display:flex;
            // align-items: center;
            margin-bottom: .2rem;
            font-size:.18rem;
            .condition-item-label{
                font-weight:bold;
            }
            
            .condition-item-values{
                display:flex;
                flex-wrap: wrap;
                flex:1;
                .condition-item-value{
                    margin-right:.2rem;
                    box-sizing:border-box;
                    height:.5rem;
                    line-height:.5rem;
                    text-align:center;
                    padding:0 .3rem;
                    border:1px solid #0091b6;
                    color:#0091b6
                    border-radius:4px;
                    cursor:pointer;
                    margin-bottom:.2rem;
                }
                
                .condition-item-value.on{
                    background:#0091b6;
                    color:#FFFFFF;
                }
            }
        }
    }
    
    
    .paginationWrap{
        display: none;
    }
    .paginationWrapM{
        width:7.1rem;
        overflow:hidden;
        margin:.1rem auto;
        .paginationWrapM_list{
            width:100%;
            overflow:hidden;
            margin:.3rem auto;
        }
        
        .empty{
            padding:1rem 0;
        }
        
        .ycjh_section{
            width: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: .27rem 0;
            border-bottom: 0.01rem dashed #a0a0a0;
        
            .ycjh_section_img_wrap {
                width: 2.45rem;
                height: 1.5rem;
                border-radius: .1rem;
                overflow: hidden;
                position: relative;
                .zd{
                    width:.8rem;
                    position: absolute;
                    top:.1rem;
                    left:0;
                    img{
                        width:100%;
                    }
                }
                img {
                    width: 100%;
                    min-height: 100%;
                }
            }
        
            .ycjh_section_detail {
                flex: 1;
                margin-left: .1rem;
                display: flex;
                align-items: center;
                text-align: left;
                flex-direction: column;
                height: 1.5rem;
                justify-content: space-between;
        
                .ycjh_section_detail_title {
                    font-size: .29rem;
                    color: #000;
                    line-height: .4rem;
                    width: 100%;
                }
        
                .ycjh_section_date {
                    width: 100%;
                    text-align: left;
                    color: #00a1b4;
                    font-size: .28rem;
                }
            }
        }
        
        .see_more{
            width:2.5rem;
            height:.64rem;
            background:#009cbd;
            border-radius:.04rem;
            font-size:.3rem;
            color:#fff;
            line-height:.64rem;
            text-align:center;
            margin:.5rem auto 0;
        }
    }
    
    .ant-modal{
        .ant-modal-body{
            text-align:center;
            .video{
                width:6.0rem;
                height:4rem;
                border-radius:10px;
                overflow:hidden;
                // background:#009cbe;
                background:#000000;
                margin:0 auto;
                video{
                    width:100%;
                    height:100%;
                }
            }
        }
    }
}

`

export const EduWrap = styled.div `
    @media(min-width:750px){
        width:100%;
        overflow:hidden;
        .title_type_1{
            height:32px;
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            text-align:center;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:60px auto 30px;
            text-align:center;
            justify-content:center;
            .i_small{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-left:7px;             
            }
            .i_small2{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large2{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-right:7px;             
            }            
            span{
                padding:10px 40px;
                background:#00a1b4;
                border-radius:5px;
                margin:0 16px;
            }
        }
        .nav_title:hover{
            color:orange
        }
        .eduHeader{
            width:1200px;;
            height:380px;
            display:flex;
            flex-direction:row;
            margin:10px auto;
            .sw{
                width:760px;
                height:380px;
                border-radius:10px;
                overflow:hidden;
            }
            .edu_hdyg{
                padding-left:24px;
                flex:1;
                .edu_hdyg_title{
                    font-size:32px;
                    font-weight:bold;
                    line-height:32px;
                    color:#fff;
                    display:flex;
                    flex-direction:row;
                    align-items:center;
                    .i_small{
                        width:8px;
                        height:8px;
                        background:#f55c5d;
                    }
                    .i_large{
                        width:16px;
                        height:16px;
                        background:#00a1b4;   
                        margin-left:7px;             
                    }
                    .i_small2{
                        width:8px;
                        height:8px;
                        background:#f55c5d;
                    }
                    .i_large2{
                        width:16px;
                        height:16px;
                        background:#00a1b4;   
                        margin-right:7px;             
                    }            
                    span{
                        padding:10px 25px;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 16px 0 0;
                        box-sizing:border-box;
                    }
                }
                .edu_hdyg_title:hover{
                    color:orange
                }
                .edu_hdyg_list{
                    width:100%;
                    height:256px;
                    display:flex;
                    flex-direction:column;
                    margin-bottom:20px;
                    .more{
                        width:100%;
                        text-align:right;
                        /* margin-left:300px; */
                        font-size: 18px;
                        color:#00a1b4;
                        margin-top:-5px;
                    }
                    .hdyg_section{
                        width:437px;
                        height:46px;
                        .time{
                            /* width:60px;
                            height:60px;
                            background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/eduIcons_06.png) 0 0 no-repeat;
                            background-size:100% 100%;
                            font-size:18px;
                            font-weight:bold;
                            color:#585858;
                            text-align:center;
                            line-height:70px; */
                        }
                        .content{
                            font-size:18px;
                            line-height:46px;
                            color:#515151;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .content::before{
                            content:'';
                            width:8px;
                            height:8px;
                            background:#00a1b4;
                            border-radius:4px;
                            display: block;
                            margin:19px 10px 0 0;
                            float:left;
                        }
                    }
                }
                .sign_link{
                    width:365px;
                    height:50px;
                    background:#f55668;
                    color:#fff;
                    font-size:26px;
                    border-radius:5px;
                    margin:20px auto;
                    display:flex;
                    flex-direction:row;
                    align-items:center;
                    justify-content:center;
                    i{
                        display:flex;
                        align-items:center;
                        margin-right:20px;
                    }
                }
            }
        }
        .hot_avtivity{
            width:1200px;
            margin:0 auto;
            overflow:hidden;
            .hot_a_wrap{
                width:100%;
                height:270px;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                margin-top:30px;
                .hot_a_section{
                    width:390px;
                    height:270px;
                    .hot_a_section_img_wrap{
                        width:390px;
                        height:235px;
                        border-radius:10px;
                        overflow:hidden;
                        display:block;
                        img{
                            width:100%;
                        }
                    }
                    .hot_a_section_bot{
                        width:100%;
                        height:35px;
                        display:flex;
                        flex-direction:row;
                        justify-content:center;
                        align-items:flex-end;
                        span{
                            color:#0098bb;
                            font-weight:bold;
                            font-size:20px;
                        }
                        a{
                            font-size:14px;
                            background:#0098bb;
                            padding:2px 16px;
                            border-radius:8px;
                            color:#fff;
                            margin-left:20px;
                        }
                    }
                }
            }
        }
        .edu_jxhd{
            width:100%;
            overflow:hidden;
            position:relative;
            padding-bottom: 30px;
            .edu_more{
                width:1200px;
                overflow:hidden;
                margin:0 auto;
                position:relative;
                .edu_more_link{
                    position:absolute;
                    right:0;
                    top:64px;
                    font-size: 18px;
                    color: #00a1b4;
                    z-index:20;
                }
            }
            .edu_jhhd_wrap{
                width:1200px;
                overflow:hidden;
                margin:0 auto;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
                .jxhd_section{
                    width:585px;
                    height:340px;
                    margin-top:30px;
                    position:relative;
                    overflow:hidden;
                    background-position:center center;
                    background-size:cover;
                    background-repeat:no-repeat;
                    border-radius:10px;
                    .jxhd_section_p{
                        width:100%;
                        height:170px;
                        background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                        background-size:100% 100%;
                        position:absolute;
                        left:0;
                        bottom:0;
                        display:flex;
                        padding:10px 35px;
                        box-sizing:border-box;
                        color:#fff;
                        font-size:18px;
                        align-items:flex-end;
                    }
                }
            }
        }
        .edu_jckc{
            width:100%;
            overflow:hidden;
            position:relative;
            .edu_more{
                width:1200px;
                overflow:hidden;
                margin:0 auto;
                position:relative;
                .edu_more_link{
                    position:absolute;
                    right:0;
                    top:64px;
                    font-size: 18px;
                    color: #00a1b4;
                    z-index:20;
                }
            }
            .edu_jhhd_wrap{
                width:1200px;
                overflow:hidden;
                margin:0 auto;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
                .jckc_section{
                    width:585px;
                    height:340px;
                    margin-top:30px;
                    position:relative;
                    overflow:hidden;
                    background-position:center center;
                    background-size:cover;
                    background-repeat:no-repeat;
                    border-radius:10px;
                    .jckc_section_p{
                        width:100%;
                        height:170px;
                        background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                        background-size:100% 100%;
                        position:absolute;
                        left:0;
                        bottom:0;
                        display:flex;
                        padding:10px 35px;
                        box-sizing:border-box;
                        color:#fff;
                        font-size:18px;
                        align-items:flex-end;
                    }
                }
                .jckc_section_full{
                    width:100%;
                }
            }
        }
        .edu_mkjkx{
            width:1200px;
            margin:0 auto;
            overflow:hidden;
            .edu_mk_wrap{
                display:flex;
                flex-direction:row;
                width:1200px;
                height:450px;
                justify-content:space-between;
                margin-top:30px;
                .edu_video{
                    width:750px;
                    height:450px;
                    overflow:hidden;
                    border-radius:10px;
                    video{
                        width:100%;
                        height:100%;
                    }
                }
                .edu_mk_list{
                    width:430px;
                    display:flex;
                    flex-direction:column;
                    justify-content:space-between;
                    .edu_mk_img_section{
                        width:100%;
                        height:215px;
                        position:relative;
                        overflow:hidden;
                        background-position:center center;
                        background-size:cover;
                        background-repeat:no-repeat;
                        border-radius:10px;
                        .edu_mk_img_section_mask{
                            position:absolute;
                            left:0;
                            bottom:0;
                            background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                            background-size:100% 100%;
                            display:flex;
                            padding:10px 35px;
                            box-sizing:border-box;
                            color:#fff;
                            font-size:16px;
                            align-items:flex-end;
                        }
                    }
                }
            }

        }
        .edu_hdbd{
            width:1200px;
            overflow:hidden;
            margin:0 auto 95px;
            .edu_hdbd_wrap{
                width:1200px;
                overflow:hidden;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
            }
        }
    }
    @media(max-width:750px){
        width:100%;
        overflow:hidden;
        .title_type_1{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:.2rem 0;
            text-indent:0;
            .i_small2{
                        width:.16rem;
                        height:.16rem;
                        background:#f55c5d;
            }
            .i_large2{
                        width:.3rem;
                        height:.3rem;
                        background:#00a1b4;   
                        margin-right:.07rem;             
            }            
            span{
                        padding:.1rem .4rem;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 .16rem 0 0;
                        box-sizing:border-box;
            }
        }
        .eduHeader{
            width:100%;
            overflow:hidden;
            .sw{
                width:100%;
                height:4rem;
                overflow:hidden;
            }
            .edu_hdyg{
                width:7.1rem;
                margin:.52rem auto 0;
                .edu_hdyg_title{
                    font-size:.32rem;
                    font-weight:bold;
                    line-height:.32rem;
                    color:#fff;
                    display:flex;
                    flex-direction:row;
                    align-items:center;
                    margin:0;
                    text-indent:0;
                    .i_small2{
                                width:.16rem;
                                height:.16rem;
                                background:#f55c5d;
                    }
                    .i_large2{
                                width:.3rem;
                                height:.3rem;
                                background:#00a1b4;   
                                margin-right:.07rem;             
                    }            
                    span{
                                padding:.1rem .4rem;
                                background:#00a1b4;
                                border-radius:5px;
                                margin:0 .16rem 0 0;
                                box-sizing:border-box;
                    }
                }
                .edu_hdyg_list{
                    width:100%;
                    overflow:hidden;
                    display:flex;
                    flex-direction:column;
                    .more{
                        color:#00a1b4;
                        width:100%;
                        font-size:.35rem;
                        text-align:right;
                        line-height:.7rem;
                    }
                    .hdyg_section{
                        width:100%;
                        display:flex;
                        flex-direction:row;
                        align-items:center;
                        border-bottom:1px dashed #dddddd;
                        padding:.2rem 0;
                        .time{
                            width:60px;
                            height:60px;
                            background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/eduIcons_06.png) 0 0 no-repeat;
                            background-size:100% 100%;
                            font-size:18px;
                            font-weight:bold;
                            color:#585858;
                            text-align:center;
                            line-height:70px;
                        }
                        .content{
                            flex:1;
                            padding:0 9px 0 20px;
                            box-sizing:border-box;
                            font-size:18px;
                            line-height:30px;
                            color:#515151
                        }
                    }

                }
                .sign_link{
                    width:7rem;
                    height:1rem;
                    background:#f55668;
                    color:#fff;
                    font-size:26px;
                    border-radius:5px;
                    margin:10px auto 20px;
                    display:flex;
                    flex-direction:row;
                    align-items:center;
                    justify-content:center;
                    i{
                        display:flex;
                        align-items:center;
                        margin-right:20px;
                    }
                }
            }
        }
        .hot_avtivity{
            width:7.1rem;
            margin:0 auto;
            overflow:hidden;
            .hot_a_wrap{
                width:100%;
                overflow:hidden;
                .hot_a_section{
                    width:100%;
                    height:2.35rem;
                    display:flex;
                    flex-direction:row;
                    justify-content:space-between;
                    margin-top:.3rem;
                    .hot_a_section_img_wrap{
                        width:4.65rem;
                        height:2.35rem;
                        border-radius:.1rem;
                        overflow:hidden;
                        display:block;
                        img{
                            width:100%;
                        }
                    }
                    .hot_a_section_bot{
                        flex:1;
                        display:flex;
                        flex-direction:column;
                        justify-content:flex-end;
                        align-items:flex-start;
                        margin-left:.2rem;
                        span{
                            color:#0098bb;
                            font-weight:bold;
                            font-size:.28rem;
                        }
                        a{
                            font-size:.25rem;
                            background:#0098bb;
                            padding:.08rem .5rem;
                            border-radius:.25rem;
                            color:#fff;
                            margin-top:.1rem;
                        }
                    }
                }
            }
        }
        .edu_jxhd{
            width:7.1rem;
            margin:0rem auto;
            overflow:hidden;
            .edu_more{
                width:100%;
                overflow:hidden;
                margin:0 auto;
                position:relative;
                .edu_more_link{
                    position: absolute;
                    right: 0;
                    top: 0.2rem;
                    font-size: .35rem;
                    color: #00a1b4;
                    z-index: 20;
                }
            }
            .edu_jhhd_wrap{
                width:7.1rem;
                overflow:hidden;
                margin:0.3rem auto 0;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
                .jxhd_section{
                    width:3.45rem;
                    height:2.18rem;
                    margin-bottom:.2rem;
                    position:relative;
                    overflow:hidden;
                    background-position:center center;
                    background-size:cover;
                    background-repeat:no-repeat;
                    border-radius:.1rem;
                    .jxhd_section_p{
                        width: 100%;
                        height: 1rem;
                        background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        padding: .1rem .35rem;
                        box-sizing: border-box;
                        color: #fff;
                        font-size: .3rem;
                        -webkit-align-items: flex-end;
                        -webkit-box-align: flex-end;
                        -ms-flex-align: flex-end;
                        align-items: flex-end;
                    }
                }
            }
        }
        .edu_jckc{
            width:7.1rem;
            margin:0rem auto;
            overflow:hidden;
            .edu_more{
                width:100%;
                overflow:hidden;
                margin:0 auto;
                position:relative;
                .edu_more_link{
                    position: absolute;
                    right: 0;
                    top: 0.2rem;
                    font-size: .35rem;
                    color: #00a1b4;
                    z-index: 20;
                }
            }
            .edu_jhhd_wrap{
                width:7.1rem;
                overflow:hidden;
                margin:0.3rem auto 0;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
                .jckc_section{
                    width:3.45rem;
                    height:2.18rem;
                    margin-bottom:.2rem;
                    position:relative;
                    overflow:hidden;
                    background-position:center center;
                    background-size:cover;
                    background-repeat:no-repeat;
                    border-radius:.1rem;
                    .jckc_section_p{
                        width: 100%;
                        height: 1rem;
                        background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        padding: .1rem .35rem;
                        box-sizing: border-box;
                        color: #fff;
                        font-size: .3rem;
                        -webkit-align-items: flex-end;
                        -webkit-box-align: flex-end;
                        -ms-flex-align: flex-end;
                        align-items: flex-end;
                    }
                }
                .jckc_section_full{
                    width:100%;
                }
            }
        }
        .edu_mkjkx{
            width:7.1rem;
            margin:0 auto;
            overflow:hidden;
            .edu_mk_wrap{
                width:100%;
                overflow:hidden;
                .edu_video{
                    width:7.1rem!important;
                    height:4rem!important;
                    overflow:hidden;
                    border-radius:.1rem;
                    margin-top:.3rem;
                    video{
                        width:100%;
                        height:100%;
                    }
                }
                .edu_mk_list{
                    width:100%;
                    display:flex;
                    flex-direction:column;
                    justify-content:space-between;
                    .edu_mk_img_section{
                        width:100%;
                        overflow:hidden;
                        background:none!important;
                        .edu_mk_img_section_mask{
                            font-size:.28rem;
                            color:#515151;
                            line-height:.4rem;
                            padding:.25rem 0;
                            border-bottom:0.02rem dashed #dddddd;
                        }
                    }
                }
            }

        }
        .edu_hdbd{
            width:7.1rem;
            overflow:hidden;
            margin:.3rem auto;
        }
    }
`
export const EduOtherWrap = styled.div `
    @media(min-width:750px){
        .listWrapYjkx {
            display: flex;
            width: 1200px;
            margin: 0 auto;
            justify-content: space-between;
        }
        .paginationWrap02 {
            width: 580px;
        }
        .paginationWrap02 .paginationWrap_title {
            position: relative;
            width: 100%;
            height: 37px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: flex-end;
        }
        .paginationWrap02 .paginationWrap_title .line {
            width: 55%;
            height: 2px;
            background: #009cbe;
            margin-left: -10px;
        }
        .paginationWrap02 .paginationWrap_title .gdBtn {
            position: absolute;
            right: 0;
            bottom: 0;
            font-size:18px;
            color:#009cbe;
            width: 50px;
        }
        width:100%;
        overflow:hidden;
        .paginationWrapM{
            display: none;
        }
        .title_type_4{
            height:32px;
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            text-align:center;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:60px auto 30px;
            text-align:center;
            justify-content:center;
            .i_small{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-left:7px;             
            }
            .i_small2{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large2{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-right:7px;             
            }            
            span{
                padding:10px 40px;
                background:#00a1b4;
                border-radius:5px;
                margin:0 16px;
            }
        }
        // .title_type_4:hover{
        //     color:orange;
        // }
        .pagenation{
                margin:60px auto 80px;
                text-align:center;
                .ant-pagination-item{
                    
                }
                .ant-pagination-item-active {
                    border-radius:0;
                    color:#fff;
                    background: #009cbe;
                    a{
                        color:#fff;     
                    }
                }
        }
        .title_type_1{
            font-size:20px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            .i_small2{
                        width:8px;
                        height:8px;
                        background:#f55c5d;
            }
            .i_large2{
                        width:16px;
                        height:16px;
                        background:#00a1b4;   
                        margin-right:7px;             
            }            
            span{
                        padding:10px 20px;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 16px 0 0;
                        box-sizing:border-box;
            }
        }
        .title_type_2{
                width: 210px;
                height:37px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/qthd_03.png) center center no-repeat;
                background-size: 100% 100%;
                font-size: 18px;
                color:#fff;
                font-weight:bold;
                line-height:37px;
                text-indent: 9px;
                
        }
        .header{
            width:1200px;
            height:380px;
            display:flex;
            flex-direction:row;
            margin:10px auto 0;
            .sw{
                width:830px;
                height:380px;
                border-radius:10px;
                overflow:hidden;
            }
            .header_detail{
                flex:1;
                margin-left:45px;
                p{
                    font-size:18px;
                    line-height:30px;
                    color:#000000;
                    margin-top:30px;
                }
            }
        }
        .paginationWrap{
            width:1200px;
            overflow:hidden;
            margin:0 auto 0;
            .paginationWrap_title{
                position:relative;
                width:1200px;
                height:37px;
                display:flex;
                flex-direction:row;
                align-items:center;
                .line{
                    width:1060px;
                    height:2px;
                    background:#009cbe;
                    margin-left:-10px;
                }
            }
            .other_section_wrap{
                width:1200px;
                overflow:hidden;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
            }
        }
        .new-arr-box{
            margin-bottom:50px;
        }
    }
    @media(max-width:750px){
        width:100%;
        overflow:hidden;
        .title_type_4:hover{
            color:orange;
        }
        .listWrapYjkx {
            margin: 0.2rem;
        }
        .other_section_wrap {
            margin-bottom: 0.2rem;
        }
        .title_type_4{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:.2rem 0.2rem;
            text-indent:0;
            .i_small2{
                width:.16rem;
                height:.16rem;
                background:#f55c5d;
                    }
            .i_large2{
                width:.3rem;
                height:.3rem;
                background:#00a1b4;   
                margin-right:.07rem;             
            }            
            span{
                 padding:.1rem .4rem;
                background:#00a1b4;
                border-radius:5px;
                margin:0 .16rem 0 0;
                box-sizing:border-box;
            }
        }
        .paginationWrap_title{
            position:relative;
            .gdBtn{
                position: absolute;
                right: 0;
                top: 0;
                font-size: .35rem;
                color: #00a1b4;
            }
        }
        .title_type_1{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:0;
            text-indent:0;
            .i_small2{
                width:.16rem;
                height:.16rem;
                background:#f55c5d;
            }
            .i_large2{
                width:.3rem;
                height:.3rem;
                background:#00a1b4;   
                margin-right:.07rem;             
            }            
            span{
                padding:.1rem .4rem;
                background:#00a1b4;
                border-radius:5px;
                margin:0 .16rem 0 0;
                box-sizing:border-box;
            }
        }
        .title_type_2{
            width: 3.9rem;
            height:.5rem;
            background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/qthd_03.png) center center no-repeat;
            background-size: 100% 100%;
            font-size:.28rem;
            color:#fff;
            font-weight:bold;
            line-height:.5rem;
            text-indent:.5rem;              
        }
        .header{
            width:100%;
            overflow:hidden;
            .sw{
                width:100%;
                height:4rem;
            }
            .header_detail{
                width:7.1rem;
                margin:.2rem auto 0;
                p{
                    font-size:.28rem;
                    color:#282828;
                    line-height:.4rem;
                    text-indent:.0;          
                    color:#000; 
                    margin-top:.2rem;
                    text-align:justify;
                }
            }
        }
        /* .paginationWrap{
            display: none;
        } */
        .paginationWrapM{
            width:7.1rem;
            overflow:hidden;
            margin:.1rem auto;
            .paginationWrapM_list{
                width:100%;
                overflow:hidden;
                margin:.3rem auto .9rem;
            }
        }
        .see_more{
            width:2.5rem;
            height:.64rem;
            background:#009cbd;
            border-radius:.04rem;
            font-size:.3rem;
            color:#fff;
            line-height:.64rem;
            text-align:center;
            margin:.5rem auto .7rem;
        }
        
        .new-arr-box{
            margin-bottom:.5rem;
        }
    }
`

export const VideoWrap = styled.div `
    @media(min-width:750px){
        width:1200px;
        overflow:hidden;
        margin:0 auto;
        .hdhx{
            width:1200px;
            overflow:hidden;
            margin:0 auto;
            .hdhx_wrap{
                width:100%;
                height:450px;
                display:flex;
                flex-direction:row;
                .video{
                    width:750px;
                    height:450px;
                    border-radius:10px;
                    overflow:hidden;
                    background:#009cbe;
                    video{
                        width:100%;
                        height:100%;
                    }
                }
                .hdxh_list{
                    flex:1;
                    margin-left:13px;
                    display:flex;
                    flex-direction:column;
                    margin-top:12px;
                    margin-bottom:20px;
                    .hdhx_link{
                        padding:8px 10px;
                        font-size:22px;           
                        color:#221815;
                        line-height:34px;
                        border-bottom:1px solid #d0d0d0;
                        cursor: pointer;
                        box-sizing:border-box;
                        display: flex;
                    }
                    .hdhx_link_active{
                        color:#00a1b4;
                        font-weight:bold;
                        background:#eeeeee;
                    }
                    span{
                        width:22px;
                        display: flex;
                        align-items:center;
                        img{
                            width:100%;
                        }
                    }
                }
            }
        }
    }
    @media(max-width:750px){
        width:100%;
        margin:0 auto;
        .hdhx{
            width:7.1rem;
            overflow:hidden;
            margin:0 auto;
            .hdhx_wrap{
                width:100%;
                display:flex;
                flex-direction:column;
                .video{
                    width:7.1rem!important;
                    height:4rem!important;
                    border-radius:10px;
                    overflow:hidden;
                    background:#009cbe;
                    margin:0 auto;
                    video{
                        width:100%;
                        height:100%;
                    }
                }
                .hdxh_list{
                    flex:1;
                    display:flex;
                    flex-direction:column;
                    .hdhx_link{
                        padding:0 .25rem;
                        font-size:.26rem;           
                        color:#221815;
                        line-height:.4rem;
                        padding:18px 0;
                        border-bottom:1px dashed #747373;
                        cursor: pointer;
                    }
                    .hdhx_link_active{
                        color:#00a1b4;
                        font-weight:bold;
                    }
                }
            }
        }
    }
`

export const ListWrap = styled.div `
    @media(min-width:750px){
        width:1200px;
        overflow:hidden;
        margin:0 auto;
        .other_section_wrap{
                width:1200px;
                overflow:hidden;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
        }
    }
    @media(max-width:750px){
        width:100%;
        margin:0 auto;
        .hdhx{
            width:7.1rem;
            overflow:hidden;
            margin:0 auto;
            .hdhx_wrap{
                width:100%;
                display:flex;
                flex-direction:column;
                .video{
                    width:7.1rem!important;
                    height:4rem!important;
                    border-radius:10px;
                    overflow:hidden;
                    background:#009cbe;
                    margin:0 auto;
                    video{
                        width:100%;
                        height:100%;
                    }
                }
                .hdxh_list{
                    flex:1;
                    display:flex;
                    flex-direction:column;
                    .hdhx_link{
                        padding:0 .25rem;
                        font-size:.26rem;           
                        color:#221815;
                        line-height:.4rem;
                        padding:18px 0;
                        border-bottom:1px dashed #747373;
                        cursor: pointer;
                    }
                    .hdhx_link_active{
                        color:#00a1b4;
                        font-weight:bold;
                    }
                }
            }
        }
    }
`

