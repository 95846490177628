import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { actions } from './../../store'
import { HdygWrap,HdygWrapM} from './style'

import { Pagination,Skeleton,LocaleProvider,Empty} from 'antd';
import 'antd/dist/antd.css';
import zhCN from 'antd/es/locale-provider/zh_CN';

class Hdyg extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            count: 10,
            list: [],
            allCount: null,
            Mlist:[],
            Mpage:null,
            label:[],
            currentLabel:0,
            lebelId:'',
            loading:false
        }
        this.onChange = this.onChange.bind(this)
    }
    componentWillUnmount(){
        localStorage.removeItem('NOW_TZGG_PAGE')
        localStorage.removeItem('NOW_LABEL_ID')
        localStorage.removeItem('NOW_LABEL_INDEX')
    }
    componentDidMount() {
        document.title = '活动预告'
        this.initNav()

        let page = parseInt(localStorage.getItem('NOW_TZGG_PAGE') || 1)
        let labelId = localStorage.getItem('NOW_LABEL_ID') || ''
        let labelIndex =  parseInt(localStorage.getItem('NOW_LABEL_INDEX') || 0)

        localStorage.removeItem('NOW_TZGG_PAGE')
        localStorage.removeItem('NOW_LABEL_ID')
        localStorage.removeItem('NOW_LABEL_INDEX')

        this.setState({
            loading: true,
            page:page,
            lebelId:labelId,
            currentLabel:labelIndex
        })
        React.$request({
            url:'Portal/Index/is_ajax_list',
            method:'get',
            params: {
                type: 2,
                termid: 1003,
                size: this.state.count,
                p: this.state.page,
                label:this.state.lebelId
            }
        }).then(res=>{
            this.setState({
                loading: false,
                list: res.data.list,
                allCount: parseInt(res.data.count),
                Mlist: res.data.list,
                Mpage:Math.ceil(parseInt(res.data.count)/10)
            })
        }) 
        this.goTo()
    }
    initNav(){
        React.$request({
            url:'Portal/Index/label',
            method:'get'
        }).then(res=>{
            this.setState({
                label:res.data
            })
        }) 
    }
    onChange(pageNumber) {
        this.setState({
            page: pageNumber,
            list: [],
            loading: true
        })
        React.$request({
            url:'Portal/Index/is_ajax_list',
            method:'get',
            params: {
                type: 2,
                termid: 1003,
                size: this.state.count,
                p:pageNumber,
                label:this.state.lebelId
            }
        }).then(res=>{
            this.setState({
                loading: false,
                list: res.data.list,
                allCount: parseInt(res.data.count)
            })
        })
    }
    loadMore(){
        const _page = this.state.page+1
        React.$request({
            url:'Portal/Index/is_ajax_list',
            method:'get',
            params: {
                type: 2,
                termid: 1003,
                size: this.state.count,
                p:_page,
                label:this.state.lebelId
            }
        }).then(res=>{
            const _list = this.state.Mlist
            this.setState({
                Mlist:_list.concat(res.data.list)
            })
        })
        this.setState({
            page:_page
        })
    }
    changeLabel(id,index){
        this.setState({
            currentLabel:index,
            lebelId:id
        })
        setTimeout(()=>{
            this.onChange(1)
        })

    }
    loadRecordDetail(id){
        localStorage.removeItem('NOW_TZGG_PAGE')
        localStorage.removeItem('NOW_LABEL_ID')
        localStorage.removeItem('NOW_LABEL_INDEX')
        localStorage.setItem('article_hdyg_'+id,this.state.page)
        localStorage.setItem('article_labelId_'+id,this.state.lebelId)
        localStorage.setItem('article_labelIndex_'+id,this.state.currentLabel)
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    render() {
        return (
            <Fragment>
                <HdygWrap>
                    <div className="hdyg_list">
                        {
                            this.state.loading ? <Skeleton />:(
                                this.state.list.length > 0 ?this.state.list.map((item) => {
                                    return (
                                        <li key={item.id}>
                                            <NavLink to={'/article/' + item.id} onClick={()=>{this.loadRecordDetail(item.id)}}>
                                                {/* <span className='tag'>{item.username}</span> */}
                                                <span className='title'>
                                                    {
                                                    parseInt(item.istop, 10) > 0 ?
                                                        <span className='zd'>
                                                        <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zda.png`} alt=""/>
                                                        </span> :
                                                        ""
                                                    }
                                                    {item.post_title}
                                                </span>
                                                <span className="time">{item.post_date}</span>
                                            </NavLink>
                                        </li>
                                    )
                                }):<Empty className='empty'/>
                            )
                        }
                    </div>
                    {
                        this.state.list.length?
                        <LocaleProvider locale={zhCN}>
                            <Pagination  size="small" defaultCurrent={this.state.page} total={this.state.allCount} onChange={this.onChange} showQuickJumper className='pagenation'/>
                        </LocaleProvider>:null
                    }
                </HdygWrap>
                <HdygWrapM>
                    {/* <div className='tabWrap'>
                        {
                            this.state.label.map((item,index)=>{
                                return(
                                    <div className={this.state.currentLabel== index?"tab_label tab_label_active":"tab_label"} onClick={()=>this.changeLabel(item.id,index)} key={item.id}>{item.username}</div>
                                )
                            })
                        }
                    </div> */}
                    <div className="hdyg_list">
                        {
                            this.state.Mlist.map((item) => {
                                return (
                                    <NavLink to={'/library/detail/' + item.id} className='hdyg_section' key={item.id}>
                                            {/* <div className="xwzx_section_left">
                                                <span className='tag'>
                                                    {item.username}
                                                </span>
                                                <span className="time">{item.post_date}</span>
                                            </div> */}
                                            <div className='title' >
                                                {
                                                parseInt(item.istop, 10) > 0 ?
                                                    <span className='zd'>
                                                    <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zda.png`} alt=""/>
                                                    </span> :
                                                    ""
                                                }
                                                {item.post_title}
                                            </div>
                                    </NavLink>
                                )
                            })
                        }
                    </div>
                    {
                        this.state.page<this.state.Mpage?
                        <div className="see_more" onClick={()=>this.loadMore(this)}>
                            查看更多
                        </div>:null
                    }
                </HdygWrapM>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        HdygList: state.getIn(['library', 'hdyg'])
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        get_Hdyg() {
            dispatch(actions.getHdyg())
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Hdyg))
