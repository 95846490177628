import React from 'react'
import CommonCarousel from 'common/carousel.js'
import { EduOtherWrap } from './style'
import { Skeleton, LocaleProvider, Pagination } from 'antd'
import zhCN from 'antd/es/locale-provider/zh_CN'
import { NavLink } from 'react-router-dom'
import NavSection from 'common/navSection'
import SectionWrap from 'common/newSection'
import VideoList from './video'
import CommList from './commList.jsx'

export const title_type_1 = (font) => {
  return (
    <div className="title_type_1">
      <span>{font}</span>
      <i className="i_large2"></i>
      <i className="i_small2"></i>
    </div>
  )
}
export const title_type_2 = (font) => {
  return <div className="title_type_2">{font}</div>
}
class EduOther2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      otherArr: null,
      currentList: null,
      currentPage: 1,
      allCount: null,
      Mlist: null,
      Mpage: null,
      listId: null,
      videoArr:{},
      listArr:{},
      newListArr:{}
    }
    this.onChange = this.onChange.bind(this)
  }
  componentDidMount() {
    this.goTo()
    this.sdkpjtInit()
    this.getHdygData()
    this.getJcspData()
  }
  zxyd(arr) {
    let zxydList = []
    for (let item in arr) {
      if (item !== 'cat_name') {
        zxydList.push(arr[item])
      }
    }
    return zxydList
  }
  onChange(pageNumber) {
    this.setState({
      currentList: null,
      currentPage: pageNumber,
    })
    React.$request({
      url:'Portal/Index/is_ajax_list',
      method:'get',
      params: {
        termid: this.state.listId,
        type: 2,
        size: 6,
        p: pageNumber,
      }
    }).then(res=>{
      this.setState({
        currentList: res.data.list,
        allCount: parseInt(res.data.count),
        Mlist: res.data.list,
        Mpage: Math.ceil(parseInt(res.data.count) / 10),
      })
    })
  }
  currentListInit() {
    this.onChange(1)
  }
  getHdygData () {
    React.$request({
      url:'Portal/Index/is_ajax_list',
      method:'get',
      params: {
        termid: 406,
        type: 2,
        size: 1,
      }
    }).then(res=>{
      this.setState({
        hdygList: res.data.list
      })
    })
  }
  getJcspData () {
    React.$request({
      url:'Portal/Index/is_ajax_list',
      method:'get',
      params: {
        termid: 407,
        type: 2,
        size: 1,
      }
    }).then(res=>{
      this.setState({
        jcspList: res.data.list
      })
    })
  }
  sdkpjtInit() {
    React.$request({
      url:'Portal/Index/education_son',
      method:'get',
      params: {
        activity: 179,
      }
    }).then(res=>{
      const newArr = res.data.assort[3]
      if(newArr){
        newArr.type = 2
      }
      this.setState({
        otherArr: res.data,
        listId: res.data.assort[0].term_id,
        videoArr: res.data.assort[2],
        listArr:res.data.assort[1],
        newListArr: newArr
      })
      this.currentListInit()
    })
  }
  goTo() {
    let scrollToTop = window.setInterval(function () {
      let pos = window.pageYOffset
      if (pos > 0) {
        window.scrollTo(0, pos - 10000) // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop)
      }
    }, 2)
  }
  loadMore() {
    const _page = this.state.currentPage + 1
    React.$request({
      url:'Portal/Index/is_ajax_list',
      method:'get',
      params: {
        termid: this.state.listId,
        type: 2,
        size: 6,
        currentPage: _page,
      }
    }).then(res=>{
      const _list = this.state.Mlist
      this.setState({
        Mlist: _list.concat(res.data.list),
      })
    })
    this.setState({
      currentPage: _page,
    })
  }
  render() {
    return this.state.otherArr && this.state.currentList ? (
      <EduOtherWrap>
        <NavSection />
        <div className="header">
          <CommonCarousel className="sw" Carouselarr={this.state.otherArr.lp} />
          <div className="header_detail">
            {title_type_1(this.state.otherArr.lp[0].post_title)}
            <p>{this.state.otherArr.hdgk[0].post_excerpt}</p>
          </div>
        </div>
        <div className="paginationWrap">
          <NavLink className='title_type_4' to={'/list/'+406}>
            <i className='i_small'></i>
            <i className='i_large'></i>
            <span>预见科学</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>
          </NavLink>
        </div>
        <div className="listWrapYjkx">
          <div className="paginationWrap02">
            <div className="paginationWrap_title">
              <NavLink to={'/list/406'}>{title_type_2('活动预告')}</NavLink>
              <div className="line"></div>
            </div>
            <div className="other_section_wrap">
              {this.state.hdygList.map((item) => {
                return <SectionWrap secarr={item} key={item.id}></SectionWrap>
              })}
            </div>
          </div>
          <div className="paginationWrap02">
            <div className="paginationWrap_title">
              <NavLink to={'/list/407'}>{title_type_2('精彩视频')}</NavLink>
              <div className="line"></div>
            </div>
            <div className="other_section_wrap">
              {this.state.jcspList.map((item) => {
                return <SectionWrap secarr={item} key={item.id}></SectionWrap>
              })}
            </div>
          </div>
        </div>
        <div className="paginationWrap">
            <NavLink className='title_type_4' to={'/list/'+180}>
              <i className='i_small'></i>
              <i className='i_large'></i>
              <span>科普活动</span>
              <i className='i_large2'></i>
              <i className='i_small2'></i>
            </NavLink>
            <div className="other_section_wrap">
              {this.state.currentList.map((item) => {
                return <SectionWrap secarr={item} key={item.id}></SectionWrap>
              })}
            </div>
          {/* {this.state.currentList ? (
            <LocaleProvider locale={zhCN}>
              <Pagination
                size="small"
                defaultCurrent={this.state.currentPage}
                total={this.state.allCount}
                onChange={this.onChange}
                pageSize={6}
                showQuickJumper
                className="pagenation"
              />
            </LocaleProvider>
          ) : (
            <Skeleton active />
          )} */}
        </div>
        {
          this.state.listArr.term_id?<CommList {...this.state.listArr}/>:null
        }
        {
          this.state.videoArr.term_id?<VideoList {...this.state.videoArr}/>:null
        }
        <div className="new-arr-box">
        {
          (this.state.newListArr && this.state.newListArr.term_id)?<CommList {...this.state.newListArr}/>:null
        }
        </div>
        {/* <div className="paginationWrapM">
          <div className="paginationWrap_title">{title_type_2('科普活动')}</div>
          <div className="paginationWrapM_list">
            {this.state.Mlist.map((item) => {
              return <SectionWrap secarr={item} key={item.id} />
            })}
            {this.state.currentPage < this.state.Mpage ? (
              <div className="see_more" onClick={() => this.loadMore(this)}>
                查看更多
              </div>
            ) : null}
          </div>
        </div> */}
      </EduOtherWrap>
    ) : (
      <Skeleton active />
    )
  }
}
export default EduOther2
