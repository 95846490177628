import React from 'react'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'
import './style.css'

const swSection = (arr,pa,sw) =>{
    if(arr.length){
        return(
                <div className={sw}>
                    <div className="swiper-wrapper">
                        {
                            arr.map((item)=>{
                                return(
                                    <a className="swiper-slide" key={item.id} href={item.posts_link} target={item.posts_link.indexOf(location.host)>=0?'':'_blank'} without rel="noopener noreferrer">
                                        <img src={item.path} alt='' />
                                    </a>
                                )
                            })
                        }
                    </div>
                    {
                        arr.length>1?<div className={pa}></div>:<div className="swiper-pagination"></div>
                    }

                </div>
        )

    }
}
class CommonCarouselMk extends React.Component{
    constructor(props){
        super(props)
        this.state={
            swArr:[],
            paginationM:"swiper-pagination opa "+'pa'+Math.floor((Math.random()*10000)),
            sw:"swiper-container swiper-container-common "+'sw'+Math.floor((Math.random()*10000))
        }
    }
    componentDidMount() {
        var _name ='.'+this.state.paginationM.split('opa ')[1]
        var _sw = '.'+this.state.sw.split('swiper-container-common ')[1]
        new Swiper(_sw, {
            loop: false,  //循环,
            pagination: {
                el:_name
            },
        })
    }
    render() {
        return (
            <div className={this.props.className}>
                    {
                        swSection(this.props.Carouselarr,this.state.paginationM,this.state.sw)
                    }
            </div>

        )
    }
}
export default CommonCarouselMk
