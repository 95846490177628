import React from 'react';
import {SdkpjtWrap} from './style'
import CommonCarouselTitle from 'common/carouselTitle.js'
import {Skeleton} from 'antd'
import {NavLink} from 'react-router-dom'
import NavSection from 'common/navSection'
import SectionWrap from 'common/newSection'

export const title_type_1 = (font)=>{
    return(
        <div className='title_type_1'>
            <span>{font}</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>
        </div>
    )
}
export const title_type_2 = (font)=>{
    return(
        <div className='title_type_2'>
            <span>{font}</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>
        </div>
    )
}
class EduSdkpjt extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            sdkpjtArr:null,
            routerLink:{

            }
        }
    }
    componentDidMount(){
        this.sdkpjtInit()
    }
    zxyd(arr){
        let zxydList = []
        for(let item in arr){
            if(item !== 'cat_name'){
                zxydList.push(arr[item])
            }
        }
        return zxydList
    }
    sdkpjtInit(){
        React.$request({
            url:'Portal/Index/education_son',
            method:'get',
            params: {
                activity:105
            }
        }).then(res=>{
            this.setState({
                sdkpjtArr:res.data
            })
        })

    }
    render(){
        return(
            this.state.sdkpjtArr?
            <SdkpjtWrap>
                <NavSection />
                <div className="header">
                    <div className="video">
                        <video src={this.state.sdkpjtArr.sp[0].video} controls='controls' poster={this.state.sdkpjtArr.sp[0].path}></video>
                    </div>
                    <div className="header_detail">
                        {
                            title_type_1(this.state.sdkpjtArr.sdkpjt[0].post_title)
                        }
                        <p>{this.state.sdkpjtArr.sdkpjt[0].post_excerpt}</p>
                    </div>
                </div>
                <div className="sdkp_part_2">
                    <div className="jdjm">
                        {
                            title_type_2(this.state.sdkpjtArr.jdjm.cat_name)
                        }
                        <div className="jdjm_wrap">
                            {
                                this.zxyd(this.state.sdkpjtArr.jdjm).map((item)=>{
                                    return(
                                        <div className="jdjm_section" key={item.id}>
                                            <img src={item.path} alt=""/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="ycxx">
                        <NavLink className='ycxx_title_type_2' to={'/list/107'}>
                            <span>{this.state.sdkpjtArr.ycxy.cat_name}</span>
                            <i className='i_large2'></i>
                            <i className='i_small2'></i>
                        </NavLink>
                        <div className="edu_ycxx_list">
                            {
                                this.zxyd(this.state.sdkpjtArr.ycxy).map((item)=>{
                                    return(
                                        <NavLink key={item.id} className='hdyg_section' to={'/article/'+item.id}>
                                            <div className="content">
                                                <span className="post_title">{item.post_title}</span>
                                                <span className="post_date">{item.post_date}</span>
                                            </div>
                                        </NavLink>
                                    )
                                })
                            }
                            <NavLink className='more' to={'/list/107'}>
                                更多
                            </NavLink>
                        </div>
                        {/*<div className="ycxx_wrap">*/}
                        {/*    <div className="ycxx_img_wrap" style={{backgroundImage:'url('+this.state.sdkpjtArr.ycxy[0].path+')'}}>*/}
                        {/*    </div>*/}
                        {/*    <div className="ycxx_detail">*/}
                        {/*        <div className="detail_wrap">*/}
                        {/*            <h2>{this.state.sdkpjtArr.ycxy[0].post_title}</h2>*/}
                        {/*            <p>{this.state.sdkpjtArr.ycxy[0].posts_address}</p>*/}
                        {/*            <p>{this.state.sdkpjtArr.ycxy[0].post_date}</p>*/}
                        {/*        </div>*/}
                        {/*        <div className="detail_links">*/}
                        {/*            {*/}
                        {/*                this.zxyd(this.state.sdkpjtArr.ycxywl).map((item)=>{*/}
                        {/*                    return(*/}
                        {/*                        <a href={item.posts_link} key={item.id}>{item.post_title}</a>*/}
                        {/*                    )*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="jcsjWrap">
                    {
                        title_type_2(this.state.sdkpjtArr.jcsj.cat_name)
                    }
                    <CommonCarouselTitle className='sw'  Carouselarr={this.zxyd(this.state.sdkpjtArr.jcsj)} />
                </div>
                <div className="sdkp_hdbd">
                    {
                        title_type_2(this.state.sdkpjtArr.hdbd.cat_name)
                    }
                    <div className="hdbd_wrap">
                        {
                            this.zxyd(this.state.sdkpjtArr.hdbd).map((item)=>{
                                return(
                                    <SectionWrap secarr={item} key={item.id}/>
                                )
                            })
                        }
                    </div>
                </div>
            </SdkpjtWrap>
            :<Skeleton active />
        )
    }
}
export default EduSdkpjt
