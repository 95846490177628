import React from 'react'
import {SectionWrap} from './style'
import {NavLink} from 'react-router-dom'

class NewSection extends React.Component{
    constructor(props){
        super(props)
        this.state={
            swArr:[],
            showSubTitle:false
        }
    }
    componentDidMount(){
        console.log(this.props.yc)
    }
    clickCount(item) {
        React.$request({
            url:'Portal/Index/storeArticleClicks',
            method:'get',
            params: {
                id: item.id,
            }
        }).then(res=>{
            console.log(res)
        })
    }
    render(){
        const {yc} = this.props

        return(
            <SectionWrap key={this.props.secarr.id} className='hdbd_section'>
                <div className="hdbd_section_img_wrap">
                        {
                            parseInt(this.props.secarr.istop, 10) > 0 ?
                            <div className='zd'>
                                <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zd.png`} alt=""/>
                            </div>:''
                        }
                    {
                        this.props.secarr.posts_link?
                            <a onClick={() => this.clickCount(this.props.secarr) } href={this.props.secarr.posts_link} target={this.props.secarr.posts_link.indexOf(location.host)>=0?'':'_blank'} >
                                <img src={this.props.secarr.path?this.props.secarr.path:`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/header/logodemo_02.jpg`} alt=""/>
                            </a>
                            :
                            yc?
                            <NavLink to={`/article/${this.props.secarr.id}?yc=${yc}`}>
                                <img src={this.props.secarr.path?this.props.secarr.path:`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/header/logodemo_02.jpg`} alt=""/>
                            </NavLink>:
                            <NavLink to={`/article/${this.props.secarr.id}`}>
                                <img src={this.props.secarr.path?this.props.secarr.path:`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/header/logodemo_02.jpg`} alt=""/>
                            </NavLink>
                    }

                    </div>
                    <div className="hdbd_section_detail">
                        {
                            this.props.secarr.posts_link?
                                <a className='hdbd_section_detail_title' onClick={() => this.clickCount(this.props.secarr) } href={this.props.secarr.posts_link} target={this.props.secarr.posts_link.indexOf(location.host)>=0?'':'_blank'} >
                                    {this.props.secarr.post_title}
                                </a>
                                :yc?
                                <NavLink className='hdbd_section_detail_title' to={`/article/${this.props.secarr.id}?yc=${yc}`}>{this.props.secarr.post_title}</NavLink>
                                :<NavLink className='hdbd_section_detail_title' to={`/article/${this.props.secarr.id}`}>{this.props.secarr.post_title}</NavLink>
                            }
                        {
                            (this.props.showSubTitle && this.props.secarr.post_excerpt)?<div className='hdbd_section_subtitle'>{this.props.secarr.post_excerpt}</div>:null
                        }
                        <div className='hdbd_section_date'>{this.props.secarr.post_date}</div>
                    </div>
            </SectionWrap>
        )
    }
}
export default NewSection
