import styled from 'styled-components'

export const CommunicationWrap = styled.div`
    @media(min-width:750px){
        width:100%;
        overflow:hidden;
        .banner-img {
            width: 1200px;
            margin: 30px auto 0;
            display: flex;  
            justify-content: space-between; /* 如果需要均匀分布图片，可以使用这个属性 */  
            align-items: center; /* 如果需要垂直居中图片，可以使用这个属性 */  
            .banner-img-link {  
                width: 591px;  
                height: 388px;  
                display: inline-block; /* 使得每个链接都表现为内联块级元素 */  
                text-align: center; /* 保证图片在链接中居中 */  
                position: relative; /* 确保图片标题在图片下方 */
                // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                .banner-img-item {  
                    width: 100%;
                    height: 100%;
                    display: block; /* 将图片作为块级元素显示 */  
                    margin-right: 38px; /* 为每个图片添加右边距 */  
                } 
                .banner-img-caption {  
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding: 5px;
                    background-color: #00a1b4;
                    color: white;
                    text-align: center;
                    font-size: 25px;
                    line-height: 1.2;
                    box-sizing: border-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                } 
            } 
              
            /* 移除最后一个图片的右边距 */  
            .banner-img-link:last-child {  
                margin-right: 0;  
                border: 2px solid #00a1b4;
                border-bottom: none;   
                .banner-img-item{
                    width: 92%;
                    height: auto;
                    margin: auto;
                }             
            }
           
        }
        .title_type_1{
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            .i_small2{
                        width:8px;
                        height:8px;
                        background:#f55c5d;
            }
            .i_large2{
                        width:16px;
                        height:16px;
                        background:#00a1b4;   
                        margin-right:7px;             
            }            
            span{
                        padding:10px 25px;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 16px 0 0;
                        box-sizing:border-box;
            }
        }
        .header{
            width:1200px;
            height:380px;
            margin:10px auto 0;
            display:flex;
            flex-direction:row;
            .sw{
                width:765px;
                height:380px;
                overflow:hidden;
                border-radius:10px;
            }
            .header_content{
                flex:1;
                margin-left:30px;
                p{  
                    font-size:18px;
                    line-height:30px;
                    margin-top:30px;
                    color:#000;
                }
            }
        }
        .tabWrap{
            width:1200px;
            height:40px;
            margin: 30px auto 0px;
            display:flex;
            flex-direction:row;
            text-align:center;
            .tab_1{
                width:226px;
                height:40px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_09.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:26px;
                color:#009cbe;
                font-weight:bold;
                line-height:40px;
                display: block;
                text-indent: -27px;
                
            }
            .tab_2{
                width:258px;
                height:40px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_06.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:26px;
                color:#009cbe;
                font-weight:bold;
                line-height:40px;
                display: block;
                margin-left:-15px;
            }
            .tab_3{
                width:226px;
                height:40px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_16.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:26px;
                color:#009cbe;
                font-weight:bold;
                line-height:40px;
                display: block;
                margin-left:-15px;
                text-indent: 26px;
            }
            .tab_1_active{
                width:226px;
                height:40px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_19.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:26px;
                color:#fff;
                font-weight:bold;
                line-height:40px;
                display: block;
                text-indent: -27px;
            }
            .tab_2_active{
                width:258px;
                height:40px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_03.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:26px;
                color:#fff;
                font-weight:bold;
                line-height:40px;
                display: block;
                margin-left:-15px;
            }
            .tab_3_active{
                width:226px;
                height:40px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_11.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:26px;
                color:#fff;
                font-weight:bold;
                line-height:40px;
                display: block;
                margin-left:-15px;
                text-indent:26px;
            }
        }
    }
    @media(max-width:750px){
        width:100%;
        overflow:hidden;
        .banner-img {
            width: 100%; /* 使用百分比或视口单位vw，以确保宽度适应屏幕 */  
            max-width: 7.1rem; /* 设置最大宽度，防止过大 */  
            margin: 0.3rem auto;  
            display: flex;  
            flex-wrap: wrap; /* 允许图片在必要时换行 */  
            justify-content: space-between; /* 均匀分布图片，如果需要的话 */
            .banner-img-link {  
                flex: 0 0 calc(50% - 0.1rem); /* 每个链接占据50%宽度减去一半间距 */  
                box-sizing: border-box; /* 包含padding和border在元素的总宽度和总高度中 */  
                position: relative;
                .banner-img-item {  
                    width: 100%; /* 图片宽度设置为100%，以填充链接容器 */  
                    height: 100%; /* 高度自动，以保持图片的原始比例 */  
                    margin-right: 0.38rem; /* 设置图片之间的间隔 */  
                } 
                .banner-img-caption {  
                    position: absolute; /* 确保标题在图片下方 */
                    bottom: 0;  
                    left: 0;  
                    width: 100%;  
                    padding: 0.03rem;  
                    background-color: #00a1b4 
                    color: white; /* 标题颜色 */  
                    text-align: center; /* 标题居中 */  
                    font-size: 0.09rem; /* 标题字体大小 */  
                    box-sizing: border-box; /* 确保 padding 不会增加元素的总宽度 */  
                    overflow: hidden; 
                    text-overflow: ellipsis; 
                    white-space: nowrap; 
                    line-height: 1.2; /* 标题行高 */
                } 
            }  
          
            /* 移除最后一个图片的右边距 */  
            
            .banner-img-link:last-child {  
                margin-right: 0;  
                border: 2px solid #00a1b4;
                border-bottom: none;   
                .banner-img-item{
                    width: 66%;
                    height: auto;
                    margin: 0 17%;
                }             
            }
        }
        .title_type_1{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:0;
            text-indent:0;
            .i_small2{
                width:.16rem;
                height:.16rem;
                background:#f55c5d;
            }
            .i_large2{
                width:.3rem;
                height:.3rem;
                background:#00a1b4;   
                margin-right:.07rem;             
            }            
            span{
                padding:.1rem .4rem;
                background:#00a1b4;
                border-radius:5px;
                margin:0 .16rem 0 0;
                box-sizing:border-box;
            }
        }
        .header{
            width:100%;
            overflow:hidden;
            .sw{
                width:100%;
                height:4rem;
                overflow:hidden;
            }
            .header_content{
                width:7.1rem;
                margin:.5rem auto;
                p{  
                    font-size:.28rem;
                    line-height:.45rem;
                    margin-top:.3rem;
                    color:#000;
                }
            }
        }
        .tabWrap{
            width:7.1rem;
            height:.5rem;
            margin:0 auto .5rem;
            display:flex;
            flex-direction:row;
            text-align:center;
            .tab_1{
                width:2.47rem;
                height:.5rem;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_09.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:.35rem;
                color:#009cbe;
                font-weight:bold;
                line-height:.5rem;
                display: block;
                
            }
            .tab_2{
                width:2.6rem;
                height:.5rem;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_06.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:.35rem;
                color:#009cbe;
                font-weight:bold;
                line-height:.5rem;
                display: block;
                margin-left:-0.1rem;
            }
            .tab_3{
                width:2.47rem;
                height:.5rem;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_16.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:.35rem;
                color:#009cbe;
                font-weight:bold;
                line-height:.5rem;
                display: block;
                margin-left:-0.1rem;
            }
            .tab_1_active{
                width:2.47rem;
                height:.5rem;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_19.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:.35rem;
                color:#fff;
                font-weight:bold;
                line-height:.5rem;
                display: block;
                
            }
            .tab_2_active{
                width:2.6rem;
                height:.5rem;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_03.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:.35rem;
                color:#fff;
                font-weight:bold;
                line-height:.5rem;
                display: block;
                margin-left:-0.1rem;
            }
            .tab_3_active{
                width:2.47rem;
                height:.5rem;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_11.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:.35rem;
                color:#fff;
                font-weight:bold;
                line-height:.5rem;
                display: block;
                margin-left:-0.1rem;
            }
        }
    }

`