import styled from 'styled-components'

export const XwzxWrap = styled.div `
    @media(max-width:750px){
        display: none;
    }
    @media(min-width:750px){
        width:953px;
        margin:0 auto;
        .xwzx_title{
            width:215px;
            margin-top:0;
            img{
                width:100%;
            }
        }
        .wxzx_list{
            width:953px;
            margin:0 auto;
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            overflow:hidden;
            flex-wrap:wrap;
            .xwzxSection{
                width:460px;
                margin:10px 0;
                height:170px;
                margin-top:30px;
                display:flex;
                flex-direction:row;
                align-items:center;
                justify-content:space-between;
                position: relative;
                .tag{
                    height:33px;
                    line-height:33px;
                    background-color:#70c1f8;
                    color:#fff;
                    position:absolute;
                    top:20px;
                    left:0;
                    padding:0 20px;
                    text-align:center;
                    border-radius:0 20px 20px 0;
                }
                .hdbd_section_img_wrap{
                    width:240px;
                    height:150px;
                    border-radius:7px;
                    overflow:hidden;
                    background-size:cover;
                    background-position:center center;
                    background-repeat:no-repeat;
                    position: relative;
                    .zd{
                        width:50px;
                        position: absolute;
                        left:0;
                        top:10px;
                        img{
                            width:50px;
                        }
                    }
                }
                .hdbd_section_detail{
                    width:205px;
                    height:150px;
                    display:flex;
                    text-align:left;
                    flex-direction:column;
                    justify-content:space-between;
                    .hdbd_section_detail_title{
                        font-size:18px;
                        color:#221815;
                        line-height:30px;
                        width:100%;
                    }
                    .hdbd_section_date{
                        width:100%;
                        color:#009cbe;
                        font-size:18px;
                    }
                }
            }
        }
        .pagenation{
            margin:60px auto 80px;
            text-align:center;
            .ant-pagination-item{
                
            }
            .ant-pagination-item-active {
                border-radius:0;
                color:#fff;
                background: #009cbe;
                a{
                    color:#fff;     
                }
            }
        }
    }
`

export const XwzxWrapM = styled.div `
    @media(min-width:750px){
        display: none;
    }
    @media(max-width:750px){
        width:7.1rem;
        margin:.5rem auto;
        .wxzx_list{
            width:100%;
            overflow:hidden;
            .xwzxSection{
                width:100%;
                display:flex;
                overflow:hidden;
                flex-direction:row;
                justify-content:space-between;
                align-items:center;
                padding:0.3rem 0;
                border-bottom:0.01rem dashed #9f9f9f;
                position:relative;
                .tag{
                    height:.33rem;
                    line-height:.33rem;
                    background-color:#70c1f8;
                    color:#fff;
                    position:absolute;
                    top:.43rem;
                    left:0;
                    padding:0 .2rem;
                    text-align:center;
                    border-radius:0 .2rem .2rem 0;
                }
                .imgWrap{
                    width: 2.45rem;
                    height: 1.5rem;
                    border-radius: .1rem;
                    overflow: hidden;
                    background-position:center center;
                    background-size:cover;
                    position: relative;
                    .zd{
                        width:.8rem;
                        position: absolute;
                        left:0;
                        top:0;
                        img{
                            width:100%;
                        }
                    }
                }
                .sec_detail{
                    flex: 1;
                    margin-left: .1rem;
                    display: flex;
                    align-items: center;
                    text-align: left;
                    flex-direction: column;
                    height: 1.5rem;
                    justify-content: space-between;
                    .sec_detail_top{
                        font-size: .29rem;
                        color: #000;
                        line-height: .4rem;
                        width: 100%;
                        .sec_detail_title{
                            font-size: .29rem;
                            color: #000;
                            line-height: .4rem;
                            width: 100%;
                        }
                    }
                    .sec_detail_time{
                        width: 100%;
                        text-align: left;
                        color: #00a1b4;
                        font-size: .28rem;
                    }
                }
            }
        }
        .see_more{
            width:2.5rem;
            height:.64rem;
            background:#009cbd;
            border-radius:.04rem;
            font-size:.3rem;
            color:#fff;
            line-height:.64rem;
            text-align:center;
            margin:.5rem auto .7rem;
        }
    }
`
