import styled from 'styled-components'

export const XnmyWrap = styled.div `
    @media(min-width:750px){
        width:100%;
        .tabWrap{
            width:864px;
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            flex-wrap:wrap;
            .tab{
                // width:110px;
                padding:0 10px;
                height:43px;
                border:1px solid #00a1b4;
                font-size:18px;
                line-height:43px;
                text-align:center!important;
                color:#00a1b4;
                cursor: pointer;
                border-radius:6px;
                text-indent:0;
                margin-bottom:10px;
            }
            .tabActive{
                // width:110px;
                height:43px;
                border:1px solid #00a1b4;
                font-size:18px;
                line-height:43px;
                text-align:center;
                background:#00a1b4;
                color:#fff;
                cursor: pointer;    
                border-radius:6px;      
            }
        }
        .tabSwrap{
            width:100%;
            display:flex;
            flex-direction:row;
            margin-top:20px;
            flex-wrap: wrap;
            .tabS{
                min-width:99px;
                height:27px;
                border:1px solid #ff7413;
                font-size:15px;
                text-align:center;
                line-height:27px;
                margin-right:5px;
                color:#ff7413;
                cursor: pointer;   
                border-radius:6px; 
                padding: 0 10px;
                margin-bottom: 10px;
            }
            .tabSactive{
                background:#ff7413;
                color:#fff;
                border-radius:6px;
            }
        }
        .videoWrap{
            width:862px;
            height:478px;
            overflow:hidden;
            margin:20px 0 20px;
            border-radius:5px;
            video{
                width:100%;
            }
        }
    }
    @media(max-width:750px){
        width:100%;
        .tabWrap{
            width:7.1rem;
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            flex-wrap:wrap;
            margin:0 auto;
            .tab{
                // width:2.3rem;
                height:.6rem;
                border:1px solid #00a1b4;
                font-size:.25rem;
                line-height:.6rem;
                text-align:center;
                color:#00a1b4;
                cursor: pointer;
                margin-top:.2rem;
                text-indent:0;
                padding: 0 0.2rem;
            }
            .tabActive{
                // width:2.3rem;
                height:.6rem;
                border:1px solid #00a1b4;
                font-size:.25rem;
                line-height:.6rem;
                text-align:center;
                background:#00a1b4;
                color:#fff;
                cursor: pointer; 
                margin-top:.2rem;           
            }
        }
        .tabSwrap{
            width:7.1rem;
            display:flex;
            flex-direction:row;
            margin:20px auto;
            flex-wrap: wrap;
            .tabS{
                min-width:1.2rem;
                height:.5rem;
                border:1px solid #ff7413;
                font-size:15px;
                text-align:center;
                line-height:27px;
                margin-right:5px;
                color:#ff7413;
                cursor: pointer;
                padding: 0 0.2rem;
                margin-bottom: 0.2rem;    
            }
            .tabSactive{
                background:#ff7413;
                color:#fff;
            }
        }
        .videoWrap{
            width:7.1rem;
            height:3.94rem;
            overflow:hidden;
            margin:.2rem auto;
            border-radius:.2rem;
            video{
                width:100%;
                height:100%;
            }
        }
    }
`
