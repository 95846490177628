import styled from 'styled-components'

export const BjkxzxtxWrap = styled.div`
    @media(max-width:750px){
        width:7.1rem;
        margin:.1rem auto;
        font-size:.2rem;
        line-height:.35rem;
        .headerWrap{
            width:100%;
            overflow:hidden;
            .kxzxtx{
                width:6.8rem;
                height:4.5rem;
                margin:0 auto;
                div{
                    width:6.8rem;
                    height:4.5rem;
                }
            }
            .kxzxlp{
                width:7.1rem;
                font-size:.24rem;
                line-height:.4rem;
                text-indent:.48rem;
                color:#282828;
                h2{
                    font-size:.32rem;
                    font-weight:bold;
                    line-height:.32rem;
                    color:#fff;
                    display:flex;
                    flex-direction:row;
                    align-items:center;
                    margin:.2rem 0;
                    text-indent:0;
                    .i_small2{
                                width:.16rem;
                                height:.16rem;
                                background:#f55c5d;
                    }
                    .i_large2{
                                width:.3rem;
                                height:.3rem;
                                background:#00a1b4;   
                                margin-right:.07rem;             
                    }            
                    span{
                                padding:.1rem .4rem;
                                background:#00a1b4;
                                border-radius:5px;
                                margin:0 .16rem 0 0;
                                box-sizing:border-box;
                    }
                }
                p{
                    font-size: .3rem;
                    line-height: .45rem;
                    margin-top: .3rem;
                    color: #000;      
                    text-indent:0;    
                    text-align:justify;         
                }
                .kxzxlpDetail{
                    color:#00a1b4;
                    float:right;
                }
            }
        }
        .fzxjs{
            width:100%;
            margin-top:.5rem;
            p{
                    font-size:.32rem;
                    font-weight:bold;
                    line-height:.32rem;
                    color:#fff;
                    display:flex;
                    flex-direction:row;
                    align-items:center;
                    margin:.2rem 0;
                    text-indent:0;
                    .i_small2{
                                width:.16rem;
                                height:.16rem;
                                background:#f55c5d;
                    }
                    .i_large2{
                                width:.3rem;
                                height:.3rem;
                                background:#00a1b4;   
                                margin-right:.07rem;             
                    }            
                    span{
                                padding:.1rem .4rem;
                                background:#00a1b4;
                                border-radius:5px;
                                margin:0 .16rem 0 0;
                                box-sizing:border-box;
                    }
            }
            .fzxjsWrap{
                width:100%;
                overflow:hidden;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
                ._li{
                    list-style:none;
                    width:3.45rem;
                    height:2.18rem;
                    font-size:.3rem;
                    overflow:hidden;
                    position:relative;
                    margin-bottom:.3rem;
                    display:flex;
                    flex-direction:row;
                    justify-content:space-between;
                    border-radius:.1rem;
                    .imgWrap{
                        width:3.45rem;
                        height:2.18rem;
                        background-repeat:no-repeat;
                        background-size:cover;
                        background-position:center center;
                        
                        overflow:hidden;
                        position:absolute;
                    }
                    .fzx_title{
                        width: 100%;
                        height: 1rem;
                        background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        padding: .1rem .25rem;
                        box-sizing: border-box;
                        color: #fff;
                        font-size: .26rem;
                        -webkit-align-items: flex-end;
                        -webkit-box-align: flex-end;
                        -ms-flex-align: flex-end;
                        align-items: flex-end;
                    }
                }
            }
        }
    }
    @media(min-width:750px){
        width:953px;
        margin:0 auto;
        .headerWrap{
            width:100%;
            height:350px;
            display:flex;
            flex-direction:row;
            overflow:hidden;
            margin:0 auto 0;
            .kxzxtx{    
                flex:1;
                .sw{
                    width:100%;
                }
            }
            .kxzxlp{
                flex:1;
                h2{
                    font-size:32px;
                    font-weight:bold;
                    line-height:32px;
                    color:#fff;
                    display:flex;
                    flex-direction:row;
                    align-items:center;
                    .i_small2{
                                width:8px;
                                height:8px;
                                background:#f55c5d;
                    }
                    .i_large2{
                                width:16px;
                                height:16px;
                                background:#00a1b4;   
                                margin-right:7px;             
                    }            
                    span{
                                padding:10px 25px;
                                background:#00a1b4;
                                border-radius:5px;
                                margin:0 16px 0 0;
                                box-sizing:border-box;
                    }
                }
                p{
                    font-size: 18px;
                    line-height: 30px;
                    color: #000000;
                    margin-top: 50px;
                }
                .kxzxlpDetail{
                    color:#00a1b4;
                    float:right;
                }
            }
        }
        .fzxjs{
            width:100%;
            .fzxTitle{
                height:32px;
                font-size:32px;
                font-weight:bold;
                line-height:32px;
                color:#fff;
                text-align:center;
                display:flex;
                flex-direction:row;
                align-items:center;
                margin:60px auto 30px;
                text-align:center;
                justify-content:center;
                .i_small{
                    width:8px;
                    height:8px;
                    background:#f55c5d;
                }
                .i_large{
                    width:16px;
                    height:16px;
                    background:#00a1b4;   
                    margin-left:7px;             
                }
                .i_small2{
                    width:8px;
                    height:8px;
                    background:#f55c5d;
                }
                .i_large2{
                    width:16px;
                    height:16px;
                    background:#00a1b4;   
                    margin-right:7px;             
                }            
                span{
                    padding:10px 40px;
                    background:#00a1b4;
                    border-radius:5px;
                    margin:0 16px;
                } 
            }
            .fzxjsWrap{
                width:100%;
                overflow:hidden;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
                padding-bottom:90px;
                ._li{
                    list-style:none;
                    width:470px;
                    height:273px;
                    position:relative;
                    border-radius:9px;
                    overflow:hidden;
                    margin-top:30px;
                    .imgWrap{
                        width:100%;
                        height:100%;
                        position:absolute;
                        left:0;
                        top:0;
                        background-size:cover;
                        background-repeat:no-repeat;
                        background-position:center center;
                    }
                    .fzx_title{
                        width: 100%;
                        height: 170px;
                        position: absolute;
                        left: 0;
                        padding: 10px 27px;
                        color: #fff;
                        font-size: 18px;
                        bottom: 0;
                        z-index: 2;
                        line-height: 25px;
                        min-height: 25px;
                        background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) 0 0 no-repeat;
                        background-size: cover;
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                    }
                }
            }
        }        
    }    
`