import React from 'react'
import { ZyzWrap } from './style'
import { GoTo } from 'common/index'
import NavSection from 'common/navSection'
import { NavLink } from 'react-router-dom'
const title_1 = (font) => {
  return (
    <div className="title_1">
      <span>{font}</span>
      <i className="i_large2"></i>
      <i className="i_small2"></i>
    </div>
  )
}
const title_2 = (font) => {
  return (
    <div className="title_2">
      <i className="i_small"></i>
      <i className="i_large"></i>
      <span>{font}</span>
      <i className="i_large2"></i>
      <i className="i_small2"></i>
    </div>
  )
}
class Zyz extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      zyzArr: null,
    }
  }
  componentDidMount() {
    document.title = '志愿者招募'
    this.initZyz()
  }
  zxyd(arr) {
    let zxydList = []
    for (let item in arr) {
      if (item !== 'cat_name') {
        zxydList.push(arr[item])
      }
    }
    return zxydList
  }
  initZyz() {
    React.$request({
      url:'Portal/index/volunteer',
      method:'get',
    }).then(res=>{
      console.log(res.data)
      this.setState({
        zyzArr: res.data,
      })
      GoTo()
    })
  }
  render() {
    return this.state.zyzArr ? (
      <ZyzWrap>
        <NavSection />
        <div className="header">
          <div className="imgWrap">
            <img src={this.state.zyzArr.cwzyzlp[0].path} alt="" />
          </div>
          <div className="header_right">
            {title_1(this.state.zyzArr.cwzyz.cat_name)}
            <p
              dangerouslySetInnerHTML={{
                __html: this.state.zyzArr.cwzyz[0].post_content,
              }}
            ></p>
            {/* <a
              className="header_link"
              href={this.state.zyzArr.cwzyz[0].posts_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>
                <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/onlineIcon_03.png`} alt="" />
              </i>
              <span>在线报名</span>
            </a> */}
          </div>
        </div>
        {title_2(this.state.zyzArr.zyzzm.cat_name)}
        <div className="zyzzm">
          <div className="zyzzm_wrap">
            {this.zxyd(this.state.zyzArr.zyzzm).map((item) => {
              return (
                <div className="fwgwSection" key={item.id}>
                  <div className="img_wrap">
                    <img src={item.path} alt="" />
                    {
                      parseInt(item.istop, 10) > 0 ?
                        <div className='zd'>
                          <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zd.png`} alt="" />
                        </div> : ''
                    }
                  </div>
                  {/* <div className="section_content">
                    <h2>{item.post_title}</h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.post_content,
                      }}
                    ></p>
                  </div> */}
                </div>
              )
            })}
          </div>
        </div>
        {title_2(this.state.zyzArr.zyfwgw.cat_name)}
        <div className="zyfwgw">
          <div className="zyfwgw_wrap">
            {this.zxyd(this.state.zyzArr.zyfwgw).map((item) => {
              return (
                <div className="fwgwSection" key={item.id}>
                  <div className="img_wrap">
                    <img src={item.path} alt="" />
                    {
                      parseInt(item.istop, 10) > 0 ?
                        <div className='zd'>
                          <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zd.png`} alt="" />
                        </div> : ''
                    }
                  </div>
                  <div className="section_content">
                    <h2>{item.post_title}</h2>
                    <p>{item.post_excerpt}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <NavLink to="/list/99" className="nav_title">
          {title_2(this.state.zyzArr.zyzfc.cat_name)}
        </NavLink>
        {/* <div className="zyzfc">
                    <CommonCarousel className='sw'  Carouselarr={this.state.zyzArr.zyzfclp} />
                    <div className="zyzfcBot">
                        {
                            this.zxyd(this.state.zyzArr.zyzfc).map((item)=>{
                                return(
                                    <div className="zyz_bot_img" key={item.id}>
                                        <img src={item.path} alt=""/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div> */}
        <div className="edu_jxhd">
          <div className="edu_jhhd_wrap">
            {this.zxyd(this.state.zyzArr.zyzfc)
              .slice(0, 6)
              .map((item) => {
                let _style = {
                  backgroundImage: 'url(' + item.path + ')',
                }
                return (
                  <NavLink
                    key={item.id}
                    className="jxhd_section"
                    style={_style}
                    to={'/article/' + item.id}
                  >
                      {
                          parseInt(item.istop, 10) > 0 ?
                          <div className='zd'>
                              <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zd.png`} alt=""/>
                          </div>:''
                      }
                    <div className="jxhd_section_p">{item.post_title}</div>
                  </NavLink>
                )
              })}
          </div>
        </div>
        <div className="edu_jxhdM">
          <div className="edu_jhhd_wrap">
            {this.zxyd(this.state.zyzArr.zyzfc)
              .slice(0, 6)
              .map((item) => {
                let _style = {
                  backgroundImage: 'url(' + item.path + ')',
                }
                return (
                  <NavLink
                    key={item.id}
                    className="jxhd_section"
                    to={'/article/' + item.id}
                  >
                    <div className="jxhd_section_bg" style={_style}>
                      {
                          parseInt(item.istop, 10) > 0 ?
                          <div className='zd'>
                              <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zd.png`} alt=""/>
                          </div>:''
                      }
                    </div>
                    <div className="hdbd_section_detail">{item.post_title}</div>
                  </NavLink>
                )
              })}
          </div>
        </div>
      </ZyzWrap>
    ) : null
  }
}
export default Zyz
