import React,{Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import { actions } from './../../store'
import {GzzcWrap} from './style'
import { Skeleton } from 'antd';

const Gzzc_dom = (arr)=>{
    return(
            <Fragment>
                <h1>{arr.post_title}</h1>
                <p dangerouslySetInnerHTML={{__html:arr.post_content}}></p>
            </Fragment>
    )
}

class Gzzc extends React.Component{
    componentDidMount(){
        document.title = '馆长致辞'
        if(this.props.gzzcDetail){
        }else{
            this.props.get_gzzc()
        }    
        this.goTo()
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    render(){
        return(
            <GzzcWrap>
                {
                    this.props.gzzcDetail?Gzzc_dom(this.props.gzzcDetail):<Skeleton active />
                }
            </GzzcWrap>
        )
    }

}
const mapStateToProps = (state)=>{
    return{
        gzzcDetail:state.getIn(['library','gzzc'])
    }
}
const mapDispatchToProps = (dispatch)=>{
    return{
        get_gzzc(){
            dispatch(actions.getGzzc())
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Gzzc))