import React, { Fragment } from 'react'
import { NavWrap } from './style'
import { NavLink, withRouter } from 'react-router-dom'

class ArticleSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      swArr: [],
      pathList: {
        education: 30,
        train: 32,
        library: 33,
        tzgg: 46,
        hdyg: 1003,
        xwzx: 47,
        // hdyg: 64,
        hdbd: 68,
        xsdll: 421,
        partyMasses: 34,
        djgz: 36,
        ghgz: 37,
        communication: 28,
        gjjl: 39,
        gatjl: 40,
        guojjl: 49,
        bjkxzxtx: 45,
        service: 41,
        105: 105,
        100: 100,
        103: 103,
        zyz: 96,
        jtlx: 89,
        mkjkx: 119,
        yszjjkx: 85,
        132: 132,
        125: 125,
        126: 126,
        zxgk: 42,
        exhibition: 29,
        52: 52,
        181: 181,
        179: 179,
        301: 301,
        302: 302,
        303: 303,
        304: 304,
        192: 192,
        193: 193,
        194: 194,
        195: 195,
        272: 272,
        282:282,
        960:960,
        qwwx: 982,
      },
      pathNameList: {
        library: '本馆信息',
        tzgg: '通知公告',
        hdyg: '活动预告',
        xwzx: '新闻资讯',
        education: '教育活动',
        // hdyg: '活动预告',
        66: '精品活动',
        67: '科代表讲科学',
        68: '活动报道',
        64: '活动预告',
        partyMasses: '党群园地',
        djgz: '党建工作',
        ghgz: '工会工作',
        communication: '交流合作',
        gjjl: '馆际交流',
        gatjl: '港澳台交流',
        guojjl: '国际交流',
        bjkxzxtx: '北京科学中心体系',
        train: '科普培训',
        talentPlan: '英才学堂',
        newtrain:'英才学堂',
        81: '辅导员培训',
        82: '四点钟开讲',
        83: '名师工作室',
        85: '院士专家讲科学',
        86: '媒体报道',
        service: '参观服务',
        105: '首都科普剧团',
        100: '科学咖啡馆',
        103: '展线课程',
        zyz: '志愿者招募',
        mkjkx: '科代表讲科学',
        xydgg: '信仰的高光',
        xsdll: '向上的力量',
        yszjjkx: '院士专家讲科学',
        132: '动漫别错过',
        133: '30秒懂',
        135: '原创科普文章',
        294: '口袋科学辞典',
        295: '小创讲堂',
        296: '信仰的高光',
        421: '向上的力量',
        99: '志愿者风采',
        125: '科学时光趴',
        126: '流动科学中心',
        zxgk: '中心概况',
        exhibition: '展览展示',
        52: '具象数学实验室',
        181: '北京科学传播大赛',
        179: '小球大世界主题展教区',
        301: '科学三分半',
        302: 'AI科学',
        303: '展教活动明星学员',
        304: '红领巾讲科学',
        309: '青少活动',
        314: '活动报道',
        192: '首都科学讲堂',
        193: '活动预告',
        194: '活动回顾',
        195: '第十届北京科学嘉年华',
        272: '展区动态',
        282: '地球方圆主题展',
        960: '国际联盟',
        qwwx: '趣玩无限',
      },
      currentPath: '',
      pathArr: [],
      linkArr: [],
    }
  }
  componentDidMount() {
    this.initPathArr(this.props.articleLink)
    console.log(this.props.articleLink,'cat')
  }
  initPathArr(arr) {
    var _pathArr = []
    arr.map((item) => {
      _pathArr.push(parseInt(item.term_id))
    })
    this.setState({
      pathArr: _pathArr,
    })
    console.log(_pathArr,'_pathArr')
    setTimeout(() => {
      this.initNav(this.state.pathArr)
    }, 0)
  }
  initNav(arr) {
    var _len = arr.length,
      arrs = [];
    console.log(arr,'arr')
    arr.map((item, index) => {
      if (index == 0) {
        const _arr = {
          name: this.getPathName(arr[0]),
          path: '/' + this.getPathName(arr[0]),
        }
        arrs.push(_arr)
        if (this.props.articleLink[0].list) {
          const _arr_ = {
            name: this.props.articleLink[0].list,
            path: '/list/' + this.props.articleLink[0].list,
          }
          arrs.push(_arr_)
        }
      } else if (index == 1) {


        // 地球方圆主题展 2021-05-31添加 ，52-->：临时展览
        if(arr[2] && arr[1] == 52){
          const _arr = {
            name: this.getPathName([arr[2]]),
            path: '/' + this.getPathName(arr[0]) + '/' + this.getPathName(arr[2]),
          }
          arrs.push(_arr)
        }else{
          const _arr = {
            name: this.getPathName([arr[1]]),
            path: '/' + this.getPathName(arr[0]) + '/' + this.getPathName(arr[1]),
          }
          arrs.push(_arr)
        }


        if (this.props.articleLink[1].list) {
          const _arr_ = {
            name: this.getPathName(arr[0]),
            path: '/' + this.getPathName(arr[0]),
          }
          // arrs.push(_arr_)
        }
      } else if (index == 2) {
        const _deleteArr = [105, 100, 103, 125, 128, 126, 181, 179, 195,52]
        if (_deleteArr.indexOf(arr[1]) >= 0) {
          return
        } else {
          const _arr = {
            name: this.getPathName(arr[2]),
            path:
              '/' +
              this.getPathName(arr[0]) +
              '/' +
              this.getPathName(arr[1]) +
              '/' +
              this.getPathName(arr[2]),
          }
          arrs.push(_arr)
        }
      }
    })
    console.log(arrs)
    this.setState({
      linkArr: arrs,
    })
  }
  getPathName(id) {
    console.log(id)
    for (let i in this.state.pathList) {
      if (id == this.state.pathList[i]) {
        return i
      }
    }
  }
  render() {
    return (
      <NavWrap>
        <NavLink to="/home">首页&gt;</NavLink>
        {this.props.match.params.id == 973 ||
        this.props.match.params.id == 972 ? (
          <Fragment>
            <NavLink to="/exhibition">展览展示&gt;</NavLink>
            <NavLink to="/exhibition/sdcxkjz">首都创新成果展&gt;</NavLink>
          </Fragment>
        ) : (
          this.state.linkArr.map((item) => {
            return (
                  this.state.pathNameList[item.name]?
                      <NavLink key={item.path} to={item.path}>
                        {this.state.pathNameList[item.name]}&gt;
                      </NavLink>:null
            )
          })
        )}
        <a>正文</a>
      </NavWrap>
    )
  }
}
export default withRouter(ArticleSection)
