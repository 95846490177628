import { fromJS } from 'immutable'
import * as constants from './constants'

const exhibitionArr = fromJS({
    exhibitionIndex:null,
    zzq:null,
    etly:null,
    sdcxcgz:null,
    qwwx:null
})

export default(state=exhibitionArr,action)=>{
    switch(action.type){
        case constants.EXHIBITIONINDEX:
            return state.set('exhibitionIndex',action.data)
        case constants.ZZQ:
            return state.set('zzq',action.data)
        case constants.ETLY:
            return state.set('etly',action.data)
        case constants.SDCXCGZ:
            return state.set('sdcxcgz',action.data)
        case constants.QWWX:
            return state.set('qwwx',action.data)
        default:
            return state
    }
}