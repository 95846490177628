import React from 'react';

import {NavLink,Switch,Route,Redirect} from 'react-router-dom'
import Djgz from './components/djgz'
import Ghgz from './components/ghgz'
import {Skeleton} from 'antd'

import CommonCarousel from 'common/carousel'

import {CommunicationWrap} from './style'
import NavSection from 'common/navSection'

const title_type_1 = (font)=>{
    return(
        <div className='title_type_1'>
            <span>{font}</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>  
        </div>
    )
}
class PartyMasses extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            headerList:{}
        }
    }
    componentDidMount(){
        document.title = '党群园地'
        this.goTo()
        React.$request({
            url:'Portal/Index/communication_party',
            method:'get',
            params: {
                termid:34
            }
        }).then(res=>{
            this.setState({
                headerList:res.data
            })
        }) 
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    render(){
        return(
            this.state.headerList.lp?
            <CommunicationWrap>
                <NavSection />
                <div className="header">                
                    <CommonCarousel Carouselarr={this.state.headerList.lp} className='sw' />
                    <div className='header_content'>
                            {
                                title_type_1(this.state.headerList.gk[0].post_title)
                            }
                            <p dangerouslySetInnerHTML={{__html:this.state.headerList.gk[0].post_excerpt}}></p>
                    </div>
                </div>
                <div className='tabWrap'>
                    <NavLink to='/partyMasses/djgz' className='tab_1' activeClassName='tab_1_active'>党建工作</NavLink>
                    <NavLink to='/partyMasses/ghgz' className='tab_3' activeClassName='tab_3_active'>工会工作</NavLink>               
                </div>
                <Switch>
                    {/* <Route exact path='/partyMasses/' component={Djgz}></Route> */}
                    <Route path='/partyMasses/djgz' component={Djgz}></Route>
                    <Route path='/partyMasses/ghgz' component={Ghgz}></Route>
                    <Redirect from="/partyMasses*" to="/partyMasses/djgz" />
                </Switch>
            </CommunicationWrap>:<Skeleton active />
        )
    }
}
export default PartyMasses