import React from 'react';
import {ExIndexEtly} from './style'
import {NavLink} from 'react-router-dom'
const ExhEtly = (arr)=>{
    if(arr){
        return(
            <ExIndexEtly>
                {
                    arr.map((item,index)=>{
                        return(
                            <NavLink key={item.id} className='etly_img_wrap ex_index' to={'/exhibition/etly/tab'+(index+1)}>
                                <img src={item.path} alt={item.id}/>
                            </NavLink>
                        )
                    })   
                }
                {
                    arr.map((item,index)=>{
                            return(
                                <NavLink key={item.id} className='etly_img_wrap ex_m' to={'/exhibition/etly/tab'+(index+1)}>
                                    <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/t`+(index+5)+'.png'} alt=''/>
                                </NavLink>
                            )
                    })   
                }
            </ExIndexEtly>       
        )

    }
}
export default ExhEtly