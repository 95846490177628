import * as constants  from './constants'

export const SetHistory = (data)=>{
    const action = {
        type:constants.HISTORYPATH,
        data:data
    }
    return (dispatch)=>{
        dispatch(action)
    }
}