import styled from 'styled-components'

export const Ldetail = styled.div`
    @media(max-width:750px){
        width:7.1rem;
        overflow:hidden;
        margin:0 auto;
        h2{
            font-size:.4rem;
            font-weight:bold;
            color:#424242;
            text-align:center;
            margin-top:.6rem;
            line-height:.65rem;
        }
        .empty{
            margin:100px auto;
        }
        .l_d_time{
            text-align:center;
            font-size:.35rem;
            p{
                color:#8d8d8d;
            }
        }
        .goBack{
            width:100px;
            height:40px;
            background:#00a1b4;
            margin:.1rem auto .5rem;
            font-size:28px;
            color:#fff;
            text-align:center;
            line-height:40px;
            border-radius:5px;
            cursor: pointer;
        }
        .l_d_content{
            font-size:.34rem;
            line-height:.53rem;
            /* text-indent:.68rem; */
            color:#424242;
            width:7.1rem;
            margin: 0 auto;
            p{
                text-align:justify;
                video{
                    width:100%!important;
                    height:auto!important;  
                }
                p{
                    img{
                        width:100%!important;
                        height:auto!important;
                        margin:0 auto;
                    }                       
                }
                img{
                        width:100%!important;
                        height:auto!important;
                }   
                iframe{
                    width:100%!important;
                    height:5rem;
                    margin:0 auto; 
                }                
            }
            .fileMore{
                width:.3rem!important;
                height:.3rem!important;
                margin-right:.1rem;
            }
            div{
                span{
                    font-size:.34rem;
                    line-height:.53rem;
                    text-indent:.68rem;
                    color:#424242; 
                    img{
                        width:100%!important;
                        height:auto!important;
                        height: auto;
                    }
                }
                margin-top:.5rem;    
                img{
                    width:100%!important;
                    height:auto!important;
                    /* margin-left:-.68rem; */
                }         
            }
        }        
    }
    @media(min-width:750px){
        width:1100px;
        margin:0 auto;

        h2{
            font-size:36px;
            font-weight:bold;
            color:#404040;
            text-align:center;
            margin-top:50px;
        }
        
        .empty{
            margin:100px auto;
        }
        .demo{
            color:red;
        }
        .goBack{
            width:100px;
            height:40px;
            background:#00a1b4;
            margin:30px auto;
            font-size:18px;
            color:#fff;
            text-align:center;
            line-height:40px;
            border-radius:5px;
            cursor: pointer;
        }
        .l_d_time{
            text-align: center;
            font-size: 15px;
            margin-bottom: 45px;
            p{
                color:#fff;
            }
        }
        .l_d_content{
            font-size:18px;
            line-height:30px;
            text-indent:34px;
            color:#404040;
            .fileMore{
                display: inline;
            }
            p{
                img{          
                    max-width:100%;
                    margin:0 auto;
                    display: block;
                }   
            div{
                span{
                    font-size:17px;
                    line-height:30px;
                    text-indent:34px;
                    color:#000;   
                }
                margin-top:12px;             
            }
        }
    }
`
