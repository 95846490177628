import styled from 'styled-components'
export const CinemaWrap = styled.div`
    @media(min-width:750px){
        width:100%;
        overflow:hidden;

        .none_img{
            width:100%;
            overflow:hidden;
            img{
                width:100%;
            }
        }
        .title_type_1{
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            .i_small2{
                        width:8px;
                        height:8px;
                        background:#f55c5d;
            }
            .i_large2{
                        width:16px;
                        height:16px;
                        background:#00a1b4;   
                        margin-right:7px;             
            }            
            span{
                        padding:10px 25px;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 16px 0 0;
                        box-sizing:border-box;
            }
        }
        .header{
            width:1200px;
            height:380px;
            margin:10px auto 0;
            display:flex;
            background:rgb(64, 181, 206);
            flex-direction:row;
            border-radius:10px;
            color:#fff;
            span{
                font-size: 30px;
                text-align: center;
                margin: 50px auto 0;
                display: block;
            }
            .sw{
                width:765px;
                height:380px;
                border-radius:10px;
                overflow:hidden;
            }
            .header_detail{
                flex:1;
                margin:0 35px 0 24px;
                position: relative;
                p{
                    font-size:18px;
                    line-height:30px;
                    // color:#000000;
                    margin-top:20px
                }
                a{
                    width:180px;
                    height:36px;
                    font-size:26px;
                    color:#fff;
                    display:block;
                    background:#00a1b4;
                    text-align:center;
                    line-height:36px;
                    border-radius:5px;
                    position: absolute;
                    bottom:0;
                    left:50%;
                    margin-left:-90px;
                }
            }
        }

        .content{
            width:1200px;
            overflow:hidden;
            margin:70px auto;
            .cinemaLink1{
                width:197px;
                height:34px;
                font-size:25px;
                line-height:34px;
                text-align:center;
                color:#fff;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/cinema/cinematab_17.png) center center no-repeat;
                background-size:100% 100%;
                display:block;
                float:left;
                font-size:25px;
                color:#848484;
            }
            .cinemaLink1Active{
                width:197px;
                height:34px;
                font-size:25px;
                line-height:34px;
                text-align:center;
                color:#fff;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/cinema/cinematab_06.png) center center no-repeat;
                background-size:100% 100%;
                display:block;
                float:left;
                font-size:25px;
                color:#fff;
            }
            .cinemaLink2{
                width:197px;
                height:34px;
                font-size:25px;
                line-height:34px;
                text-align:center;
                color:#fff;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/cinema/cinematab_03.png) center center no-repeat;
                background-size:100% 100%;
                display:block;
                float:left;
                color:#848484;
                margin-left:-15px;
            }
            .cinemaLink2Active{
                width:197px;
                height:34px;
                font-size:25px;
                line-height:34px;
                text-align:center;
                color:#fff;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/cinema/cinematab_20.png) center center no-repeat;
                background-size:100% 100%;
                display:block;
                float:left;
                font-size:25px;
                color:#fff;
                margin-left:-15px;
            }
        }
        .cinemaList{
            width:1200px;
            margin:20px auto;
            .cinemaSeciton{
                width:580px;
                float:left;
                margin:10px;
                display:flex;
                flex-direction:row;
                .imgWrap{
                    flex:1;
                    img{
                        width:100%;
                    }
                }
                .textArea{
                    flex:1;
                    p{
                        font-size:16px;
                        line-height:30px;
                        color:red;
                    }
                }
            }
        }
    }
    @media(max-width:750px){
        width:100%;
        overflow:hidden;
        min-height:8.5rem;
        
        .none_img{
            width:100%;
            height:8rem;
            display:flex;
            align-items:center;
            overflow:hidden;
            img{
                width:100%;
            }
        }
        .title_type_1{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:0;
            text-indent:0;
            .i_small2{
                width:.16rem;
                height:.16rem;
                background:#f55c5d;
            }
            .i_large2{
                width:.3rem;
                height:.3rem;
                background:#00a1b4;   
                margin-right:.07rem;             
            }            
            span{
                padding:.1rem .4rem;
                background:#00a1b4;
                border-radius:5px;
                margin:0 .16rem 0 0;
                box-sizing:border-box;
            }
        }
        .header{
            width:100%;
            overflow:hidden;
            background:rgb(64, 181, 206);
            color:#fff;
            .sw{
                width:100%;
                // height:5.4rem;
                overflow:hidden;
            }
            .header_detail{
                width:7.1rem;
                margin:.5rem auto 0;
                span{
                    font-size:.35rem;
                }
                p{
                    font-size:.29rem;
                    line-height:.4rem;
                    color:#000000;
                    color:#fff;
                    margin-top:.2rem;
                }
                a{
                    width:100%;
                    height:.7rem;
                    font-size:0.4rem;
                    color:#fff;
                    display:block;
                    background:#00a1b4;
                    margin:.3rem auto;
                    text-align:center;
                    line-height:.7rem;
                    border-radius:.1rem;
                }
            }
        }
        .content{
            width:7.1rem;
            overflow:hidden;
            margin:.3rem auto;
            .cinemaLink1{
                width:2.7rem;
                height:.5rem;
                font-size:.34rem;
                line-height:.5rem;
                text-align:center;
                color:#fff;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/cinema/cinematab_17.png) center center no-repeat;
                background-size:100% 100%;
                display:block;
                float:left;
                color:#848484;
            }
            .cinemaLink1Active{
                width:2.7rem;
                height:.5rem;
                font-size:.34rem;
                line-height:.5rem;
                text-align:center;
                color:#fff;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/cinema/cinematab_06.png) center center no-repeat;
                background-size:100% 100%;
                display:block;
                float:left;
                color:#fff;
            }
            .cinemaLink2{
                width:2.7rem;
                height:.5rem;
                font-size:.34rem;
                line-height:.5rem;
                text-align:center;
                color:#fff;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/cinema/cinematab_03.png) center center no-repeat;
                background-size:100% 100%;
                display:block;
                float:left;
                color:#848484;
                margin-left:-0.2rem;
            }
            .cinemaLink2Active{
                width:2.7rem;
                height:.5rem;
                font-size:.34rem;
                line-height:.5rem;
                text-align:center;
                color:#fff;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/cinema/cinematab_20.png) center center no-repeat;
                background-size:100% 100%;
                display:block;
                float:left;
                color:#fff;
                margin-left:-0.2rem;
            }
        }
    }
`