import React,{Fragment} from 'react';
import {jsonp} from 'common/index'


class CommunicationDetail extends React.Component{
    render(){
        return(
            <Fragment>
                {
                    this.state.article?
                    <div>
                        <h2>{this.state.article.post_title}</h2>
                        <p>{this.state.article.post_date}</p>
                        <p dangerouslySetInnerHTML={{__html:this.state.article.post_content}}></p>
                    </div>
                    :null
                }

            </Fragment>
        )
    }
    constructor(props){
        super(props)
        this.state = {
            article:{}
        }
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    componentDidMount(){
        this.goTo()
        if(this.props.match.params.id){
            jsonp('/administrator/Portal/index/post_detail',{
                id:this.props.match.params.id
            }).then((res)=>{
                this.setState({
                    article:res.data
                })
            })   
        }
    }
}

export default CommunicationDetail