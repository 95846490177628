import { fromJS } from 'immutable'
import * as constants from './constants'

const service = fromJS({
    serviceArr:null
})

export default(state=service,action)=>{
    switch(action.type){
        case constants.SERVICE:
            return state.set('serviceArr',action.data)
        default:
            return state
    }
}