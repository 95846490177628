import React from 'react'
import {CaretLeftOutlined} from '@ant-design/icons';
import styles from '../index.module.scss'
import { changeImg } from '../pub'
import { NavLink } from 'react-router-dom'

const new_title = (font, id) => {
    return (
      <div
        dangerouslySetInnerHTML={{
            __html: font.split('|').join("<br />"),
        }} 
        className="new_title">
      </div>
    )
  }

export default class NewsCenter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          homeList: null,
          dateList: {},
          newsTab: 1,
          isBtn: true,
          isModalOpen:'none',
          mkArr: {wwmxd:[{
            term_id:''
          }],
          ssmd:[{}]},
          modelBtnImg:'',
          modelDiaImg:'',
          dcwjEWM: false
        }
    }
  changeNew(index) {
    this.setState({
      newsTab: index,
    })
  }
  render() {
    const { zxzxData, wjxxData} = this.props
    console.log('==========')
    return (
        <div className={styles.newsCenter}>
            <div className='content'>
                <div className="zxzx">
                    {new_title(
                        zxzxData.name,
                        1
                    )}
                    <div className="zxzx_list">
                    {zxzxData.articles.slice(0,5).map((item) => {
                        return (
                            item.posts_link?
                                <a className='zxzx_link' onClick={() => this.clickCount(item) } href={item.posts_link} target={item.posts_link.indexOf(location.host)>=0?'':'_blank'} >
                                {
                                    parseInt(item.istop, 10) > 0 ?
                                        <span className='zd'>
                                        <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zda.png`} alt=""/>
                                        </span> : ""
                                }
                                {item.post_title}
                                </a>
                                :
                        <NavLink
                            to={`/unionCon?articleId=${item.id}&termId=${zxzxData.term_id}`}
                            className="zxzx_link"
                            key={item.id}
                            title={item.post_title}
                        >
                            {
                            parseInt(item.istop, 10) > 0 ?
                                <span className='zd'>
                                <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zda.png`} alt=""/>
                                </span> :
                                ""
                            }
                            {item.post_title}
                        </NavLink>
                        )
                    })}
                    <NavLink className="more" to={`/unionNewsList?termId=${zxzxData.term_id}&typeId=${zxzxData.post_keywords}`}>
                        More
                    </NavLink>
                    </div>
                </div>
                <div className="zxzx">
                    {new_title(
                        wjxxData.name,
                        1
                    )}
                    <div className="zxzx_list">
                    {wjxxData.articles.slice(0,5).map((item) => {
                        return (
                            item.posts_link?
                                <a className='zxzx_link' onClick={() => this.clickCount(item) } href={item.posts_link} target={item.posts_link.indexOf(location.host)>=0?'':'_blank'} >
                                {
                                    parseInt(item.istop, 10) > 0 ?
                                        <span className='zd'>
                                        <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zda.png`} alt=""/>
                                        </span> : ""
                                }
                                {item.post_title}
                                </a>
                                :
                        <NavLink
                            to={`/unionCon?articleId=${item.id}&termId=${wjxxData.term_id}`}
                            className="zxzx_link"
                            key={item.id}
                            title={item.post_title}
                        >
                            {
                            parseInt(item.istop, 10) > 0 ?
                                <span className='zd'>
                                <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zda.png`} alt=""/>
                                </span> :
                                ""
                            }
                            {item.post_title}
                        </NavLink>
                        )
                    })}
                    <NavLink className="more" to={`/unionNewsList?termId=${wjxxData.term_id}&typeId=${wjxxData.post_keywords}`}>
                        More
                    </NavLink>
                    </div>
                </div>
            </div>
            
            <div className='contentYd'>
                <div className="news_wrap">
                    <div className="news_content">
                    <div className="news_part_1">
                        <div className="news_part_1_tab">
                        <div
                            dangerouslySetInnerHTML={{
                            __html: zxzxData.name.split('|').join("<br />"),
                            }} 
                            className={
                            this.state.newsTab === 1
                                ? 'news_tab_1 news_tab_1_active'
                                : 'news_tab_1'
                            }
                            onClick={() => this.changeNew(1)}
                        >
                        </div>
                        <div
                            dangerouslySetInnerHTML={{
                            __html: wjxxData.name.split('|').join("<br />"),
                            }} 
                            className={
                            this.state.newsTab === 2
                                ? 'news_tab_2 news_tab_2_active'
                                : 'news_tab_2'
                            }
                            onClick={() => this.changeNew(2)}
                        >
                        </div>
                        </div>
                        {this.state.newsTab === 1 ? (
                        <div className="hdyg">
                            <div className="hdyg_list">
                            {zxzxData.articles.slice(0,5).map((item) => {
                                return (
                                    item.posts_link?
                                        <a className='hdyg_link' onClick={() => this.clickCount(item) } href={item.posts_link} target={item.posts_link.indexOf(location.host)>=0?'':'_blank'} >
                                        <div className="article">
                                            <i></i>
                                            {
                                            parseInt(item.istop, 10) > 0 ?
                                                <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zda.png`} alt=""/> :
                                                ""
                                            }
                                            <span>{item.post_title}</span>
                                        </div>
                                        <div className="time">
                                            <div className="year">
                                            {item.post_date}
                                            </div>
                                        </div>
                                        </a>
                                        :
                                <NavLink
                                    to={`/unionCon?articleId=${item.id}&termId=${zxzxData.term_id}`}
                                    className="hdyg_link"
                                    key={item.id}
                                >
                                    <div className="article">
                                    <i></i>
                                    {
                                        parseInt(item.istop, 10) > 0 ?
                                        <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zda.png`} alt=""/> :
                                        ""
                                    }
                                    <span>{item.post_title}</span>
                                    </div>
                                    <div className="time">
                                    <div className="year">
                                        {item.post_date}
                                    </div>
                                    </div>
                                </NavLink>
                                )
                            })}
                            </div>
                        </div>
                        ) : (
                        <div className="tzgg">
                            <div className="tzgg_list">
                            {wjxxData.articles.slice(0,5).map((item) => {
                                return (
                                    item.posts_link?
                                        <a className='hdyg_link' onClick={() => this.clickCount(item) } href={item.posts_link} target={item.posts_link.indexOf(location.host)>=0?'':'_blank'} >
                                        <div className="article">
                                            <i></i>
                                            {
                                            parseInt(item.istop, 10) > 0 ?
                                                <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zda.png`} alt=""/> :
                                                ""
                                            }
                                            <span>{item.post_title}</span>
                                        </div>
                                        <div className="time">
                                            <div className="year">
                                            {item.post_date}
                                            </div>
                                        </div>
                                        </a>
                                        :
                                    <NavLink
                                        to={`/unionCon?articleId=${item.id}&termId=${wjxxData.term_id}`}
                                        className="hdyg_link"
                                        key={item.id}
                                    >
                                        <div className="article">
                                        <i></i>
                                        {
                                            parseInt(item.istop, 10) > 0 ?
                                            <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zda.png`} alt=""/> :
                                            ""
                                        }
                                        <span>{item.post_title}</span>
                                        </div>
                                        <div className="time">
                                        <div className="year">
                                            {item.post_date}
                                        </div>
                                        </div>
                                    </NavLink>
                                )
                            })}
                            </div>
                        </div>
                        )}
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
