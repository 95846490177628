import styled from 'styled-components'

export const SectionWrap = styled.div ` @media(min-width:750px) {
    width: 541px;
    margin: 10px;
    height: 170px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .hdbd_section_img_wrap {
        width: 270px;
        height: 170px;
        border-radius: 7px;
        overflow: hidden;
        position: relative;
        .zd{
            width:50px;
            position: absolute;
            top:10px;
            left:0;
            img{
                width:100%;
            }
        }
        img {
            width: 100%;
            min-height: 100%;
        }
    }
    .hdbd_section_detail {
        width: 252px;
        height: 170px;
        display: flex;
        text-align: left;
        flex-direction: column;
        justify-content: space-between;

        .hdbd_section_detail_title {
            font-size: 22px;
            color: #221815;
            line-height: 30px;
            width: 100%;
        }

        .hdbd_section_subtitle{
            font-size: 22px;
            color: #666666;
            letter-spacing: 0;
            text-align: justify;
        }
        .hdbd_section_date {
            width: 100%;
            color: #009cbe;
            font-size: 15px;
        }
    }

}

@media(max-width:750px) {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: .27rem 0;
    border-bottom: 0.01rem dashed #a0a0a0;

    .hdbd_section_img_wrap {
        width: 2.45rem;
        height: 1.5rem;
        border-radius: .1rem;
        overflow: hidden;
        position: relative;
        .zd{
            width:.8rem;
            position: absolute;
            top:.1rem;
            left:0;
            img{
                width:100%;
            }
        }
        img {
            width: 100%;
            min-height: 100%;
        }
    }

    .hdbd_section_detail {
        flex: 1;
        margin-left: .1rem;
        display: flex;
        align-items: center;
        text-align: left;
        flex-direction: column;
        height: 1.5rem;
        justify-content: space-between;

        .hdbd_section_detail_title {
            font-size: .29rem;
            color: #000;
            line-height: .4rem;
            width: 100%;
        }

        .hdbd_section_date {
            width: 100%;
            text-align: left;
            color: #00a1b4;
            font-size: .28rem;
        }
    }
}

`
