import React from 'react'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'
import './style.css'
import {NavLink} from 'react-router-dom'

const swSection = (arr,pa,sw) =>{
    if(arr.length){
        return(     
                <div className={sw}>
                    <div className="swiper-wrapper">                       
                        {                     
                            arr.map((item)=>{
                                let _style = {
                                    backgroundImage:'url('+item.path+')'
                                }
                                return(
                                    <div className="swiper-slide xwzxsl" key={item.id} style={_style}>      
                                        <a className='sw_link' href={item.posts_link}>
                                        </a>   
                                    </div>
                                )
                            })                   
                        }             
                    </div>
                    {
                        arr.length>1?<div className={pa}></div>:<div className="swiper-pagination opa"></div>
                    }
                </div>            
        )

    }
}
class BannerCarousel extends React.Component{
    constructor(props){
        super(props)
        this.state={
            swArr:[],
            paginationM:"swiper-pagination opa "+'pa'+Math.floor((Math.random()*10000)),
            sw:"swiper-container swiper-container-banner "+'sw'+Math.floor((Math.random()*10000))
        }
    }
    componentDidMount() {
        var _name ='.'+this.state.paginationM.split('opa ')[1]
        var _sw = '.'+this.state.sw.split('swiper-container-banner ')[1]
        new Swiper(_sw, {
            loop: false,
            pagination: {
                el:_name
            }
        }) 
    }
    render() {
        return (
            <div className={this.props.className}>
                {
                    swSection(this.props.Carouselarr,this.state.paginationM,this.state.sw)
                }
            </div>

        )
    }
}

export default BannerCarousel