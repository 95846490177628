import React from 'react';
import {KxcbWrap} from './style'
import CommonCarouselTitle from 'common/qthdSlider.js'
import {Skeleton} from 'antd'
import {NavLink} from 'react-router-dom'
import NavSection from 'common/navSection'
import SectionWrap from 'common/newSection'

export const title_type_1 = (font)=>{
    return(
        <div className='title_type_1'>
            <span>{font}</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>
        </div>
    )
}
export const title_type_2 = (font)=>{
    return(
        <div className='title_type_2'>
            <span>{font}</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>
        </div>
    )
}
export const title_type_3 = (font,id)=>{
    if(id>0){
        return (<NavLink style={{color:'#FFF'}} className='title_type_3 nav_title' to={'/list/'+id}>
            <i className='i_small'></i>
            <i className='i_large'></i>
            <span>{font}</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>
        </NavLink>)
    }
    return(
        <div className='title_type_3'>
            <i className='i_small'></i>
            <i className='i_large'></i>
            <span>{font}</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>
        </div>
    )
}
class Kxcbds extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            sdkpjtArr:null,
            routerLink:{

            }
        }
    }
    componentDidMount(){
        this.sdkpjtInit()
        document.title = '北京科学传播大赛'
    }
    zxyd(arr){
        let zxydList = []
        for(let item in arr){
            if(item !== 'cat_name'){
                zxydList.push(arr[item])
            }
        }
        return zxydList
    }
    sdkpjtInit(){
        React.$request({
            url:'Portal/index/education_son',
            method:'get',
            params:{
                activity:181
            }
          }).then(res=>{
            this.setState({
                sdkpjtArr:res.data
            })
          })
    }
    render(){
        return(
            this.state.sdkpjtArr?
            <KxcbWrap>
                <NavSection />
                <div className="header">
                    <div className="video">
                        <video src={this.state.sdkpjtArr.sp[0].video} controls='controls' poster={this.state.sdkpjtArr.sp[0].path}></video>
                    </div>
                    <div className="header_detail">
                        {
                            title_type_1("活动概况")
                        }
                        <p dangerouslySetInnerHTML={{__html:this.state.sdkpjtArr.edu_cbds[0].post_excerpt}}></p>
                        <NavLink to={'/article/'+this.state.sdkpjtArr.edu_cbds[0].id} className='more'>更多</NavLink>
                    </div>
                </div>
                {
                    (this.state.sdkpjtArr.dsgg && this.state.sdkpjtArr.dsgg.length > 0)?
                    <div className="dsgg_wrap">
                        <div className='edu_more'>
                            {
                                title_type_3("大赛公告",299)
                            }
                            <NavLink className='edu_more_link' to={'/list/299'}>更多</NavLink>
                        </div>
                        <div className="dsgg">
                            {
                                this.state.sdkpjtArr.dsgg.map((item,index) => {
                                    if(index<=3){
                                        return <SectionWrap secarr={item} key={item.id}></SectionWrap>
                                    }
                                })
                            }
                        </div>
                    </div>:null
                }
                <div className="jcsjWrap">
                    {
                        title_type_3("精彩瞬间")
                    }
                    <CommonCarouselTitle className='sw'  Carouselarr={this.zxyd(this.state.sdkpjtArr.jcsj)} />
                </div>
                <div className="sdkp_hdbd">
                    {
                        title_type_3("荣耀时刻")
                    }
                    {/* <div className="hdbd_wrap">
                        {
                            this.zxyd(this.state.sdkpjtArr.rysk).map((item)=>{
                                return(
                                    <SectionWrap secarr={item} key={item.id}/>
                                )
                            })
                        }
                    </div> */}
                    <div className="edu_jxhd">
                        <div className="edu_jhhd_wrap">
                            {
                                this.zxyd(this.state.sdkpjtArr.rysk).slice(0,6).map((item)=>{
                                    let _style = {
                                        backgroundImage:"url("+item.path+")"
                                    }
                                    return(
                                        <NavLink key={item.id} className='jxhd_section' style={_style} to={'/article/'+item.id}>
                                            <div className="jxhd_section_p">{item.post_title}</div>
                                        </NavLink>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="edu_jxhdM">
                        <div className="edu_jhhd_wrap">
                            {
                                this.zxyd(this.state.sdkpjtArr.rysk).slice(0,6).map((item)=>{
                                    let _style = {
                                        backgroundImage:"url("+item.path+")"
                                    }
                                    return(
                                        <NavLink key={item.id} className='jxhd_section' to={'/article/'+item.id}>
                                            <div className="jxhd_section_bg" style={_style}></div>
                                            <div className="hdbd_section_detail">{item.post_title}</div>
                                        </NavLink>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>
            </KxcbWrap>
            :<Skeleton active />
        )
    }
}
export default Kxcbds
