import React from 'react';
import {connect} from 'react-redux';
import {withRouter,NavLink} from 'react-router-dom';
import { actions } from './../../store'
import {Qwwx} from './style'
import CommonCarousel from 'common/carousel.js'
import {LeftImg,RightImg,LeftImg2} from './../exhcommon'
import {title_type_1} from './../index/index'
import {Skeleton} from 'antd'
import NavSection from 'common/navSection'

class ExQwwx extends React.Component{
    componentDidMount(){
        this.goTo()
        if(!this.props.exhQwwxArr){
            this.props.getExQwwx()
        }
    }
    zxyd(arr){
        let zxydList = []
        for(let item in arr){
            if(item !== 'cat_name'){
                zxydList.push(arr[item])
            }
        }
        console.log(zxydList)
        return zxydList
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    render(){
        return(
            this.props.exhQwwxArr?
            <Qwwx>
                <NavSection />
                <div className="wqhg_wrap">
                    {
                        this.zxyd(this.props.exhQwwxArr.qwwx).map((item)=>{
                            return(
                                <NavLink key={item.id} className='wqhg_section' to={'/article/'+item.id}>
                                    <div className="wqhg_section_img_wrap">
                                        <img src={item.path} alt='...' />
                                        <div className="wqhg_section_p">
                                            {item.post_title}
                                        </div>
                                    </div>
                                </NavLink>
                            )
                        })
                    }
                </div>
            </Qwwx>
            :<Skeleton active />
        )
    }
}
const mapStateToProps = (state)=>{
    return{
        exhQwwxArr:state.getIn(['exhibition','qwwx'])
    }
}
const mapDispatchToProps = (dispatch)=>{
    return{
        getExQwwx(){
            dispatch(actions.getExhQwwx())
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExQwwx))
