import React from 'react'
import { TalentPlanWrap,EduWrap,EduOtherWrap } from './style'
import CommonCarousel from 'common/carousel.js'
import SectionWrap from './newSection'
import { Link, NavLink } from 'react-router-dom'
import NavSection from 'common/navSection'
import VideoList from './video'
import Msection from 'common/Msection'
import {Empty, LocaleProvider, Modal, Pagination, Skeleton} from 'antd'
import zhCN from "antd/es/locale-provider/zh_CN";
import {KxcbWrap} from "../education/components/qshd/style";

class TalentPlan extends React.Component {
    state={
        columnList:null,
        listData:{},
        yc:423,
        currentVideo: '',
        currentVideoName: '',
        currentId: '',
        poster:'',
        visible:false,
        otherArr:[
            {
                id:1,
                post_title: "",
                path:`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/ycjh/yckc1.jpg`
            },
            {
                id:2,
                post_title: "",
                path:`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/ycjh/yckc2.jpg`
            },
            {
                id:3,
                post_title: "",
                path:`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/ycjh/yckc3.jpg`
            },
            {
                id:4,
                post_title: "",
                path:`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/ycjh/yckc4.jpg`
            }
        ]
    }
    title_type_1 = (font,path)=>{
        return(
            <NavLink className='title_type_1 nav_title' to={path}>
                    <i className='i_small'></i>
                    <i className='i_large'></i>
                    <span>{font}</span>
                    <i className='i_large2'></i>
                    <i className='i_small2'></i>
            </NavLink>
        )
    }
    title_type_10 = (font) => {
        return (
        <div className='title_type_1'>
            <i className='i_small'></i>
            <i className='i_large'></i>
            <span>{font}</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>
        </div>
        )
    }
    title_type_11 = (font) => {
        return (
            <div className='title_type_1' style={{margin:'10px auto 20px',fontSize:'32px',justifyContent:'flex-start'}}>
            <span>{font}</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>
        </div>
        )
    }

    
    title_type_2 = (font) => {
        const styleObj={}
        return <div className="title_type_2" style={styleObj} dangerouslySetInnerHTML = {{ __html : font}}></div>
    }

    turnItemData=(data)=>{

    }

    initEduIndex(){
        React.$request({
            url:'Portal/zt/getTermTrees',
            method:'get',
            params: {
                termId:422
            }
        }).then(res=>{
            this.setState({columnList:res.data})
            res.data.son.map(el=>{
                this.getListData(el)
                if(el.son.length!=0){
                    el.son.map(pl=>{
                        this.getListData(pl)
                    })
                }
            })
        })
    }
    getListData(val){
        React.$request({
            url:'Portal/zt/getListsByTermId',
            method:'get',
            params: {
                termId:val.term_id,
                page:1,
                limit:val.term_id=='426'?50:4,
            }
        }).then(res=>{
            const {listData}=this.state
            listData[val.term_id]=res.data
            this.setState({listData:listData})
        })
    }
    componentWillMount(){
        document.title = '英才学堂'
        this.initEduIndex()
    }
    changeVideo(item) {
        this.setState({
            currentVideo:`${process.env.REACT_APP_IMGBASE}/administrator/data/`+ item.smeta.thumb,
            currentVideoName: item.post_title,
            currentId: item.id,
            poster: item.smeta.photo[0].url,
            visible:true
        })
    }
    setVisible(flag){
        this.setState({
            visible:flag
        })
    }
  render() {
    return this.state.columnList?(
        <EduWrap>
            <NavSection/>
            <EduOtherWrap>
                <div className="header">
                    <CommonCarousel className='sw'  Carouselarr={this.state.otherArr} />

                    <div className="header_detail">
                        {this.title_type_11('英才学堂')}
                        <p>“英才学堂”栏目整合北京市知名高校及市科协系统优质科普课程资源，为广大英才学子及社会同龄青少年群体长期、持续打造在线学习平台，及时提供丰富的学习资源，为科研实践储备充裕的基础知识。栏目同时也为广大社会观众提供了中国科协及北京高校组织开展英才计划项目的宣传视频、图片，项目解读及培养取得的部分优秀案例等信息，便于更多的人了解英才计划，加入英才计划。</p>
                    </div>
                </div>
            </EduOtherWrap>
            {/* 科研基础课程 */}
            <TalentPlanWrap>
                <Modal
                  getContainer={false}
                  visible={this.state.visible}
                  title={this.state.currentVideoName} width={800} footer={null} onCancel={() => this.setVisible(false)}>
                  <div className="video">
                      {
                          <video src={this.state.currentVideo} controls='controls' poster={this.state.poster}></video>
                      }
                  </div>
                </Modal>
                  
                <div className="edu_jxhd paginationWrap">
                    <div className='edu_more'>
                        {
                            this.title_type_1(this.state.columnList.son[0].name, '/talentPlan?yc=423')
                        }
                        <NavLink className='edu_more_link' to={`talentPlan?yc=423`}>更多</NavLink>
                    </div>
                    <div className="other_section_wrap">
                        {
                             this.state.listData[423] ? (
                                this.state.listData[423].lists.map((item,index)=>{
                                    return(
                                        <div key={index} className='ycjh_section' onClick={() => this.changeVideo(item)} >
                                            <div className="ycjh_section_img_wrap" >
                                                <img src={item.smeta.photo[0].url?item.smeta.photo[0].url:`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/header/logodemo_02.jpg`} alt=""/>
                                            </div>
                                            <div className="ycjh_section_detail">
                                                <div className="ycjh_section_detail_title">{item.post_title}</div>
                                                <div className='ycjh_section_date'>{item.post_date}</div>
                                            </div>
                                        </div>
                                    )
                                })
                             ):<Skeleton active />
                        }

                    </div>
                </div>
            </TalentPlanWrap>
            {/* 市科协优质科普课程资源 */}
            <div className="edu_jxhd">
                <div className='edu_more'>
                {this.title_type_10(this.state.columnList.son[1].name)}
                </div>
            </div>
            <EduOtherWrap>
                <div className="listWrapYjkx">
                  <div className="paginationWrap02">
                    <div className="paginationWrap_title">
                      <NavLink to={'/list2/428'}>{this.title_type_2('科学家精神系列课堂')}</NavLink>
                      <div className="line"></div>
                      <Link className="gdBtn" to={'/list2/428'}>更多</Link>
                    </div>
                    <div className="other_section_wrap">
                        {
                            this.state.listData[428]?(
                                this.state.listData[428].lists.slice(0,2).map((item) => {
                                    return <SectionWrap secarr={item} key={item.id}></SectionWrap>
                                })
                            ):<Skeleton active />
                        }

                    </div>
                  </div>
                  <div className="paginationWrap02">
                    <div className="paginationWrap_title">
                      <NavLink to={'/list/429'}>{this.title_type_2('基础五学科系列课程')}</NavLink>
                      <div className="line"></div>
                      <Link className="gdBtn" to={'/list2/429'}>更多</Link>
                    </div>
                    <div className="other_section_wrap">
                        {
                            this.state.listData[429]?(
                                this.state.listData[429].lists.slice(0,2).map((item) => {
                                    return <SectionWrap secarr={item} key={item.id}></SectionWrap>
                                })
                            ):<Skeleton active />
                        }
                    </div>
                  </div>
                </div> 
            </EduOtherWrap>
            {/* 英才计划宣传片 */}
            <div className="edu_jxhd">
                <div className='edu_more'>
                    {
                        this.title_type_1(this.state.columnList.son[2].name, '/list/' + this.state.columnList.son[2].term_id)
                    }
                    <NavLink className='edu_more_link' to={'/list/'+this.state.columnList.son[2].term_id+'?yc=426'}>更多</NavLink>
                </div>
            {
                this.state.listData[426]?
                    <VideoList {...this.state.listData[426]}/>
                :null
            }
            </div>
          

            {/* 优秀导师学生案例 */}
            <div className="edu_jxhd">
                <div className='edu_more'>
                    {
                        this.title_type_1(this.state.columnList.son[3].name, '/list/' + this.state.columnList.son[3].term_id)
                    }
                    <NavLink className='edu_more_link' to={'/list/'+this.state.columnList.son[3].term_id+'?yc=427'}>更多</NavLink>
                </div>
                <div className="edu_jhhd_wrap">
                    {
                         this.state.listData[427] ? (
                            this.state.listData[427].lists.map((item)=>{
                                return(
                                    <SectionWrap secarr={item} key={item.id}/>
                                )
                            })
                         ):<Skeleton active />
                    }

                </div>
            </div>
        </EduWrap> 
    ):<Skeleton active />
  }
}
export default TalentPlan
