import React from 'react';


import Home from './component/home'
import List from './component/list'
import Detail from './component/detail'

import {NavLink,Switch,Route,Redirect} from 'react-router-dom'
import { actions } from './store'

import {WeifuwuRoute,WeifuwuWrap} from './style'

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';



class Weifuwu extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            bannerUrl:null
        }
    }
    componentDidMount(){
        document.title = '微服务'
        this.goTo()
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    render(){
        return(
            <WeifuwuWrap>
                <div className="weifuwu_content">
                    <WeifuwuRoute>
                        <Switch>
                                <Route path='/weifuwu/home/:termid' component={Home}/>
                                <Route path='/weifuwu/list/:termid' component={List}/>
                                <Route path='/weifuwu/detail/:id' component={Detail}/>
                        </Switch>
                    </WeifuwuRoute>
                </div>
            </WeifuwuWrap>
        )
    }
}
const mapStateToProps = (state)=>{
    return{
        bannerUrl:state.getIn(['weifuwu'])
    }
}
const mapDispatchToProps = (dispatch)=>{
    return{
        get_Banner(){
            dispatch(actions.getBgxxBanner())
        }
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Weifuwu))
