import React from 'react';
import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import { actions } from './../../store'
import CommonCarousel from 'common/carousel.js'
import {ExhibitionIndexWrap} from './style'
import ExhZzt from './components/zzt'
import ExhEtly from './components/etly'
import ExhLszl from './components/lszl'
import ExhQwwx from './components/qwwx'
import ExhSdcx from './components/sdcxcgz'
import { Skeleton } from 'antd';
import NavSection from 'common/navSection'

export const title_type_1 = (font)=>{
    return(
        <div className='title_type_1'>
            <i className='i_small'></i>
            <i className='i_large'></i>
            <span>{font}</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>  
        </div>
    )
}
const title_type_2 = (font)=>{
    return(
        <div className='title_type_2'>
            <i className='i_small'></i>
            <i className='i_large'></i>
            <span>{font}</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>  
        </div>
    )
}

class ExIndex extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            kjzp_p_flag:true,
            cinemaArr:{
                lp:'',
                yyjs:[{
                    post_excerpt:''
                }]
            },
        }
    }
    componentDidMount(){
        if(!this.props.exhibitionIndex){
            this.props.getExhIndex()
        }
        this.initCinema()
    }
    initCinema (){
        

        React.$request({
            url:'Portal/index/cinema',
            method:'get',
          }).then(res=>{
            this.setState({
                cinemaArr: res.data,
              })
          })

      }
    zxyd(arr){
        let zxydList = []
        for(let item in arr){
            if(item !== 'cat_name'){
                zxydList.push(arr[item])
            } 
        }
        return zxydList
    }
    kjqz(arr){
        let exhkjzq = []
        arr.map((item,index)=>{
            let _arr = {}
            _arr.id = 'kjqz_'+index
            _arr.path = item
            exhkjzq.push(_arr)
        })
        return exhkjzq
    }
    kjzp_more(){
        this.setState({
            kjzp_p_flag:!this.state.kjzp_p_flag
        })
    }
    toVidelPage(){
            
    }
    render(){
        return(
            this.props.exhibitionIndex?
            <ExhibitionIndexWrap>
                <NavSection />
                <CommonCarousel className='sw'  Carouselarr={this.props.exhibitionIndex.lp} />
                <CommonCarousel className='swM'  Carouselarr={this.props.exhibitionIndex.lp} />
                <NavLink to='/exhibition/zzt' className='nav_title'>
                    {title_type_1('主展馆')}
                </NavLink>
                {
                    ExhZzt(this.zxyd(this.props.exhibitionIndex.zl))
                }
                <NavLink to='/exhibition/etly' className='nav_title'>
                    {title_type_1('儿童乐园')}
                </NavLink>
                {
                    ExhEtly(this.zxyd(this.props.exhibitionIndex.etly))
                }

                <NavLink to='/cinema/thismonth' className='nav_title'>
                    {title_type_2('特效影院')}
                </NavLink>
                <div className='kjzq'>
                <NavLink to='/cinema/thismonth'>
                <CommonCarousel
                    onClick={()=>this.toVidelPage()}
                    className="kjzqsw"
                    Carouselarr={this.state.cinemaArr.lp}
                  />
                </NavLink>
                  
                   <p className='kjzp_p'>{this.state.cinemaArr.yyjs[0].post_excerpt}</p>
                    {
                        this.state.kjzp_p_flag?<p className='kjzp_p_m kjzp_p_cut'>{this.state.cinemaArr.yyjs[0].post_excerpt}</p>:
                        <p className='kjzp_p_m kjzp_p_all'>{this.state.cinemaArr.yyjs[0].post_excerpt}</p>
                    }
                </div>

                <NavLink to='/exhibition/qwwx' className='nav_title'>
                    {title_type_2('趣玩无限')}
                </NavLink>
                <ExhQwwx
                    Carouselarr={this.zxyd(this.props.exhibitionIndex.qwwx)}
                    className="zzry_sw"
                />
                
                <NavLink to='/list/52' className='nav_title'>
                    {title_type_2('短期展')}
                </NavLink>     
                {/* <div to='/list/52' className='nav_title2'>
                    {title_type_2('临时展览')}
                </div>        */}
                {

                    ExhLszl(this.zxyd(this.props.exhibitionIndex.lszp))
                }

                {title_type_1('科教专区')}
                <div className='kjzq'>
                    <CommonCarousel className='kjzqsw'  Carouselarr={this.zxyd(this.props.exhibitionIndex.kjzq)} />
                    <p className='kjzp_p'>{this.props.exhibitionIndex.kjzq[0].post_excerpt}</p>
                    {
                        this.state.kjzp_p_flag?<p className='kjzp_p_m kjzp_p_cut'>{this.props.exhibitionIndex.kjzq[0].post_excerpt.substring(0,60)}<span className='more' onClick={()=>this.kjzp_more()}>[更多]</span></p>:
                        <p className='kjzp_p_m kjzp_p_all'>{this.props.exhibitionIndex.kjzq[0].post_excerpt}</p>
                    }
                </div>

                <NavLink to='/exhibition/sdcxkjz' className='nav_title'>
                    {title_type_2('首都创新成果展')}
                </NavLink>
                {
                    ExhSdcx(this.zxyd(this.props.exhibitionIndex.sdgz))
                }
               
            </ExhibitionIndexWrap>
            :<Skeleton active />
        )
    }
}
const mapStateToProps = (state)=>{
    return{
        exhibitionIndex:state.getIn(['exhibition','exhibitionIndex'])
    }
}
const mapDispatchToProps = (dispatch)=>{
    return{
        getExhIndex(){
            dispatch(actions.getExhibition())
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExIndex))