import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import { actions } from './../../store'
import {ZkgkWrap} from './style'
import { Skeleton } from 'antd';

class Zxgk extends React.Component{
    constructor(props){
        super(props)
        this.state = {
        }
    }
    componentDidMount(){
        document.title = '中心概况'
        if(this.props.zxgkDetail){
        }else{
            this.props.get_Zxgk()
        } 
        this.goTo()
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    render(){
        return(
            <ZkgkWrap>
                <h1>中心概况</h1>
                {
                    this.props.zxgkDetail?                
                    <p dangerouslySetInnerHTML={{__html:this.props.zxgkDetail.post_content}}>
                    </p>:
                    <Skeleton active />
                }
            </ZkgkWrap>
        )
    }
}
const mapStateToProps = (state)=>{
    return{
        zxgkDetail:state.getIn(['library','zxgk'])
    }
}
const mapDispatchToProps = (dispatch)=>{
    return{
        get_Zxgk(){
            dispatch(actions.getZxgk())
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Zxgk))