import React from 'react'
import { ClockCircleOutlined } from '@ant-design/icons';
import './nyrzZqyhDefault.scss'
import styles from './index.module.scss'
import { withRouter } from 'react-router-dom';
import qs from 'query-string'
import Crumbs from './compontents/crumbs';


class NyrzZqyhContent extends React.Component {
  state={
    articleCon:null,
    termId:0,
    articleId:0
  }

  componentDidMount() {
    const { search } = this.props.location
    const { termId, articleId } = qs.parse(search)
    this.setState({
        termId:termId,
        articleId:articleId
    },()=>{
        this.getArticleData()
    })
    
  }
  back=()=>{
    this.props.history.goBack()
  }

  getArticleData() {
    const { articleId } = this.state
    React.$request({
      url: 'Portal/index/post_detail',
      method: 'get',
      params: {
        id: articleId
      }
    }).then(res => {
      this.setState({ 'articleCon': res.data })
    })
  }

  render() {
    const {articleCon,termId,articleId}=this.state
    return (
        articleCon!=null?
        <div className={styles.contentStyle}>
            <Crumbs termId={termId} articleId={articleId}/>
            <p className='title'>{articleCon.post_title}</p>
            <p className='time'><ClockCircleOutlined />&nbsp;&nbsp;{articleCon.post_date}</p>
            <div className='main' dangerouslySetInnerHTML={{ __html: articleCon.post_content }}>
            </div>
            <div className='ljfhBtn' onClick={()=>this.back()}>返回</div>
        </div>:''
    )
  }
}
export default withRouter(NyrzZqyhContent)
