import React from 'react'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'
import '../home/carousel/style.css'
import {NavLink} from 'react-router-dom'

const swSection = (arr) =>{
    if(arr.length){
        return(     
                <div className="swiper-container swiper-container-jxhd">
                    <div className="swiper-wrapper">                       
                        {                     
                            arr.map((item,index)=>{
                                let _style = {
                                    background:'url('+item+')'
                                }
                                return(
                                    <div className="swiper-slide swiper-slide-jxhd" key={index} style={_style}>  
                                        <a href={item.posts_link} className='navlink'>
                                            <div className="swMask"></div>    
                                            <div className="title" style={{'background': `url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat`,'backgroundSize':'100% 100%'}}>
                                                <span className='swjxhdindex'><i>{index+1}</i>/{arr.length}</span><span className='swjxhdtitle'></span> 
                                            </div>  
                                        </a>
                                    </div>
                                )
                            })                   
                        }             
                    </div>
                </div>            
        )
    }
}
class JxhdCarousel extends React.Component{
    constructor(props){
        super(props)
        this.state={
            swArr:[]
        }
    }
    componentDidMount() {
        new Swiper('.swiper-container-jxhd', {
            slidesPerView:2,
            spaceBetween:0,
            centeredSlides: true,
            loop: true      
        }) 
    }
    render() {
        const Carouselarr=[
            `${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/ycjh/ycjh-swiper1.png`,
            `${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/ycjh/ycjh-swiper2.png`,
            `${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/ycjh/ycjh-swiper3.png`,
            `${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/ycjh/ycjh-swiper4.png`
        ]
        return (
            <div>
                {
                    swSection(Carouselarr)
                }
            </div>

        )
    }
}

export default JxhdCarousel