import React from 'react';
import {ExIndexZzt} from './style'
import {NavLink} from 'react-router-dom'
const ExhZzt = (arr)=>{
    if(arr){
        return(
            <ExIndexZzt>
                    {
                        arr.map((item,index)=>{
                            return(
                                <NavLink key={item.id} className='zzt_img_wrap ex_index' to={'/exhibition/zzt/tag_'+(index+1)}>
                                    <img src={item.path} alt=''/>
                                </NavLink>
                            )
                        })   
                    }
                    {
                        arr.map((item,index)=>{
                            return(
                                <NavLink key={item.id} className='zzt_img_wrap ex_m' to={'/exhibition/zzt/tag_'+(index+1)}>
                                    <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/t`+(index+1)+'.png'} alt=''/>
                                </NavLink>
                            )
                        })   
                    }
            </ExIndexZzt>       
        )

    }
}
export default ExhZzt