import React, { memo, useEffect, useState } from 'react'
import { JnhWrap } from './style'
import NavSection from 'common/navSection'
import List from './list'
import List2 from './list2'
import Xkj from './xkh'
import Dyz from './dyz'
import Kxsgp from './kxsgp'
import Mryx from './mryx'
import Dyz2 from './dyz2'
import { NavLink } from 'react-router-dom'
const Jnh = memo((props) => {
  const [jnharr, setJnhArr] = useState({})
  const [videoUrl, setVideoUrl] = useState('')
  const [videoFlag, setVideoFlag] = useState(false)
  useEffect(() => {

    React.$request({
      url:'Portal/index/education_son',
      method:'get',
      params:{
        activity: 195,
      }
    }).then(res=>{
      console.log(res.data)
      setJnhArr(res.data)
    })

    // axios
    //   .get('http://tp5.scimall.org/portal/clicks/index?h5Name=swjlyn')
    //   .then((res) => {
    //     console.log(res)
    //   })
  }, [])
  const zxyd = (arr) => {
    let zxydList = []
    for (let item in arr) {
      if (item !== 'cat_name') {
        zxydList.push(arr[item])
      }
    }
    return zxydList
  }
  const videoopen = (url) => {
    setVideoUrl(url)
    setVideoFlag(true)
  }
  const goto = (dom) => {
    document.querySelector('.' + dom).scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }
  return (
    <JnhWrap>
      {/* <NavSection /> */}
      <div className="banner">
        <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/jnh/banner_02.png`} alt="" />
      </div>
      <div className="mbanner">
        <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/jnh/mbanner.jpg`} alt="" />
      </div>
      <div className="nav">
        <div className="nbtn" onClick={() => goto('cm1')}>
          <div className="font">小球大世界</div>
          <div className="ball"></div>
        </div>
        <div className="nbtn" onClick={() => goto('cm2')}>
          <div className="font">线上云课堂</div>
          <div className="ball"></div>
        </div>
        <div className="nbtn" onClick={() => goto('cm3')}>
          <div className="font">互动与实践</div>
          <div className="ball"></div>
        </div>
        <div className="nbtn" onClick={() => goto('cm4')}>
          <div className="font">小空间大科学</div>
          <div className="ball"></div>
        </div>
        <div className="nbtn" onClick={() => goto('cm5')}>
          <div className="font">科普实验室</div>
          <div className="ball"></div>
        </div>
        <div className="nbtn" onClick={() => goto('cm5')}>
          <div className="font">科普展览室</div>
          <div className="ball"></div>
        </div>
        <div className="nbtn" onClick={() => goto('cm12')}></div>
        <div className="nbtn" onClick={() => goto('cm8')}></div>
        <div className="nbtn" onClick={() => goto('cm7')}></div>
        <div className="nbtn" onClick={() => goto('cm9')}>
          <div className="font">云上观影</div>
          <div className="ball"></div>
        </div>
        <div className="nbtn" onClick={() => goto('cm10')}>
          <div className="font">科学秀场</div>
          <div className="ball"></div>
        </div>
        <div className="nbtn" onClick={() => goto('cm11')}></div>
      </div>
      <div className="commonBox">
        {jnharr.kpdjs && <Dyz2 sliderArr={jnharr.hys} kmysArr={jnharr.hdbd} />}
      </div>
      <a
        className="dkx_bot cm12"
        href="http://h5.dangdang.com/mix_20200916_wk3d"
        target="_blank"
      >
        <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/jnh/dkx_bot_03.png`} alt=""/>
      </a>
      <div className="commonBox cm1">
        <div className="h2 cm11">
          <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/jnh/title_01.png`} alt="" />
        </div>
        {jnharr.kpdjs && <List listarr={zxyd(jnharr.kpdhc)[7]} />}
      </div>
      <div className="commonBox cm2">
        {jnharr.kpdjs && <List listarr={zxyd(jnharr.kpdhc)[6]} />}
      </div>
      <div className="commonBox cm3">
        {jnharr.kpdjs && <List2 listarr={zxyd(jnharr.kpdhc)[5]} />}
      </div>
      <div className="commonBox cm4">
        {jnharr.kpdjs && <Xkj listarr={zxyd(jnharr.kpdhc)[4]} />}
      </div>
      <div className="dkx">
        <div className="container">
          <div className="section_nav_3">
            {jnharr.kpdjs && (
              <>
                {zxyd(jnharr.kpdhc)[2].list.map((item) => {
                  return (
                    <NavLink
                      className="section_comm_3"
                      to={'/article/' + item.id}
                      key={item.id}
                    >
                      <div className="x_img_3">
                        <img src={item.path} alt=''/>
                      </div>
                      <div className="mask">{item.post_title}</div>
                    </NavLink>
                  )
                })}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="commonBox cm5">
        {jnharr.kpdjs && <List listarr={zxyd(jnharr.kpdhc)[3]} pd={0} />}
        {jnharr.kpdjs && <List listarr={zxyd(jnharr.kpdhc)[1]} />}
      </div>
      <div className="dkx">
        <div className="container">
          <div className="section_nav_3">
            {jnharr.kpdjs && (
              <>
                {zxyd(jnharr.kpdhc)[0].list.map((item) => {
                  return (
                    <NavLink
                      className="section_comm_3"
                      to={'/article/' + item.id}
                      key={item.id}
                    >
                      <div className="x_img_3">
                        <img src={item.path} alt=''/>
                      </div>
                      <div className="mask">{item.post_title}</div>
                    </NavLink>
                  )
                })}
              </>
            )}
          </div>
        </div>
      </div>
      {jnharr.kpdjs && (
        <Dyz sliderArr={zxyd(jnharr.dyz)[3]} kmysArr={zxyd(jnharr.dyz)[0]} />
      )}
      {jnharr.kpdjs && <Kxsgp kxsgpArr={zxyd(jnharr.dyz)[1]} />}
      <div className="commonBox cm9">
        {jnharr.kpdjs && <Mryx listarr={zxyd(jnharr.dyz)[2]} />}
      </div>
      <div className="commonBox cm8">
        <div className="mkygg">
          <div className="container">
            <div className="title">
              <img src="img/edu/jnh/title_03.png" />
            </div>
            <div className="mkygg_nav">
              <div className="container">
                <div className="more">
                  <a href="" target="_blank">
                    <span>+更多</span>
                  </a>
                </div>
                <div className="section_nav_3">
                  {jnharr.kpdjs &&
                    jnharr.mkygg.map((item) => {
                      return (
                        <a className="section_comm_3" key={item.id}>
                          <div className="x_img_3">
                            <img src={item.path} />
                            <div className="mkygg_mask">
                              <img
                                src="img/edu/jnh/vid_03.png"
                                onClick={() => videoopen(item.video)}
                              />
                            </div>
                          </div>
                          <p className="mkygg_txt">{item.post_title}</p>
                        </a>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {videoFlag && (
        <div className="videoplay">
          <video src={videoUrl} controls="controls"></video>
          <div className="close" onClick={() => setVideoFlag(false)}>
            <svg
              t="1600161391382"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2710"
              width="64"
              height="64"
            >
              <path
                d="M509.262713 5.474574c281.272162 0 509.262713 228.02238 509.262713 509.262713 0 281.272162-227.990551 509.262713-509.262713 509.262713s-509.262713-227.990551-509.262713-509.262713c0-281.240333 227.990551-509.262713 509.262713-509.262713z m135.050106 278.725849L509.262713 419.250528l-135.050106-135.050105-90.012184 90.012184L419.186871 509.262713l-135.018277 135.081935 90.012184 90.012184L509.262713 599.274897l135.050106 135.050106 90.012184-90.012184L599.274897 509.262713l135.050106-135.050106-90.012184-90.012184z"
                p-id="2711"
                fill="#ffffff"
              ></path>
            </svg>
          </div>
        </div>
      )}
    </JnhWrap>
  )
})

export default Jnh
