import React from 'react';

import {NavLink,Switch,Route,Redirect} from 'react-router-dom'
import Gjjl from './components/gjjl'
import Gatjl from './components/gatjl'
import Guojjl from './components/guojjl'
import CommunicationDetail from './components/communicationDetail'

import CommonCarousel from 'common/carousel'

import {CommunicationWrap} from './style'
import NavSection from 'common/navSection'
import {Skeleton} from 'antd'

const title_type_1 = (font)=>{
    return(
        <div className='title_type_1'>
            <span>{font}</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>  
        </div>
    )
}
class Communication extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            headerList:{}
        }
    }
    componentDidMount(){
        document.title = '交流活动'
        this.goTo()
        React.$request({
            url:'Portal/Index/communication_party',
            method:'get'
        }).then(res=>{
            this.setState({
                headerList:res.data
            })
        })
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    render(){
        return(
            this.state.headerList.lp?
            <CommunicationWrap>
                <NavSection />
                <div className="header">
                    <CommonCarousel Carouselarr={this.state.headerList.lp} className='sw' />
                    <div className='header_content'>
                            {
                                title_type_1(this.state.headerList.gk[0].post_title)
                            }
                            <p dangerouslySetInnerHTML={{__html:this.state.headerList.gk[0].post_excerpt}}></p>
                    </div>
                </div>
                <div className="banner-img">
                    <a href='/#/curatorSession' target='_blank' className="banner-img-link">  
                        <img src={`/img/communication/kjggzdhh-banner.jpg`} className="banner-img-item" />  
                    </a>  
                    <a href='/#/union' target='_blank' className="banner-img-link">  
                        <img src={`/img/communication/lianmeng-banner.jpg`} className="banner-img-item" /> 
                        <div className="banner-img-caption">  
                            北京国际城市科学节联盟<br/>
                            Beijing Global Network of Science Festivals
                        </div> 
                    </a>  
                </div>
                <div className='tabWrap'>
                    <NavLink to='/communication/gjjl' className='tab_1' activeClassName='tab_1_active'>馆际交流</NavLink>
                    <NavLink to='/communication/gatjl' className='tab_2' activeClassName='tab_2_active'>港澳台交流</NavLink>
                    <NavLink to='/communication/guojjl' className='tab_3' activeClassName='tab_3_active'>国际交流</NavLink>               
                </div>
                <Switch>
                    {/* <Route exact path='/communication/' component={Gjjl}></Route> */}
                    <Route path='/communication/gjjl' component={Gjjl}></Route>
                    <Route path='/communication/gatjl' component={Gatjl}></Route>
                    <Route path='/communication/guojjl' component={Guojjl}></Route>
                    <Route path='/communication/detail/:id' component={CommunicationDetail}></Route>
                    <Redirect from="/communication*" to="/communication/gjjl" />
                </Switch>
            </CommunicationWrap>:<Skeleton active />
        )
    }
}
export default Communication