/*
 * @Author: your name
 * @Date: 2020-09-15 14:00:18
 * @LastEditTime: 2020-09-19 07:41:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \kxzxweb\src\pages\education\components\jnh\dyz.js
 */
import React, { memo, useEffect } from 'react'
import { DyzWrap } from './style'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'
import { NavLink } from 'react-router-dom'

const Dyz = memo((props) => {
  const { sliderArr, kmysArr } = props
  useEffect(() => {
    new Swiper('.swiper-container1', {
      loop: false,
      //   autoplay: {
      //     delay: 2500,
      //     disableOnInteraction: false,
      //   },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    })
  })
  return (
    <DyzWrap>
      <div className="h2 cm7">
        <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/jnh/title_02.png`} alt="" />
      </div>
      <div className="container section">
        <div className="kjdyz_nav">
          <div className="kjdyz_left">
            <div className="swiper-container swiper-container1">
              <div className="swiper-wrapper">
                {sliderArr.list &&
                  sliderArr.list.map((item) => {
                    return (
                      <div className="swiper-slide" key={item.id}>
                        <div>
                          <img src={item.path} alt="" />
                        </div>
                      </div>
                    )
                  })}
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
          <div className="kjdyz_right">
            <div className="kjdyz_right_nav">
              <div className="kjdyz_right_content">
                <NavLink
                  className="kjdyz_tit"
                  to={'/article/' + kmysArr.list[0].id}
                >
                  {kmysArr.list[0].post_title}
                </NavLink>
                <p className="kjdyz_content">{kmysArr.list[0].post_excerpt}</p>
              </div>
              <div className="kjdyz_right_list">
                {kmysArr.list.slice(1, 4).map((item, index) => {
                  return (
                    <NavLink
                      className="kjdyz_li"
                      to={'/article/' + item.id}
                      key={item.id}
                    >
                      <p>{item.post_title}</p>
                    </NavLink>
                  )
                })}
              </div>
              <div className="more">
                <NavLink to={'/list/' + kmysArr.term_id}>
                  <span>+更多</span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DyzWrap>
  )
})

export default Dyz
