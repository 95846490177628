import styled from 'styled-components'

export const ZxfkWrap = styled.div`
    @media(min-width:750px){
        width:1200px;
        overflow:hidden;
        margin:0 auto;
        position:relative;
        .banner{
            width:1200px;
            height:380px;
            display:flex;
            justify-content:space-between;
            align-items:center;
            border-radius:10px;
            overflow:hidden;
            margin:10px auto;
            img{
                width:100%;
                height:100%;
            }
        }
        .yzm_wrap{
            width:100%;
            height:100%;
            position:fixed;
            left:0;
            top:0;
            background:rgba(0,0,0,.5);
            display:flex;
            justify-content:center;
            align-items:center;
            .yzm_box{
                width:750px;
                height:320px;
                background:#fff;
                border-radius:10px;
                display:flex;
                flex-direction:column;
                justify-content:center;
                align-items:center;
                .yzm_top{
                    width:560px;
                    height:45px;
                    display:flex;
                    flex-direction:row;
                    justify-content:space-between;
                    align-items:center;
                    span{
                        color:#515151;
                        font-size:20px; 
                    }
                    .ipt_wrap{
                        width:470px;
                        height:43px;
                        border:1px solid #bfbfbf;
                        display:flex;
                        flex-direction:row;
                        justify-content:space-between;
                        align-items:center;
                        input{
                            border:none;
                            outline:none;
                            flex:4;
                        }
                        img{
                            height:40px;
                        }
                    }
                }
                .yzm_submit{
                    width:180px;
                    height:48px;
                    border-radius:5px;
                    background:#00a1b4;
                    color:#fff;
                    line-height:48px;
                    text-align:center;
                    font-size:20px;
                    margin-top:50px;
                    cursor:pointer;
                }
            }
        }
        .form{
            width:100%;
            overflow:hidden;
            background:#e7fcff;
            margin-bottom:44px;
            border-radius:10px;
            h2{
                font-size:29px;
                color:#00a1b4;
                font-weight:bold;
                margin:34px auto 43px;
                text-align:center;
            }
            input{
                border:none;
                padding-left:5px;
            }
            .yzm_section{
                width:600px;
                height:40px;
                display:flex;
                flex-direction:row;
                align-items:center;
                margin:0 auto;
                span{
                    font-size:26px;
                    color:#00a1b4;
                    font-weight:bold;
                }
                span::before{
                    content:'';
                    width:12px;
                    height:12px;
                    background:#00a1b4;
                    border-radius:100%;
                }
                input{
                    width:200px;
                    height:40px;
                    background:#fff;
                    border-radius:10px;
                    outline:none;
                    margin-left:40px;
                    
                }   
                img{
                    height:40px;
                    margin-left:40px;
                    cursor: pointer;
                }             
            }
            .form_section{
                width:600px;
                height:40px;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                align-items:center;
                margin:0 auto;
                span{
                    font-size:26px;
                    color:#00a1b4;
                    font-weight:bold;
                }
                span::before{
                    content:'';
                    width:12px;
                    height:12px;
                    background:#00a1b4;
                    border-radius:100%;
                }
                input{
                    width:500px;
                    height:40px;
                    background:#fff;
                    border-radius:10px;
                    outline:none;
                    font-size:20px;
                }
            }
            .error{
                width:800px;
                height:60px; 
                margin:0 auto;
                color:red;
                text-indent:190px;          
            }
            h3{
                width:600px;
                font-size:26px;
                color:#00a1b4;
                font-weight:bold;
                margin:0 auto;
            }
            .form_section2{
                width:800px;
                margin:40px auto;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                align-items:center;
                display: none;
                label{
                    cursor: pointer;
                }
                input{
                    width:26px;
                    height:26px;
                    background:#00a1b4;
                    margin-right:22px;
                    
                }
                span{
                    font-size:30px;
                    color:#00a1b4;
                    margin-right:66px;
                }
            }
            textarea{
                width:600px;
                height:250px;
                background:#fff;
                border-radius:10px;
                margin:10px auto;
                overflow-y:hidden;
                display:block;
                outline:none;
                text-indent:32px;
                font-size:16px;
            }
            .form_submit{
                width:200px;
                height:50px;
                background:#00a1b4;
                color:#fff;
                font-size:20px;
                font-weight:bold;
                margin:25px auto 75px;
                border-radius:12px;
                text-align:center;
                cursor: pointer;
                line-height:50px;
            }
        }
    }
    @media(max-width:750px){
        width:100%;
        overflow:hidden;
        margin:0 auto;

        .banner{
            width:100%;
            height:4rem;
            display:flex;
            justify-content:space-between;
            align-items:center;
            overflow:hidden;
            img{
                width:100%;
                height:100%;
            }
        }
        .form{
            width:7.1rem;
            overflow:hidden;
            background:#e7fcff;
            margin:.3rem auto;
            h2{
                font-size:.34rem;
                color:#00a1b4;
                font-weight:bold;
                margin:.52rem auto;
                text-align:center;
            }
            .form_section{
                width:6.4rem;
                height:.65rem;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                align-items:center;
                margin:0 auto;
                span{
                    font-size:.3rem;
                    color:#00a1b4;
                    font-weight:bold;
                }
                span::before{
                    content:'';
                    width:.2rem;
                    height:.2rem;
                    background:#00a1b4;
                    border-radius:100%;
                }
                input{
                    width:4.76rem;
                    height:.65rem;
                    background:#fff;
                    border-radius:.1rem;
                    outline:none;
                    text-indent:.3rem;
                }
            }
            .error{
                width:6.4rem;
                height:.65rem;
                margin:0 auto;
                color:red;
                text-indent:1.63rem;          
            }
            h3{
                width:6.4rem;
                font-size:.35rem;
                color:#00a1b4;
                font-weight:bold;
                margin:0 auto;
            }
            .form_section2{
                width:6.5rem;
                margin:.4rem auto;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                align-items:center;
                label{
                    cursor: pointer;
                }
                input{
                    width:.2rem;
                    height:.2rem;
                    background:#00a1b4;
                    margin-right:.1rem;
                }
                span{
                    font-size:.28rem;
                    color:#00a1b4;
                    margin-right:.9rem
                }
            }
            textarea{
                width:6.5rem;
                height:3.3rem;
                background:#fff;
                border-radius:10px;
                margin:.3rem auto;
                overflow-y:hidden;
                display:block;
                outline:none;
                padding:.2rem;
                box-sizing:border-box;
            }
            .form_submit{
                width:2.68rem;
                height:.64rem;
                background:#00a1b4;
                color:#fff;
                font-size:.36rem;
                font-weight:bold;
                margin:.75rem auto .75rem;
                border-radius:.12rem;
                text-align:center;
                cursor: pointer;
            }
            .yzm_section{
                width:6.5rem;
                margin:.1rem auto;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                align-items:center;
                input{
                    width:2.5rem;
                    height:.8rem;
                    border:none;
                    text-indent:.2rem;
                }
                .verify_img{
                    width:2.5rem;
                }
            }
        }
    }
`