import styled from 'styled-components'

export const KexkfgWrap = styled.div `
    @media(min-width:750px){
        width:100%;
        overflow:hidden;
        .title_type_1{
            height:32px;
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            text-align:center;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:60px auto 30px;
            text-align:center;
            justify-content:center;
            .i_small{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-left:7px;             
            }
            .i_small2{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large2{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-right:7px;             
            }            
            span{
                padding:10px 40px;
                background:#00a1b4;
                border-radius:5px;
                margin:0 16px;
            }         
        }
        
        .header{
            width:1200px;
            height:300px;
            position:relative;
            margin:10px auto 0;
            border-radius:10px;
            overflow:hidden;
            .header_detail{
                width:540px;
                height:300px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/kfgmask_03.png) center center no-repeat;
                background-size:100% 100%;
                position:absolute;
                right:0;
                top:0;
                padding:0 45px 0 165px;
                box-sizing:border-box;
                z-index:2;
                display:flex;
                justify-content:center;
                flex-direction:column;

                h2{
                    font-size:34px;
                    color:#fff;
                    font-weight:bold;
                }
                p{
                    font-size:18px;
                    color:#fff;
                    line-height:30px;
                
                }
            } 
            .sw{
                width:100%;
                height:100%;
                position:absolute;
                left:0;
                top:0;
            }
        }
        .wqhd{
                width:1200px;
                margin:0 auto;
                overflow:hidden;
                .wqhd_wrap{
                    width:1200px;
                    overflow:hidden;
                    margin:0 auto;
                    display:flex;
                    flex-direction:row;
                    flex-wrap:wrap;
                    justify-content:space-between;
                    .wqhd_section{
                        width:585px;
                        height:340px;
                        margin-top:30px;
                        position:relative;
                        overflow:hidden;
                        background-position:center center;
                        background-size:cover;
                        background-repeat:no-repeat;
                        border-radius:10px;
                        display: block;
                        .wqhd_section_p{
                            width:100%;
                            height:170px;
                            background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                            background-size:100% 100%;
                            position:absolute;
                            left:0;
                            bottom:0;
                            display:flex;
                            padding:10px 35px;
                            box-sizing:border-box;
                            color:#fff;
                            font-size:18px;
                            align-items:flex-end;
                        }
                        .zd{
                            width:50px;
                            position: absolute;
                            left:0;
                            top:10px;
                            img{
                                width:100%;
                            }
                        }
                    }
                }
        }
        .hdhx{
            width:1200px;
            overflow:hidden;
            margin:0 auto 50px;
            .hdhx_wrap{
                width:100%;
                height:450px;
                display:flex;
                flex-direction:row;
                margin-top:35px;
                .video{
                    width:750px;
                    height:450px;
                    border-radius:10px;
                    overflow:hidden;
                    background:#009cbe;
                    video{
                        width:100%;
                        height:100%;
                    }
                }
                .hdxh_list{
                    flex:1;
                    margin-left:13px;
                    display:flex;
                    flex-direction:column;
                    margin-top:12px;
                    margin-bottom:20px;
                    .hdhx_link{
                        padding:8px 10px;
                        font-size:22px;           
                        color:#221815;
                        line-height:34px;
                        border-bottom:1px solid #d0d0d0;
                        cursor: pointer;
                        box-sizing:border-box;
                    }
                    .hdhx_link_active{
                        color:#00a1b4;
                        font-weight:bold;
                        background:#eeeeee;
                    }
                }
            }
        }
        .hdhxM{
            display: none;
        }
    }
    @media(max-width:750px){
        width:100%;
        overflow:hidden;
        .title_type_1{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:0;
            text-indent:0;
            .i_small2{
                width:.16rem;
                height:.16rem;
                background:#f55c5d;
            }
            .i_large2{
                width:.3rem;
                height:.3rem;
                background:#00a1b4;   
                margin-right:.07rem;             
            }            
            span{
                padding:.1rem .4rem;
                background:#00a1b4;
                border-radius:5px;
                margin:0 .16rem 0 0;
                box-sizing:border-box;
            }
        }
        .header{
            width:100%;
            margin:0 auto;
            overflow:hidden;
            position: relative;
            .header_detail{
                width:7.1rem;
                margin:0 auto 0;
                display:flex;
                justify-content:center;
                flex-direction:column;
                h2{
                    font-size:.32rem;
                    font-weight:bold;
                    line-height:.32rem;
                    color:#fff;
                    display:flex;
                    flex-direction:row;
                    align-items:center;
                    margin-top:0.2rem;
                    text-indent:0;
                    .i_small2{
                        width:.16rem;
                        height:.16rem;
                        background:#f55c5d;
                    }
                    .i_large2{
                        width:.3rem;
                        height:.3rem;
                        background:#00a1b4;   
                        margin-right:.07rem;             
                    }            
                    span{
                        padding:.1rem .4rem;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 .16rem 0 0;
                        box-sizing:border-box;
                    }
                }
                p{
                    font-size:.28rem;
                    color:#282828;
                    line-height:.4rem;
                    text-indent:0;          
                    color:#000; 
                }
            } 
            .sw{
                width:100%;
                height:2.5rem;
            }
        }
        .wqhd{
                width:7.1rem;
                margin:0.1rem auto 0;
                overflow:hidden;
                .wqhd_wrap{
                    width:7.1rem;
                    overflow:hidden;
                    margin:0.3rem auto 0;
                    .wqhd_section{
                        width:7.1rem;
                        height:2.18rem;
                        margin-bottom:.2rem;
                        position:relative;
                        overflow:hidden;
                        background-position:left center;
                        background-size:3.45rem 2.18rem;
                        background-repeat:no-repeat;
                        border-radius:.1rem;
                        display: block;
                        .wqhd_section_p{
                            width:3.45rem;
                            height:2.18rem;
                            position:absolute;
                            right:0;
                            bottom:0;
                            display:flex;
                            padding:.1rem .25rem;
                            color:#000;
                            font-size:.28rem;
                            line-height:.45rem;
                        }
                    }
                }
        }
        .hdhx{
            display: none;
        }     
        .hdhxM{
            width:7.1rem;
            margin:.5rem auto;
            .hdxh_list{
                width:100%;
                overflow:hidden;
                margin:.5rem auto;
                .hdhx_link{
                    width:100%;
                    overflow:hidden;
                    .video{
                        width:100%;
                        height:4rem;
                        display: block;
                        video{
                            width:100%;
                            height:100%;
                            display: block;
                        }
                    }
                    p{
                        font-size:.23rem;
                        line-height:.4rem;
                        color:#111;
                        margin:.2rem auto;
                    }
                }
            }
        }   
    }
`