import React from 'react'
import './nyrzZqyhDefault.scss'
import styles from './index.module.scss'
import { Pagination } from 'antd';
import { changeImg } from './pub';
import { withRouter } from 'react-router-dom';
import qs from 'query-string'
import Crumbs from './compontents/crumbs';




class NyrzZqyhList extends React.Component {
  state={
    dataList:null,
    pageNum:1,
    total:0,
    termId:0
  }

  componentDidMount() {
    const { search } = this.props.location
    const { termId } = qs.parse(search)
    this.setState({termId:termId},()=>{
        this.getDataList()
    })
   
  }
  turn(id, termId) {
    console.log(id)
    console.log(termId)  
    this.props.history.push(`/nyrzZqyhContent?articleId=${id}&termId=${termId}`)
  }
  sizeChange=(page,val)=>{
    console.log(page,val)
    this.setState({
        pageNum:page
    },()=>{
      this.getDataList()
    })
    
  }

  getDataList = () => {
    const {pageNum,termId}=this.state
    React.$request({
      url: '/Portal/zt/getListsByTermId',
      method: 'get',
      params: {
        termId: termId,
        page:pageNum,
        limit:6
      }
    }).then(res => {
        this.setState({
            dataList:res.data.lists,
            total:res.data.totalPage*6
        })
    })
  }

  render() {
    const {dataList,total,termId}=this.state
    return (
        dataList!=null?
        <div className={styles.curatorSession}>
            <Crumbs termId={termId}/>
            <div className="conBox flexBox-sb">
                {
                    dataList.map(el=>{
                        return (
                            <div className="conItem" onClick={()=>this.turn(el.id,el.term_id)}>
                                <img src={changeImg(el.smeta.photo[0].url)} alt="" />
                                <p className='title'>{el.post_title}</p>
                                <p className='time'>{el.post_date.split(' ')[0]}</p>
                            </div>
                        )
                    })
                }
            </div>
            <div className='paginationBox flexBox-cen'>
                <Pagination onChange={this.sizeChange} defaultPageSize={6} defaultCurrent={1} total={total}/>
            </div>
        </div>:''
    )
  }
}
export default withRouter(NyrzZqyhList)
