import styled from 'styled-components'

export const Zzt = styled.div`
    @media(min-width:750px){
        width:100%;
        overflow:hidden;
        margin:0 auto 115px;
        .post_crumb{
            width:1200px;
            margin:10px auto 0;
            a{
                color:#00a1b4;
            }
        }
        .zzt_header{
            width:1200px;
            height:380px;
            display:flex;
            flex-direction:row;
            border-radius:10px;
            margin:10px auto;
            position:relative;
            overflow:hidden;
            .sw{
                width:100%;
                height:100%;
                position:absolute;
                left:0;
                top:0;
            }
            .zzt_header_detail{
                width:415px;
                height:325px;
                position:absolute;
                right:0;
                top:0;
                z-index:2;
                .zzt_title{
                    width:197px;
                    height:34px;
                    background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/icons_03.png) center center no-repeat;
                    background-size:100% 100%;
                    font-size:28px;
                    color:#fff;
                    font-weight:bold;
                    line-height:34px;
                    margin:16px 0 20px;
                    text-indent:47px;
                }
                .zzt_content{
                    width:415px;
                    height:250px;
                    background:rgba(33,158,188,.5);
                    padding:25px 35px;
                    box-sizing:border-box;
                    font-size:16px;
                    line-height:28px;
                    color:#fff;
                    text-align:justify;
                }
            }
        }
    }
    @media(max-width:750px){
        width:100%;
        overflow:hidden;
        .zzt_header{
            width:100%;
            overflow:hidden;
            .sw{
                width:100%;
                height:2.4rem;
            }
            .zzt_header_detail{
                width:7.1rem;
                margin:0 auto .5rem;
                .zzt_title{
                    width:2.58rem;
                    height:.5rem;
                    background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/icons_03.png) center center no-repeat;
                    background-size:100% 100%;
                    font-size:.34rem;
                    color:#fff;
                    font-weight:bold;
                    line-height:.5rem;
                    text-indent:.57rem;
                    margin:.54rem 0 .12rem 0;
                }
                .zzt_content{
                    width:100%;
                    line-height:.4rem;
                    font-size:.28rem;
                    color:#282828;
                    line-height:.45rem;
                    text-align:justify;
                }
                .openTime{
                    width:4.7rem;
                    height:.5rem;
                    /* display:flex;
                    flex-direction:row; */
                    border-radius:0.06rem;
                    font-size:.28rem;
                    line-height:.5rem;
                    background:#ff7413;
                    .title{
                        width:1.55rem;
                        height:.45rem;
                        color:#ff7413;
                        float:left;
                        margin:.02rem;
                        background:#fff;
                        line-height:.45rem;
                        text-align:center;
                        border-radius:0.06rem 0 0 .06rem;
                    }
                    .time{
                        float:right;
                        width:3.1rem;
                        height:100%; 
                        color:#fff;
                        text-align:center;
                    }
                }
            }
        }
    }
`