import styled from 'styled-components';

export const TrainWrap = styled.div `
@media(min-width:750px) {
    width: 1200px;
    overflow: hidden;
    margin: 0 auto;
    .pc{
        display: none;
    }
    .edu_jhhd_wrapM{
        display: none;
    }
    .title_type_1 {
        height: 32px;
        font-size: 32px;
        font-weight: bold;
        line-height: 32px;
        color: #fff;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 60px auto 30px;
        text-align: center;
        justify-content: center;

        .i_small {
            width: 8px;
            height: 8px;
            background: #f55c5d;
        }

        .i_large {
            width: 16px;
            height: 16px;
            background: #00a1b4;
            margin-left: 7px;
        }

        .i_small2 {
            width: 8px;
            height: 8px;
            background: #f55c5d;
        }

        .i_large2 {
            width: 16px;
            height: 16px;
            background: #00a1b4;
            margin-right: 7px;
        }

        span {
            padding: 10px 40px;
            background: #00a1b4;
            border-radius: 5px;
            margin: 0 16px;
        }
    }

    .title_type_1:hover {
        color: orange;
    }

    .title_type_2:hover {
        color: orange;
    }

    .title_type_2 {
        height: 32px;
        font-size: 32px;
        font-weight: bold;
        line-height: 32px;
        color: #fff;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 60px auto 30px;
        text-align: center;
        justify-content: center;

        .i_small {
            width: 8px;
            height: 8px;
            background: #f55c5d;
        }

        .i_large {
            width: 16px;
            height: 16px;
            background: #00a1b4;
            margin-left: 7px;
        }

        .i_small2 {
            width: 8px;
            height: 8px;
            background: #f55c5d;
        }

        .i_large2 {
            width: 16px;
            height: 16px;
            background: #00a1b4;
            margin-right: 7px;
        }

        span {
            padding: 10px 40px;
            background: #00a1b4;
            border-radius: 5px;
            margin: 0 16px;
        }
    }
    
    .title_type_3{
        height:32px;
        font-size:32px;
        font-weight:bold;
        line-height:32px;
        color:#fff;
        text-align:center;
        display:flex;
        flex-direction:row;
        align-items:center;
        margin:60px auto 30px;
        text-align:center;
        justify-content:center;
        .i_small{
            width:8px;
            height:8px;
            background:#f55c5d;
        }
        .i_large{
            width:16px;
            height:16px;
            background:#00a1b4;   
            margin-left:7px;             
        }
        .i_small2{
            width:8px;
            height:8px;
            background:#f55c5d;
        }
        .i_large2{
            width:16px;
            height:16px
            background:#00a1b4;   
            margin-right:7px;             
        }            
        span{
            padding:10px 40px;
            background:#00a1b4;
            border-radius:5px;
            margin:0 16px;
        }
    }

    .header {
        width: 100%;
        height: 380px;
        display: flex;
        flex-direction: row;
        margin: 10px auto 0;
        border-radius: 10px;
        overflow: hidden;

        .sw {
            width: 835px;
            height: 380px;
        }

        .header_detail {
            flex: 1;
            background: #40b5ce;
            padding: 0 20px;
            display: flex;
            font-size: 20px;
            color: #fff;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h2 {
                font-size: 30px;
                font-weight: bold;
                color: #fff;
            }

            p {
                font-size: 18px;
                line-height: 30px;
            }
        }
    }
    .ycjh{
        width:100%;
        overflow:hidden;
        position:relative;
        .ycjh_wrap{
            width:1200px;
            overflow:hidden;
            margin:0 auto;
            display:flex;
            flex-direction:row;
            flex-wrap:wrap;
            justify-content:space-between;
            .ycjh_section{
                width:585px;
                height:340px;
                margin-top:30px;
                position:relative;
                overflow:hidden;
                background-position:center center;
                background-size:cover;
                background-repeat:no-repeat;
                border-radius:10px;
                .ycjh_section_p{
                    width:100%;
                    height:170px;
                    background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                    background-size:100% 100%;
                    position:absolute;
                    left:0;
                    bottom:0;
                    display:flex;
                    padding:10px 35px;
                    box-sizing:border-box;
                    color:#fff;
                    font-size:18px;
                    align-items:flex-end;
                }
            }
            .ycjh_section_full{
                width:100%;
            }
        }
    }
    
    .kjfdypx {
        width: 100%;
        overflow: hidden;
        .edu_jhhd_wrapM{
            display: none;
        }
        .edu_jhhd_wrap {
            width: 1200px;
            overflow: hidden;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            .jxhd_section {
                width: 585px;
                height: 340px;
                margin-top: 30px;
                position: relative;
                overflow: hidden;
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 10px;

                .jxhd_section_p {
                    width: 100%;
                    height: 170px;
                    background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                    background-size: 100% 100%;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    display: flex;
                    padding: 10px 35px;
                    box-sizing: border-box;
                    color: #fff;
                    font-size: 18px;
                    align-items: flex-end;
                }
            }
        }
    }

    .sdzkjWrap {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 40px;

        .imgWrap {
            width: 285px;
            height: 285px;
            border-radius: 11px;
            overflow: hidden;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;

            .jxhd_section_p {
                width: 100%;
                height: 100px;
                background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                background-size: 100% 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                display: flex;
                padding: 10px 25px;
                box-sizing: border-box;
                color: #fff;
                font-size: 16px;
                align-items: flex-end;
            }
        }
    }

    .msgzsWrap {
        width: 100%;
        display: flex;
        flex-direction: row;

        .imgWrap {
            margin: 20px;

            img {
                width: 100%;
            }
        }
    }

    .kjypt {
        width: 100%;
        height: 165px;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 60px;

        img {
            width: 100%;
        }
    }

    .pxkj {
        width: 100%;
        overflow: hidden;

        .pxkjWrap {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 40px;

            .pxkjSection {
                width: 285px;
                overflow: hidden;
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                .imgWrap {
                    width: 285px;
                    height: 165px;
                    border-radius: 7px;
                    overflow: hidden;
                    position: relative;

                    img {
                        width: 100%;
                    }

                    .p {
                        width: 100%;
                        height: 40px;
                        background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        display: flex;
                        padding: 5px 35px;
                        box-sizing: border-box;
                        color: #fff;
                        font-size: 18px;
                        align-items: flex-end;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }
                }

                a {
                    width: 110px;
                    height: 35px;
                    background: #009cbe;
                    color: #fff;
                    line-height: 35px;
                    text-align: center;
                    font-size: 20px;
                    border-radius: 5px;
                    margin-left: 175px;
                }
            }
        }
    }

    .mtbd_wrap {
        width: 1200px;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 30px;

        .hdbd_section {
            width: 540px;
            margin: 10px;
            height: 170px;
            margin-top: 30px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .hdbd_section_img_wrap {
                width: 270px;
                height: 170px;
                border-radius: 7px;
                overflow: hidden;

                img {
                    width: 100%;
                }
            }

            .hdbd_section_detail {
                width: 252px;
                height: 170px;
                display: flex;
                text-align: left;
                flex-direction: column;
                justify-content: space-between;

                h2 {
                    font-size: 22px;
                    color: #221815;
                    line-height: 30px;
                    width: 100%;
                }

                p {
                    width: 100%;
                    color: #009cbe;
                    font-size: 15px;
                }
            }
        }
    }
    .zd{
                width:50px;
                position: absolute;
                top:10px;
                left:0;
                img{
                    width:100%;
                }
            }
}

@media(max-width:750px) {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;

    .title_type_1 {
        font-size: .32rem;
        font-weight: bold;
        line-height: .32rem;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0.3rem .2rem;
        text-indent: 0;

        .i_small2 {
            width: .16rem;
            height: .16rem;
            background: #f55c5d;
        }

        .i_large2 {
            width: .3rem;
            height: .3rem;
            background: #00a1b4;
            margin-right: .07rem;
        }

        span {
            padding: .1rem .4rem;
            background: #00a1b4;
            border-radius: 5px;
            margin: 0 .16rem 0 0rem;
            box-sizing: border-box;
        }
    }

    .title_type_2 {
        font-size: .32rem;
        font-weight: bold;
        line-height: .32rem;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 auto .3rem;
        text-indent: 0;

        .i_small2 {
            width: .16rem;
            height: .16rem;
            background: #f55c5d;
        }

        .i_large2 {
            width: .3rem;
            height: .3rem;
            background: #00a1b4;
            margin-right: .07rem;
        }

        span {
            padding: .1rem .4rem;
            background: #00a1b4;
            border-radius: 5px;
            margin: 0 .16rem 0 0.2rem;
            box-sizing: border-box;
        }
    }
    .title_type_3{
        font-size:.32rem;
        font-weight:bold;
        line-height:.32rem;
        color:#fff;
        display:flex;
        flex-direction:row;
        align-items:center;
        margin:.3rem .2rem .3rem;
        text-indent:0;
        .i_small2{
            width:.16rem;
            height:.16rem;
            background:#f55c5d;
        }
        .i_large2{
            width:.3rem;
            height:.3rem;
            background:#00a1b4;   
            margin-right:.07rem;             
        }            
        span{
            padding:.1rem .4rem;
            background:#00a1b4;
            border-radius:5px;
            margin:0 .16rem 0 0;
            box-sizing:border-box;
        }
    }

    .header {
        width: 100%;
        overflow: hidden;

        .sw {
            width: 100%;
            height: 4rem;
        }

        .header_detail {
            width: 7.1rem;
            margin: 0 auto;

            h2 {
                font-size: .32rem;
                font-weight: bold;
                line-height: .32rem;
                color: #fff;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: .2rem auto;
                text-indent: 0;

                .i_small2 {
                    width: .16rem;
                    height: .16rem;
                    background: #f55c5d;
                }

                .i_large2 {
                    width: .3rem;
                    height: .3rem;
                    background: #00a1b4;
                    margin-right: .07rem;
                }

                span {
                    padding: .1rem .4rem;
                    background: #00a1b4;
                    border-radius: 5px;
                    margin: 0 .16rem 0 0;
                    box-sizing: border-box;
                }
            }

            p {
                font-size:.28rem;
                line-height:.45rem;
                margin-top:.3rem;
                color:#000;
            }
        }
    }
    .ycjh{
        width:7.1rem;
        margin:0rem auto;
        overflow:hidden;
        .ycjh_wrap{
            width:7.1rem;
            overflow:hidden;
            margin:0.3rem auto 0;
            display:flex;
            flex-direction:row;
            flex-wrap:wrap;
            justify-content:space-between;
            .ycjh_section{
                width:3.45rem;
                height:2.18rem;
                margin-bottom:.2rem;
                position:relative;
                overflow:hidden;
                background-position:center center;
                background-size:cover;
                background-repeat:no-repeat;
                border-radius:.1rem;
                .ycjh_section_p{
                    width: 100%;
                    height: 1rem;
                    background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                    background-size: 100% 100%;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: -ms-flexbox;
                    display: flex;
                    padding: .1rem .35rem;
                    box-sizing: border-box;
                    color: #fff;
                    font-size: .3rem;
                    -webkit-align-items: flex-end;
                    -webkit-box-align: flex-end;
                    -ms-flex-align: flex-end;
                    align-items: flex-end;
                }
            }
            .ycjh_section_full{
                width:100%;
            }
        }
    }
    
    .kjfdypx {
        width: 100%;
        overflow: hidden;

        .edu_jhhd_wrapM{
            width: 7.1rem;
            overflow: hidden;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .edu_jhhd_wrap{
            display: none;
        }
    }
    .edu_jhhd_wrapM{
            width: 7.1rem;
            overflow: hidden;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-between;
    }
    .sdzkjWrap {
        width: 7.1rem;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        display: none;
        .imgWrap {
            width: 3.4rem;
            height: 2.2rem;
            position: relative;
            overflow: hidden;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: .1rem;
            margin-bottom: .2rem;
            position: relative;
            .zd{
                width:50px;
                position: absolute;
                top:10px;
                left:0;
                img{
                    width:100%;
                }
            }
            .jxhd_section_p {
                width: 100%;
                height: 1rem;
                background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                background-size: 100% 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                display: flex;
                padding: .1rem .2rem;
                box-sizing: border-box;
                color: #fff;
                font-size: .28rem;
                align-items: flex-end;
            }
        }

    }

    .msgzsWrap {
        width: 7.1rem;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .imgWrap {
            width: 3.4rem;
            height: 2.2rem;
            overflow: hidden;

            img {
                width: 100%;
            }
        }
    }

    .kjypt {
        width: 7.1rem;
        height: 1.23rem;
        border-radius: .1rem;
        overflow: hidden;
        margin: .3rem auto;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 100%;
            width: auto;
        }
    }

    .mtbd {
        margin-top: -.2rem;
    }

    .pxkj {
        width: 7.1rem;
        overflow: hidden;
        margin: 0 auto;

        .pxkjWrap {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;

            .pxkjSection {
                width: 3.45rem;
                height: 2.6rem;
                position: relative;
                margin-bottom: .3rem;

                .imgWrap {
                    width: 100%;
                    height: 2.1rem;
                    border-radius: .1rem;
                    overflow: hidden;
                    position: relative;
                    .zd{
                        width:50px;
                        position: absolute;
                        top:10px;
                        left:0;
                        img{
                            width:100%;
                        }
                    }
                    img {
                        width: 100%;
                    }
                }
                .pm{
                    display: none;
                }
                .p {
                    width: 100%;
                    font-size: .3rem;
                    height: .3rem;
                    color: #00a1b4;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    font-weight: bold;
                    text-align: center;
                    line-height: .3rem;
                }

                a {
                    width: 1.13rem;
                    height: .3rem;
                    background: #009cbe;
                    color: #fff;
                    position: absolute;
                    left: 1.85rem;
                    bottom: 0;
                    display: block;
                    line-height: .3rem;
                    text-align: center;
                    border-radius: .05rem;
                }
            }
        }
    }

    .mtbd_wrap {
        width: 7.1rem;
        overflow: hidden;
        margin: -0.3rem auto .9rem;

        .hdbd_section {
            width: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: .27rem 0;
            border-bottom: 0.01rem dashed #a0a0a0;

            .hdbd_section_img_wrap {
                width: 2.45rem;
                height: 1.5rem;
                border-radius: .1rem;
                overflow: hidden;

                img {
                    width: 100%;
                }
            }

            .hdbd_section_detail {
                flex: 1;
                margin-left: .1rem;
                display: flex;
                align-items: center;
                text-align: left;
                flex-direction: column;

                h2 {
                    font-size: .29rem;
                    color: #000;
                    line-height: .4rem;
                    width: 100%;
                }

                p {
                    width: 100%;
                    text-align: right;
                    color: #00a1b4;
                    font-size: .2rem;
                }
            }
        }
    }
}

`
