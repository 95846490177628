export function changeImg(val){
    if (!val) {
        return ''
    }
    if(val.indexOf('www.bjsc.net.cn')!=-1){
        return val
    }else{
        return `${process.env.REACT_APP_IMGBASE}` + '/data/upload/' + val
    }
}
