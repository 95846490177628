import React from 'react';
import CommonCarousel from 'common/carousel.js'
import {Etly} from './style.js'
import EtlyTab from './tabUl';
import {Skeleton} from 'antd';
import NavSection from 'common/navSection'
class ExEtly extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            headerArr:null,
            tabArr:null,
            currentId:null
        }
        this.tab = React.createRef();
    }
    changeTab(id){
        React.$request({
            url:'Portal/index/children_second',
            method:'get',
            params:{
                termid:id
            }
          }).then(res=>{
            this.setState({
                tabArr:res.data,
                currentId:id
            })
          })
        
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
        }, 2);
    }
    etlyInit(){
        React.$request({
            url:'Portal/index/exhibition_hall_son',
            method:'get',
            params:{
                termid:51
            }
          }).then(res=>{
            this.setState({
                headerArr:res.data
            })
            if(this.props.match.params.id){
                window.scrollTo(0 ,this.tab.current.offsetTop);
                switch(this.props.match.params.id){
                    case 'tab1':
                        this.changeTab(res.data.assort[0].term_id)
                        break;
                    case 'tab2':
                        this.changeTab(res.data.assort[1].term_id)
                        break;  
                    case 'tab3':
                        this.changeTab(res.data.assort[2].term_id)
                        break;
                    case 'tab4':
                        this.changeTab(res.data.assort[3].term_id)
                        break;                      
                }
            }else{
                this.changeTab(res.data.assort[0].term_id)
            }
          })
    }
    componentDidMount(){
        this.etlyInit()
        this.goTo()
    }
    render(){
        return(
            this.state.headerArr?
            <Etly>
                <NavSection />
                <div className="header_wrap">
                    <div className="header">
                        <CommonCarousel className='sw' Carouselarr={this.state.headerArr.lp} />       
                        <div className="right_font">
                            <h2 className='r_f_title'>儿童乐园</h2>
                            <div className="r_f_detail">“儿童乐园”位于4号楼，展厅面积3820平方米，面向3-8岁儿童及其家长开放，分为“奇趣大自然”“小小科学城”“健康小主人”“亲子活动区”四个展区，设有76件展品。儿童乐园内以自然环境类展项和基础科学展项为主，为儿童提供了寓教于乐的科普教育。</div>
                            <div className="r_f_line"></div>
                            <div className="r_f_bot_title">每周二至周日</div>
                            <div className="r_f_detail">
                                上午09:30 - 12:30　12:00停止入场<br/>
                                下午13:30 - 16:30　16:00停止入场
                            </div>
                        </div>
                    </div>
                    <div className="bot"></div>
                </div>        
                <div className="tab" ref={this.tab}>
                    {
                        this.state.headerArr.assort.map((item)=>{
                            return(
                                <div key={item.term_id} className='etly_tab'>
                                    <div onClick={()=>this.changeTab(item.term_id)} className={item.term_id === this.state.currentId?'active link_part':'link_part'}>
                                        <p>{item.name.substring(0,2)}</p>
                                        <p>{item.name.substring(2,)}</p>
                                    </div>                               
                                </div>
                            )
                        })
                    }
                </div>
                {
                    this.state.tabArr?<EtlyTab tabArr={this.state.tabArr} />:<Skeleton active />
                   
                }
            </Etly>
            :<Skeleton active />
        )
    }
}
export default ExEtly