import styled from 'styled-components'

export const Etly = styled.div`
    @media(min-width:750px){
        width:100%;
        overflow:hidden;
        background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/etly_03.png) center center repeat-y;
        background-size:100% auto;
        .header_wrap{
            width:100%;
            height:540px;
            background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/etly_02.png) center center no-repeat;
            background-size:cover;
            position:relative;
            .header{
                width:1200px;
                margin:0 auto;
                position:relative;
                .sw{
                    width:1200px;
                    height:540px;
                    margin:0 auto;
                }
                .right_font{
                    width:415px;
                    height:100%;
                    background:rgba(40,177,166,.8);
                    position:absolute;
                    right:0;
                    bottom:0;
                    z-index:2;
                    padding:20px 47px;
                    box-sizing:border-box;
                    .r_f_title{
                        font-size:35px;
                        color:#fff;
                        font-weight:bold;
                        text-align:center;
                    }
                    .r_f_detail{
                        font-size:16px;
                        line-height:28px;
                        color:#fff;
                        text-align:justify;
                    }
                    .r_f_line{
                        width:100%;
                        height:2px;
                        background:#fff;
                        margin:35px 0;
                    }
                    .r_f_bot_title{
                        font-size:20px;
                        color:#fff;
                        
                        height:20px;
                        margin-bottom:10px;
                    }
                    .r_f_detail{
                        font-size:16px;
                        line-height:27px;
                    }
                }
            }
            .bot{
                    width:100%;
                    height:180px;
                    background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/bannerBot_02.png) center center no-repeat;
                    background-size:100% 100%;
                    position:absolute;
                    left:0;
                    bottom:0;
                    z-index:2;
            }
        }
        .tab{
            width:1200px;
            height:380px;
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            margin:-50px auto 0;
            align-items:flex-end;
            position:relative;
            z-index:30;
            .etly_tab{
                width:195px;  
                .link_part{
                    width:190px;
                    height:190px;
                    display:flex;
                    flex-direction:row;
                    justify-content:center;
                    align-items:center;
                    cursor: pointer;
                    p{
                        width:36px;
                        font-size:36px;
                        color:#fff;
                        margin:0 6px; 
                        line-height:36px;
                        font-weight:bold;
                    }
                }
            }
            .etly_tab:nth-of-type(1){
                height:380px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/tab_03.png) center center no-repeat;
                background-size:100% 100%;
            }
            .etly_tab:nth-of-type(3){
                height:375px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/tab_06.png) center center no-repeat;
                background-size:100% 100%;
            }
            .etly_tab:nth-of-type(2){
                height:330px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/tab_09.png) center center no-repeat;
                background-size:100% 100%;
            }
            .etly_tab:nth-of-type(4){
                height:305px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/tab_12.png) center center no-repeat;
                background-size:100% 100%;
            }
            .active{
                p{
                    color:#fffd4a!important;
                }
            }
        }
    }
    @media(max-width:750px){
        width:100%;
        overflow:hidden;
        background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/etly_03.png) center center repeat-y;
        background-size:100% auto;
        .opa{
            opacity:1!important;
        }
        .header_wrap{
            width:100%;
            overflow:hidden;
            .header{
                width:100%;
                .sw{
                    width:100%;
                    height:4rem;
                }
                .right_font{
                    width:100%;
                    background:rgba(40,177,166,.8);
                    padding:.2rem .47rem;
                    box-sizing:border-box;
                    .r_f_title{
                        font-size:.35rem;
                        color:#fff;
                        font-weight:bold;
                        text-align:center;
                    }
                    .r_f_detail{
                        font-size:.26rem;
                        line-height:.38rem;
                        color:#fff;
                    }
                    .r_f_line{
                        width:100%;
                        height:2px;
                        background:#fff;
                        margin:.3rem 0;
                    }
                    .r_f_bot_title{
                        font-size:.26rem;
                        color:#fff;                    
                        height:.26rem;
                        margin-bottom:.1rem;
                    }
                }
            }
            .bot{
                display: none;
            }
        }
        .tab{
            width:7.1rem;
            height:2.8rem;
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            margin:.5rem auto 0;
            align-items:flex-end;
            position:relative;
            z-index:0;
            .etly_tab{
                width:1.6rem;  
                .link_part{
                    width:.8rem;
                    height:1.2rem;
                    display:flex;
                    flex-direction:row;
                    justify-content:center;
                    align-items:center;
                    cursor: pointer;
                    margin:0.1rem auto;
                    p{
                        width:.36rem;
                        font-size:.36rem;
                        color:#fff;
                        margin:0 .06rem; 
                        line-height:.36rem;
                        font-weight:bold;
                    }
                }
            }
            .etly_tab:nth-of-type(1){
                height:2.8rem;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/tab_03.png) center center no-repeat;
                background-size:100% 100%;
            }
            .etly_tab:nth-of-type(3){
                height:2.76rem;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/tab_06.png) center center no-repeat;
                background-size:100% 100%;
            }
            .etly_tab:nth-of-type(2){
                height:2.34rem;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/tab_09.png) center center no-repeat;
                background-size:100% 100%;
            }
            .etly_tab:nth-of-type(4){
                height:2.2rem;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/tab_12.png) center center no-repeat;
                background-size:100% 100%;
            }
            .active{
                p{
                    color:#fffd4a!important;
                }
            }
        }
    }
`
export const EtlyDetail = styled.div`
    @media(min-width:750px){
        width:1200px;
        border:1px solid #48b393;
        overflow:hidden;  
        margin:0 auto 120px;
        padding:25px 25px;
        box-sizing:border-box;
        border-radius:6px;
        .etlyHeader{
            width:1150px;
            height:370px;
            display:flex;
            flex-direction:row;
            border-radius:10px;
            margin:0 auto;
            overflow:hidden;
            .sw{
                width:780px;
                height:370px;
            }
            .etly_describe{
                flex:1;
                background:#17a280;
                padding:0 27px;
                display:flex;
                justify-content:center;
                align-items:center;
                font-size:18px;
                color:#fff;
                line-height:26px;
                text-align:justify;
            }
        }  
        .etlyList{
            width:1150px;
            margin-top:30px;
            display:flex;
            flex-direction:row;
            justify-content:flex-start;
            overflow:hidden;
            flex-wrap:wrap;
            .tabSection:nth-of-type(3n){
                margin-right:0px
            }
            .tabSection{
                width:365px;
                height:226px;
                border-radius:10px;
                overflow:hidden;
                margin-top:25px;
                background-position:center center;
                background-repeat:no-repeat;
                margin-right:27px;
                cursor: pointer;
                .tab_section_mask{
                    width:100%;
                    height:100%;
                    background:rgba(0,0,0,.7);
                    color:#fff;
                    transition:1s all;
                    opacity:0;
                    overflow:hidden;
                    .t_s_mask_wrap{
                        width:333px;
                        height:190px;
                        margin:17px auto;
                        border:3px solid #fff;
                        display:flex;
                        flex-direction:column;
                        align-items:center;
                        justify-content:center;
                        border-radius:10px;
                        padding:0 25px;
                        box-sizing:border-box;
                        h2{
                            color:#fff;
                            font-size:18px;
                        }
                        p{
                            color:#fff;
                            font-size:14px;
                            text-align: justify;
                        }
                    }
                }
            }
            .tabSection:hover{
                .tab_section_mask{
                    opacity:1;
                }            
            }
        }
        .hide{
            display: none;
        }
        .tabMask{
            display: none!important;
        }
    }
    @media(max-width:750px){
        width:7.1rem;
        overflow:hidden;  
        margin:0 auto 0;
        .tabMask{
            width:100%;
            height:100%;
            position: fixed;
            left:0;
            top:0;
            background:rgba(0,0,0,.7);
            z-index:31;
            img{
                width:.8rem;
                float:right;
                margin-top:2rem; 
                margin-right:.2rem;
            }
            .t_s_mask_wrap{
                width: 90%;
                overflow-y: scroll;
                background: #fff;
                z-index: 1001;
                padding: 20px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                line-height: 25px;
                margin:3.5rem auto;
                border-radius:.2rem;
                h2{
                    text-align:center;
                }
            }
        }
        .hide{
            display: none;
        }
        .etlyHeader{
            width:7.1rem;
            overflow:hidden;
            .sw{
                width:100%;
                height:3.4rem;
                border-radius:.1rem;
                overflow:hidden;
            }
            .etly_describe{
                font-size:.28rem;
                color:#282828;
                line-height:.45rem;
                text-align:justify;
                margin-top:.3rem;
            }
        }  
        .etlyList{
            width:7.1rem;
            margin:0.3rem auto;
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            align-items:center;
            overflow:hidden;
            flex-wrap:wrap;
            .tabSection{
                width:3.47rem;
                height:2.17rem;
                border-radius:.1rem;
                overflow:hidden;
                background-position:center center;
                background-repeat:no-repeat;
                background-size:cover;
                margin-bottom:.3rem;
                .tab_section_mask{
                    width:100%;
                    height:100%;
                    background:rgba(0,0,0,.7);
                    color:#fff;
                    transition:1s all;
                    opacity:0;
                    overflow:hidden;
                    .t_s_mask_wrap{
                        width:3.2rem;
                        height:1.88rem;
                        margin:.14rem auto;
                        border:.02rem solid #fff;
                        display:flex;
                        flex-direction:column;
                        align-items:center;
                        justify-content:center;
                        border-radius:.1rem;
                        padding:0 .1rem;
                        box-sizing:border-box;
                        opacity:0;
                        h2{
                            color:#fff;
                            font-size:.2rem;
                        }
                        p{
                            color:#fff;
                            font-size:.15rem;
                        }
                    }
                }
            }
            .tabSection:hover{
                .tab_section_mask{
                    opacity:1;
                }            
            }
        }
    }
`