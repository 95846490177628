import React from 'react'
import styles from '../list.module.scss'
import GjjlItem from './gjjlItem'
import { Pagination } from 'antd';
import JcspItem from './jcspItem';
import JchdItem from './jchdItem';
import qs from 'query-string'
import { withRouter } from 'react-router-dom';
import { getColumKey } from '../pub'
import PubSub from 'pubsub-js';
class ListCon extends React.Component {
    state = {
        dataList: [],
        title: '',
        dataTotal: 0,
        nowIndex: 1,
        termId: 0,
        typeId: 0,
    }
    componentDidMount() {
        const { search } = this.props.location
        const { termId, typeId } = qs.parse(search)
        this.setState({ 'termId': termId == '463' ? '1025' : termId, 'typeId': typeId }, () => {
            this.getData()
        })

        this.pubData = PubSub.subscribe('termId', (key, value) => {
            console.log(key)
            console.log(value)
            this.setState({ termId: value[0] == '458' ? '560' : value[0] == '463' ? '1025' : value[0] == '464' ? '1029' : value[0] == '465' ? '1032' : value[0], typeId: value[1], nowIndex: 1 }, () => {
                this.getData()
            })
        })
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.pubData)
    }
    turnData = (newTermId, newTypeId) => {
        PubSub.publish('termId', [newTermId, newTypeId || '1'])
    }
    getData = () => {
        const { nowIndex, termId } = this.state
        React.$request({
            url: 'Portal/zt/getListsByTermId',
            method: 'get',
            params: {
                termId: termId,
                page: nowIndex,
                limit: 6,
                order: 'id ASC'
            }
        }).then(res => {
            this.setState({ dataList: res.data.lists, dataTotal: res.data.totalPage * 6 })
        })
    }
    itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <p className='turnBtn'>&lt;</p>;
        }
        if (type === 'next') {
            return <p className='turnBtn'>&gt;</p>;
        }
        return originalElement;
    }
    pageChange = (page, pageSize) => {
        this.setState({ nowIndex: page }, () => {
            this.getData()
        })
    }
    render() {
        const { dataList, dataTotal, nowIndex, termId, typeId } = this.state

        return (
            <div className={styles.listCon}>
                <p className='columTitle'>&nbsp;&nbsp;{getColumKey()[termId]}</p>
                {
                    ['458', '560', '459', '460', '461'].includes(termId) ?
                        <div className='btnGroup'>
                            <p className={termId == '560' ? 'btnActive' : ''} onClick={() => this.turnData('560')}>全部</p>
                            <p className={termId == '459' ? 'btnActive' : ''} onClick={() => this.turnData('459')}>新闻速递</p>
                            <p className={termId == '460' ? 'btnActive' : ''} onClick={() => this.turnData('460')}>精彩看点</p>
                            <p className={termId == '461' ? 'btnActive' : ''} onClick={() => this.turnData('461')}>精彩瞬间</p>
                        </div> : ['463', '1024', '1025'].includes(termId) ? 
                        <div className='btnGroup'>
                            <p className={termId == '1025' ? 'btnActive' : ''} onClick={() => this.turnData('1025', '3')}>2024</p>
                            <p className={termId == '1024' ? 'btnActive' : ''} onClick={() => this.turnData('1024', '3')}>2023</p>
                        </div> : ['464', '1028', '1029'].includes(termId) ? 
                        <div className='btnGroup'>
                            <p className={termId == '1029' ? 'btnActive' : ''} onClick={() => this.turnData('1029', '2')}>2024</p>
                            <p className={termId == '1028' ? 'btnActive' : ''} onClick={() => this.turnData('1028', '2')}>2023</p>
                        </div> : ['465', '1031', '1032'].includes(termId) ? 
                        <div className='btnGroup'>
                            <p className={termId == '1032' ? 'btnActive' : ''} onClick={() => this.turnData('1032', '2')}>2024</p>
                            <p className={termId == '1031' ? 'btnActive' : ''} onClick={() => this.turnData('1031', '2')}>2023</p>
                        </div> : null
                }
                {
                    dataList.map((item, i) => {
                        return typeId === '3' ?
                            <JcspItem {...item} termId={termId} dindex={i} key={item.id} /> : typeId === '2' ?
                                <JchdItem {...item} termId={termId} dindex={i} key={item.id} /> :
                                <GjjlItem {...item} termId={termId} key={item.id} />
                    })
                }
                <div className='paginationBox'>
                    <Pagination onChange={this.pageChange} current={nowIndex} defaultPageSize={6} total={dataTotal} showQuickJumper itemRender={this.itemRender} />
                </div>
            </div>
        )
    }
}
export default withRouter(ListCon)
