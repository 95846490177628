/*
 * @Author: your name
 * @Date: 2020-07-20 09:22:39
 * @LastEditTime: 2020-07-27 14:00:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \kxzxweb\src\pages\education\components\sdkxjt\index.js
 */
import React from 'react'
import CommonCarousel from 'common/carousel.js'
import { EduOtherWrap } from './style.js'
import { Skeleton, LocaleProvider, Pagination } from 'antd'
import zhCN from 'antd/es/locale-provider/zh_CN'
import { NavLink } from 'react-router-dom'
import NavSection from 'common/navSection'
import SectionWrap from 'common/sdkxjtSec'
import NewSection2 from 'common/sdkxjtSec/index2.js'

export const title_type_1 = (font) => {
  return (
    <div className="title_type_1">
      <span>{font}</span>
      <i className="i_large2"></i>
      <i className="i_small2"></i>
    </div>
  )
}
export const title_type_2 = (font) => {
  return (
    <div className="title_type_2" style={{ textIndent: '10px' }}>
      {font}
    </div>
  )
}
class Sdkxjt extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      otherArr: null,
      currentList: null,
      currentList2: null,
      currentPage: 1,
      allCount: null,
      Mlist: null,
      Mpage: null,
      listId: null,
      listId2: null,
    }
    this.onChange = this.onChange.bind(this)
  }
  componentDidMount() {
    this.goTo()
    this.sdkpjtInit()
  }
  zxyd(arr) {
    let zxydList = []
    for (let item in arr) {
      if (item !== 'cat_name') {
        zxydList.push(arr[item])
      }
    }
    return zxydList
  }
  onChange(pageNumber) {
    this.setState({
      currentList: null,
      currentPage: pageNumber,
    })
    React.$request({
      url:'Portal/Index/is_ajax_list',
      method:'get',
      params: {
        termid: this.state.listId,
        type: 2,
        size: 60,
        p: pageNumber,
      }
    }).then(res=>{
      this.setState({
        currentList: res.data.list,
        allCount: parseInt(res.data.count),
        Mlist: res.data.list,
        Mpage: Math.ceil(parseInt(res.data.count) / 10),
      })
    })
  }
  onChange2(pageNumber) {
    this.setState({
      currentList: null,
      currentPage: pageNumber,
    })
    React.$request({
      url:'Portal/Index/is_ajax_list',
      method:'get',
      params: {
        termid: this.state.listId2,
        type: 2,
        size: 60,
      }
    }).then(res=>{
      this.setState({
        currentList2: res.data.list,
        allCount: parseInt(res.data.count),
        Mlist: res.data.list,
        Mpage: Math.ceil(parseInt(res.data.count) / 10),
      })
    })
  }
  currentListInit() {
    this.onChange(1)
    this.onChange2(1)
  }
  sdkpjtInit() {
    React.$request({
      url:'Portal/Index/education_son',
      method:'get',
      params: {
        activity: 192,
      }
    }).then(res=>{
      this.setState({
        otherArr: res.data,
        listId: res.data.assort[0].term_id,
        listId2: res.data.assort[1].term_id,
      })
      this.currentListInit()
    })
  }
  goTo() {
    let scrollToTop = window.setInterval(function () {
      let pos = window.pageYOffset
      if (pos > 0) {
        window.scrollTo(0, pos - 10000) // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop)
      }
    }, 2)
  }
  loadMore() {
    const _page = this.state.currentPage + 1
    React.$request({
      url:'Portal/Index/is_ajax_list',
      method:'get',
      params: {
        termid: this.state.listId,
        type: 2,
        size: 6,
        currentPage: _page,
      }
    }).then(res=>{
      const _list = this.state.Mlist
      this.setState({
        Mlist: _list.concat(res.data.list),
      })
    })
    this.setState({
      currentPage: _page,
    })
  }
  render() {
    return this.state.otherArr &&
      this.state.currentList &&
      this.state.currentList2 ? (
      <EduOtherWrap>
        <NavSection />
        <div className="header">
          <CommonCarousel className="sw" Carouselarr={this.state.otherArr.lp} />
          <div className="header_detail">
            {title_type_1(this.state.otherArr.hdgk[0].post_title)}
            <p>{this.state.otherArr.hdgk[0].post_excerpt}</p>
          </div>
        </div>
        <div className="listWrap">
          <div className="paginationWrap">
            <div className="paginationWrap_title">
              <NavLink to={'/kxjtlist/193'}>{title_type_2('活动预告')}</NavLink>
              <div className="line"></div>
            </div>
            <div className="other_section_wrap">
              {this.state.currentList.splice(0,7).map((item) => {
                return <SectionWrap secarr={item} key={item.id}></SectionWrap>
              })}
            </div>
            {/* {this.state.currentList ? (
            <LocaleProvider locale={zhCN}>
              <Pagination
                size="small"
                defaultCurrent={this.state.currentPage}
                total={this.state.allCount}
                onChange={this.onChange}
                pageSize={6}
                showQuickJumper
                className="pagenation"
              />
            </LocaleProvider>
          ) : (
            <Skeleton active />
          )} */}
          </div>
          <div className="paginationWrap">
            <div className="paginationWrap_title">
              <NavLink to={'/kxjtlist/194'}>{title_type_2('活动回顾')}</NavLink>
              <div className="line"></div>
            </div>
            <div className="other_section_wrap">
              {this.state.currentList2.splice(0, 7, 0).map((item) => {
                return <NewSection2 secarr={item} key={item.id}></NewSection2>
              })}
            </div>
          </div>
        </div>

        <div className="paginationWrapM">
          <div className="paginationWrap_title">{title_type_2('动态')}</div>
          <div className="paginationWrapM_list">
            {this.state.Mlist.map((item) => {
              return <SectionWrap secarr={item} key={item.id} />
            })}
            {this.state.currentPage < this.state.Mpage ? (
              <div className="see_more" onClick={() => this.loadMore(this)}>
                查看更多
              </div>
            ) : null}
          </div>
        </div>
      </EduOtherWrap>
    ) : (
      <Skeleton active />
    )
  }
}
export default Sdkxjt
