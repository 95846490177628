import React from 'react'
import {NavWrap} from './style'
import {NavLink,withRouter} from 'react-router-dom'

class ListSection extends React.Component{
    constructor(props){
        super(props)
        this.state={
            swArr:[],
            pathList:{
                'education':30,
                'train':32,
                'mkjkx':119,
                'exhibition':29,
            },
            ycList:['423','426','427','428','429'],
            currentPath:''
        }
    }
    componentDidMount(){
        console.log(this.props.catId)
        this.getPathName(parseInt(this.props.catId))
    }
    getPathName(id){
        let that=this
        for(let i in this.state.pathList){
            if(this.state.pathList[i]===id){
                console.log('...........')
                that.setState({
                    currentPath:i
                })
            }
        }
        console.log(that.state.currentPath)
        if(this.state.ycList.includes(this.props.catId)){
            this.setState({
                currentPath:'newtrain'
            })
        }
    }
    render(){
        return(
            <NavWrap>        
                    <NavLink to='/home'>首页&gt;</NavLink>
                    <NavLink to={'/'+this.state.currentPath}>{this.props.catName}&gt;</NavLink>
                    <a>{this.props.name}</a>
            </NavWrap>
        ) 
    }
}
export default withRouter(ListSection)