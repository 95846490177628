/*
 * @Author: your name
 * @Date: 2020-03-19 10:54:01
 * @LastEditTime: 2020-09-15 17:21:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \kxzxweb\src\common\navSection\index.js
 */

import React, { Fragment } from 'react'
import { NavWrap } from './style'
import { NavLink, withRouter } from 'react-router-dom'

class NavSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      swArr: [],
      pathList: {
        service: '参观服务',
        questions: 'Q&A问答',
        exhibition: '展览展示',
        zzt: '主展馆',
        qwwx: '趣玩无限',
        sdcxkjz: '首都创新成果展',
        etly: '儿童乐园',
        education: '教育活动',
        cinema: '特效影院',
        thismonth: '本月热映',
        nextmonth: '下月预告',
        communication: '交流合作',
        gjjl: '馆际交流',
        gatjl: '港澳台交流',
        guojjl: '国际交流',
        train: '科普培训',
        talentPlan: '英才学堂',
        newtrain: '英才学堂',
        partyMasses: '党群园地',
        djgz: '党建工作',
        ghgz: '工会工作',
        105: '首都科普剧团',
        100: '科学咖啡馆',
        103: '展线课程',
        zyz: '志愿者招募',
        zxfk: '在线反馈',
        mkjkx: '科代表讲科学',
        xydgg: '信仰的高光',
        xsdll: '向上的力量',
        yszjjkx: '院士专家讲科学',
        125: '科学时光趴',
        zxgj: '中心概况',
        126: '流动科学中心',
        181: '北京科学传播大赛',
        179: '小球大世界主题展教区',
        301: '科学三分半',
        302: 'AI科学',
        303: '展教活动明星学员',
        304: '红领巾讲科学',
        309: '青少活动',
        314: '活动报道',
        192: '首都科学讲堂',
        195: '第十届北京科学嘉年华',
        52: '具象数学实验室',
        282: '地球方圆主题展',
        85:'院士专家讲科学',
      },
      currentPathList: [],
      deleteArr: [
        'thismonth',
        'nextmonth',
        'gjjl',
        'gatjl',
        'guojjl',
        'djgz',
        'ghgz',
        'tag_1',
        'tag_2',
        'tag_3',
        'tag_4',
        'tab1',
        'tab2',
        'tab3',
        'tab4',
      ],
      catalist:{
        '422':'英才课程',
        '423':'科研基础课程',
        '425':'市科协优质科普课程资源',
        '426':'英才计划宣传片',
        '427':'优秀导师学生案例',
        '428':'科学家精神系列课程',
        '429':'基础五学科课程'
    },
    }
  }
  componentDidMount() {
    this.getCurrentPath()
  }
  getCurrentPath() {
    var _paths = this.props.location.pathname.split('/')
    _paths.splice(0, 1)
    this.state.deleteArr.map((item) => {
      if (_paths.indexOf(item) >= 0) {
        _paths.splice(_paths.indexOf(item), 1)
      }
    })
    console.log(_paths)
    if(_paths.includes('cinema')){
      _paths=['exhibition',..._paths]
    }
    console.log(_paths)
    this.setState({
      currentPathList: _paths,
    })
  }
  getPathName(pathName) {
    for (let i in this.state.pathList) {
      if (pathName == i) {
        return this.state.pathList[i]
      }
    }
  }
  render() {
    const {yc} = this.props
    const {catalist} = this.state
    const findCata=()=>{
      for(var i in catalist){
          if(i == yc){
              return catalist[i]
          }
      }
  }
    return (
      <NavWrap>
        <NavLink to="/home">首页&gt;</NavLink>
        {
          yc? 
          <Fragment>
          <NavLink to="/newtrain">英才学堂&gt;</NavLink>
          {
              <a>{findCata()}</a>
          }
        </Fragment>:this.state.currentPathList
          ? this.state.currentPathList.map((item, index) => {
              return (
                <NavLink
                  to={
                    index == 0
                      ? '/' + this.state.currentPathList[0]
                      : '/' +
                        this.state.currentPathList[0] +
                        '/' +
                        this.state.currentPathList[index]
                  }
                  key={item}
                >
                  {this.getPathName(item)}
                  {this.state.currentPathList.length - 1 == index ? '' : '>'}
                </NavLink>
              )
            })
          : null
        }
        {/* {this.state.currentPathList
          ? this.state.currentPathList.map((item, index) => {
              return (
                <NavLink
                  to={
                    index == 0
                      ? '/' + this.state.currentPathList[0]
                      : '/' +
                        this.state.currentPathList[0] +
                        '/' +
                        this.state.currentPathList[index]
                  }
                  key={item}
                >
                  {this.getPathName(item)}
                  {this.state.currentPathList.length - 1 == index ? '' : '>'}
                </NavLink>
              )
            })
          : null} */}
      </NavWrap>
    )
  }
}
export default withRouter(NavSection)
