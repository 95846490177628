import styled from 'styled-components'

export const ZkgkWrap = styled.div`
    width:100%;
    overflow:hidden;
    @media(max-width:750px){
        width:7.1rem;
        margin:.1rem auto;
        font-size:.26rem;
        line-height:.4rem;
        color:#272727;
        h1{
            font-size:.36rem;
            color:#404040;
            font-weight:bold;
            text-align:center;
            line-height:.72rem;
        }
        p{
            font-size:.3rem;
            text-indent:.6rem;
            line-height:.45rem;
        }
    }
    @media(min-width:750px){
        width:940px;
        margin: auto;
        h1{
            font-size:36px;
            color:#404040;
            font-weight:bold;
            text-align:center;
        }
        p{
            color:#404040;
            font-size:18px;
            text-indent:30px;
        }
    }    
`