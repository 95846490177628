import React from 'react'
import CommonCarousel from 'common/carousel.js'
import {EtlyDetail} from './style'
import {Skeleton} from 'antd';

var _current_mask_index = 0;
class EtlyTab extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            headerArr:null,
            tabArr:null,
            currentId:null,
            arr:null,
            post_title:null,
            post_excerpt:null,
            mask_flag:false
        }
    }
    componentDidMount(){
        this.setState({
            arr:this.props.tabArr,
        })
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.tabArr !== this.props.tabArr) {
            this.setState({
                arr:this.props.tabArr,
            })
        }

    }
    showMask(index){
        this.setState({
            post_title:this.state.arr.tp[index].post_title,
            post_excerpt:this.state.arr.tp[index].post_excerpt,
            mask_flag:true 
        })
    }
    hideMask(){
        this.setState({
            mask_flag:false 
        })
    }
    render(){
        return(
            this.state.arr?
            <EtlyDetail>
                        <div className='etlyHeader'>
                            <CommonCarousel className='sw' Carouselarr={this.state.arr.lp}/>
                            <div className="etly_describe">
                                <div dangerouslySetInnerHTML={{__html:this.state.arr.lp[0].post_excerpt}}></div>
                            </div>
                        </div>
                        <div className="etlyList">
                            {
                                this.state.arr.tp.map((item,index)=>{
                                    let _style = {}
                                    if(item.path){
                                        _style = {
                                            backgroundImage:"url("+item.path+")"
                                        }
                                    }else{
                                        _style = {
                                            // 换肤-logo
                                            backgroundImage:"url(/img/skin/logo_2024.png)",
                                            backgroundSize:'156px 51px'
                                        }
                                    }
                                    return(
                                        <div className='tabSection' key={item.id} style={_style} onClick={()=>this.showMask(index)}> 
                                            <div className="tab_section_mask" >
                                                <div className={_current_mask_index==index?"t_s_mask_wrap":'t_s_mask_wrap'} >
                                                    <h2>{item.post_title}</h2>
                                                    <p className=''>
                                                        {item.post_excerpt}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className={this.state.mask_flag?'tabMask':'hide tabMask'}> 
                                <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/close.png`} alt='' onClick={()=>this.hideMask()}/>
                                <div className='t_s_mask_wrap'>
                                    <h2>{this.state.post_title}</h2>
                                    <p>
                                        {this.state.post_excerpt}
                                    </p>
                                </div>

                            </div>
                        </div>      
            </EtlyDetail>
            :<Skeleton active />
        )
    }
}
export default EtlyTab