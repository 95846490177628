import React from 'react';
import {NavLink} from 'react-router-dom';
import zhCN from 'antd/es/locale-provider/zh_CN';
import { Pagination,Skeleton, LocaleProvider} from 'antd';
import 'antd/dist/antd.css';
import {GjjlWrap} from './style'
import SectionWrap from 'common/newSection'

class Gjjl extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            page:1,
            count:10,
            list:[],
            allCount:null,
            Mpage:null,
            Mlist:[]
        }
        this.onChange = this.onChange.bind(this)
    }
    iniArr(){
        React.$request({
            url:'Portal/Index/is_ajax_list',
            method:'get',
            params: {
                type:2,
                termid:39,
                size:this.state.count,
                p:this.state.page
            }
        }).then(res=>{
            this.setState({
                list:res.data.list,
                allCount:parseInt(res.data.count),
                Mpage:Math.ceil(parseInt(res.data.count)/10),
                Mlist:res.data.list
            })
        })
    }
    componentDidMount(){
        this.iniArr()
        this.goTo()
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    onChange(pageNumber) {
        React.$request({
            url:'Portal/Index/is_ajax_list',
            method:'get',
            params: {
                type:2,
                termid:39,
                size:this.state.count,
                p:pageNumber
            }
        }).then(res=>{
            this.setState({
                list:res.data.list,
                allCount:parseInt(res.data.count)
            })
        })
    }
    loadMore(){
        const _page = this.state.page+1
        React.$request({
            url:'Portal/Index/ListCat',
            method:'get',
            params: {
                type:2,
                termid:39,
                size: this.state.count,
                p:_page
            }
        }).then(res=>{
            const _list = this.state.Mlist
            this.setState({
                Mlist:_list.concat(res.data.list)
            })
        })
        this.setState({
            page:_page
        })
    }
    render(){
        return(
            <GjjlWrap>
                <div className="hdbd_wrap">
                        {
                            this.state.list.map((item)=>{
                                return(
                                    <SectionWrap secarr={item} key={item.id}/>
                                )
                            })   
                        }
                </div>
                <div className="hdbd_wrapM">
                        {
                            this.state.Mlist.map((item)=>{
                                return(
                                    <SectionWrap secarr={item} key={item.id}/>
                                )
                            })   
                        }
                </div>
                {
                        this.state.list?
                        <LocaleProvider locale={zhCN}>
                            <Pagination  size="small" defaultCurrent={this.state.page} total={this.state.allCount} onChange={this.onChange} pageSize={10} showQuickJumper className='pagenation'/> 
                        </LocaleProvider>:<Skeleton active />
                }  
                {
                        this.state.page<this.state.Mpage?                    
                        <div className="see_more" onClick={()=>this.loadMore(this)}>
                            查看更多
                        </div>:null
                }   
            </GjjlWrap>
        )
    }
}
export default Gjjl