import React from 'react'
import styles from './index.module.scss'
import TitleBox from '../titleBox'
import { changeImg } from '../../pub'
import { withRouter } from 'react-router-dom';


class ThreeLine extends React.Component {
  turn(id, termId) {
    console.log(id)
    console.log(termId)  
    this.props.history.push(`/nyrzZqyhContent?articleId=${id}&termId=${termId}`)
  }
  render() {
    const {hdyg} = this.props
    return (
        <>
        <TitleBox name={hdyg.name} termId={hdyg.term_id}/>
        <div className={styles.threeLine} >
           {
                hdyg.articles.slice(0,6).map((el)=>{
                    return (
                        <div onClick={()=>this.turn(el.id,el.term_id)} className="threeItem" key={el.id}>
                             <img src={changeImg(el.smeta.photo[0].url)} alt="" />
                             <p className="title">{el.post_title}</p>
                             <p className="time">{el.post_date.split(' ')[0]}</p>
                        </div>
                    )
                })
           }
           

        </div>
        </>
    )
  }
}
export default withRouter(ThreeLine)
