import React, { memo, useEffect, useState } from 'react'
import { LszlWrap } from './style'
import NavSection from 'common/navSection'
import CommonCarousel from './slide'
import CommonCarouselm from './slidem'
import SectionWrap from 'common/newSection'

const Lszl = memo(() => {
  const title_type_1 = (font) => {
    return (
      <div className='title_type_1'>
        <i className='i_small'></i>
        <i className='i_large'></i>
        <span>{font}</span>
        <i className='i_large2'></i>
        <i className='i_small2'></i>
      </div>
    )
  }
  const commonAbstra = (font) => {
    return (
      <div className="commAbstra">
        <div className="cal">展区<br />概述</div>
        <div className="car">{font}</div>
      </div>
    )
  }
  const goTo = () => {
    let scrollToTop = window.setInterval(function () {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 10000); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 2);
    console.log('goto')
  }
  const [arr, setArr] = useState([
    {
      title: '算盘模型',
      abstra: '榫卯结构是极为精巧的发明，这种构件连接方式，使得中国传统的木结构成为超越了当代建筑排架、框架的特殊柔性结构体。',
      image: `${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/20200526/5eccda1258b59.png`
    }, {
      title: '算盘模型',
      abstra: '榫卯结构是极为精巧的发明，这种构件连接方式，使得中国传统的木结构成为超越了当代建筑排架、框架的特殊柔性结构体。',
      image: `${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/20200526/5eccda1258b59.png`
    }, {
      title: '算盘模型',
      abstra: '榫卯结构是极为精巧的发明，这种构件连接方式，使得中国传统的木结构成为超越了当代建筑排架、框架的特殊柔性结构体。',
      image: `${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/20200526/5eccda1258b59.png`
    }, {
      title: '算盘模型',
      abstra: '榫卯结构是极为精巧的发明，这种构件连接方式，使得中国传统的木结构成为超越了当代建筑排架、框架的特殊柔性结构体。',
      image: `${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/20200526/5eccda1258b59.png`
    }, {
      title: '算盘模型',
      abstra: '榫卯结构是极为精巧的发明，这种构件连接方式，使得中国传统的木结构成为超越了当代建筑排架、框架的特殊柔性结构体。',
      image: `${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/20200526/5eccda1258b59.png`
    }, {
      title: '算盘模型',
      abstra: '榫卯结构是极为精巧的发明，这种构件连接方式，使得中国传统的木结构成为超越了当代建筑排架、框架的特殊柔性结构体。',
      image: `${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/20200526/5eccda1258b59.png`
    }
  ])
  const [state, setState] = useState()
  useEffect(() => {
    goTo()
    React.$request({
      url: 'Portal/index/exhibition_hall_son',
      method: 'get',
      params: {
        termid: 52
      }
    }).then(res => {
      setState(res.data)
      console.log(res.data)
    })
  }, [])
  return (
    <>
      {
        state &&
        <LszlWrap>
          <NavSection />
          <div className="top">
            <video className="video" controls="controls" src={state.jxsys[0].video} poster={state.jxsys[0].path}></video>
            <div className="topcontent">
              <div className="title">{state.jxsys[0].post_title}</div>
              <div className="abstra">{state.jxsys[0].post_excerpt}</div>
              <div className="area">{state.jxsys[0].posts_address}</div>
            </div>
          </div>
          <div className="sxzs">
            {title_type_1(state.sxzs.excerpt[0].post_title)}
            <div className="sxzx_top">
              <div className="left">
                {state.sxzs.excerpt[0].post_excerpt}
              </div>
              <div className="right">
                <div className="cor">
                </div>
                <img src={state.sxzs.excerpt[0].path} alt="" />
              </div>
            </div>
            <div className="sxzx_list">
              {state.sxzs.list.map((item, index) => {
                return (
                  <div className="tab" style={{ 'backgroundImage': 'url(' + item.path + ')' }} key={index}>
                    <div className="title">
                      {item.post_title}
                    </div>
                    <div className="abstra">
                      {item.post_excerpt}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="sxzm">
            {title_type_1(state.sxzm.excerpt[0].post_title)}
            {commonAbstra(state.sxzm.excerpt[0].post_excerpt)}
            <div className="sxzx_list">
              {state.sxzm.list.map((item, index) => {
                return (
                  <div className="tab" style={{ 'backgroundImage': 'url(' + item.path + ')' }} key={index}>
                    <div className="title">
                      {item.post_title}
                    </div>
                    <div className="abstra">
                      {item.post_excerpt}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="sxzy">
            {title_type_1(state.sxzq.excerpt[0].post_title)}
            {commonAbstra(state.sxzq.excerpt[0].post_excerpt)}
            <CommonCarousel list={state.sxzq.list} />
            <CommonCarouselm list={state.sxzq.list} />
          </div>
          <div className="sxzy">
            {title_type_1(state.sxzy.excerpt[0].post_title)}
            <div className="sxzy_wrap">
              <div className="sxzy_left sxzy_leftpc" style={{ 'backgroundImage': `url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/lszl/lszl_1_03.png)` }}>
                {state.sxzy.excerpt[0].post_excerpt}
              </div>
              <div className="sxzy_left sxzy_leftm" style={{ 'backgroundImage': `url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/lszl/lszlmbg_03.png)` }}>
                {state.sxzy.excerpt[0].post_excerpt}
              </div>
              <div className="sxzy_list">
                {state.sxzy.list.slice(0, 4).map((item, index) => {
                  return (
                    <div className="tab" style={{ 'backgroundImage': 'url(' + item.path + ')' }} key={index}>
                      <div className="title">
                        {item.post_title}
                      </div>
                      <div className="abstra">
                        {item.post_excerpt}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="zqdt">
            {title_type_1('展区动态')}
            <div className="hdbd_wrap">
              {
                state.zqdt.map((item) => {
                  return (
                    <SectionWrap secarr={item} key={item.id} />
                  )
                })
              }
            </div>
          </div>
        </LszlWrap>
      }
    </>
  )
})

export default Lszl