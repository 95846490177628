import styled from 'styled-components';

export const HdygWrap = styled.div`
    @media(max-width:750px){
        display:none;
    }
    @media(min-width:750px){
        width:953px;
        margin:0 auto;
        .empty{
            margin:100px auto;
        }
        .hdyg_title{
            width:1200px;
            height:28px;
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            align-items:center;
            margin-top:40px;
            i{
                width:12px;
                height:28px;
                background:#009cbe;
            }
            span{
                font-size:28px;
                color:#323232;
                font-weight:bold;
                margin:0 20px 0 10px;
            }
            .line{
               flex:1;
               height:5px;
               background:#009cbe; 
            }
        }
        .tabWrap{
            width:1200px;
            height:40px;
            margin:0 auto 0;
            display:flex;
            flex-direction:row;
            text-align:center;
            .tab_label{
                //width:100px;
                padding:0 20px;
                height:35px;
                font-size:18px;
                line-height:35px;
                border:1px solid #009cbe;
                color:#009cbe;
                cursor: pointer;
                margin-right:10px;
                border-radius:6px;
            }
            .tab_label_active{
                border:1px solid #fff;
                color:#fff;      
                background:#009cbe;   
                border-radius:6px;       
            }
        }
        .hdyg_list{
            width:100%;
            margin-top:5px;
            li{
                font-size:13px;
                height:25px;
                margin-top:17px;
                list-style:none;
                cursor: pointer;
                a{
                    color:#323232;
                }
                .tag{
                    padding:6px;
                    background:#dfdfdf;
                    color:#212121;
                }
                .title{
                    width:880px;
                    // margin-left:20px;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                }
                .time{
                    color:#858484;
                    float:right;
                    margin-right:21px;
                }
            }
            li:hover{
                font-size:13px;
                height:25px;
                margin-top:17px;
                list-style:none;
                .tag{
                    padding:6px;
                    background:#009cbe;
                    color:#fff;
                }
                .title{
                    width:880px;
                    // margin-left:20px;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                    color:#009cbe;
                }
                .time{
                    color:#858484;
                    float:right;
                    margin-right:21px;
                }
            }
        }
        .pagenation{
            margin:60px auto 80px;
            text-align:center;
            .ant-pagination-item{
                
            }
            .ant-pagination-item-active {
                border-radius:0;
                color:#fff;
                background: #009cbe;
                a{
                    color:#fff;     
                }
            }
        }
    }
`

export const HdygWrapM = styled.div`
    @media(min-width:750px){
        display:none;
    }
    @media(max-width:750px){
        width:7.1rem;
        margin:0 auto;
        .hdyg_list{
            width:100%;
            margin-top:.2rem;
            .hdyg_section{
                overflow:hidden;
                margin-top:17px;
                list-style:none;
                display:flex;
                justify-content:space-between;
                align-items:center;
                flex-direction:row;
                .xwzx_section_left{
                    width:1.4rem;
                        .time{
                            color:#858484;
                            font-size:.2rem;
                            text-align:center;
                            margin-top:.1rem;
                            width:100%;
                            display: block;
                        }
                        .tag{
                            font-size:.24rem;
                            padding:.08rem;
                            background:#dfdfdf;
                            color:#212121;
                        }
                }
                .title{
                    width:5.7rem;
                    overflow: hidden;
                    border-bottom:0.02rem solid #d0d0d0;
                    padding:.3rem 0 .36rem;
                    font-size:.26rem;
                    line-height:.4rem;
                    color:#333333;
                }
            }
        }
        .see_more{
            width:2.5rem;
            height:.64rem;
            background:#009cbd;
            border-radius:.04rem;
            font-size:.3rem;
            color:#fff;
            line-height:.64rem;
            text-align:center;
            margin:.5rem auto .7rem;
        }
    }
`
