import React from 'react';
import CommonCarousel from 'common/carousel.js'
import { KexkfgWrap } from './style'
import { Skeleton } from 'antd'
import { NavLink } from 'react-router-dom'
import NavSection from 'common/navSection'
export const title_type_1 = (font) => {
    return (
        <div className='title_type_1'>
            <i className='i_small'></i>
            <i className='i_large'></i>
            <span>{font}</span>
            <i className='i_large2'></i>
            <i className='i_small2'></i>
        </div>
    )
}
class EduKxkfg extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            kxkfgArr: null,
            currentVideo: null,
            poster: null,
            currentId: null
        }
    }
    componentDidMount() {
        this.sdkpjtInit()
        this.goTo()
    }
    zxyd(arr) {
        let zxydList = []
        for (let item in arr) {
            if (item !== 'cat_name') {
                zxydList.push(arr[item])
            }
        }
        return zxydList
    }
    sdkpjtInit() {
        React.$request({
            url:'Portal/index/education_son',
            method:'get',
            params:{
                activity: 100
            }
          }).then(res=>{
            this.setState({
                kxkfgArr: res.data,
                currentVideo: res.data.hdhx[0].video,
                poster: res.data.hdhx[0].path,
                currentId: res.data.hdhx[0].id
            })
          })
    }
    goTo() {
        let scrollToTop = window.setInterval(function () {
            let pos = window.pageYOffset
            console.log('here')
            if (pos > 0) {
                window.scrollTo(0, pos - 10000) // how far to scroll on each step
            } else {
                window.clearInterval(scrollToTop)
            }
        }, 2)
    }
    changeVideo(video, path, id) {
        this.setState({
            currentVideo: video,
            poster: path,
            currentId: id
        })
    }
    render() {
        return (
            this.state.kxkfgArr ?
                <KexkfgWrap>
                    <NavSection />
                    <div className="header">
                        <CommonCarousel className='sw' Carouselarr={this.state.kxkfgArr.lp} />
                        <div className="header_detail">
                            <h2>
                                <span>{this.state.kxkfgArr.kxkfg[0].post_title}</span>
                                <i className='i_large2'></i>
                                <i className='i_small2'></i>
                            </h2>
                            <p>{this.state.kxkfgArr.kxkfg[0].post_excerpt}</p>
                        </div>
                    </div>
                    <div className="wqhd">
                        <NavLink className='title_type_1' to={'/list/' + 101}>
                            <i className='i_small'></i>
                            <i className='i_large'></i>
                            <span>{this.state.kxkfgArr.wqhd.cat_name}</span>
                            <i className='i_large2'></i>
                            <i className='i_small2'></i>
                        </NavLink>
                        <div className="wqhd_wrap">
                            {
                                this.zxyd(this.state.kxkfgArr.wqhd).slice(0, 4).map((item) => {
                                    let _style = {
                                        backgroundImage: "url(" + item.path + ")"
                                    }
                                    return (
                                        <NavLink key={item.id} className='wqhd_section' style={_style} to={'/article/' + item.id} >
                                            {
                                                parseInt(item.istop, 10) > 0 ?
                                                    <div className='zd'>
                                                        <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zd.png`} alt="" />
                                                    </div> : ''
                                            }
                                            <div className="wqhd_section_p">{item.post_title}</div>
                                        </NavLink>
                                    )
                                })
                            }

                        </div>
                    </div>
                    <div className="hdhx">
                        {
                            title_type_1(this.state.kxkfgArr.hdhx.cat_name)
                        }
                        <div className="hdhx_wrap">
                            <div className="video">
                                <video src={this.state.currentVideo} controls='controls' poster={this.state.poster}></video>
                            </div>
                            <div className="hdxh_list">
                                {
                                    this.zxyd(this.state.kxkfgArr.hdhx).map((item) => {
                                        return (
                                            <div className={item.id === this.state.currentId ? 'hdhx_link hdhx_link_active' : 'hdhx_link'} key={item.id} onClick={() => this.changeVideo(item.video, item.path, item.id)}>
                                                {item.post_title}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="hdhxM">
                        {
                            title_type_1(this.state.kxkfgArr.hdhx.cat_name)
                        }
                        <div className="hdxh_list">
                            {
                                this.zxyd(this.state.kxkfgArr.hdhx).map((item) => {
                                    return (
                                        <div className="hdhx_link" key={item.id}>
                                            <div className="video">
                                                <video src={item.video} controls='controls' poster={item.path}></video>
                                            </div>
                                            <p>{item.post_title}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </KexkfgWrap>
                : <Skeleton active />
        )
    }
}
export default EduKxkfg