import React,{Component, Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import { Pagination,Skeleton,LocaleProvider} from 'antd';
import 'antd/dist/antd.css';
import zhCN from 'antd/es/locale-provider/zh_CN';
import {HomeWrapM} from './style'
import SectionWrap from 'common/newSection'
import { changeImg } from '../../pub';


class Home extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            term: [],
            term_list: [],
        }
    }
    iniArr(){
        React.$request({
            url:'Portal/Index/termInfo',
            method:'get',
            params: {
                term_id:this.props.match.params.termid,
            }
        }).then(res=>{
            this.setState({
                term:res.data
            })
        }) 
        React.$request({
            url:'Portal/Index/is_ajax_classify',
            method:'get',
            params: {
                term_id:this.props.match.params.termid,
            }
        }).then(res=>{
            this.setState({
                term_list:res.data
            })
        }) 
    }
    componentDidMount(){
        document.title = '微服务'
        this.iniArr()
    }
    
    render(){
        return(
            <Fragment>
                <HomeWrapM>
                <div className="page-container">
                    <div className="logo">
                        <img src={`/img/skin/logo_2024.png`} alt="Logo" />
                    </div>
                    <div className="background">
                        <img src={`/img/weifuwu/jianzhu.png`} alt="Background" />
                    </div>
                    <h1 className="title">{this.state.term.name}</h1>
                    <div className="grid-container">
                        {this.state.term_list.map((list, index) => (
                            <a className={`type${this.state.term.css_class}`} href={!list.post_keywords ? list.term_link : 'javaScript:;'} key={index} >
                                <div className="grid-item">
                                    <img src={changeImg(list.thumb)} alt={`Logo ${index + 1}`} />
                                    {/* {!list.post_keywords && (
                                        <h2 dangerouslySetInnerHTML={{
                                            __html: list.name.split('|').join("<br />")
                                        }} >
                                        </h2>
                                    )} */}
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
                </HomeWrapM>
            </Fragment>

        )
    }
}
export default Home
