import { combineReducers } from 'redux-immutable';
import {reducer as libraryReducers} from './../pages/library/store'
import {reducer as serviceReducers} from './../pages/service/store'
import {reducer as exhibitionReducers} from './../pages/exhibition/store'
import {reducer as headerReducers} from './../pages/header/store'
const reducer = combineReducers({
    library:libraryReducers,
    service:serviceReducers,
    exhibition:exhibitionReducers,
    history:headerReducers
});
export default reducer;