import React, { memo, useEffect, useState } from 'react'
import { VideoWrap } from './style'
const VideoList = memo((props) => {
  const { lists } = props
  const [list, setList] = useState([])
  const [curId, setCurId] = useState(lists[0].id)
  const [curVideo, setCurVideo] = useState({
    video: lists[0].smeta.thumb,
    path: lists[0].smeta.photo[0].url,
  })
  // useEffect(() => {
  //   Axios('', {
  //     g: 'Portal',
  //     m: axios_index,
  //     a: 'is_ajax_list',
  //     termid: term_id,
  //     type: type,
  //     size: 10,
  //   }).then((res) => {
  //     setList(res.data)
  //     setCurId(res.data[0].id)
  //     setCurVideo({
  //       video: res.data[0].video,
  //       path: res.data[0].path
  //     })
  //   })
  // }, [term_id])
  const changeVideo = (video, path, id) => {
    setCurId(id)
    setCurVideo({
      video,
      path
    })
  }
  return (
    <VideoWrap>
      {/* <div className='title_type_4'>
        <i className='i_small'></i>
        <i className='i_large'></i>
        <span>{props.name}</span>
        <i className='i_large2'></i>
        <i className='i_small2'></i>
      </div> */}
      {
        lists.length &&
        <div className="hdhx">
          <div className="hdhx_wrap">
            <div className="video" style={{ width: '745px', height: '420px', marginTop: '12px' }}>
              <video src={`${process.env.REACT_APP_IMGBASE}/administrator/data/` + curVideo.video} controls='controls' poster={curVideo.path}></video>
            </div>
            <div className="hdxh_list" style={{ overflow: 'auto' }}>
              {
                lists.map((item) => {
                  return (
                    <div key={item.id} onClick={() => changeVideo(item.smeta.thumb, item.smeta.photo[0].url, item.id)} className={item.id === curId ? 'hdhx_link hdhx_link_active' : 'hdhx_link'} >
                      {item.post_title}
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      }
    </VideoWrap>
  )
})

export default VideoList