import React from 'react'
import {CaretLeftOutlined} from '@ant-design/icons';
import styles from '../index.module.scss'
import { changeImg } from '../pub'

export default class VideoSelect extends React.Component {
state={
    videoDefault: '',
    posterDefault: '',
    active:0
}
componentDidMount(){
    this.setState({
        'videoDefault': changeImg(this.props.gfspData.articles[0].smeta.thumb),
        'posterDefault': changeImg(this.props.gfspData.articles[0].smeta.photo[0].url)
    })
}
checkVideo(v, i,j) {
    this.setState({
        'videoDefault': changeImg(v),
        'posterDefault': changeImg(i),
        'active':j
    })
    // this.forceUpdate()
}
  render() {
    const { gfspData } = this.props
    console.log('==========')
    const {videoDefault, posterDefault,active} = this.state
    return (
        <div className={styles.videoSelect}>
            <div className='videoBox'>
                <video controls poster={posterDefault} src={videoDefault} type="video/mp4">
                </video>
            </div>
            <div className='videoList'>
            {gfspData.articles.slice(0,7).map((el,i)=>{
                return (
                <div className={i==active?'videoItem videoActive':'videoItem'} key={el.id}  onClick={()=>this.checkVideo(el.smeta.thumb, el.smeta.photo[0].url,i)}>
                    {/* <CaretLeftOutlined className='iconImg'/> */}
                    <p>{el.post_title}</p>
                </div>
                )
                })
            }
            </div>
        </div>
    )
  }
}
