/*
 * @Author: your name
 * @Date: 2020-03-19 10:54:01
 * @LastEditTime: 2020-10-20 09:57:52
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \kxzxweb\src\common\index.js
 */
import originJsonp from 'jsonp'
import axios from 'axios'

let BaseUrl = ''
export function jsonp(url, data, option) {
  BaseUrl = process.env.REACT_APP_APIBASE
  BaseUrl += url
  BaseUrl += (BaseUrl.indexOf('?') < 0 ? '?' : '&') + param(data)
  return new Promise((resolve, reject) => {
    originJsonp(BaseUrl, option, (err, data) => {
      if (!err) {
        resolve(data)
      } else {
        reject(err)
      }
    })
  })
}
export function jsonp2(url, data, option) {
  BaseUrl = url
  BaseUrl += (BaseUrl.indexOf('?') < 0 ? '?' : '&') + param(data)
  return new Promise((resolve, reject) => {
    originJsonp(BaseUrl, option, (err, data) => {
      if (!err) {
        resolve(data)
      } else {
        reject(err)
      }
    })
  })
}
// http://kxzx.scimall.org/
// http://111.203.124.253
export function Axios(url, data) {
  let axiosUrl = ip
  axiosUrl += (axiosUrl.indexOf('?') < 0 ? '?' : '&') + param(data)
  return new Promise((resolve, reject) => {
    axios
      .get(axiosUrl)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

function param(data) {
  let url = ''
  for (var k in data) {
    let value = data[k] !== undefined ? data[k] : ''
    url += '&' + k + '=' + encodeURIComponent(value)
  }
  return url ? url.substring(1) : ''
}

export function GoTo() {
  let scrollToTop = window.setInterval(function () {
    let pos = window.pageYOffset
    if (pos > 0) {
      window.scrollTo(0, pos - 10000) // how far to scroll on each step
    } else {
      window.clearInterval(scrollToTop)
    }
  }, 2)
}
