/*
 * @Author: your name
 * @Date: 2020-03-19 10:54:01
 * @LastEditTime: 2020-08-04 10:11:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \kxzxweb\src\pages\footer\index.js
 */

import React from 'react'
import { NavLink } from 'react-router-dom'
import { FooterWrap } from './style.js'

class Footer extends React.Component {
  mooterRef=React.createRef()
  aaa = () => {
    this.refs.code.style.display = 'block'
  }
  bbb = () => {
    this.refs.code.style.display = 'none'
  }
  
  showMooter=()=>{
    console.log(this.mooterRef.current)
    this.mooterRef.current.style.display='block'
  }

  render() {
    return (
      <FooterWrap>
        <div className="footer">
          <div className="footer_top">
            <div className="footer_top_left">
              <div className="footer_meun">
                <div className="footer_menu_title">参观服务</div>
                <div className="footer_menu_list">
                  <a
                    className="footer_link"
                    href="https://ticket.bjsc.net.cn/"
                    target="_blank"
                  >
                    在线预订
                  </a>
                  <NavLink className="footer_link" to="/article/1353">
                    交通路线
                  </NavLink>
                  <NavLink className="footer_link" to="/library/xnmy">
                    场馆地图
                  </NavLink>
                </div>
              </div>
              <div className="footer_meun">
                <div className="footer_menu_title">关于中心</div>
                <div className="footer_menu_list">
                  <NavLink className="footer_link" to="/library/zxgk">
                    中心概况
                  </NavLink>
                  {/* <NavLink className="footer_link" to="/library/gzzc">
                    馆长致辞
                  </NavLink> */}
                  <NavLink className="footer_link" to="/library/tzgg">
                    通知公告
                  </NavLink>
                  <NavLink className="footer_link" to="/library/xwzx">
                    新闻资讯
                  </NavLink>
                </div>
              </div>
              <div className="footer_meun">
                <div className="footer_menu_title">联系我们</div>
                <div className="footer_menu_list">
                  <div className="footer_link">
                    地址：中国北京市西城区北辰路9号院
                  </div>
                  <div className="footer_link">
                    观众服务电话：010-83059999 （9:30~17:00）
                  </div>
                  <div className="footer_link">EMAIL：zixun@bjsc.net.cn</div>
                </div>
              </div>
            </div>

            <div className="footer_top_right">
              {/* <div className="ir_img">
                  <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/footer/footer_06.png" alt=""/>
                  <span>扫一扫，关注我们</span>
              </div> */}
              <NavLink to="/zyz">
                <div className="ir_img">
                  <img style={{borderRadius:"50%"}} src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/curatorSession/zyzBtn.png`}/>
                  <span>志愿者招募<br/>&nbsp;</span>
                </div>
              </NavLink>
              <div style={{marginLeft:"20px"}} className="ir_img" onClick={this.showMooter} onMouseOver={this.aaa} onMouseOut={this.bbb}>
                <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/footer/icon.png`} alt="" />
                <span>北京科学中心<br/>融媒体矩阵</span>
              </div>
              
              {/*<div className="ir_img">*/}
              {/*  <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/footer/ar.jpg" alt="" />*/}
              {/*  <span>北京科学中心AR</span>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="line"></div>
          <div className="footer_bot">
            <span>©2018 北京科学中心 All Rights Reserved</span>
            <a href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank'>@京ICP备18007054号-1</a>
            <a href='https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010202007950' target='_blank'>京公网安备 11010202007950号</a>
          </div>
        </div>
        <div className="code-list" ref="code">
          <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/footer/code-list.png`} />
        </div>
        <div className='jzmooter' ref={this.mooterRef}>
            <div className='conBox'>
              <img className='closeBtn' src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/curatorSession/close.png`} onClick={()=>this.mooterRef.current.style.display='none'}/>
              <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/footer/code-list.png`} />
            </div>
        </div>
      </FooterWrap>
    )
  }
}
export default Footer
