import React,{Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import { Pagination,Skeleton,LocaleProvider} from 'antd';
import 'antd/dist/antd.css';
import zhCN from 'antd/es/locale-provider/zh_CN';
import {XwzxWrap,XwzxWrapM} from './style'
import SectionWrap from 'common/newSection'

class Xwzx extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            page: 1,
            count: 10,
            list: [],
            allCount: null,
            Mlist:[],
            Mpage:null
        }
        this.onChange = this.onChange.bind(this)
    }
    iniArr(){
        let page = localStorage.getItem('NOW_PAGE')
        if(page){
            page = parseInt(page)
        }
        localStorage.removeItem('NOW_PAGE')

        page = page || 1
        this.setState({
            page:page
        })
        localStorage.removeItem('NOW_PAGE')
        React.$request({
            url:'Portal/Index/is_ajax_list',
            method:'get',
            params: {
                type:2,
                termid:47,
                size:this.state.count,
                p:page
            }
        }).then(res=>{
            this.setState({
                list:res.data.list,
                allCount:parseInt(res.data.count),
                Mpage:Math.ceil(parseInt(res.data.count)/10),
                Mlist:res.data.list
            })
        }) 
    }
    componentDidMount(){
        document.title = '新闻资讯'
        this.iniArr()
        this.goTo()
    }
    onChange(pageNumber) {
        this.setState({
            list:[],
            page:pageNumber
        })
        React.$request({
            url:'Portal/Index/is_ajax_list',
            method:'get',
            params: {
                type:2,
                termid:47,
                size:this.state.count,
                p:pageNumber
            }
        }).then(res=>{
            this.setState({
                list:res.data.list,
                allCount:parseInt(res.data.count)
            })
        }) 
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    loadMore(){
        const _page = this.state.page+1
        React.$request({
            url:'Portal/Index/is_ajax_list',
            method:'get',
            params: {
                type: 2,
                termid: 47,
                size: this.state.count,
                p:_page
            }
        }).then(res=>{
            const _list = this.state.Mlist
            this.setState({
                Mlist:_list.concat(res.data.list)
            })
        }) 
        this.setState({
            page:_page
        })
    }
    loadRecordDetail(id){
        localStorage.removeItem('NOW_PAGE')
        localStorage.setItem('article_'+id,this.state.page)
    }
    componentWillUnmount(){
        localStorage.removeItem('NOW_PAGE')
    }
    clickCount(item) {
        React.$request({
            url:'Portal/Index/storeArticleClicks',
            method:'get',
            params: {
                id: item.id
            }
        }).then(res=>{
            console.log(res)
        }) 

    }
    render(){
        return(
            <Fragment>
                <XwzxWrap>
                    <div className="xwzx_title">
                        <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/library/xwzx/xwzx_03.png`} alt=""/>
                    </div>
                    <div className="wxzx_list">
                        {
                            this.state.list.map((item)=>{
                                let _style = {}
                                if(item.path){
                                    _style = {
                                        backgroundImage:"url("+item.path+")"
                                    }
                                }else{
                                    _style = {
                                        backgroundImage:`url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/header/header_03.png)`,
                                        backgroundSize:'156px 51px'
                                    }
                                }
                                return(
                                    <div key={item.id} className='xwzxSection'>
                                        {
                                            item.posts_link?
                                                <a className='hdbd_section_img_wrap' onClick={() => this.clickCount(item) } style={_style} href={item.posts_link} target={item.posts_link.indexOf(location.host)>=0?'':'_blank'} />
                                                :
                                                <NavLink className="hdbd_section_img_wrap" onClick={() => this.loadRecordDetail(item.id)} style={_style} to={'/article/' + item.id}>
                                                    {
                                                        parseInt(item.istop, 10) > 0 ?
                                                            <div className='zd'>
                                                                <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zd.png`} alt=""/>
                                                            </div>:''
                                                    }
                                                </NavLink>
                                        }
                                        <div className="hdbd_section_detail">
                                            {
                                                item.posts_link
                                                    ?
                                                    <a className='hdbd_section_detail_title' onClick={() => this.clickCount(item) } href={item.posts_link} target={item.posts_link.indexOf(location.host)>=0?'':'_blank'}>
                                                        {item.post_title}
                                                    </a>
                                                    :
                                                    <NavLink to={'/article/'+item.id} onClick={() => this.loadRecordDetail(item.id)} className='hdbd_section_detail_title' title={item.post_title}>
                                                        {item.post_title}
                                                    </NavLink>
                                            }
                                            <div className='hdbd_section_date'>{item.post_date}</div>
                                        </div>
                                        {
                                            item.post_keywords?
                                                <div className="tag">
                                                    {item.post_keywords}
                                                </div>
                                                :null
                                        }

                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        this.state.list.length?
                            <LocaleProvider locale={zhCN}>
                                <Pagination  size="small" defaultCurrent={this.state.page} total={this.state.allCount} onChange={this.onChange} showQuickJumper className='pagenation'/>
                            </LocaleProvider>:<Skeleton active />
                    }
                </XwzxWrap>
                <XwzxWrapM>
                    <div className="wxzx_list">
                        {
                            this.state.Mlist.map((item)=>{
                                let _style = {}
                                if(item.path){
                                    _style = {
                                        backgroundImage:"url("+item.path+")"
                                    }
                                }else{
                                    _style = {
                                        // 换肤-logo
                                        backgroundImage:"url(/img/skin/logo_2024.png)",
                                        backgroundSize:'1.56rem .51rem'
                                    }
                                }
                                return(
                                    <div key={item.id} className='xwzxSection'>
                                        <div className="imgWrap" style={_style}>
                                            {
                                                parseInt(item.istop, 10) > 0 ?
                                                <div className='zd'>
                                                    <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zd.png`} alt=""/>
                                                </div>:''
                                            }
                                        </div>
                                        <div className="sec_detail">
                                            <div className="sec_detail_top">
                                                {
                                                    item.posts_link?
                                                        <a className='sec_detail_title' onClick={() => this.clickCount(item) } href={item.posts_link} target={item.posts_link.indexOf(location.host)>=0?'':'_blank'}>
                                                            {item.post_title}
                                                        </a>
                                                        :
                                                        <NavLink to={'/article/'+item.id} className='sec_detail_title'>
                                                            {item.post_title}
                                                        </NavLink>
                                                }

                                            </div>
                                            <div className='sec_detail_time'>{item.post_date}</div>
                                        </div>
                                        {
                                            item.post_keywords?
                                                <div className="tag">
                                                    {item.post_keywords}
                                                </div>
                                                :null
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        this.state.page<this.state.Mpage?
                        <div className="see_more" onClick={()=>this.loadMore(this)}>
                            查看更多
                        </div>:null
                    }
                </XwzxWrapM>
            </Fragment>

        )
    }
}
export default Xwzx
