import React from 'react'
import styles from '../newslist.module.scss'
import indexStyles from '../index.module.scss'
import { RightOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import qs from 'query-string'
import { getColumKey } from '../pub'
class Crumbs extends React.Component {
  state = {
    termId: 0,
    typeId: -1
  }
  componentDidMount() {
    const { search } = this.props.location
    const { termId } = qs.parse(search)
    this.setState({ termId: termId })

    this.pubData = PubSub.subscribe('termId', (key, value) => {
      this.setState({ termId: value[0] })
    })
    this.turnType = PubSub.subscribe('typeId', (key, value) => {
      this.setState({ typeId: value })
    })
  }
  componentWillUnmount() {
    PubSub.unsubscribe(this.pubData)
    PubSub.unsubscribe(this.turnType)
  }
  render() {
    const { termId, typeId } = this.state
    const { currentPos } = this.props
    return (
      <div className={currentPos === 'index' ? `${indexStyles.indexCrumbs}` : `${styles.crumbs}`}>
        <p className='crumbsCon'>
          {/* <img src="${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/union/area.png" alt="" />&nbsp; */}
          {/* <span>当前位置：</span> */}
          <Link to={'/'}>
            <span>首页&gt;</span>
          </Link>
          {
            currentPos === 'index' ? <span>国际联盟</span> : (
              <Link to={'/union'}>
                <span>国际联盟&gt;</span>
              </Link>
            )
          }
          {
            typeId != -1 ?
              <Link to={`/unionList?termId=${termId}&typeId=${typeId}`}>
                <span>{getColumKey()[termId]}</span>
              </Link> :
              <span>{getColumKey()[termId]}</span>
          }

        </p>
      </div>
    )
  }
}
export default withRouter(Crumbs)
