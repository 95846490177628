// 头部banner
export const HEADERBANNER = 'libraryBanner'

//中心概况
export const ZXGK = 'libraryZxgk'

//馆长致辞
export const GZZC = 'libraryGzZC'

//组织架构
export const ZZJG = 'libraryZzjg'

//科学中心体系
export const KXZXTX = 'libraryKxzxtx'

//通知公告
export const TZGG = 'libraryTzgg'

//新闻资讯
export const XWZX = 'libraryXwzx'


