import React, { Fragment } from 'react'
import { NavWrap } from './style'
import { NavLink, withRouter } from 'react-router-dom'

class ArticleSectionNew extends React.Component {
    state={
        columnList:{
          '/xsdll':'向上的力量',
          '/mkjkx':'科代表讲科学'
        },
        catalist:{
            '421':'向上的力量',
            '422':'英才课程',
            '423':'科研基础课程',
            '425':'市科协优质科普课程资源',
            '426':'英才计划宣传片',
            '427':'优秀导师学生案例',
            '428':'科学家精神系列课程',
            '429':'基础五学科课程'
        },

    }
    
  render() {
    const {yc} = this.props
    const {catalist}=this.state
    const findCata=()=>{
        for(var i in catalist){
            if(i == yc){
                return catalist[i]
            }
        }
    }
    return (
      <NavWrap>
        <NavLink to="/home">首页&gt;</NavLink>
        {
          <Fragment>
            <NavLink to="/newtrain">英才学堂&gt;</NavLink>
            {
                yc==0?'':<NavLink to={`/list/${yc}`} >{findCata()}&gt;</NavLink>
            }
          </Fragment>
        }
        <a>正文</a>
      </NavWrap>
    )
  }
}
export default withRouter(ArticleSectionNew)
