/*
 * @Author: your name
 * @Date: 2020-09-15 09:52:43
 * @LastEditTime: 2020-09-16 18:44:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \kxzxweb\src\pages\education\components\jnh\list.js
 */
import React, { memo, useEffect } from 'react'
import { ListWrap2 } from './style'
import { NavLink } from 'react-router-dom'

const List2 = memo((props) => {
  const { listarr } = { ...props }
  useEffect(() => {
    console.log(listarr)
  })
  return (
    <ListWrap2>
      <div className="h3">
        <div className="title_type_1">
          <i className="i_small"></i>
          <i className="i_large"></i>
          <span>{listarr.name}</span>
          <i className="i_large2"></i>
          <i className="i_small2"></i>
        </div>
      </div>
      <div className="section_nav_1">
        {listarr.list.map((item) => {
          return (
            <NavLink
              className="section_comm_1"
              to={'/article/' + item.id}
              key={item.id}
            >
              <div className="x_img_1">
                <img src={item.path} />
              </div>
              <p>{item.post_title}</p>
            </NavLink>
          )
        })}
      </div>
    </ListWrap2>
  )
})

export default List2
