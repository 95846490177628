import React,{Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter,NavLink} from 'react-router-dom';
import { actions } from './../../store'
import CommonCarousel from 'common/carousel.js'
import {BjkxzxtxWrap} from './style'
import { Skeleton } from 'antd';

const Fzxjs = (arr)=>{
    if(arr){
        return(
            arr.map((item)=>{
                let _style = {
                    backgroundImage:"url("+item.path+")"
                }
                return(
                    <NavLink to={'/article/'+item.id} key={item.id} className='_li'>
                        <div className="imgWrap" style={_style}>
                        </div>                      
                        <div className='fzx_title'>{item.post_title}</div>
                    </NavLink>
                )
            })
        )
    }
}
class Bjkxzxtx extends React.Component{
    componentDidMount(){
        document.title = '北京科学中心体系'
        this.goTo()
        if(!this.props.BjkxzxtxDetail){
            this.props.get_Bjkxzxtx()
        } 
    }
    zxyd(arr){
        let zxydList = []
        for(let item in arr){
            if(item !== 'cat_name'){
                zxydList.push(arr[item])
            } 
        }
        return zxydList
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    render(){
        return(
            
            <BjkxzxtxWrap>
                {this.props.BjkxzxtxDetail?
                <Fragment>
                    <div className="headerWrap">
                        <div className="kxzxtx">                       
                            <CommonCarousel Carouselarr={this.props.BjkxzxtxDetail.kxlp} />            
                        </div>
                        <div className="kxzxlp">
                            <h2>
                                <span>
                                    {this.props.BjkxzxtxDetail.kxlp[0].post_title}
                                </span>
                                <i className="i_large2"></i>
                                <i className="i_small2"></i>
                            </h2>
                            <p dangerouslySetInnerHTML={{__html:this.props.BjkxzxtxDetail.kxlp[0].post_excerpt}}></p>
                            <NavLink to={'/article/'+this.props.BjkxzxtxDetail.kxlp[0].id} className='kxzxlpDetail'>[详情]</NavLink>
                        </div>
                    </div>
                    <div className="fzxjs">
                            <p className='fzxTitle'>
                                <i className="i_small"></i>
                                <i className="i_large"></i>   
                                <span>
                                    分中心介绍
                                </span>
                                <i className="i_large2"></i>
                                <i className="i_small2"></i>                                
                            </p>
                            <div className="fzxjsWrap">
                                {
                                    Fzxjs(this.zxyd(this.props.BjkxzxtxDetail.fzx))
                                }                              
                            </div>

                    </div>
                </Fragment>
                :<Skeleton active />}
            </BjkxzxtxWrap>
        )
    }
}
const mapStateToProps = (state)=>{
    return{
        BjkxzxtxDetail:state.getIn(['library','bjkxzxtx'])
    }
}
const mapDispatchToProps = (dispatch)=>{
    return{
        get_Bjkxzxtx(){
            dispatch(actions.getBjkxzxtx())
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bjkxzxtx))