import React from 'react'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'

class CommonCarouselm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: props.list
    }
  }
  componentDidMount() {
    new Swiper('.slm', {
      loop: true,  //循环,
      navigation: {
        nextEl: '.lsright',
        prevEl: '.lsleft',
      },
    })
  }
  render() {
    return (
      <div className="sw swm">
        <div className="lsleft">
          <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/lszl/slarrow_03.png`} alt=""/>
        </div>
        <div className="lsright">
          <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/lszl/slarrow_05.png`} alt=""/>
        </div>
        <div className="slm">
          <div className="swiper-wrapper">
            {
              this.state.list.map((item,index) => {
                return (
                  <div className="swiper-slide" key={item.id}>
                    <img src={item.path} alt="" />
                    <div className="title">{item.post_title}</div>
                    <div className="abr">{ item.post_excerpt}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}
export default CommonCarouselm