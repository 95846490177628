import React from 'react';
import {Pagination, Skeleton, LocaleProvider, Empty} from 'antd';
import {withRouter} from 'react-router-dom';
import {ListWrap} from './style'
import SectionWrap from 'common/newSection'
import zhCN from 'antd/es/locale-provider/zh_CN';
import ListSection from 'common/navSection/list'
class ListTab extends React.Component{
    render(){
        return(
            !this.state.listLoading && this.state.label.length?
            <ListWrap>
                <ListSection catName={this.state.cat_name} catId={this.state.cat_id} name={this.state.name}/>
                <div className='tabWrap'>
                        {
                            this.state.label.map((item,index)=>{
                                return(
                                    <div className={this.state.currentLabel=== index?"tab_label tab_label_active":"tab_label"} onClick={()=>this.changeLabel(item.id,index)} key={item.id}>{item.username}</div>
                                )
                            })
                        }
                </div>
                <div className="hdbd_wrap">
                        {
                            this.state.list.length? this.state.list.map((item)=>{
                                return(
                                    <SectionWrap secarr={item} key={item.id}/>
                                )
                            }):<Empty className='empty'/>
                        }
                </div>
                {
                    this.state.list.length?
                    <LocaleProvider locale={zhCN}>
                        <Pagination  size="small" defaultCurrent={this.state.page} total={this.state.allCount} onChange={this.onChange} showQuickJumper className='pagenation'/>
                    </LocaleProvider>:null
                }
            </ListWrap>:<Skeleton active />
        )
    }
    constructor(props){
        super(props)
        this.state = {
            list:[],
            listLoading: null,
            allCount: null,
            page: 1,
            cat_name:null,
            cat_id:null,
            name:null,
            label:[],
            currentLabel:0,
            lebelId:''
        }
        this.onChange = this.onChange.bind(this)
    }
    changeLabel(id,index){
        this.setState({
            currentLabel:index,
            lebelId:id
        })
        setTimeout(()=>{
            this.onChange(1)
        })
    }
    initNav(){
        React.$request({
            url:'Portal/Index/label',
            method:'get',
            params: {
                type:2
            }
        }).then(res=>{
            this.setState({
                label:res.data
            })
        })
    }
    goBack(){
        this.props.history.goBack()
    }
    goTo(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 10000 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    onChange(pageNumber) {
        this.setState({
            page: pageNumber,
            list: [],
            listLoading: true
        })
        React.$request({
            url:'Portal/Index/is_ajax_list',
            method:'get',
            params: {
                type: 2,
                size:10,
                p:pageNumber,
                termid:this.props.match.params.id,
                report:this.state.lebelId
            }
        }).then(res=>{
            this.setState({
                list: res.data.list,
                allCount: parseInt(res.data.count),
                listLoading: false
            })
            this.goTo()
        })
    }
    componentDidMount(){
        this.initNav()
        this.goTo()
        if(this.props.match.params.id){
            React.$request({
                url:'Portal/Index/is_ajax_list',
                method:'get',
                params: {
                    termid:this.props.match.params.id,
                    size:10,
                    type: 2,
                    p:1,
                    report:this.state.lebelId
                }
            }).then(res=>{
                this.setState({
                    list:res.data.list,
                    allCount: parseInt(res.data.count),
                    cat_name:res.data.cat_name,
                    cat_id:res.data.cat_id,
                    name:res.data.name
                })
                document.title = res.data.name
            })
        }
    }
}

export default withRouter(ListTab)
