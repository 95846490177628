import styled from 'styled-components'

export const EduWrap = styled.div `
    @media(min-width:750px){
        width:100%;
        overflow:hidden;
        .yyry {
            width:1200px;
            margin: 30px auto 0;
            border-radius: 10px;
            overflow:hidden;
            img {
                width:100%;
                height:100%;
            }
        }
        .title_type_1{
            height:32px;
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            text-align:center;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:60px auto 30px;
            text-align:center;
            justify-content:center;
            .i_small{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-left:7px;             
            }
            .i_small2{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large2{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-right:7px;             
            }            
            span{
                padding:10px 40px;
                background:#00a1b4;
                border-radius:5px;
                margin:0 16px;
            }
        }
        .nav_title:hover{
            color:orange
        }
        .eduHeader{
            width:1200px;;
            height:380px;
            display:flex;
            flex-direction:row;
            margin:10px auto;
            .sw{
                width:760px;
                height:380px;
                border-radius:10px;
                overflow:hidden;
            }
            .edu_hdyg{
                padding-left:24px;
                flex:1;
                .edu_hdyg_title{
                    font-size:32px;
                    font-weight:bold;
                    line-height:32px;
                    color:#fff;
                    display:flex;
                    flex-direction:row;
                    align-items:center;
                    .i_small{
                        width:8px;
                        height:8px;
                        background:#f55c5d;
                    }
                    .i_large{
                        width:16px;
                        height:16px;
                        background:#00a1b4;   
                        margin-left:7px;             
                    }
                    .i_small2{
                        width:8px;
                        height:8px;
                        background:#f55c5d;
                    }
                    .i_large2{
                        width:16px;
                        height:16px;
                        background:#00a1b4;   
                        margin-right:7px;             
                    }            
                    span{
                        padding:10px 25px;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 16px 0 0;
                        box-sizing:border-box;
                    }
                }
                .edu_hdyg_title:hover{
                    color:orange
                }
                .edu_hdyg_list{
                    width:100%;
                    height:256px;
                    display:flex;
                    flex-direction:column;
                    margin-bottom:20px;
                    .more{
                        width:100%;
                        text-align:right;
                        /* margin-left:300px; */
                        font-size: 18px;
                        color:#00a1b4;
                        margin-top:-5px;
                    }
                    .hdyg_section{
                        width:437px;
                        height:46px;
                        .time{
                            /* width:60px;
                            height:60px;
                            background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/eduIcons_06.png) 0 0 no-repeat;
                            background-size:100% 100%;
                            font-size:18px;
                            font-weight:bold;
                            color:#585858;
                            text-align:center;
                            line-height:70px; */
                        }
                        .content{
                            font-size:18px;
                            line-height:46px;
                            color:#515151;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .content::before{
                            content:'';
                            width:8px;
                            height:8px;
                            background:#00a1b4;
                            border-radius:4px;
                            display: block;
                            margin:19px 10px 0 0;
                            float:left;
                        }
                    }
                }
                .sign_link{
                    width:365px;
                    height:50px;
                    background:#f55668;
                    color:#fff;
                    font-size:26px;
                    border-radius:5px;
                    margin:20px auto;
                    display:flex;
                    flex-direction:row;
                    align-items:center;
                    justify-content:center;
                    i{
                        display:flex;
                        align-items:center;
                        margin-right:20px;
                    }
                }
            }
        }
        .hot_avtivity{
            width:1200px;
            margin:0 auto;
            overflow:hidden;
            .hot_a_wrap{
                width:100%;
                height:270px;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                margin-top:30px;
                .hot_a_section{
                    width:390px;
                    height:270px;
                    .hot_a_section_img_wrap{
                        width:390px;
                        height:235px;
                        border-radius:10px;
                        overflow:hidden;
                        display:block;
                        img{
                            width:100%;
                        }
                    }
                    .hot_a_section_bot{
                        width:100%;
                        height:35px;
                        display:flex;
                        flex-direction:row;
                        justify-content:center;
                        align-items:flex-end;
                        span{
                            color:#0098bb;
                            font-weight:bold;
                            font-size:20px;
                        }
                        a{
                            font-size:14px;
                            background:#0098bb;
                            padding:2px 16px;
                            border-radius:8px;
                            color:#fff;
                            margin-left:20px;
                        }
                    }
                }
            }
        }
        .edu_jxhd{
            width:100%;
            overflow:hidden;
            position:relative;
            .edu_more{
                width:1200px;
                overflow:hidden;
                margin:0 auto;
                position:relative;
                .edu_more_link{
                    position:absolute;
                    right:0;
                    top:64px;
                    font-size: 18px;
                    color: #00a1b4;
                    z-index:20;
                }
            }
            .edu_jhhd_wrap{
                width:1200px;
                overflow:hidden;
                margin:0 auto;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
                .jxhd_section{
                    width:585px;
                    height:340px;
                    margin-top:30px;
                    position:relative;
                    overflow:hidden;
                    background-position:center center;
                    background-size:cover;
                    background-repeat:no-repeat;
                    border-radius:10px;
                    .jxhd_section_p{
                        width:100%;
                        height:170px;
                        background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                        background-size:100% 100%;
                        position:absolute;
                        left:0;
                        bottom:0;
                        display:flex;
                        padding:10px 35px;
                        box-sizing:border-box;
                        color:#fff;
                        font-size:18px;
                        align-items:flex-end;
                    }
                }
            }
        }
        .edu_jckc{
            width:100%;
            overflow:hidden;
            position:relative;
            .edu_more{
                width:1200px;
                overflow:hidden;
                margin:0 auto;
                position:relative;
                .edu_more_link{
                    position:absolute;
                    right:0;
                    top:64px;
                    font-size: 18px;
                    color: #00a1b4;
                    z-index:20;
                }
            }
            .edu_jhhd_wrap{
                width:1200px;
                overflow:hidden;
                margin:0 auto;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
                .jckc_section{
                    width:585px;
                    height:340px;
                    margin-top:30px;
                    position:relative;
                    overflow:hidden;
                    background-position:center center;
                    background-size:cover;
                    background-repeat:no-repeat;
                    border-radius:10px;
                    .jckc_section_p{
                        width:100%;
                        height:170px;
                        background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                        background-size:100% 100%;
                        position:absolute;
                        left:0;
                        bottom:0;
                        display:flex;
                        padding:10px 35px;
                        box-sizing:border-box;
                        color:#fff;
                        font-size:18px;
                        align-items:flex-end;
                    }
                }
                .jckc_section_full{
                    width:100%;
                }
            }
        }
        .edu_mkjkx{
            width:1200px;
            margin:0 auto;
            overflow:hidden;
            .edu_mk_wrap{
                display:flex;
                flex-direction:row;
                width:1200px;
                height:450px;
                justify-content:space-between;
                margin-top:30px;
                .edu_video{
                    width:750px;
                    height:450px;
                    overflow:hidden;
                    border-radius:10px;
                    video{
                        width:100%;
                        height:100%;
                    }
                }
                .edu_mk_list{
                    width:430px;
                    display:flex;
                    flex-direction:column;
                    justify-content:space-between;
                    .edu_mk_img_section{
                        width:100%;
                        height:215px;
                        position:relative;
                        overflow:hidden;
                        background-position:center center;
                        background-size:cover;
                        background-repeat:no-repeat;
                        border-radius:10px;
                        .edu_mk_img_section_mask{
                            position:absolute;
                            left:0;
                            bottom:0;
                            background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                            background-size:100% 100%;
                            display:flex;
                            padding:10px 35px;
                            box-sizing:border-box;
                            color:#fff;
                            font-size:16px;
                            align-items:flex-end;
                        }
                    }
                }
            }

        }
        .edu_hdbd{
            width:1200px;
            overflow:hidden;
            margin:0 auto 95px;
            .edu_hdbd_wrap{
                width:1200px;
                overflow:hidden;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
            }
        }
    }
    @media(max-width:750px){
        width:100%;
        overflow:hidden;
        .yyry {
            width: 7.1rem;
            margin: .2rem auto 0;
            border-radius: 0.1rem;
            overflow:hidden;
            img {
                width:100%;
                height:100%;
            }
        }
        .title_type_1{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:.2rem 0;
            text-indent:0;
            .i_small2{
                        width:.16rem;
                        height:.16rem;
                        background:#f55c5d;
            }
            .i_large2{
                        width:.3rem;
                        height:.3rem;
                        background:#00a1b4;   
                        margin-right:.07rem;             
            }            
            span{
                        padding:.1rem .4rem;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 .16rem 0 0;
                        box-sizing:border-box;
            }
        }
        .eduHeader{
            width:100%;
            overflow:hidden;
            .sw{
                width:100%;
                height:4rem;
                overflow:hidden;
            }
            .edu_hdyg{
                width:7.1rem;
                margin:.52rem auto 0;
                .edu_hdyg_title{
                    font-size:.32rem;
                    font-weight:bold;
                    line-height:.32rem;
                    color:#fff;
                    display:flex;
                    flex-direction:row;
                    align-items:center;
                    margin:0;
                    text-indent:0;
                    .i_small2{
                                width:.16rem;
                                height:.16rem;
                                background:#f55c5d;
                    }
                    .i_large2{
                                width:.3rem;
                                height:.3rem;
                                background:#00a1b4;   
                                margin-right:.07rem;             
                    }            
                    span{
                                padding:.1rem .4rem;
                                background:#00a1b4;
                                border-radius:5px;
                                margin:0 .16rem 0 0;
                                box-sizing:border-box;
                    }
                }
                .edu_hdyg_list{
                    width:100%;
                    overflow:hidden;
                    display:flex;
                    flex-direction:column;
                    .more{
                        color:#00a1b4;
                        width:100%;
                        font-size:.35rem;
                        text-align:right;
                        line-height:.7rem;
                    }
                    .hdyg_section{
                        width:100%;
                        display:flex;
                        flex-direction:row;
                        align-items:center;
                        border-bottom:1px dashed #dddddd;
                        padding:.2rem 0;
                        .time{
                            width:60px;
                            height:60px;
                            background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/eduIcons_06.png) 0 0 no-repeat;
                            background-size:100% 100%;
                            font-size:18px;
                            font-weight:bold;
                            color:#585858;
                            text-align:center;
                            line-height:70px;
                        }
                        .content{
                            flex:1;
                            padding:0 9px 0 20px;
                            box-sizing:border-box;
                            font-size:18px;
                            line-height:30px;
                            color:#515151
                        }
                    }

                }
                .sign_link{
                    width:7rem;
                    height:1rem;
                    background:#f55668;
                    color:#fff;
                    font-size:26px;
                    border-radius:5px;
                    margin:10px auto 20px;
                    display:flex;
                    flex-direction:row;
                    align-items:center;
                    justify-content:center;
                    i{
                        display:flex;
                        align-items:center;
                        margin-right:20px;
                    }
                }
            }
        }
        .hot_avtivity{
            width:7.1rem;
            margin:0 auto;
            overflow:hidden;
            .hot_a_wrap{
                width:100%;
                overflow:hidden;
                .hot_a_section{
                    width:100%;
                    height:2.35rem;
                    display:flex;
                    flex-direction:row;
                    justify-content:space-between;
                    margin-top:.3rem;
                    .hot_a_section_img_wrap{
                        width:4.65rem;
                        height:2.35rem;
                        border-radius:.1rem;
                        overflow:hidden;
                        display:block;
                        img{
                            width:100%;
                        }
                    }
                    .hot_a_section_bot{
                        flex:1;
                        display:flex;
                        flex-direction:column;
                        justify-content:flex-end;
                        align-items:flex-start;
                        margin-left:.2rem;
                        span{
                            color:#0098bb;
                            font-weight:bold;
                            font-size:.28rem;
                        }
                        a{
                            font-size:.25rem;
                            background:#0098bb;
                            padding:.08rem .5rem;
                            border-radius:.25rem;
                            color:#fff;
                            margin-top:.1rem;
                        }
                    }
                }
            }
        }
        .edu_jxhd{
            width:7.1rem;
            margin:0rem auto;
            overflow:hidden;
            .edu_more{
                width:100%;
                overflow:hidden;
                margin:0 auto;
                position:relative;
                .edu_more_link{
                    position: absolute;
                    right: 0;
                    top: 0.2rem;
                    font-size: .35rem;
                    color: #00a1b4;
                    z-index: 20;
                }
            }
            .edu_jhhd_wrap{
                width:7.1rem;
                overflow:hidden;
                margin:0.3rem auto 0;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
                .jxhd_section{
                    width:3.45rem;
                    height:2.18rem;
                    margin-bottom:.2rem;
                    position:relative;
                    overflow:hidden;
                    background-position:center center;
                    background-size:cover;
                    background-repeat:no-repeat;
                    border-radius:.1rem;
                    .jxhd_section_p{
                        width: 100%;
                        height: 1rem;
                        background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        padding: .1rem .35rem;
                        box-sizing: border-box;
                        color: #fff;
                        font-size: .3rem;
                        -webkit-align-items: flex-end;
                        -webkit-box-align: flex-end;
                        -ms-flex-align: flex-end;
                        align-items: flex-end;
                    }
                }
            }
        }
        .edu_jckc{
            width:7.1rem;
            margin:0rem auto;
            overflow:hidden;
            .edu_more{
                width:100%;
                overflow:hidden;
                margin:0 auto;
                position:relative;
                .edu_more_link{
                    position: absolute;
                    right: 0;
                    top: 0.2rem;
                    font-size: .35rem;
                    color: #00a1b4;
                    z-index: 20;
                }
            }
            .edu_jhhd_wrap{
                width:7.1rem;
                overflow:hidden;
                margin:0.3rem auto 0;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                justify-content:space-between;
                .jckc_section{
                    width:3.45rem;
                    height:2.18rem;
                    margin-bottom:.2rem;
                    position:relative;
                    overflow:hidden;
                    background-position:center center;
                    background-size:cover;
                    background-repeat:no-repeat;
                    border-radius:.1rem;
                    .jckc_section_p{
                        width: 100%;
                        height: 1rem;
                        background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        padding: .1rem .35rem;
                        box-sizing: border-box;
                        color: #fff;
                        font-size: .3rem;
                        -webkit-align-items: flex-end;
                        -webkit-box-align: flex-end;
                        -ms-flex-align: flex-end;
                        align-items: flex-end;
                    }
                }
                .jckc_section_full{
                    width:100%;
                }
            }
        }
        .edu_mkjkx{
            width:7.1rem;
            margin:0 auto;
            overflow:hidden;
            .edu_mk_wrap{
                width:100%;
                overflow:hidden;
                .edu_video{
                    width:7.1rem;
                    height:4rem;
                    overflow:hidden;
                    border-radius:.1rem;
                    margin-top:.3rem;
                    video{
                        width:100%;
                        height:100%;
                    }
                }
                .edu_mk_list{
                    width:100%;
                    display:flex;
                    flex-direction:column;
                    justify-content:space-between;
                    .edu_mk_img_section{
                        width:100%;
                        overflow:hidden;
                        background:none!important;
                        .edu_mk_img_section_mask{
                            font-size:.28rem;
                            color:#515151;
                            line-height:.4rem;
                            padding:.25rem 0;
                            border-bottom:0.02rem dashed #dddddd;
                        }
                    }
                }
            }

        }
        .edu_hdbd{
            width:7.1rem;
            overflow:hidden;
            margin:.3rem auto;
        }
    }
`
