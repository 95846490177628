import React from "react"
import NavSection from 'common/navSection'
import { withRouter, NavLink } from 'react-router-dom'
import { XsdllWrap } from "./style";
import SectionWrapItem from 'common/newSectionItem'
import {Empty, Skeleton} from 'antd';

class Xsdll extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            xyArr: null,
            loading:false
        }
    }
    initXsdll(){
        this.setState({
            loading: true
        })
        React.$request({
            // url:'Portal/index/highlightOfFaith',
            url: 'Portal/zt/getListsByTermId',
            method:'get',
            params: {
                termId: 421,
                page: 1,
                limit: 100
            }
        }).then(res=>{
            console.log(res.data)
            this.setState({
                xyArr: res.data,
                loading: false
            })
        })        
    }
    componentDidMount() {
        document.title = '向上的力量'
        this.initXsdll()
    }
    render() {
        return (
            <XsdllWrap>
                <NavSection />
                <div className='head-box'>
                    <div className='head-title'>
                        <img className='head-title-icon' src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/library/xsdll/xsdll-head.png`} alt="" />
                        {/* <div className='subtitle'>—— 党员科学家专题</div> */}
                    </div>
                    <div className='head-content'>
                        <p>为了大力弘扬科学家精神，讲好新时代首都创新故事，营造良好的社会舆论氛围，北京市科协推出“向上的力量—科技之光”系列科学家演讲视频。这是北京市科协落实北京市第十三次党代会精神，在全社会厚植科学家精神根脉，激励和引导科技工作者坚定理想信念，自觉践行科技报国之志的重要举措。</p>
                        <p>本次演讲共有20位嘉宾参与，涵盖院士、茅以升北京青年科技奖获得者、北京青年学术演讲比赛优胜者、最美科技工作者、网络科普大V等各领域科技工作者的顶尖人物，汇聚老、中、青三代科技领域高端人才。演讲内容分为“精神坐标”“青年接棒”“时代先锋”与“科创未来”，其中既有中国工程院院士杜祥琬以国家需要为己任，为祖国氢弹制造和激光事业奋斗不息的故事；有中国科学院院士，人称“针灸院士”的韩济生一辈子研究针刺止痛原理、提携后学、致力团队攻关的讲述；有人大附中物理教师李永乐以教育为使命，将教育送进山区，发出天下兴亡，匹夫有责的感叹；有首都医科大学公共卫生学院博士生导师段军超带领团队在颗粒物毒理学领域的深耕不辍和默默坚守；也有历史学者、战争史博主王铭苇回顾中国科学家家国情怀、回国报效的艰辛历程……</p>
                        <p>“在黑夜中，总有光芒在引导前进，这个光芒就是科学”“没有科学家精神的支撑，他就成为不了真正的科学家”，演讲嘉宾以科学家精神培育为内核，用一个个鲜活生动的案例与充满激情的演讲，展示可亲、可敬、可感、可学的科学家精神与家国情怀，彰显时代先锋的责任担当，传达科技工作者对科学事业的热爱与毕生追求，实现科学精神的薪火相传。</p>
                    </div>
                </div>
                <div className='xsdll-wrap'>
                    {
                        this.state.loading?<Skeleton active />:
                        (
                            (this.state.xyArr && this.state.xyArr.lists)?this.state.xyArr.lists.map((item,index) => {
                                return (
                                    <SectionWrapItem secarr={item} key={item.id} showSubTitle={true}/>
                                )
                            }):<Empty className='empty'/>
                        )
                    }
                    {/* { this.state.xyArr?<NavLink className='check-more' to={'/list/296'}>查看更多</NavLink>:null } */}
                </div>
            </XsdllWrap>
        )
    }
}
export default withRouter(Xsdll)

