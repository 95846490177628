import React from 'react'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'
import './style.css'
import {NavLink} from 'react-router-dom'

const swSection = (arr) =>{
    if(arr.length){
        return(     
                <div className="swiper-container swiper-container-zzry">
                    <div className="swiper-wrapper">                       
                        {                     
                            arr.map((item,index)=>{
                                let _style = {
                                    backgroundImage:'url('+item.path+')'
                                }
                                return(
                                    <div className="swiper-slide swiper-slide-zzry" key={item.id} style={_style}>  
                                        <a className='navlink'>
                                            <div className="swMask"></div>    
                                            <div className="title" style={{'background': `url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat`,'backgroundSize':'100% 100%'}}>
                                                <span className='swzzryindex'><i>{index+1}</i>/{arr.length}</span><span className='swzzrytitle'>{item.post_title}</span> 
                                            </div>  
                                        </a>
                                    </div>
                                )
                            })                   
                        }             
                    </div>
                </div>            
        )
    }
}
class ZzryCarousel extends React.Component{
    constructor(props){
        super(props)
        this.state={
            swArr:[]
        }
    }
    componentDidMount() {
        new Swiper('.swiper-container-zzry', {
            slidesPerView:4,
            centeredSlides: true,
            loop: true,
            grabCursor: true,
            centeredSlides: true,
            slideToClickedSlide: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }, 
        }) 
    }
    render() {
        return (
            <div className={this.props.className}>
                {
                    swSection(this.props.Carouselarr)
                }
            </div>

        )
    }
}

export default ZzryCarousel