import React,{Fragment} from 'react'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.min.css'
import './style.css'
import {NavLink} from 'react-router-dom'

const swSection = (arr,isBtn) =>{
    if(arr.length){
        return(     
                <div className="swiper-container swiper-container-xwzx">
                    <div className="swiper-wrapper">                       
                        {                     
                            arr.map((item)=>{
                                let _style = {
                                    backgroundImage:'url('+item.path+')'
                                }
                                let _style_2 = {
                                    backgroundImage:`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png)`
                                }
                                return(
                                    <div className="swiper-slide xwzxsl" key={item.id} style={_style}>
                                        <a className='sw_title' href={item.posts_link} style={_style_2}>
                                            {item.post_title}
                                        </a>                                   
                                    </div>
                                )
                            })                   
                        }             
                    </div>
                    {
                        isBtn?
                        <Fragment>
                            <div className="swiper-button-prev"></div>
                            <div className="swiper-button-next"></div>
                        </Fragment>:null
                    }

                </div>            
        )
    }
}
class XwzxCarousel extends React.Component{
    constructor(props){
        super(props)
        this.state={
            swArr:[]
        }
    }
    componentDidMount() {
        this.props.isBtn?
        new Swiper('.swiper-container-xwzx', {
            loop: false,  //循环
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        }):new Swiper('.swiper-container-xwzx', {
            loop: false,  //循环
        })
    }
    render() {
        return (
            <div className={this.props.className}>
                    {
                        swSection(this.props.Carouselarr,this.props.isBtn)
                    }
            </div>

        )
    }
}

export default XwzxCarousel