import React from 'react'
import { Link } from 'react-router-dom'
import {AppstoreFilled,FileTextFilled,FireFilled,ClockCircleOutlined} from '@ant-design/icons';
import styles from '../index.module.scss'
import { changeImg } from '../pub'
import { withRouter } from 'react-router-dom'

 class NewsCenter extends React.Component {
    state={
        nowTab:-1,
        checkData:[],
        allData:[]
    }
    // 钩子
    componentDidMount(){
        let all=[]
        this.props.xwzxData.son.forEach(el=>{
            all=[...all,...el.articles.slice(0,5)]
        })
        all=all.sort((a,b) => new Date(b.post_date) - new Date(a.post_date)).splice(0,5)
        this.setState({'checkData':all,'allData':all})
    }
    checkTab=(val)=>{
        this.setState({'nowTab':val})
        if(val==-1){
            this.setState({'checkData':this.state.allData})
        }else{
            const {xwzxData} = this.props
            this.setState({'checkData':xwzxData.son[val].articles.slice(0,5)})
        }
        
    }
  render() {
    const {xwzxData} = this.props
    const {nowTab,checkData} = this.state
    return (
        xwzxData.length > 0 ? (<div className={styles.curatorNewsCenter}>
            <p className='title'>新闻中心 
                <Link className='link' to={`/curatorSessionList?termId=560&typeId=${xwzxData.post_keywords}`}>
                    更多
                </Link>
            </p>
            <div className='btnBox flex-center'>
                <div className={nowTab==-1?'btnItem btnItem-active':'btnItem'} onClick={()=>this.checkTab(-1)}><AppstoreFilled />&nbsp;全部</div>
                {
                    xwzxData.son.filter(item=>item.term_id!='560').map((el,i)=>{
                        return <div className={nowTab==i?'btnItem btnItem-active':'btnItem'} key={i} onClick={()=>this.checkTab(i)}>
                                    {i!=0?<FireFilled />:<FileTextFilled />}
                                    &nbsp;{el.name}
                               </div>
                    })
                }
            </div>
            <div className='contentBox'>
                <div className='conLeft flex-sb'>
                    {
                        checkData.slice(0,2).map(el=>{
                            return (
                                <Link to={`/curatorSessionCon?articleId=${el.id}&termId=${el.term_id}`} key={el.id}>
                                    <div className='leftItem'>
                                        <img src={changeImg(el.smeta.photo[0].url)} alt="" />
                                        <div className='msgBox'>
                                            <p className="title">{el.post_title}</p>
                                            <p className="msg">{el.post_excerpt}</p>
                                            <div className='flex-jus'>
                                                <div className='time'>
                                                    <ClockCircleOutlined />&nbsp;
                                                    {el.post_date.split(' ')[0]}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>
                <div className='conRight'>
                    {
                        checkData.slice(2,5).map(el=>{
                            return (
                                <Link to={`/curatorSessionCon?articleId=${el.id}&termId=${el.term_id}`} key={el.id}>
                                    <div className='rightItem flex-sb' key={el.id}>
                                        <div className='dateBox'>
                                            <p className='bigSize'>{el.post_date.split(' ')[0].substring(el.post_date.split(' ')[0].length-2,el.post_date.split(' ')[0].length)}</p>
                                            <p>{el.post_date.split(' ')[0].split('-').slice(0,2).join('-')}</p>
                                        </div>
                                        <p className='title'>{el.post_title}</p>
                                    </div> 
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
        </div>)
        : null
    )
  }
}

export default withRouter(NewsCenter)
