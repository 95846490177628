/*
 * @Author: your name
 * @Date: 2020-07-20 09:58:27
 * @LastEditTime: 2020-07-20 10:28:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \kxzxweb\src\common\sdkxjtSec\index2.js
 */
import React from 'react'
import { SectionWrap } from './style'
import { NavLink } from 'react-router-dom'

class NewSection2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      swArr: [],
    }
  }
  render() {
    return (
      <SectionWrap key={this.props.secarr.id} className="hdbd_section">
        <div className="icon">
          <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/icon.jpg`} />
        </div>
        <div className="hdbd_section_detail">
          <NavLink
            className="hdbd_section_detail_title"
            to={'/article/' + this.props.secarr.id}
          >
            {
              parseInt(this.props.secarr.istop, 10) > 0 ?
                <span className='zd'>
                  <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zda.png`} alt="" />
                </span> :
                ""
            }
            {this.props.secarr.post_title}
          </NavLink>
          <div className="hdbd_section_date">
            {this.props.secarr.post_excerpt}
          </div>
        </div>
      </SectionWrap>
    )
  }
}
export default NewSection2
