import styled from 'styled-components'

export const DqfyWrap = styled.div `
    @media(min-width:750px){
      width:100%;
      overflow:hidden;
      background-size:cover;
      background-position:center top;
      .iframeWrap{
        width:100%;
        height:100%;
        position: fixed;
        left:0;
        top:0;
        display: flex;
        justify-content:center;
        align-items:center;
        z-index:300;
        background:rgba(0,0,0,.3);
        .iframe{
          width:80%;
          height:80%;
          display: block; 
        }
        .closeiframe{
          width:50px;
          height:50px;
          position: absolute;
          right:10px;
          top:10px;
          cursor: pointer;
          svg{
            width:100%;
          }
        }
      }
      .phide{
        display: none!important;
      }
      .ptitle{
        width:100%;
        display: flex;
        justify-content:center;
        margin:83px auto 50px;
      }
      .pconent{
        width:1200px;
        border:1px solid #00ffff;
        background-size:188px 172px;
        background-position:13px center;
        background-repeat:no-repeat;
        height:190px;
        padding:0 50px 0 261px;
        margin:0 auto;
        color:#fff;
        display: flex;
        align-items:center;
        font-size:20px;
        line-height:32px;
      }
      .slm,.swm{
        display: none;
      }
      .tab{
            width:395px;
            height:250px;
            border-radius:7px;
            overflow:hidden;
            background-size:cover;
            margin-bottom:35px;
            cursor: pointer;
            position: relative;
            .title{
              width:100%;
              height:54px;
              text-align:center;
              font-size:22px;
              line-height:54px;
              color:#fff;
              position: absolute;
              left:0;
              bottom:0;
              opacity:1;
            }
            .abstra{
              width:100%;
              height:100%;
              background-color:rgba(12,49,88,.6);
              display: flex;
              justify-content:center;
              align-items:center;
              font-size:18px;
              color:#fff;
              line-height:30px;
              padding:0 50px;
              box-sizing:border-box;
              opacity:0;
              text-indent:0;
            }
        }
      .top{
        width:1200px;
        height:380px;
        display: flex;
        justify-content:space-between;
        margin:35px auto 0;
        background:#1f2343;
        border-radius:8px;
        overflow:hidden;
        .top_left_img{
          width:560px;
          height:100%;
          object-fit:cover;
          overflow:hidden;
          cursor: pointer;
          img{
            width:100%;
          }
        }
        .top_right{
          width:550px;
          margin-right:58px;
          .top_right_title{
            width:203px;
            height:59px;
            background-size:cover;
            background-position:center top;
            font-size:23px;
            color:#fff;
            line-height:59px;
            text-indent:23px;
            margin-top:15px;
          }
          .address{
            font-size:20px;
            background-size:22px 21px;
            background-position:left center;
            background-repeat:no-repeat;
            color:#fff;
            text-indent:30px;
            height:60px;
            line-height:60px;
            margin-left:30px;
          }
          .content{
            width:550px;
            color:#fff;
            font-size:18px;
            line-height:31px;
          }
        }
      }
      .tab:hover{
            .title{
              opacity:0;
            }
            .abstra{
              opacity:1;
            }
      }
      .sw{
        width:1240px;
        margin:0 auto;
        position: relative;
        .lsleft{
          width:40px;
          position: absolute;
          left:0;
          top:50%;
          transform:translate(0,-50%);
          cursor: pointer;
          opacity: .7;
          img{
            width:100%;
          }
        }
        .lsright{
          width:40px;
          position: absolute;
          right:0;
          top:50%;
          transform:translate(0,-50%);
          cursor: pointer;
          opacity: .7;
          img{
            width:100%;
          }
        }
      }
      .hdbd_wrap{
            width:1200px;
            overflow:hidden;
            display:flex;
            flex-direction:row;
            flex-wrap:wrap;
            justify-content:space-between;
            margin:0 auto 50px;
            .hdbd_section_detail_title{
              color:#fff;
            }
      }
      .part4{
        background:rgba(0,0,0,.3);
        padding:3px 0 30px;
        margin-bottom:40px;
      } 
      .sl{
          width:1125px;
          margin:50px auto 0;
          overflow:hidden;
          .swiper-wrapper{
            width:1240px;
            height:540px;
            margin:0 auto;
            .swiper-slide:nth-of-type(2n){
              flex-direction:column-reverse;
            }
            .swiper-slide:nth-of-type(2n+1){
              flex-direction:column;
            }
            .swiper-slide{
              height:482px;
              border-radius:7px;
              background:#f0f0f0;
              display: flex;
              align-items:center;
              justify-content: center;
              img{
                width:330px;
                height:238px;
                border-radius:7px;
                margin-top:17px;
                margin-bottom:17px;
              }
              .title{
                width:330px;
                font-size:23px;
                color:#3a4045;
                font-weight:bold;
                text-align:left;
                margin:25px auto;
              }
              .abr{
                width:330px;
                font-size:16px;
                color:#3a4045;
                height:140px;
                line-height:26px;
              }
            }
            .swiper-slide:hover{
              background:#001238;
              .title{
                width:330px;
                font-size:23px;
                color:#fff;
                font-weight:bold;
                text-align:left;
                margin:25px auto;
              }
              .abr{
                width:330px;
                font-size:16px;
                color:#fff;
                height:140px;
                line-height:26px;
              }
            }
            /* .swiper-slide-active{
              background:#bf0123;
              .title{
                width:330px;
                font-size:23px;
                color:#fff;
                font-weight:bold;
                text-align:left;
                margin:25px auto;
              }
              .abr{
                width:330px;
                font-size:16px;
                color:#fff;
                height:140px;
                line-height:26px;
              }
            } */
          }
      }
      .p1list{
        width: 1240px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin:33px auto 0;
      }
      .part2{
        width:1200px;
        display: flex;
        justify-content:space-between;
        margin:0 auto;
        .p2list{
          width:825px;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }
        .p2left{
          width:333px;
          height:531px;
          background-size:100% 100%;
          display: flex;
          padding:33px 63px 0 44px;
          flex-direction:column;
          box-sizing:border-box;
          h2{
            color:#fff;
            font-size:33px;
          }
          .content{
            font-size:16px;
            line-height:26px;
            color:#fff;
          }
        }
      }
    }
    @media(max-width:750px){
      width:100%;
      overflow:hidden;
      background-size:cover;
      background-position:center top;
      .ptitle{
        width:100%;
        display: flex;
        justify-content:center;
        margin:.8rem auto .5rem;
        img{
          width:60%;
        }
      }
      .iframeWrap{
        width:100%;
        height:100%;
        position: fixed;
        left:0;
        top:0;
        display: flex;
        justify-content:center;
        align-items:center;
        z-index:300;
        background:rgba(0,0,0,.3);
        .iframe{
          width:100%;
          height:30%;
          display: block; 
        }
        .closeiframe{
          width:40px;
          height:40px;
          position: absolute;
          right:5px;
          top:5px;
          cursor: pointer;
          svg{
            width:100%;
          }
        }
      }
      .p1list{
        .tab:nth-of-type(6){
          display: none;
        }
      }
      .p2left{
        display: none!important;
      }
      .pconent{
        width:90%;
        border:1px solid #00ffff;
        background-size:99px 86px;
        background-position:left center;
        background-repeat:no-repeat;
        margin:0 auto;
        color:#fff;
        display: flex;
        align-items:center;
        font-size:13px;
        line-height:20px;
        padding:20px 20px 20px 110px;
        box-sizing:border-box;
        text-align:justify;
      }
      .tab{
            width: 100%;  
            height:3rem;
            overflow:hidden;
            background-size:3rem 1.8rem;
            background-position: .2rem center;
            background-repeat:no-repeat;
            position: relative;
            padding:.3rem 0 .3rem 0;
            display: flex;
            justify-content:center;
            flex-direction:column;
            position: relative;
            margin:0 auto!important;
            .title{
              flex:1;
              height:.5rem;
              text-align:left;
              font-size:.26rem;
              line-height:.54rem;
              color:#fff;
              font-weight:bold;
              text-indent:0;
              margin-left: 3.4rem;
            }
            .abstra{
              width: 3.6rem;
              height:3rem;
              font-size:.24rem;
              color:#fff;
              line-height:.35rem;
              padding: .2rem 0rem;
              box-sizing:border-box;
              color:#fff;
              text-indent:0;
              margin-left: 3.4rem;
            }
          }
      .top{
        width:100%;
        overflow:hidden;
        margin:35px auto 0;
        background:#1f2343;
        border-radius:8px;
        overflow:hidden;
        .top_left_img{
          width:100%;
          object-fit:cover;
          overflow:hidden;
          iframe{
            width:500px;
          }
          img{
            width:100%;
          }
        }
        .top_right{
          width:80%;
          margin:0 auto;
          .top_right_title{
            width:152px;
            height:40px;
            background-size:100% 100%;
            background-position:center top;
            font-size:16px;
            color:#fff;
            line-height:40px;
            text-indent:20px;
            margin-top:15px;
          }
          .address{
            font-size:20px;
            background-size:22px 21px;
            background-position:left center;
            background-repeat:no-repeat;
            color:#fff;
            text-indent:30px;
            height:60px;
            line-height:60px;
          }
          .content{
            width:100%;
            color:#fff;
            font-size:14px;
            line-height:24px;
          }
        }
      }
      .tab:hover{
            .title{
              opacity:0;
            }
            .abstra{
              opacity:1;
            }
      }
      .swp{
        display: none;
      }
      .sw{
        width:100%;
        margin:0 auto;
        position: relative;
        .lsleft{
          width:40px;
          position: absolute;
          left:0;
          top:50%;
          transform:translate(0,-50%);
          cursor: pointer;
          img{
            width:100%;
          }
        }
        .lsright{
          width:40px;
          position: absolute;
          right:0;
          top:50%;
          transform:translate(0,-50%);
          cursor: pointer;
          img{
            width:100%;
          }
        }
      }
      .sl{
        display: none;
      }
      .slm{
          width:5.6rem;
          margin: 20px auto 0;
          overflow:hidden;
          .swiper-wrapper{
            width:5.6rem;
            height: 6rem;
            margin:0 auto;
            .swiper-slide{
              height:6rem;
              border-radius:7px;
              background:#f0f0f0;
              display: flex;
              align-items:center;
              flex-direction:column;
              justify-content: center;
              img{
                width:70%;
                border-radius:7px;
                margin-top:7px;
              }
              .title{
                width:90%;
                font-size:.3rem;
                color:#3a4045;
                font-weight:bold;
                text-align:left;
                margin:.25rem auto;
              }
              .abr{
                width:90%;
                font-size:.28rem;
                color:#3a4045;
                height:2.4rem;
                line-height:.4rem;
              }
            }
          }
      }
      .hdbd_wrap{
            width:90%;
                overflow:hidden;
                margin:.3rem auto .3rem;
                .hdbd_section_detail_title{
              color:#fff;
            }
        }  
      .part4{
        background:rgba(0,0,0,.3);
        padding:3px 0 30px;
        margin-bottom:40px;
        margin-top:40px;
      } 
      .sl{
          width:1125px;
          margin:50px auto 0;
          overflow:hidden;
          .swiper-wrapper{
            width:1240px;
            height:540px;
            margin:0 auto;
            .swiper-slide:nth-of-type(2n){
              flex-direction:column-reverse;
            }
            .swiper-slide:nth-of-type(2n+1){
              flex-direction:column;
            }
            .swiper-slide{
              height:482px;
              border-radius:7px;
              background:#f0f0f0;
              display: flex;
              align-items:center;
              justify-content: center;
              img{
                width:330px;
                height:238px;
                border-radius:7px;
                margin-top:17px;
                margin-bottom:17px;
              }
              .title{
                width:330px;
                font-size:23px;
                color:#3a4045;
                font-weight:bold;
                text-align:left;
                margin:25px auto;
              }
              .abr{
                width:330px;
                font-size:16px;
                color:#3a4045;
                height:140px;
                line-height:26px;
              }
            }
            .swiper-slide:hover{
              background:#001238;
              .title{
                width:330px;
                font-size:23px;
                color:#fff;
                font-weight:bold;
                text-align:left;
                margin:25px auto;
              }
              .abr{
                width:330px;
                font-size:16px;
                color:#fff;
                height:140px;
                line-height:26px;
              }
            }
            /* .swiper-slide-active{
              background:#bf0123;
              .title{
                width:330px;
                font-size:23px;
                color:#fff;
                font-weight:bold;
                text-align:left;
                margin:25px auto;
              }
              .abr{
                width:330px;
                font-size:16px;
                color:#fff;
                height:140px;
                line-height:26px;
              }
            } */
          }
      }
      .p1list{
        width: 100%;
      }
      .part2{
        width:100%;
        display: flex;
        justify-content:space-between;
        margin:0 auto;
        flex-direction:column;
        .p2list{
          width:100%;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }
        .p2left{
          width:333px;
          height:531px;
          background-size:100% 100%;
          padding:33px 63px 0 44px;
          flex-direction:column;
          box-sizing:border-box;
          margin:0 auto;
          h2{
            color:#fff;
            font-size:33px;
          }
          .content{
            font-size:16px;
            line-height:26px;
            color:#fff;
          }
        }
      }
      .part4{
        .ptitle{
          img{
            width:40%;
          }
        }
      }
    }
`