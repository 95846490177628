import React from "react"
import NavSection from 'common/navSection'
import { withRouter, NavLink } from 'react-router-dom'
import { XydggWrap } from "./style";
import SectionWrap from 'common/newSection'
import {Empty, Skeleton} from 'antd';

class Xydgg extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            xyArr: null,
            loading:false
        }
    }
    initXydgg(){
        this.setState({
            loading: true
        })
        React.$request({
            url:'Portal/Index/highlightOfFaith',
            method:'get'
        }).then(res=>{
            this.setState({
                xyArr: res.data,
                loading: false
            })
        })
    }
    componentDidMount() {
        document.title = '信仰的高光'
        this.initXydgg()
    }
    render() {
        return (
            <XydggWrap>
                <NavSection />
                <div className='head-box'>
                    <div className='head-title'>
                        <img className='head-title-icon' src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/library/xydgg/xydgg-head.png`} alt="" />
                        <div className='subtitle'>—— 党员科学家专题</div>
                    </div>
                    <div className='head-content'>
                        <p>今年，中国共产党将迎来建党100周年。100年来，中国共产党与时代同步伐、与人民共命运，取得一个又一个辉煌胜利。广大科技工作者在祖国大地上树立起了一座座科技创新的丰碑，也铸就了独特的精神气质。
                            2020年9月11日，习近平总书记在科学家座谈会上指出：“科学成就离不开精神支撑。科学家精神是科技工作者在长期科学实践中积累的宝贵精神财富。”
                        </p>
                        <p>为弘扬党员科学家精神，数字北京科学中心为大家特别策划了“信仰的高光——党员科学家”长图漫画专题，向那些伟大的党员科学家致敬！</p>
                    </div>
                </div>
                <div className='xydgg-wrap'>
                    {
                        this.state.loading?<Skeleton active />:
                        (
                            (this.state.xyArr && this.state.xyArr.xydgg)?this.state.xyArr.xydgg.map((item,index) => {
                                if (index < 8) {
                                    return (
                                        <SectionWrap secarr={item} key={item.id} showSubTitle={true}/>
                                    )
                                }
                            }):<Empty className='empty'/>
                        )
                    }
                    { this.state.xyArr?<NavLink className='check-more' to={'/list/296'}>查看更多</NavLink>:null }
                </div>
            </XydggWrap>
        )
    }
}
export default withRouter(Xydgg)

