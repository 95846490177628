import styled from 'styled-components';

export const ThisMonthWrap = styled.div`
    @media(min-width:750px){
        width:1200px;
        margin:64px auto;
        .tabs{
            width:100%;
            display:flex;
            flex-direction:row;
            .tab{
                cursor: pointer;
                height:24px;
                line-height:24px;
                font-size:24px;
                color:#848484;
                margin-right:70px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/cinema/cinematab_12.png) left top no-repeat;
                background-size:3px 24px;
                text-indent:8px;
            }
            .tabActive{
                cursor: pointer;
                height:24px;
                line-height:24px;
                font-size:24px;
                color:#ff7413;
                margin-right:70px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/cinema/cinematab_10.png) left top no-repeat;
                background-size:3px 24px;
                text-indent:8px;
            }
        }
        .cinemaList{
            width:1200px;
            overflow:hidden;
            margin:30px auto;
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            flex-wrap:wrap;
            .cinemaSeciton{
                width:519px;
                height:400px;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                .imgWrap{
                    width:290px;
                    height:100%;
                    overflow:hidden;
                    border-radius:10px;
                    background-size:cover;
                    background-position:center center;
                    background-repeat:no-repeat;
                    p{
                        width:90px;
                        height:30px;
                        font-size:16px;
                        color:#fff;
                        line-height:30px;
                        margin:22px 0 0 8px;
                        background:#ff7413;
                        text-align:center;
                        border-radius:3px;
                    }
                }
                .textArea{
                    flex:1;
                    margin-left:30px;
                    
                    p{
                        color:#686868;
                    }
                    .text_title{
                        font-size:20px;                      
                        font-weight:bold;
                    }
                    .text_time{
                        font-size:14px;
                    }
                    .text_actor{
                        font-size:14px;
                    }
                    .text_detail{
                        font-size:16px;
                        line-height:25px;

                    }
                }
            }
        }
    }
    @media(max-width:750px){
        width:7.1rem;
        margin:.5rem auto;
        overflow:hidden;
        .tabs{
            width:6rem;
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            margin-top:.4rem;
            .tab{
                cursor: pointer;
                height:.32rem;
                line-height:.32rem;
                font-size:.32rem;
                color:#848484;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/cinema/cinematab_12.png) left top no-repeat;
                background-size:0.03rem .32rem;
                text-indent:.08rem;
            }
            .tabActive{
                cursor: pointer;
                height:.32rem;
                line-height:.32rem;
                font-size:.32rem;
                color:#ff7413;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/cinema/cinematab_10.png) left top no-repeat;
                background-size:0.03rem .32rem;
                text-indent:.08rem;
            }
        }
        .cinemaList{
            width:7.1rem;
            overflow:hidden;
            margin:.3rem auto;
            .cinemaSeciton{
                width:100%;
                overflow:hidden;
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                align-items:center;
                margin-bottom:.6rem;
                .imgWrap{
                    width:2rem;
                    height:2.37rem;
                    overflow:hidden;
                    border-radius:10px;
                    background-size:cover;
                    background-position:center center;
                    background-repeat:no-repeat;
                    p{
                        width:1.5rem;
                        height:.4rem;
                        font-size:.3rem;
                        color:#fff;
                        line-height:.4rem;
                        margin:.2rem 0 0 0;
                        background:#ff7413;
                        text-align:center;
                        border-radius:.03rem;
                    }
                }
                .textArea{
                    flex:1;
                    margin-left:.2rem;
                    p{
                        color:#686868;
                        margin:.06rem auto;
                    }
                    .text_title{
                        font-size:.3rem;                      
                        font-weight:bold;
                    }
                    .text_time{
                        font-size:.24rem;
                    }
                    .text_actor{
                        font-size:.24rem;
                    }
                    .text_detail{
                        font-size:.24rem;
                        line-height:.33rem;
                    }
                }
            }
        }
    }
`