import styled from 'styled-components'

export const Sdcxcgz = styled.div `
    @media(min-width:750px){
        width:100%;
        overflow:hidden;
        .title_type_1{
            height:32px;
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#00a1b4;
            text-align:center;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:60px auto 30px;
            text-align:center;
            justify-content:center;
            .i_small{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-left:7px;             
            }
            .i_small2{
                width:8px;
                height:8px;
                background:#f55c5d;
            }
            .i_large2{
                width:16px;
                height:16px;
                background:#00a1b4;   
                margin-right:7px;             
            }            
            span{
                padding:10px 40px;
                background:#00a1b4;
                border-radius:5px;
                margin:0 16px;
                color:#fff;
            }
        }
        .post_crumb{
            width:1200px;
            margin:10px auto 0;
            a{
                color:#00a1b4;
            }
        }
        .zzt_header{
            width:1200px;
            height:380px;
            display:flex;
            flex-direction:row;
            border-radius:10px;
            margin:10px auto;
            overflow:hidden;
            position:relative;
            .sw{
                // width:100%;
                // height:100%;
                // position:absolute;
                // left:0;
                // top:0;
                width: 830px;
                height: 380px;
                .swiper-slide img {
                    width: 100%;
                    height: 380px;
                }
            }
            .zzt_header_detail{
                width:370px;
                height:380px;
                padding:0 20px;
                box-sizing:border-box;
                // position:absolute;
                // right:0;
                // top:0;
                // z-index:3;
                background:#40b5ce;
                display:flex;
                flex-direction:column;
                justify-content:center;
                align-items:center;
                .zzt_title{
                    font-size:30px;
                    color:#fff;
                    height:30px;
                    line-height:30px;
                    margin-bottom:20px;
                }
                .zzt_content{
                    font-size:16px;
                    line-height:28px;
                    color:#fff;
                    text-align:justify;
                }
                .zzt_address{
                    width:100%;
                    color:#fff;
                    span{
                        font-weight:bold;
                    }

                }
                .zzt_line{
                    width:100%;
                    height:1px;
                    background:#fff;
                    margin:20px auto;
                }
                .zzt_opentime{
                    width:100%;
                    color:#fff;
                    span{
                        font-weight:bold;
                    }
                }
            }
        }
        .mxdw_wrap{
            width:1200px;
            margin:60px auto;
            display:block;
            border-radius:6px;
            overflow:hidden;
            img{
                width:100%;
            }
        }
        .wqhg_wrap{
            width:1200px;
            overflow:hidden;
            display:flex;
            flex-direction:row;
            margin:0 auto 85px;
            flex-wrap:wrap;
            .wqhg_section{
                width:360px;
                height:290px;
                display:flex;
                flex-direction:column;
                margin-right:60px;
                position: relative;
                .wqhg_section_p{
                    width: 100%;
                    height: 100px;
                    background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
                    background-size: 100% 100%;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: -ms-flexbox;
                    display: flex;
                    padding: 10px 35px;
                    box-sizing: border-box;
                    color: #fff;
                    font-size: 18px;
                    -webkit-align-items: flex-end;
                    -webkit-box-align: flex-end;
                    -ms-flex-align: flex-end;
                    align-items: flex-end;
                }
                .wqhg_section_img_wrap{
                    width:360px;
                    height:240px;
                    border-radius:10px;
                    overflow:hidden;
                    position: absolute;
                    img{
                        width:100%;
                    }
                }
            }
            .wqhg_section:nth-of-type(3n){
                margin-right:0;
            }
        }
    }
    @media(max-width:750px){
        width:100%;
        overflow:hidden;
        .title_type_1{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:.2rem 0;
            text-indent:0;
            .i_small2{
                        width:.16rem;
                        height:.16rem;
                        background:#f55c5d;
            }
            .i_large2{
                        width:.3rem;
                        height:.3rem;
                        background:#00a1b4;   
                        margin-right:.07rem;             
            }            
            span{
                        padding:.1rem .4rem;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 .16rem 0 0.2rem;
                        box-sizing:border-box;
            }
        }
        .zzt_header{
            width:100%;
            overflow:hidden;  
            .sw{
                width:100%;
                height:2.4rem;
                overflow:hidden
            }
            .zzt_header_detail{
                width: 100%;
                background: rgba(40,177,166,.8);
                padding: 0.3rem .47rem;
                margin-top:-.1rem;
                box-sizing: border-box;
                .zzt_title{
                    font-size: .35rem;
                    color: #fff;
                    font-weight: bold;
                    text-align: center;
                }
                .zzt_content{
                    font-size: .26rem;
                    line-height: .38rem;
                    color: #fff;
                    margin-bottom:.3rem;
                }
                .zzt_address{
                    font-size: .26rem;
                    line-height: .38rem;
                    color: #fff;   
                    padding-top:.3rem;
                    border-top:2px solid #fff;                 
                }
                .zzt_opentime{
                    width:4.4rem;
                    height:.35rem;
                    /* display:flex;
                    flex-direction:row; */
                    border-radius:0.06rem;
                    font-size: .26rem;
                    line-height: .38rem;
                    color: #fff;
                    .title{
                        width:1.25rem;
                        height:.31rem;
                        color:#ff7413;
                        float:left;
                        margin:.02rem;
                        background:#fff;
                        line-height:.31rem;
                        text-align:center;
                        border-radius:0.06rem 0 0 .06rem;
                    }
                    .time{
                        float:right;
                        width:3.1rem;
                        height:100%; 
                        color:#fff;
                    }
                }
            }
        }
        .mxdw_wrap{
            width:7.1rem;
            height:1rem;
            display:flex;
            margin:.5rem auto;
            display:flex;
            justify-content:center;
            align-items:center;
            overflow:hidden;
            border-radius:0.1rem;
            img{
                height:100%;
            }
        }
        .wqhg_wrap{
            width:7.1rem;
            margin:0 auto;
            overflow:hidden;
            display:flex;
            flex-direction:row;
            flex-wrap:wrap;
            justify-content:space-between;
            .wqhg_section{
                width:3.5rem;
                height:2.2rem;
                position: relative;    
                border-radius:0.1rem;    
                overflow:hidden;  
                margin-bottom:.2rem;     
                .wqhg_section_p{
                    width:100%;
                    height:1rem;
                    background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) 0 0 no-repeat;
                    background-size:cover;
                    display:flex;
                    align-items:flex-end;
                    color:#fff;
                    font-size:.24rem;
                    padding:.1rem .3rem;
                    box-sizing:border-box;
                    z-index:2;
                    position:absolute;
                    left:0;
                    bottom:0;
                }
                .wqhg_section_img_wrap{
                    width:3.5rem;
                    height:2.2rem;   
                    overflow:hidden;
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    position:absolute;
                    left:0;
                    top:0;                    
                    img{
                        width:100%;
                    }
                }
            }
            .wqhg_section:nth-of-type(3n){
                margin-right:0;
            }
        }        
    }
`
