import React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { MkjkxWrap } from './style'
import CommonCarouselMk from 'common/mkjkxSlide'
import SectionWrap from 'common/newSection'
import { Skeleton } from 'antd';
import NavSection from 'common/navSection'

export const title_type_2 = (font, id, icon,url) => {
    return (
        <NavLink className='title_type_2' to={(url?url:'/list/') + id}>
            {/*<i className='i_small'></i>*/}
            {/*<i className='i_large'></i>*/}
            {/*<i className='i_large2'></i>*/}
            {/*<i className='i_small2'></i>*/}
            <div className='title-icon'>
                <img className='icon' src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/library/mkjkx/`+icon+'.png'} alt="" />
                <span>{font}</span>
            </div>
            <div className='more'>
                <span>查看更多</span>
                <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/library/mkjkx/more.png`} alt="" />
            </div>
        </NavLink>
    )
}
export const title_type_3 = (font, icon) => {
    return (
        <div className='title_type_2'>
            {/*<i className='i_small'></i>*/}
            {/*<i className='i_large'></i>*/}
            {/*<span>{font}</span>*/}
            {/*<i className='i_large2'></i>*/}
            {/*<i className='i_small2'></i>*/}
            <div className='title-icon'>
                <img className='icon' src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/library/mkjkx/`+icon+'.png'} alt="" />
                <span>{font}</span>
            </div>
        </div>
    )
}
class Mkjkx extends React.Component {
    goTo() {
        let scrollToTop = window.setInterval(function () {
            let pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - 10000); // how far to scroll on each step
            } else {
                window.clearInterval(scrollToTop);
            }
        }, 2);
    }
    constructor(props) {
        super(props)
        this.state = {
            mkArr: null,
            currentVideo: null,
            poster: null,
            currentId: null
        }
    }
    initMkjkx() {
        React.$request({
            url:'Portal/Index/germinaceae',
            method:'get',
            params: {
                termid:34
            }
        }).then(res=>{
            this.setState({
                mkArr: res.data,
                currentVideo: res.data.dkmdm[0].video,
                poster: res.data.dkmdm[0].path,
                currentId: res.data.dkmdm[0].id
            })
        })
    }
    componentDidMount() {
        document.title = '科代表讲科学'
        this.initMkjkx()
        this.goTo()
    }
    changeVideo(video, path, id) {
        this.setState({
            currentVideo: video,
            currentId: id,
            poster: path
        })
    }
    render() {
        return (
            this.state.mkArr ?
                <MkjkxWrap>
                    <NavSection />
                    <div className="header">
                        <CommonCarouselMk className='sw' Carouselarr={this.state.mkArr.mkjkxlp} />
                        <div className="header_detail">
                            <h2>
                                <img className='mkjkx-icon' src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/library/mkjkx/mkjkx.png`} alt="" />
                                <span>
                                    {this.state.mkArr.mkjkx[0].post_title}
                                </span>
                                {/*<i className="i_large2"></i>*/}
                                {/*<i className="i_small2"></i>*/}
                            </h2>
                            <p>{this.state.mkArr.mkjkx[0].post_excerpt}</p>
                        </div>
                    </div>

                    {title_type_2('原创科普文章', this.state.mkArr.wwmxd[0].term_id, 'wwmxd','/listtab2/')}
                    <div className="mtbd">
                        <div className="mtbd_wrap">
                            {/*<SectionWrap secarr={item} key={item.id}/>*/}
                            {
                                this.state.mkArr.wwmxd.map((item,index) => {
                                    if (index < 8) {
                                        return (
                                            <div key={item.id} className='hdbd_section'>
                                                <div className="hdbd_section_img_wrap">
                                                    <NavLink to={'/article/'+item.id}>
                                                        <img src={item.path?item.path:`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/header/logodemo_02.jpg`} alt=""/>
                                                        <div className="tag">
                                                            {item.post_keywords}
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className="hdbd_section_detail">
                                                    <NavLink className='hdbd_section_detail_title' to={'/article/'+item.id}>{item.post_title}</NavLink>
                                                    <div className='hdbd_section_date'>{item.post_date}</div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>

                    {title_type_2('30秒懂', this.state.mkArr.ssmd[0].term_id, 'ssmd')}
                    <div className="ssmWrap">
                        {
                            this.state.mkArr.ssmd.map((item, index) => {
                                if (index < 4) {
                                    return (
                                        <NavLink className='dmSection' to={'/article/' + item.id} key={item.id}>
                                            <div className="imgWrap">
                                                <img src={item.path} alt="" />
                                                {
                                                    parseInt(item.istop, 10) > 0 ?
                                                        <div className='zd'>
                                                            <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zd.png`} alt="" />
                                                        </div> : ''
                                                }
                                            </div>
                                            <div className="dmtitle">
                                                {item.post_title}
                                            </div>
                                            <div className="dm-time">
                                                {item.post_date}
                                            </div>
                                        </NavLink>
                                    )
                                }

                            })
                        }
                    </div>

                    {(this.state.mkArr.kdkxcd && this.state.mkArr.kdkxcd.length > 0) ? title_type_2('口袋科学辞典', this.state.mkArr.kdkxcd[0].term_id, 'kdkxcd'):null}
                    {(this.state.mkArr.kdkxcd && this.state.mkArr.kdkxcd.length > 0) ?
                        <div className="kdkxcdWrap">
                            {
                                this.state.mkArr.kdkxcd.map((item,index) => {
                                    if (index < 5) {
                                        return (
                                            <NavLink className='dmSection' to={'/article/' + item.id} key={item.id}>
                                                <div className="imgWrap">
                                                    <img src={item.path} alt="" />
                                                    {
                                                        parseInt(item.istop, 10) > 0 ?
                                                            <div className='zd'>
                                                                <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zd.png`} alt="" />
                                                            </div> : ''
                                                    }
                                                </div>
                                                <div className="dmtitle">
                                                    {item.post_title}
                                                </div>
                                                <div className="dm-time">
                                                    {item.post_date}
                                                </div>
                                            </NavLink>
                                        )
                                    }
                                })
                            }
                        </div>:null
                    }

                    {title_type_2('动漫别错过', this.state.mkArr.dmbcg[0].term_id, 'dmbcg')}
                    <div className="dmbcg">
                        {
                            this.state.mkArr.dmbcg.map((item, index) => {
                                if (index < 6) {
                                    return (
                                        <NavLink className='dmSection' to={'/article/' + item.id} key={item.id}>
                                            <div className="imgWrap">
                                                <img src={item.path} alt="" />
                                                {
                                                    item.post_keywords === '动画' ?
                                                        <div className="tag">
                                                            {item.post_keywords}
                                                        </div> :
                                                        <div className="tag2">
                                                            {item.post_keywords}
                                                        </div>
                                                }
                                                {
                                                    parseInt(item.istop, 10) > 0 ?
                                                        <div className='zd'>
                                                            <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/zd.png`} alt="" />
                                                        </div> : ''
                                                }
                                            </div>
                                            <div className="dmtitle">
                                                <div className='hdbd_section_detail_title'>{item.post_title}</div>
                                                <div className='hdbd_section_date'>{item.post_date}</div>
                                            </div>
                                        </NavLink>
                                    )
                                }
                            })
                        }
                    </div>

                    {(this.state.mkArr.xcjt && this.state.mkArr.xcjt.length > 0) ? title_type_2('小创讲堂', this.state.mkArr.xcjt[0].term_id, 'xcjt'):null}
                    {(this.state.mkArr.xcjt && this.state.mkArr.xcjt.length > 0) ?
                        <div className="xcjt">
                            <div className="xcjt_wrap">
                                {
                                    this.state.mkArr.xcjt.map((item, index) => {
                                        if (index < 8) {
                                            return (
                                                <SectionWrap secarr={item} key={item.id}/>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>:null
                    }


                    {title_type_3('大咖面对面','dkmdm')}
                    <div className="hdhx">
                        <div className="hdhx_wrap">
                            <div className="video">
                                <video src={this.state.currentVideo} controls='controls' poster={this.state.poster}></video>
                            </div>
                            <div className="hdxh_list">
                                {
                                    this.state.mkArr.dkmdm.map((item) => {
                                        return (
                                            <div className={item.id === this.state.currentId ? 'hdhx_link hdhx_link_active' : 'hdhx_link'} key={item.id} onClick={() => this.changeVideo(item.video, item.path, item.id)}>

                                                {item.post_title}
                                                {
                                                    item.id === this.state.currentId?<img className='active-icon' src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/library/mkjkx/active-icon.png`}/>:null
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </MkjkxWrap> : <Skeleton active />
        )
    }
}

export default withRouter(Mkjkx)
