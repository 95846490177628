import styled from 'styled-components'

export const CommunicationWrap = styled.div`
    @media(min-width:750px){
        width:100%;
        overflow:hidden;
        .title_type_1{
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            .i_small2{
                        width:8px;
                        height:8px;
                        background:#f55c5d;
            }
            .i_large2{
                        width:16px;
                        height:16px;
                        background:#00a1b4;   
                        margin-right:7px;             
            }            
            span{
                        padding:10px 25px;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 16px 0 0;
                        box-sizing:border-box;
            }
        }
        .header{
            width:1200px;
            height:380px;
            margin:0 auto 0;
            display:flex;
            flex-direction:row-reverse;
            .sw{
                width:765px;
                height:380px;
                overflow:hidden;
                border-radius:10px;
            }
            .header_content{
                flex:1;
                margin-right:30px;
                p{  
                    font-size:18px;
                    line-height:30px;
                    margin-top:30px;
                    color:#000;
                }
            }
        }
        .tabWrap{
            width:1200px;
            height:40px;
            margin:76px auto 50px;
            display:flex;
            flex-direction:row;
            text-align:center;
            .tab_1{
                width:226px;
                height:40px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_09.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:26px;
                color:#009cbe;
                font-weight:bold;
                line-height:40px;
                display: block;
                text-indent: -27px;
            }
            .tab_2{
                width:258px;
                height:40px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_06.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:26px;
                color:#009cbe;
                font-weight:bold;
                line-height:40px;
                display: block;
                margin-left:-15px;
            }
            .tab_3{
                width:226px;
                height:40px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_16.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:26px;
                color:#009cbe;
                font-weight:bold;
                line-height:40px;
                display: block;
                margin-left:-15px;
                text-indent: 26px;
            }
            .tab_1_active{
                width:226px;
                height:40px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_19.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:26px;
                color:#fff;
                font-weight:bold;
                line-height:40px;
                display: block;
                text-indent: -27px;
            }
            .tab_2_active{
                width:258px;
                height:40px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_03.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:26px;
                color:#fff;
                font-weight:bold;
                line-height:40px;
                display: block;
                margin-left:-15px;
            }
            .tab_3_active{
                width:226px;
                height:40px;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_11.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:26px;
                color:#fff;
                font-weight:bold;
                line-height:40px;
                display: block;
                margin-left:-15px;
                text-indent: 26px;
            }
        }
    }
    @media(max-width:750px){
        width:100%;
        overflow:hidden;
        .title_type_1{
            font-size:.32rem;
            font-weight:bold;
            line-height:.32rem;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            margin:0 auto .3rem;
            text-indent:0;
            .i_small2{
                width:.16rem;
                height:.16rem;
                background:#f55c5d;
            }
            .i_large2{
                width:.3rem;
                height:.3rem;
                background:#00a1b4;   
                margin-right:.07rem;             
            }            
            span{
                padding:.1rem .4rem;
                background:#00a1b4;
                border-radius:5px;
                margin:0 .16rem 0 0;
                box-sizing:border-box;
            }
        }
        .header{
            width:100%;
            overflow:hidden;
            .sw{
                width:100%;
                height:4rem;
                overflow:hidden;
            }
            .header_content{
                width:7.1rem;
                margin:.5rem auto;
                p{  
                    font-size:.28rem;
                    line-height:.45rem;
                    margin-top:.3rem;
                    color:#000;
                }
            }
        }
        .tabWrap{
            width:7.1rem;
            height:.5rem;
            margin:0 auto .5rem;
            display:flex;
            flex-direction:row;
            text-align:center;
            .tab_1{
                width:2.47rem;
                height:.5rem;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_09.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:.35rem;
                color:#009cbe;
                font-weight:bold;
                line-height:.5rem;
                display: block;
                
            }
            .tab_2{
                width:2.6rem;
                height:.5rem;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_06.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:.35rem;
                color:#009cbe;
                font-weight:bold;
                line-height:.5rem;
                display: block;
                margin-left:-0.1rem;
            }
            .tab_3{
                width:2.47rem;
                height:.5rem;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_16.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:.35rem;
                color:#009cbe;
                font-weight:bold;
                line-height:.5rem;
                display: block;
                margin-left:-0.1rem;
            }
            .tab_1_active{
                width:2.47rem;
                height:.5rem;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_19.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:.35rem;
                color:#fff;
                font-weight:bold;
                line-height:.5rem;
                display: block;
                
            }
            .tab_2_active{
                width:2.6rem;
                height:.5rem;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_03.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:.35rem;
                color:#fff;
                font-weight:bold;
                line-height:.5rem;
                display: block;
                margin-left:-0.1rem;
            }
            .tab_3_active{
                width:2.47rem;
                height:.5rem;
                background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/tab/tab_end_11.png) 0 0 no-repeat;
                background-size:100% 100%;
                font-size:.35rem;
                color:#fff;
                font-weight:bold;
                line-height:.5rem;
                display: block;
                margin-left:-0.1rem;
            }
        }
    }


`